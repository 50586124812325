import React from 'react';
import Layout from '../layout/appLayout'; // 引入你的布局组件
import Box from "@cloudscape-design/components/box"; // 引入 Box 组件用于布局
import SpaceBetween from "@cloudscape-design/components/space-between"; // 引入 SpaceBetween 组件用于布局
import Container from "@cloudscape-design/components/container"; // 引入 Container 组件
import './HomePage.css'; // 确保引入了全局样式或特定样式

const HomePage = () => {
  return (
    <Layout headerTitle="">
      <Box padding={{ top: 'm', horizontal: 'l' }}>
        <h1 className="main-title left-align">报价系统</h1>
        <SpaceBetween direction="vertical" size="l">
          <div>
            <h2 className="sub-title">介绍</h2>
            <p className="paragraph">
              报价系统是一款强大的工具，专为简化 AWS 业务开发人员的报价生成而设计。
              它类似于直观的电子商务平台，提供了全面的 AWS 服务供用户探索。通过简单的界面，用户可以将所需的服务添加到购物车中，快速准确地生成可导出的定价信息摘要。
              报价系统包含以下几种服务：EC2、EBS、Redshift、ElastiCache、Aurora 和 RDS。您可以在以下部分找到用户指南。
            </p>
          </div>
          <Container header={<h2 className="sub-title">EC2 (by instance)</h2>}>
            <ul className="paragraph">
              <li>EC2界面分为两个部分，上半部分包括实例筛选器，下半部分是当前区域的实例展示。</li>
              <li>筛选器包括：Region，Instance Family，Purchase Option，Operating System，Preinstalled Software，Memory，vCPU，InstanceType。下方的实例会根据筛选器的条件动态更新。</li>
              <li>可以选择是否为选择的EC2附加EBS。</li>
              <li>下半部分为实例展示区域，实时显示符合条件的EC2实例。</li>
              <li>选中实例之后点击Add to Cart可将实例加入购物车。</li>
            </ul>
          </Container>
          <Container header={<h2 className="sub-title">EC2 available region</h2>}>
            <ul className="paragraph">
              <li>EC2界面分为两个部分，上半部分为实例的搜索，下半部分是模糊匹配所展示的实例和对应的可用的区域。</li>
              <li>实例搜索请输入至少两个字符，例如m5, c4，r7g.xlarge。</li>
            </ul>
          </Container>
          <Container header={<h2 className="sub-title">Redshift</h2>}>
            <ul className="paragraph">
              <li>Redshift界面分为两个部分，上半部分包括实例筛选器和实例信息输入框，下半部分是当前区域的实例展示。</li>
              <li>筛选器包括：Region, Instance Family, vCPU, Memory, Instance Type。下方的实例会根据筛选器的条件动态更新。</li>
              <li>信息输入框包括：Utilization和Nodes数量，报价会根据信息输入变化。</li>
              <li>下半部分为实例展示区域，表格中显示的价格是按需实例价格，用户无需分别选择按需实例和预留实例，最终报价会同时生成按需实例和预留实例价格。</li>
              <li>选中实例之后点击Add to Cart可将实例加入购物车。</li>
            </ul>
          </Container>
          <Container header={<h2 className="sub-title">ElastiCache</h2>}>
            <ul className="paragraph">
              <li>ElastiCache的界面一共分为四个部分，第一个部分为Region，第二个部分为Serverless Settings，第三个部分为Cluster Settings，第四个部分为Data Tier Cluster Settings。</li>
              <li>首先在Region下拉框选择想要获得报价的Region，某些Region(如Hong Kong)没有Data Tier Cluster Settings的选项，该部分选项会直接消失。</li>
              <li>接下来输入想要获得elastiCache报价的各项参数，如果符合报价要求，Add to Cart选项会被点亮。然后点击Add to Cart。</li>
            </ul>
          </Container>
          <Container header={<h2 className="sub-title">Aurora MySQL & Aurora PostgreSQL</h2>}>
            <ul className="paragraph">
              <li>Aurora MySQL和Aurora PostgreSQL的界面分为两个部分，上半部分包括实例筛选器和实例信息输入框，以及Aurora Serverless实例选择部分，下半部分是当前区域的实例展示。</li>
              <li>筛选器包括：Region, Instance Family, vCPU, Memory, Instance Type, Aurora MySQL Cluster Configuration Option。下方的实例会根据筛选器的条件动态更新。</li>
              <li>信息输入框包括：Utilization和Nodes数量，报价会根据信息输入变化。同时还包括了RDS Proxy，Database Storage。</li>
              <li>RDS Proxy：用户可以选择添加或者不添加RDS代理。</li>
              <li>Database Storage：默认设置为0即不添加数据库存储，用户可以选择在Storage Amount输入数量以添加存储。Baseline IO Rate, Peak IO Rate, Duration of Peak IO Activity为数据库存储设置选项。</li>
              <li>Aurora Serverless实例部分包括：Number of Aurora Capacity Units (ACUs)信息输入框，和两个版本选择选项。用户可以选中其中一个版本后可以点击Add to Cart将Serverless实例添加到购物车。</li>
              <li>由于当Aurora MySQL Cluster Configuration Option选择为Aurora I/O-Optimized的时候，不提供Aurora Serverless v1，所以无法选择Aurora Serverless v1。</li>
              <li>下半部分为实例展示区域，表格中显示的价格是按需实例价格，用户无需分别选择按需实例和预留实例，最终报价会同时生成按需实例和预留实例价格。</li>
              <li>选中实例之后点击Add to Cart可将实例加入购物车。</li>
            </ul>
          </Container>
          <Container header={<h2 className="sub-title">RDS for MySQL</h2>}>
            <ul className="paragraph">
              <li>RDS for MySQL 的界面包含四个部分，第一部分包括实例筛选器和实例信息输入框，第二部分包括必选项Utilization、RDS Proxy、Storage，第三部分部分包括额外选项Backup Storage和Snapshot Export，第四部分包括实例展示。</li>
              <li>筛选器包括：Region, Instance Family, Nodes, Deployment Option, vCPU, Memory, Instance Type。下方的实例会根据筛选器的条件动态更新。</li>
              <li>信息输入框包括：Utilization, RDS Proxy，Storage (Throughput & IOPS) 报价会根据信息输入变化。同时还包括了Backup Storage，Snapshot Export。</li>
              <li>RDS Proxy：用户可以选择添加或者不添加RDS代理。</li>
              <li>Database Storage：必选项，用户可以根据引导选择在Storage Amount输入数量以添加存储。Throughput, Provisioning IOPS为数据库存储设置选项。</li>
              <li>Backup Storage: 选择性选项，用户可以根据引导选择在Backup Storage输入数量以添加备用储存</li>
              <li>Snapshot Export:选择性选项，用户可以根据引导选择在Snapshot Export输入数量以添加Snapshot</li>
              <li>请注意：以上必选项须填写准确无误后才可以加入购物车（购物车按钮被点亮），如填写数值在限制之外或缺少正确数值， 页面会有对应的提示，请按照相应的提示填写更改为正确数值</li>
              <li>如在选择性选项中填写数值，请按照提示填写正确的数值以添加购物车</li>
              <li>下半部分为实例展示区域，表格中显示的价格是按需实例价格，用户无需分别选择按需实例和预留实例，最终报价会同时生成按需实例和预留实例价格。</li>
              <li>在选项数值填写无误后，选中实例之后点击Add to Cart可将实例加入购物车。</li>
            </ul>
          </Container>
          <Container header={<h2 className="sub-title">RDS for PostgreSQL</h2>}>
            <ul className="paragraph">
              <li>RDS for PostgreSQL 的界面包含四个部分，第一部分包括实例筛选器和实例信息输入框，第二部分包括必选项Utilization、RDS Proxy、Storage，第三部分部分包括额外选项Backup Storage和Snapshot Export，第四部分包括实例展示。</li>
              <li>筛选器包括：Region, Instance Family, Nodes, Deployment Option, vCPU, Memory, Instance Type。下方的实例会根据筛选器的条件动态更新。</li>
              <li>信息输入框包括：Utilization, RDS Proxy，Storage (Throughput & IOPS) 报价会根据信息输入变化。同时还包括了Backup Storage，Snapshot Export。</li>
              <li>RDS Proxy：用户可以选择添加或者不添加RDS代理。</li>
              <li>Database Storage：必选项，用户可以根据引导选择在Storage Amount输入数量以添加存储。Throughput, Provisioning IOPS为数据库存储设置选项。</li>
              <li>Backup Storage: 选择性选项，用户可以根据引导选择在Backup Storage输入数量以添加备用储存</li>
              <li>Snapshot Export:选择性选项，用户可以根据引导选择在Snapshot Export输入数量以添加Snapshot</li>
              <li>请注意：以上必选项须填写准确无误后才可以加入购物车（购物车按钮被点亮），如填写数值在限制之外或缺少正确数值， 页面会有对应的提示，请按照相应的提示填写更改为正确数值</li>
              <li>如在选择性选项中填写数值，请按照提示填写正确的数值以添加购物车</li>
              <li>下半部分为实例展示区域，表格中显示的价格是按需实例价格，用户无需分别选择按需实例和预留实例，最终报价会同时生成按需实例和预留实例价格。</li>
              <li>在选项数值填写无误后，选中实例之后点击Add to Cart可将实例加入购物车。</li>
            </ul>
          </Container>
          <Container header={<h2 className="sub-title">RDS for MariaDB</h2>}>
            <ul className="paragraph">
              <li>RDS for MariaDB 的界面包含四个部分，第一部分包括实例筛选器和实例信息输入框，第二部分包括必选项Utilization、RDS Proxy、Storage，第三部分部分包括额外选项Backup Storage和Snapshot Export，第四部分包括实例展示。</li>
              <li>筛选器包括：Region, Instance Family, Nodes, Deployment Option, vCPU, Memory, Instance Type。下方的实例会根据筛选器的条件动态更新。</li>
              <li>信息输入框包括：Utilization, RDS Proxy，Storage (Throughput & IOPS) 报价会根据信息输入变化。同时还包括了Backup Storage，Snapshot Export。</li>
              <li>RDS Proxy：用户可以选择添加或者不添加RDS代理。</li>
              <li>Database Storage：必选项，用户可以根据引导选择在Storage Amount输入数量以添加存储。Throughput, Provisioning IOPS为数据库存储设置选项。</li>
              <li>Backup Storage: 选择性选项，用户可以根据引导选择在Backup Storage输入数量以添加备用储存</li>
              <li>Snapshot Export:选择性选项，用户可以根据引导选择在Snapshot Export输入数量以添加Snapshot</li>
              <li>请注意：以上必选项须填写准确无误后才可以加入购物车（购物车按钮被点亮），如填写数值在限制之外或缺少正确数值， 页面会有对应的提示，请按照相应的提示填写更改为正确数值</li>
              <li>如在选择性选项中填写数值，请按照提示填写正确的数值以添加购物车</li>
              <li>下半部分为实例展示区域，表格中显示的价格是按需实例价格，用户无需分别选择按需实例和预留实例，最终报价会同时生成按需实例和预留实例价格。</li>
              <li>在选项数值填写无误后，选中实例之后点击Add to Cart可将实例加入购物车。</li>
            </ul>
          </Container>
          <Container header={<h2 className="sub-title">RDS for Oracle</h2>}>
            <ul className="paragraph">
              <li>RDS for Oracle 的界面包含四个部分，第一部分包括实例筛选器和实例信息输入框，第二部分包括必选项Utilization， Storage，第三部分部分包括额外选项Backup Storage，第四部分包括实例展示。</li>
              <li>筛选器包括：Region, Instance Family, Nodes, Database edition, License, Deployment Option, vCPU, Memory, Instance Type。下方的实例会根据筛选器的条件动态更新。</li>
              <li>信息输入框包括：Utilization, Storage (Throughput & IOPS) 报价会根据信息输入变化。同时还包括了Backup Storage。</li>
              <li>Database Storage：必选项，用户可以根据引导选择在Storage Amount输入数量以添加存储。Throughput, Provisioning IOPS为数据库存储设置选项。</li>
              <li>Backup Storage: 选择性选项，用户可以根据引导选择在Backup Storage输入数量以添加备用储存</li>
              <li>请注意：以上必选项须填写准确无误后才可以加入购物车（购物车按钮被点亮），如填写数值在限制之外或缺少正确数值， 页面会有对应的提示，请按照相应的提示填写更改为正确数值</li>
              <li>如在选择性选项中填写数值，请按照提示填写正确的数值以添加购物车</li>
              <li>下半部分为实例展示区域，表格中显示的价格是按需实例价格，用户无需分别选择按需实例和预留实例，最终报价会同时生成按需实例和预留实例价格。</li>
              <li>在选项数值填写无误后，选中实例之后点击Add to Cart可将实例加入购物车。</li>
            </ul>
          </Container>
          <Container header={<h2 className="sub-title">RDS for SQLServer</h2>}>
            <ul className="paragraph">
              <li>RDS for SQLServer 的界面包含四个部分，第一部分包括实例筛选器和实例信息输入框，第二部分包括必选项Utilization， Storage，第三部分部分包括额外选项Backup Storage，第四部分包括实例展示。</li>
              <li>筛选器包括：Region, Instance Family, Nodes, Database edition, License, Deployment Option, vCPU, Memory, Instance Type。下方的实例会根据筛选器的条件动态更新。</li>
              <li>信息输入框包括：Utilization, Storage (Throughput & IOPS), RDS Proxy 报价会根据信息输入变化。同时还包括了Backup Storage。</li>
              <li>Database Storage：必选项，用户可以根据引导选择在Storage Amount输入数量以添加存储。Throughput, Provisioning IOPS为数据库存储设置选项。</li>
              <li>RDS Proxy：用户可以选择添加或者不添加RDS代理。</li>
              <li>Backup Storage: 选择性选项，用户可以根据引导选择在Backup Storage输入数量以添加备用储存</li>
              <li>请注意：以上必选项须填写准确无误后才可以加入购物车（购物车按钮被点亮），如填写数值在限制之外或缺少正确数值， 页面会有对应的提示，请按照相应的提示填写更改为正确数值</li>
              <li>如在选择性选项中填写数值，请按照提示填写正确的数值以添加购物车</li>
              <li>下半部分为实例展示区域，表格中显示的价格是按需实例价格，用户无需分别选择按需实例和预留实例，最终报价会同时生成按需实例和预留实例价格。</li>
              <li>在选项数值填写无误后，选中实例之后点击Add to Cart可将实例加入购物车。</li>
            </ul>
          </Container>
        </SpaceBetween>
      </Box>
    </Layout>
  );
}

export default HomePage;