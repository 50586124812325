import "./SelectFilter.css";
import React, { useEffect, useState } from "react";
import Layout from "../layout/appLayout";
import { FormField } from '@cloudscape-design/components';
import Table from "@cloudscape-design/components/table";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import TextFilter from "@cloudscape-design/components/text-filter";
import Header from "@cloudscape-design/components/header";
import Pagination from "@cloudscape-design/components/pagination";
import Select from "@cloudscape-design/components/select";
import { connect } from 'react-redux';
import { addToCart } from "../redux/actions/cartActions";
import { Link } from 'react-router-dom';
import Modal from "@cloudscape-design/components/modal";
import { calculateRDSSQLServerPrices } from '../common/RDSforSQLServerPriceCalculating.jsx';

const PaginationSetting = (props) => {
  return (
    <Pagination
      currentPageIndex={props.currentPageIndex}
      onChange={({ detail }) => {
        props.setCurrentPageIndex(detail.currentPageIndex);
        props.onPageChange(detail.currentPageIndex); // 确保调用父组件传递的翻页回调
      }}
      openEnd
      pagesCount={Math.ceil(props.totalItems / props.itemsPerPage)} // 动态计算总页数
    />
  );
};

const regionOptions = [
    { label: "US East (N. Virginia)", value: "US East (N. Virginia)" },
    { label: "US East (Ohio)", value: "US East (Ohio)" },
    { label: "US West (N. California)", value: "US West (N. California)" },
    { label: "US West (Oregon)", value: "US West (Oregon)" },
    { label: "Canada (Central)", value: "Canada (Central)" },
    { label: "Canada West (Calgary)", value: "Canada West (Calgary)" },
    { label: "Africa (Cape Town)", value: "Africa (Cape Town)" },
    { label: "Asia Pacific (Hong Kong)", value: "Asia Pacific (Hong Kong)" },
    { label: "Asia Pacific (Hyderabad)", value: "Asia Pacific (Hyderabad)" },
    { label: "Asia Pacific (Jakarta)", value: "Asia Pacific (Jakarta)" },
    { label: "Asia Pacific (Melbourne)", value: "Asia Pacific (Melbourne)" },
    { label: "Asia Pacific (Mumbai)", value: "Asia Pacific (Mumbai)" },
    { label: "Asia Pacific (Osaka)", value: "Asia Pacific (Osaka)" },
    { label: "Asia Pacific (Seoul)", value: "Asia Pacific (Seoul)" },
    { label: "Asia Pacific (Singapore)", value: "Asia Pacific (Singapore)" },
    { label: "Asia Pacific (Malaysia)", value: "Asia Pacific (Malaysia)" },
    { label: "Asia Pacific (Sydney)", value: "Asia Pacific (Sydney)" },
    { label: "Asia Pacific (Tokyo)", value: "Asia Pacific (Tokyo)" },
    { label: "EU (Frankfurt)", value: "EU (Frankfurt)" },
    { label: "EU (Ireland)", value: "EU (Ireland)" },
    { label: "EU (London)", value: "EU (London)" },
    { label: "EU (Milan)", value: "EU (Milan)" },
    { label: "EU (Paris)", value: "EU (Paris)" },
    { label: "EU (Spain)", value: "EU (Spain)" },
    { label: "EU (Stockholm)", value: "EU (Stockholm)" },
    { label: "EU (Zurich)", value: "EU (Zurich)" },
    { label: "Israel (Tel Aviv)", value: "Israel (Tel Aviv)" },
    { label: "Middle East (Bahrain)", value: "Middle East (Bahrain)" },
    { label: "Middle East (UAE)", value: "Middle East (UAE)" },
    { label: "South America (Sao Paulo)", value: "South America (Sao Paulo)" },
    { label: "AWS GovCloud (US-East)", value: "AWS GovCloud (US-East)" },
    { label: "AWS GovCloud (US)", value: "AWS GovCloud (US)" }
  ];

  const ebsvolumeTypeOptions = [
    { label: "General Purpose SSD (gp2)", value: "General Purpose" },
    { label: "General Purpose SSD (gp3)", value: "General Purpose-GP3" },
    { label: "Provisioned IOPS SSD (io1)", value: "Provisioned IOPS" },
    { label: "Provisioned IOPS SSD (io2)", value: "Provisioned IOPS-IO2" },
    { label: "Magnetic (previous generation)", value: "Magnetic" },
  ];


function RegionFilter(props) {
  return (
    <Select
      selectedAriaLabel="Selected"
      selectedOption={props.selectedOption}
      onChange={({ detail }) => {
        props.setSelectedOption({
          ...detail.selectedOption,
          label: decodeURIComponent(detail.selectedOption.label),
        });
        props.onFilterChange();
      }}
      options={regionOptions}
      ariaDescribedby={null}
      expandToViewport={true}
    />
  );
}



  function InstanceFamilyFilter(props) {
    return (
      <Select
        selectedAriaLabel="Selected"
        selectedOption={props.selectedOption}
        onChange={({ detail }) => {
          props.setSelectedOption(detail.selectedOption);
          props.onFilterChange();
        }}
        options={[
          { label: "All", value: "All" },
          { label: "General purpose", value: "General purpose" },
          { label: "Compute optimized", value: "Compute optimized" },
          { label: "Memory optimized", value: "Memory optimized" },
        ]}
        ariaDescribedby={null}
        expandToViewport={true}
      />
    );
  }

  function DatabaseEditionFilter(props) {
    return (
      <Select
        selectedAriaLabel="Selected"
        selectedOption={props.selectedOption}
        onChange={({ detail }) => {
          props.setSelectedOption(detail.selectedOption);
          props.onFilterChange();
        }}
        options={[
          { label: "Enterprise", value: "Enterprise" },
          { label: "Standard", value: "Standard" },
          { label: "Web", value: "Web" },
          { label: "Express", value: "Express" },
        ]}
        ariaDescribedby={null}
        expandToViewport={true}
      />
    );
  }
  
  function LicenseFilter(props) {
    return (
      <Select
        selectedAriaLabel="Selected"
        selectedOption={props.selectedOption}
        onChange={({ detail }) => {
          props.setSelectedOption(detail.selectedOption);
          props.onFilterChange();
        }}
        options={[
          { label: "Bring your own license", value: "Bring your own license" },
          { label: "License included", value: "License included" },
        ]}
        ariaDescribedby={null}
        expandToViewport={true}
      />
    );
  }

  function DeploymentOptionFilter(props) {
    return (
      <Select
        selectedAriaLabel="Selected"
        selectedOption={props.selectedOption}
        onChange={({ detail }) => {
          props.setSelectedOption(detail.selectedOption);
          props.onFilterChange();
        }}
        options={[
          { label: "Multi-AZ", value: "Multi-AZ" },
          { label: "Single-AZ", value: "Single-AZ" }, // no readable standbys
        ]}
        ariaDescribedby={null}
        expandToViewport={true}
      />
    );
  }

const MemoryFilter = (props) => {
  const [filteringText, setFilteringText] = React.useState(props.filteringText);

  const handleFilterChange = (newFilteringText) => {
    setFilteringText(newFilteringText);
    props.setFilteringText(newFilteringText);
    props.onFilterChange();
  };

  return (
    <TextFilter
      filteringText={filteringText}
      filteringPlaceholder="Memory equals to <input> GiB"
      filteringAriaLabel="Filter Memory"
      onChange={({ detail }) => handleFilterChange(detail.filteringText)}
    />
  );
}

function VCPUFilter(props) {
  const [filteringText, setFilteringText] = React.useState(props.filteringText);

  const handleFilterChange = (newFilteringText) => {
    setFilteringText(newFilteringText);
    props.setFilteringText(newFilteringText);
    props.onFilterChange();
  };

  return (
    <TextFilter
      filteringText={filteringText}
      filteringPlaceholder="Number of vCPU equals to <input>"
      filteringAriaLabel="Filter vCPU"
      onChange={({ detail }) => handleFilterChange(detail.filteringText)}
    />
  );
}


function InstanceTypeFilter(props) {
  const [filteringText, setFilteringText] = React.useState(props.filteringText);

  const handleFilterChange = (newFilteringText) => {
    setFilteringText(newFilteringText);
    props.setFilteringText(newFilteringText);
    props.onFilterChange();
  };

  return (
    <TextFilter
      filteringText={filteringText}
      filteringPlaceholder="Input your instance type"
      filteringAriaLabel="Filter Instance Type"
      onChange={({ detail }) => handleFilterChange(detail.filteringText)}
    />
  );
}

function ItemsPerPageDropdown(props) {
  return (
    <Select
      selectedAriaLabel="Selected"
      selectedOption={{ label: props.itemsPerPage, value: props.itemsPerPage }}
      onChange={({ detail }) => props.setItemsPerPage(detail.selectedOption.value)}
      options={props.itemsPerPageOptions.map(option => ({ label: option, value: option }))}
      ariaDescribedby={null}
      expandToViewport={true}
    />
  );
}

//Filters for Number of Nodes
const NodesFilter = (props) => {
  const getDefaultValue = () => '1';

  const [value, setValue] = React.useState(getDefaultValue());
  const [warningMessage, setWarningMessage] = React.useState('');

  const handleValueChange = ({ detail }) => {
    const newValue = detail.filteringText.replace(/[^0-9]/g, '');
    validateAndNotify(newValue);
    setValue(newValue);
  };

  const validateAndNotify = (newValue) => {
    const numericValue = parseInt(newValue, 10);
    const minLimit = 1;
    const maxLimit = 10000000000000;

    if (isNaN(numericValue)) {
      setWarningMessage(`Nodes can't be less than ${minLimit}.`);
      props.onFilterChange(numericValue);
    } else if (numericValue < minLimit) {
      setWarningMessage(`Nodes can't be less than ${minLimit}.`);
      props.onFilterChange(numericValue);
    } else if (numericValue > maxLimit) {
      setWarningMessage(`Nodes can't be more than ${maxLimit}.`);
      props.onFilterChange(numericValue);
    } else {
      setWarningMessage('');
      props.onFilterChange(numericValue);
    }
  };

  return (
    <div>
      <TextFilter
        filteringText={value}
        filteringPlaceholder="Enter the number of DB instances that you need"
        filteringAriaLabel="Filter Nodes"
        onChange={handleValueChange}
      />
      {/* <div style={{ color: 'gray', marginTop: '0.5rem' }}>
        Enter the number of DB instances that you need.
      </div> */}
      {warningMessage && <div style={{ color: 'red' }}>{warningMessage}</div>}
    </div>
  );
};

//Filters for the RDS Proxy
const RDSProxyFilter = (props) => {
    const handleChange = ({ detail }) => {
      props.onFilterChange(detail.selectedOption);
    };
  
    return (
      <Select
        selectedAriaLabel="Selected"
        selectedOption={props.selectedOption}
        onChange={handleChange}
        options={[
          { label: 'No', value: 'no' },
          { label: 'Yes', value: 'yes' },
        ]}
        ariaDescribedby={null}
        expandToViewport={true}
      />
    );
  };


//Filters for the storage part

const EBSVolumeTypeFilter = (props) => {
  const [selectedOption, setSelectedOption] = React.useState(props.selectedOption);
  const { Region } = props.currentButtonState;

  // List of regions that don't support io2 volume type
  const regionsWithoutIo2 = [
    "Canada West (Calgary)",
    "Africa (Cape Town)",
    "Asia Pacific (Hyderabad)",
    "Asia Pacific (Jakarta)",
    "Asia Pacific (Melbourne)",
    "Asia Pacific (Osaka)",
    "EU (Ireland)",
    "EU (Milan)",
    "EU (Paris)",
    "EU (Spain)",
    "EU (Zurich)",
    "Israel (Tel Aviv)",
    "Middle East (UAE)",
    "South America (Sao Paulo)",
    "AWS GovCloud (US-East)",
    "AWS GovCloud (US)",
  ];

  // Filter out the io2 option if the selected region doesn't support it
  const filteredOptions = ebsvolumeTypeOptions.filter(
      (option) => !(regionsWithoutIo2.includes(Region.label) && option.value === "Provisioned IOPS-IO2")
  );

  const handleChange = ({ detail }) => {
    setSelectedOption(detail.selectedOption);
    props.onFilterChange(detail.selectedOption.value);
  };


  return (
      <Select
          selectedAriaLabel="Selected"
          selectedOption={selectedOption}
          onChange={handleChange}
          options={filteredOptions}
          ariaDescribedby={null}
          expandToViewport={true}
      />
  );
};

//Utilization Filter
const UtilizationFilter = (props) => {
  const getDefaultValue = (unit) => {
    switch (unit) {
      case '%Utilized/Month':
        return '100';
      case 'Hours/Day':
        return '24';
      case 'Hours/Week':
        return '168';
      case 'Hours/Month':
        return '730';
      default:
        return '';
    }
  };

/*   const [value, setValue] = React.useState(props.value); */
  const [value, setValue] = React.useState(getDefaultValue(props.unit));
  const [unit, setUnit] = React.useState(props.unit);
  const [warningMessage, setWarningMessage] = React.useState('');


  const handleValueChange = ({ detail }) => {
    const newValue = detail.filteringText.replace(/[^0-9.]/g, '');
    validateAndNotify(newValue, unit);
    setValue(newValue);
  };

/*   const handleUnitChange = ({ detail }) => {
    const newUnit = detail.selectedOption.value;
    validateAndNotify(value, newUnit);
    setUnit(newUnit);
  }; */

  const handleUnitChange = ({ detail }) => {
    const newUnit = detail.selectedOption.value;
    const defaultValue = getDefaultValue(newUnit);
    setValue(defaultValue);
    setUnit(newUnit);
    validateAndNotify(defaultValue, newUnit);
  };


  const validateAndNotify = (newValue, newUnit) => {
    const { minLimit, maxLimit } = getUtilizationLimits(newUnit);
    const numericValue = parseFloat(newValue);

/*     if (isNaN(numericValue)) {
      setWarningMessage('');
    } else if (numericValue < minLimit) {
      setWarningMessage(`Utilization (On-Demand only) can't be less than ${minLimit}.`);
    } else if (numericValue > maxLimit) {
      setWarningMessage(`Utilization (On-Demand only) can't be greater than ${maxLimit}.`);
    } else {
      setWarningMessage('');
      props.onFilterChange({ value: newValue, unit: newUnit });
    } */

      if (isNaN(numericValue)) {
        setWarningMessage('');
        props.onFilterChange({ value: newValue, unit: newUnit });
      } else if (numericValue < minLimit) {
        setWarningMessage(`Utilization (On-Demand only) can't be less than ${minLimit}.`);
        props.onFilterChange({ value: newValue, unit: newUnit });
      } else if (numericValue > maxLimit) {
        setWarningMessage(`Utilization (On-Demand only) can't be greater than ${maxLimit}.`);
        props.onFilterChange({ value: newValue, unit: newUnit });
      } else {
        setWarningMessage('');
        props.onFilterChange({ value: newValue, unit: newUnit });
      }
  };

  const getUtilizationLimits = (unit) => {
    switch (unit) {
      case '%Utilized/Month':
        return { minLimit: 1, maxLimit: 100 };
      case 'Hours/Day':
        return { minLimit: 1, maxLimit: 24 };
      case 'Hours/Week':
        return { minLimit: 1, maxLimit: 168 };
      case 'Hours/Month':
        return { minLimit: 1, maxLimit: 730 };
      default:
        return { minLimit: 1, maxLimit: 100 };
    }
  };

  return (
    <div /* className="utilization-filter" */ className="EBS-storage-amount-filter">
      <TextFilter
        filteringText={value}
        filteringPlaceholder="Enter value"
        filteringAriaLabel="Filter Utilization"
        onChange={handleValueChange}
      />
      <Select
        selectedAriaLabel="Selected"
        selectedOption={{ label: unit, value: unit }}
        onChange={handleUnitChange}
        options={[
          { label: '%Utilized/Month', value: '%Utilized/Month' },
          { label: 'Hours/Day', value: 'Hours/Day' },
          { label: 'Hours/Week', value: 'Hours/Week' },
          { label: 'Hours/Month', value: 'Hours/Month' },
        ]}
        ariaDescribedby={null}
        expandToViewport={true}
      />
      {warningMessage && <div style={{ color: 'red' }}>{warningMessage}</div>}
    </div>
  );
};


//storage filters
const EBSStorageAmountFilter = (props) => {
  const [filteringText, setFilteringText] = React.useState(props.filteringText.amount);
  const [unit, setUnit] = React.useState(props.filteringText.unit);
  const [warningMessage, setWarningMessage] = React.useState('');

  const volumeType = ebsvolumeTypeOptions.find((option) => option.value === props.volumeType).label;

  const handleUnitChange = ({ detail }) => {
    setUnit(detail.selectedOption.value);
    validateAndNotify({ amount: filteringText, unit: detail.selectedOption.value }, props.volumeType);
  };

  const handleInputChange = ({ detail }) => {
    const numericValue = detail.filteringText.replace(/[^0-9.]/g, ''); // Allow only numbers and decimal points
    setFilteringText(numericValue);
    validateAndNotify({ amount: numericValue, unit }, props.volumeType);
  };

  useEffect(() => {
    validateAndNotify({ amount: filteringText, unit },  props.volumeType);
  }, [filteringText, unit, props.volumeType]);


  const validateAndNotify = (filterValue, volumeType) => {
    const { amount, unit } = filterValue;
    const volumeSizeInGB = convertToGB(amount, unit);

    const minLimit = getMinLimit(volumeType);
    const maxLimit = getMaxLimit(volumeType);

    if (volumeSizeInGB < minLimit) {
      setWarningMessage(`Storage amount per volume can't be less than ${minLimit} GB.`);
      props.onFilterChange({ amount, unit });
    } else if (volumeSizeInGB > maxLimit) {
      setWarningMessage(`Storage amount per volume can't be more than ${maxLimit} GB.`);
      props.onFilterChange({ amount, unit });
    } else {
      setWarningMessage('');
      props.onFilterChange({ amount, unit });
    }
  };

  const convertToGB = (amount, unit) => {
    const numericAmount = parseFloat(amount);
    switch (unit) {
      case 'MB':
        return numericAmount / 1024;
      case 'TB':
        return numericAmount * 1024;
      case 'GB':
      default:
        return numericAmount;
    }
  };

  return (
      <div className="EBS-storage-amount-filter">
        {volumeType === 'General Purpose SSD (gp3)' && (
        <div className="notice-message" style={{ color: 'gray', marginTop: '0.5rem' }}>
          For GP3 Storage volumes, the baseline provisioned IOPS is 3,000 and baseline throughput is 125 MiBps. The ratio Throughput/IOPS can not be greater than 0.25.
        </div>
      )} 
      <div className="input-container">
        <TextFilter
            filteringText={filteringText}
            filteringPlaceholder="Enter amount"
            filteringAriaLabel="Filter Storage Amount"
            onChange={handleInputChange}
        />
        <Select
            selectedAriaLabel="Selected"
            selectedOption={{ label: unit, value: unit }}
            onChange={handleUnitChange}
            options={[
              { label: "MB", value: "MB" },
              { label: "GB", value: "GB" },
              { label: "TB", value: "TB" },
            ]}
            ariaDescribedby={null}
            expandToViewport={true}
        />
        </div>
        {warningMessage && <div style={{ color: 'red' }}>{warningMessage}</div>}
      </div>
  );
};

const getMinLimit = (volumeType) => {
  switch (volumeType) {
    case "General Purpose":
    case "General Purpose-GP3":
    case "Magnetic":
      return 20;
    case "Provisioned IOPS":
    case "Provisioned IOPS-IO2":
      return 100;
    default:
      return 20;
  }
};



const getMaxLimit = (volumeType) => {
  switch (volumeType) {
    case "General Purpose":
    case "General Purpose-GP3":
    case "Provisioned IOPS":
    case "Provisioned IOPS-IO2":
      return 65536;
    case "Magnetic":
      return 3072;
    default:
      return 65536;
  }
};

// Pop up Provisioning IOPS per volume filter
const EBSProvisioningIOPSFilter = (props) => {
  const [filteringText, setFilteringText] = React.useState(props.filteringText);
  const[warningMessage, setWarningMessage] = React.useState('');

  const handleInputChange = ({ detail }) => {
    const numericValue = detail.filteringText.replace(/[^0-9]/g, '');
    const volumeType = ebsvolumeTypeOptions.find((option) => option.value === props.volumeType).label;
    const throughput = parseFloat(props.throughput);
    const maxRatio = 0.25;

    const minLimit = getMinLimit(volumeType);
    const maxLimit = getMaxLimit(volumeType);

    if (numericValue < minLimit) {
      setWarningMessage(`Provisioning IOPS per volume can't be less than ${minLimit}.`);
    } else if (numericValue > maxLimit) {
      setWarningMessage(`Provisioning IOPS per volume can't be more than ${maxLimit}.`);
    } else {
        setWarningMessage('');
      }

    setFilteringText(numericValue);
    props.onFilterChange(numericValue || '');
  };

  const getMinLimit = (volumeType) => {
    switch (volumeType) {
      case 'General Purpose SSD (gp3)':
        return 3000;
      case 'Provisioned IOPS SSD (io1)':
        return 1000;
      case 'Provisioned IOPS SSD (io2)':
        return 1000;
      default:
        return 0;
    }
  };

  const getMaxLimit = (volumeType) => {
    switch (volumeType) {
      case 'General Purpose SSD (gp3)':
        return 16000;
      case 'Provisioned IOPS SSD (io1)':
        return 256000;
      case 'Provisioned IOPS SSD (io2)':
        return 256000;
      default:
        return 0;
    }
  };


  const getExplanation = (volumeType) => {
    switch (volumeType) {
      case 'General Purpose SSD (gp3)':
        return 'gp3 supports 3,000 IOPS included per volume with the option to provision up to maximum of 16,000 IOPS per volume.';
      case 'Provisioned IOPS SSD (io1)':
        return 'io1 supports from 1,000 IOPS to 256,000 IOPS per volume.';
      case 'Provisioned IOPS SSD (io2)':
        return 'io2 supports from 1,000 IOPS to 256,000 IOPS per volume. Only io2 block express supports volumes up to 64 TB and 256,000 IOPS. Available in limited regions.';
      default:
        return '';
    }
  };


  return (
      <div>
        <TextFilter
            filteringText={filteringText}
            filteringPlaceholder="Enter amount"
            filteringAriaLabel={`Filter Provisioning IOPS per volume (${ebsvolumeTypeOptions.find((option) => option.value === props.volumeType).label})`}
            onChange={handleInputChange}
        />
        <div style={{ color: 'gray', marginTop: '0.5rem' }}>
          {getExplanation(ebsvolumeTypeOptions.find((option) => option.value === props.volumeType).label)}
        </div>
        {warningMessage && <div style={{ color: 'red' }}>{warningMessage}</div>}
      </div>
  );
};

// Throughput filter for General Purpose SSD (gp3)
const EBSThroughputFilter = (props) => {
  const [filteringText, setFilteringText] = React.useState(props.filteringText);
  const [warningMessage, setWarningMessage] = React.useState('');

  const handleInputChange = ({ detail }) => {
    const numericValue = detail.filteringText.replace(/[^0-9]/g, ''); // Allow only numbers
    const provisioningIOPS = parseFloat(props.provisioningIOPS);
    const maxRatio = 0.25;

    const minLimit = 125;
    const maxLimit = 1000;

    if (numericValue < minLimit) {
      setWarningMessage('General Purpose SSD (gp3) - Throughput can\'t be less than 125 MiBps.');
    } else if (numericValue > maxLimit) {
      setWarningMessage('General Purpose SSD (gp3) - Throughput can\'t be more than 1000 MiBps.');
    } else {
        setWarningMessage('');
      }

    setFilteringText(numericValue);
    props.onFilterChange(numericValue);
  };

  return (
      <div>
        <TextFilter
            filteringText={filteringText}
            filteringPlaceholder="Enter amount"
            filteringAriaLabel="Filter General Purpose SSD (gp3) - Throughput (MiBps)"
            onChange={handleInputChange}
        />
        <div style={{ color: 'gray', marginTop: '0.5rem' }}>
          gp3 supports a max of 1000 MiBps per volume
        </div>
        {warningMessage && <div style={{ color: 'red' }}>{warningMessage}</div>}
      </div>
  );
};

//Backup Storage Filter
const BackupStorageFilter = (props) => {
  const [filteringText, setFilteringText] = React.useState(props.filteringText);
  const [warningMessage, setWarningMessage] = React.useState('');

  const handleInputChange = ({ detail }) => {
    const newValue = detail.filteringText.replace(/[^0-9]/g, ''); // Allow only numbers
    const isValid = props.validateBackupStorage(newValue);

      if (newValue === '') {
        setWarningMessage('');
      } else if (isNaN(parseInt(newValue, 10))) {
        setWarningMessage('Additional backup storage cannot be in decimal.');
      } else if (!isValid) {
        if (parseInt(newValue, 10) < 0) {
          setWarningMessage('Additional backup storage can\'t be less than 0 GB.');
        } else {
          setWarningMessage('Additional backup storage can\'t be more than 1000000000000 GB.');
        }
      } else {
        setWarningMessage('');
      }

    setFilteringText(newValue);
    props.onFilterChange(newValue);
  };

  return (
    <div>
      <TextFilter
        filteringText={filteringText}
        filteringPlaceholder="Enter additional backup storage (GB)"
        filteringAriaLabel="Filter Backup Storage"
        onChange={handleInputChange}
      />
      {warningMessage && <div style={{ color: 'red' }}>{warningMessage}</div>}
    </div>
  );
};



class SQLServerProductTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentButtonState: {
        Region: {
          label: "US East (N. Virginia)",
          value: "US East (N. Virginia)",
        },
        InstanceFamily: {
          label: "All",
          value: "All",
        },
        DeploymentOption: {
          label: "Multi-AZ",
          value: "Multi-AZ",
        },
        DatabaseEdition: {
            label: "Enterprise",
            value: "Enterprise",
          },
          License: {
            label: "License included",
            value: "License included",
          },
        errorMsg: "",
        showModal: false,
        Memory: "",
        vCPU: "",
        InstanceType: "",
      },
      rdsProxy: {
        label: "No",
        value: "no",
      },
      ebsFilters: {
        volumeType: ebsvolumeTypeOptions[0].value,
        volumeSize: {
          amount: '',
          unit: 'MB',
        },
        provisioningIOPS: '',
        throughput: '',
        backupStorage: '',
        utilization: {
          value: 100,
          unit: '%Utilized/Month',
        },
        nodes: 1,
      },
      allFiltersValid: false,
      selectedItems: [],
      productItems: [],
      allProductItems: [],
      hasNext: false,
      clickButton: true,
      updatePurchaseOption: false,
      paginator: false,
      currentPageIndex: 1,
      itemsPerPageOptions: [10, 25, 50], //Offer Selections of number of instances displayed per page
      itemsPerPage: 10,
    };
    this.nextPage = this.nextPage.bind(this);
    this.handleNextPage = this.handleNextPage.bind(this);
  }


  handleItemsPerPageChange = (newItemsPerPage) => {
    const newPageIndex = this.getCorrectPageIndex(newItemsPerPage);
    this.setState({ itemsPerPage: newItemsPerPage, currentPageIndex: newPageIndex }, () => {
      this.filterProducts();
    });
  };// Add the method handle Items Per Page Change


  handleEBSFiltersChange = (newFilters) => {
    const { ebsFilters, currentButtonState} = this.state;
    const { Region } = currentButtonState;
    this.setState({ ebsFilters: newFilters, selectedRegion: Region.label });

    const isStorageAmountValid = newFilters.volumeSize.amount !== '' && this.validateStorageAmount(newFilters.volumeSize, newFilters.volumeType);
    const isBackupStorageValid = this.validateBackupStorage(newFilters.backupStorage);
    const isUtilizationValid = this.validateUtilization(newFilters.utilization.value, newFilters.utilization.unit);
    const isNodesValid = this.validateNodes(newFilters.nodes);

    const volumeType = newFilters.volumeType;
    let isProvisioningIOPSValid = true;
    let isThroughputValid = true;



      if (['Provisioned IOPS', 'Provisioned IOPS-IO2'].includes(volumeType)) {
        isProvisioningIOPSValid = newFilters.provisioningIOPS !== '' && this.validateProvisioningIOPS(newFilters.provisioningIOPS, volumeType);
      }
    
      if (volumeType === 'General Purpose-GP3') {
        isProvisioningIOPSValid = newFilters.provisioningIOPS !== '' && this.validateProvisioningIOPS(newFilters.provisioningIOPS, volumeType);
        isThroughputValid = newFilters.throughput !== '' && this.validateThroughput(newFilters.throughput);
      }

    const allFiltersValid =
        isStorageAmountValid &&
        isProvisioningIOPSValid &&
        isThroughputValid && 
        isBackupStorageValid &&
        isUtilizationValid &&
        isNodesValid;

    /* this.setState({ allFiltersValid }); */
    this.setState({ allFiltersValid }, () => {
      // Call filterProducts after state update is complete
    this.filterProducts();
    });
  };

validateNodes = (nodes) => {
    const numericValue = parseInt(nodes, 10);
    const minLimit = 1;
    const maxLimit = 10000000000000;

    return !isNaN(numericValue) && numericValue >= minLimit && numericValue <= maxLimit;
  };

validateUtilization = (value, unit) => {
    const numericValue = parseFloat(value);
    const { minLimit, maxLimit } = this.getUtilizationLimits(unit);
  
    return !isNaN(numericValue) && numericValue >= minLimit && numericValue <= maxLimit;
  };
  
  getUtilizationLimits = (unit) => {
    switch (unit) {
      case '%Utilized/Month':
        return { minLimit: 1, maxLimit: 100 };
      case 'Hours/Day':
        return { minLimit: 1, maxLimit: 24 };
      case 'Hours/Week':
        return { minLimit: 1, maxLimit: 168 };
      case 'Hours/Month':
        return { minLimit: 1, maxLimit: 730 };
      default:
        return { minLimit: 1, maxLimit: 100 };
    }
  };

validateStorageAmount = (volumeSize, volumeType) => {
    const { amount, unit } = volumeSize;
    const volumeSizeInGB = this.convertToGB(amount, unit);

    const minLimit = this.getMinLimit(volumeType);
    const maxLimit = this.getMaxLimit(volumeType);

    return volumeSizeInGB >= minLimit && volumeSizeInGB <= maxLimit;
  };
  
 validateProvisioningIOPS = (provisioningIOPS, volumeType) => {
    const numericValue = parseInt(provisioningIOPS, 10);
    const volumeLabel = ebsvolumeTypeOptions.find((option) => option.value === volumeType).label;

    const minLimit = this.getMinIOPSLimit(volumeLabel);
    const maxLimit = this.getMaxIOPSLimit(volumeLabel);


    return numericValue >= minLimit && numericValue <= maxLimit;
  };

  validateThroughput = (throughput) => {
    const numericValue = parseInt(throughput, 10);
    const minLimit = 125;
    const maxLimit = 1000;

    return numericValue >= minLimit && numericValue <= maxLimit;
  };

  convertToGB = (amount, unit) => {
    const numericAmount = parseFloat(amount);
    switch (unit) {
      case 'MB':
        return numericAmount / 1024;
      case 'TB':
        return numericAmount * 1024;
      case 'GB':
      default:
        return numericAmount;
    }
  };
  
  getMinLimit = (volumeType) => {
  switch (volumeType) {
    case "General Purpose":
    case "General Purpose-GP3":
    case "Magnetic":
      return 20;
    case "Provisioned IOPS":
    case "Provisioned IOPS-IO2":
      return 100;
    default:
      return 20;
  }
};



getMaxLimit = (volumeType) => {
  switch (volumeType) {
    case "General Purpose":
    case "General Purpose-GP3":
    case "Provisioned IOPS":
    case "Provisioned IOPS-IO2":
      return 65536;
    case "Magnetic":
      return 3072;
    default:
      return 65536;
  }
};

    getMinIOPSLimit = (volumeType) => {
    switch (volumeType) {
      case 'General Purpose SSD (gp3)':
        return 3000;
      case 'Provisioned IOPS SSD (io1)':
        return 1;
      case 'Provisioned IOPS SSD (io2)':
        return 1000;
      default:
        return 0;
    }
  };

  getMaxIOPSLimit = (volumeType) => {
    switch (volumeType) {
      case 'General Purpose SSD (gp3)':
        return 12000;
      case 'Provisioned IOPS SSD (io1)':
        return 256000;
      case 'Provisioned IOPS SSD (io2)':
        return 256000;
      default:
        return 0;
    }
  };

  validateBackupStorage = (backupStorage) => {
      // If the input is an empty string, consider it as valid
    if (backupStorage === '') {
      return true;
    }
    
    const numericValue = parseInt(backupStorage, 10); // Convert the input to a number
  
    // Check if the input is a valid integer
    if (isNaN(numericValue)) {
      return false;
    }
  
    // Check if the input is within the valid range
    const minLimit = 0;
    const maxLimit = 1000000000000;
    return numericValue >= minLimit && numericValue <= maxLimit;
  };


  getCorrectPageIndex = (newItemsPerPage) => {
    const { filteredLength, currentPageIndex, itemsPerPage } = this.state;
    const currentIndex = (currentPageIndex - 1) * itemsPerPage;

    const newPageIndex = Math.ceil((currentIndex + 1) / newItemsPerPage);
    return Math.min(newPageIndex, Math.ceil(filteredLength / newItemsPerPage));
  };// When the display row changes, the page will jump to the new page that includes the same instances that is displayed previously

  nextPage() {
    const { currentPageIndex } = this.state;
    const nextPageIndex = currentPageIndex + 1;
    this.setState({ currentPageIndex: nextPageIndex }, () => {
      this.filterProducts();
    });
  }

  handleNextPage = (currentPageIndex) => {
    this.setState({ currentPageIndex }, () => {
      this.filterProducts();
    });
  };

  handlePageChange = (pageIndex) => {
    this.setState({ currentPageIndex: pageIndex }, () => {
      this.filterProducts();
    });
  };

  
  shouldShowProvisioningIOPSFilter = () => {
    const volumeType = ebsvolumeTypeOptions.find((option) => option.value === this.state.ebsFilters.volumeType).label;


    return ['General Purpose SSD (gp3)', 'Provisioned IOPS SSD (io1)', 'Provisioned IOPS SSD (io2)'].includes(volumeType);
  };
  
  shouldShowThroughputFilter = () => {
    const volumeType = ebsvolumeTypeOptions.find((option) => option.value === this.state.ebsFilters.volumeType).label;

    return volumeType === 'General Purpose SSD (gp3)';
  };

  generateApiURL() {

    let url = `/pricing/2.0/meteredUnitMaps/rds/USD/current/rds-sqlserver-ondemand.json`;

/*     url = url.replace(/\/+/g, '/'); // 去除多余的斜杠
    url += '/index.json'; */
    console.log("Fetching URL: ", url); // 打印请求URL以进行调试
    return url;
  }



  async fetchData() {
    const url = this.generateApiURL();
    try {
      console.log("Fetching URL: ", url);
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log("Response JSON:", data);
      return data;
    } catch (error) {
      console.error('Fetching error:', error);
      return null;
    }
  }


  //Edited ProcessData so that the current generation and instance data is included
  processData(data) {
    const result_jsonlist = [];
    const regionLabel = decodeURIComponent(this.state.currentButtonState.Region.label);
    let regionData = null;
  
    // Find the matching region data
    for (const region in data.regions) {
      if (region === regionLabel) {
        regionData = data.regions[region];
        break;
      }
    }
  
    if (!regionData) {
      console.error("No data found for the selected region:", regionLabel);
      return;
    }
  
    // Filter out instances that don't match the criteria
    const validInstances = Object.entries(regionData).filter(([key, value]) =>
      key.includes("Database Instance") && key.includes("Current Generation")
    );
  
    // Used to temporarily store price information for each instance type
    const tempResult = {};
  
    for (const [key, item] of validInstances) {
      const instanceType = item["Instance Type"].toLowerCase();
      //Check the Key of each instances if there are matching string
      const instanceFamilyMatch = key.match(/\b(General purpose|Compute optimized|Memory optimized)\b/);
      const instanceFamily = instanceFamilyMatch ? instanceFamilyMatch[0] : ''; // double check
      const deploymentOptionMatch = key.match(/(Single-AZ|Multi-AZ)/);
      const deploymentOption = deploymentOptionMatch ? deploymentOptionMatch[0] : '';
      const databaseEditionMatch = key.match(/(Enterprise|Standard|Web|Express)/);
      const databaseEdition = databaseEditionMatch ? databaseEditionMatch[0] : '';
      const licenseMatch = key.match(/(Bring your own license|License included)/);
      const license = licenseMatch ? licenseMatch[0] : '';

      const uniqueKey = `${instanceType}-${deploymentOption}-${databaseEdition}-${license}`;

        // Initialize the storage structure
        if (!tempResult[uniqueKey]) {
          tempResult[uniqueKey] = {
            instanceType: item["Instance Type"],
            instanceFamily: instanceFamily,
            deploymentOption: deploymentOption,
            databaseEdition: databaseEdition,
            license: license,
            vcpu: item.vCPU,
            memory: item.Memory,
            storage: item.Storage,
            networkPerformance: item["Network Performance"],
            perUnitPrice: item.price,
            fullkey: key,
            region: regionLabel,
          };
        }
    }
  
    // Convert the temporary storage object to a result array
     for (const key in tempResult) {
      result_jsonlist.push(tempResult[key]);
    } 
  
    console.log("Processed data:", result_jsonlist);
    this.setState({ productItems: result_jsonlist, clickButton: false, allProductItems: result_jsonlist });
  }

  filterProducts() {
    const { allProductItems, currentButtonState, currentPageIndex, itemsPerPage } = this.state;
    const filterText = currentButtonState.InstanceType.toLowerCase();
    const instanceFamilyFilter = currentButtonState.InstanceFamily.value.toLowerCase();
    const deploymentOptionFilter = currentButtonState.DeploymentOption.value;
    const databaseEditionFilter = currentButtonState.DatabaseEdition.value;
    const licenseFilter = currentButtonState.License.value;


    const filteredItems = allProductItems.filter(item => {
      const memory = parseFloat(item.memory);
      const vcpu = parseInt(item.vcpu);
      const instanceType = item.instanceType.toLowerCase();
      const instanceFamily = item.instanceFamily.toLowerCase();
      const deploymentOption = item.deploymentOption;
      const databaseEdition = item.databaseEdition;
      const license = item.license;


      return (
        (!currentButtonState.Memory || memory === parseFloat(currentButtonState.Memory)) &&
        (!currentButtonState.vCPU || vcpu === parseInt(currentButtonState.vCPU)) &&
        (filterText === '' || instanceType.includes(filterText)) &&
        (instanceFamilyFilter === 'all' || instanceFamily === instanceFamilyFilter) &&
        (deploymentOption === deploymentOptionFilter) &&
        (databaseEdition === databaseEditionFilter) &&
        (license === licenseFilter)
      );
    });

    const startIndex = (currentPageIndex - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedItems = filteredItems.slice(startIndex, endIndex);

    this.setState({ productItems: paginatedItems, filteredLength: filteredItems.length });
  }


  async getData() {
    const url = this.generateApiURL();
  
    if (!url) {
      this.setState({ errorMsg: "Please input a valid Instance Type in the Instance Type filter (example: t4g.nano)." });
      return;
    }
  
    const data = await this.fetchData();
  
    if (data) {
      this.processData(data);
    }
  }

  handleFilterChange = () => {
    this.setState({ clickButton: true, currentPageIndex: 1, errorMsg: "" }); //when ever the filter is updated, the page is set to 1
  };


  async componentDidMount() {
    await this.getData();
    this.filterProducts();
  }


  async componentDidUpdate(prevProps, prevState) {
    if (this.state.clickButton && (
      prevState.currentButtonState.Region !== this.state.currentButtonState.Region ||
      prevState.currentButtonState.InstanceFamily.value !== this.state.currentButtonState.InstanceFamily.value ||
      prevState.currentButtonState.DeploymentOption.value !== this.state.currentButtonState.DeploymentOption.value ||
      prevState.currentButtonState.License.value !== this.state.currentButtonState.License.value ||
      prevState.currentButtonState.DatabaseEdition.value !== this.state.currentButtonState.DatabaseEdition.value)
    ) {
      console.log('componentDidUpdate prev: ', prevState.currentButtonState.Region);
      console.log('componentDidUpdate curr: ', this.state.currentButtonState.Region);
      await this.getData();
      // this.setState({ clickButton: false });
    }

    if (
/*       prevState.currentButtonState.InstanceFamily.value !== this.state.currentButtonState.InstanceFamily.value || */
      prevState.currentButtonState.Memory !== this.state.currentButtonState.Memory ||
      prevState.currentButtonState.vCPU !== this.state.currentButtonState.vCPU ||
      prevState.currentButtonState.InstanceType !== this.state.currentButtonState.InstanceType ||
      prevState.currentPageIndex !== this.state.currentPageIndex ||
      prevState.allProductItems !== this.state.allProductItems) {
      this.filterProducts();
      // Reset currentPageIndex if it's greater than the new pagesCount
      const pagesCount = Math.ceil(this.state.filteredLength / this.state.itemsPerPage);
      if (this.state.currentPageIndex > pagesCount) {
        this.setState({ currentPageIndex: 1 });
      }
    }
  };


  handleAddCart = () => {
    const selectedItems = this.state.selectedItems.map((item) => {
      const itemWithFilters = {
        ...item,
        rdsProxy: this.state.rdsProxy,
        ebsFilters: this.state.ebsFilters,
      };
  
      return calculateRDSSQLServerPrices(itemWithFilters, this.state.currentButtonState)
        .then((itemObject) => {
          console.log('After calculateRDSSQLServerPrices: ', itemObject);
          // Access the properties of the itemObject here
          // ...
  
          return itemObject;
        })
        .catch((error) => {
          console.error('Error:', error);
          // Handle any errors that occurred during the calculation
        });
    });
  
    Promise.all(selectedItems)
      .then((resolvedItems) => {
        resolvedItems.forEach(item => this.props.addToCart(item));
        this.setState({ selectedItems: [] });
      })
      .catch((error) => {
        console.error('Error:', error);
        // Handle any errors that occurred during Promise.all
      });
  };


    render() {
      const { PurchaseOption } = this.state.currentButtonState;
      const { allFiltersValid } = this.state;
      return (
        <Layout>
          <Modal
            visible={this.state.showModal}
            onDismiss={() => this.setState({ showModal: false })}
            header="Notes"
            footer={
              <Button onClick={() => this.setState({ showModal: false })}>Close</Button>
            }
          >
            <Box>{this.state.errorMsg}</Box>
          </Modal>
          <Table
            onSelectionChange={({ detail }) => {
              const updatedSelectedItems = detail.selectedItems.map(item => ({
                ...item,
                ServiceName: "RDSforSQLServer",
                quantity: 1
              }));
              console.log(updatedSelectedItems);
              this.setState({ selectedItems: updatedSelectedItems });
            }}
            selectedItems={this.state.selectedItems}
            ariaLabels={{
              selectionGroupLabel: "Items selection",
              allItemsSelectionLabel: ({ selectedItems }) =>
                `${selectedItems.length} ${selectedItems.length === 1 ? "item" : "items"} selected`,
              itemSelectionLabel: ({ selectedItems }, item) =>
                item.InstanceType
            }}
            columnDefinitions={[
              {
                id: "instanceType",
                header: "Instance Type",
                cell: item => item.instanceType,
                sortingField: "instanceType",
                isRowHeader: true
              },
              {
                id: "instanceFamily",
                header: "Instance Family",
                cell: item => item.instanceFamily,
              }, 
              {
                id: "deploymentOption",
                header: "Deployment Option",
                cell: item => item.deploymentOption,
              },
              {
                id: "databaseEdition",
                header: "Database Edition",
                cell: item => item.databaseEdition,
              },
              {
                id: "license",
                header: "License",
                cell: item => item.license,
              },
              {
                id: "vcpu",
                header: "vCPU",
                cell: item => item.vcpu,
                sortingField: "vcpu"
              },
              {
                id: "memory",
                header: "Memory",
                cell: item => item.memory,
                sortingField: "memory"
              },
              {
                id: "networkPerformance",
                header: "Network Performance",
                cell: item => item.networkPerformance,
              },
              {
                id: "pricePerUnit",
                header: "OnDemand Price Per Unit",
                cell: item => item.perUnitPrice,
              },
            ]}
            columnDisplay={[
              { id: "instanceType", visible: true },
              { id: "instanceFamily", visible: true}, 
              { id: "deploymentOption", visible: true },
              { id: "databaseEdition", visible: true },
              { id: "license", visible: true },
              { id: "vcpu", visible: true },
              { id: "memory", visible: true },
              { id: "networkPerformance", visible: true },
              { id: "pricePerUnit", visible: true },
            ]}
            enableKeyboardNavigation
            items={this.state.productItems}
            // loading={this.state.productItems.length === 0}
            loadingText="No Instances based on your input"
            selectionType="multi"
            stickyColumns={{ first: 0, last: 1 }}
            trackBy={(item) => `${item.instanceType}-${item.deploymentOption}-${item.databaseEdition}-${item.license}`}

            empty={
              <Box
                margin={{ vertical: "xs" }}
                textAlign="center"
                color="inherit"
              >
                <SpaceBetween size="m">
                  <b>No Instances based on your input</b>
                  {/*<Button>Create resource</Button>*/}
                </SpaceBetween>
              </Box>
            }
            filter={
              <div className="filter-container" key="filter-container">
                <div className="select-filter" key="region-filter">
                  <FormField label="Region">
                    <RegionFilter
                      selectedOption={this.state.currentButtonState.Region}
                      setSelectedOption={selectedOption => this.setState({ currentButtonState: { ...this.state.currentButtonState, Region: selectedOption }, clickButton: true })}
                      onFilterChange={this.handleFilterChange}
                    />
                  </FormField>
                </div>
                <div className="select-filter" key="instance-family-filter">
                <FormField label="Instance Family">
                  <InstanceFamilyFilter
                    selectedOption={this.state.currentButtonState.InstanceFamily}
                    setSelectedOption={(selectedOption) =>
                      this.setState({
                        currentButtonState: {
                          ...this.state.currentButtonState,
                          InstanceFamily: selectedOption,
                        },
                        clickButton: true,
                      })
                    }
                    onFilterChange={this.handleFilterChange}
                  />
                </FormField>
              </div>
              <div className="input-filter" key="nodes-filter">
                <FormField label="Nodes">
                  <NodesFilter
                    filteringText={this.state.ebsFilters.nodes.toString()}
                    setWarningMessage={(message) => this.setState({ warningMessage: message })}
                    onFilterChange={(value) => this.handleEBSFiltersChange({
                      ...this.state.ebsFilters,
                      nodes: parseInt(value, 10),
                    })}
                  />
                </FormField>
              </div>
              <div className="select-filter" key="database-edition-filter">
              <FormField label="Database Edition">
                <DatabaseEditionFilter
                  selectedOption={this.state.currentButtonState.DatabaseEdition}
                  setSelectedOption={(selectedOption) =>
                    this.setState({
                      currentButtonState: {
                        ...this.state.currentButtonState,
                        DatabaseEdition: selectedOption,
                      },
                      clickButton: true,
                    })
                  }
                  onFilterChange={this.handleFilterChange}
                />
              </FormField>
            </div>
            <div className="select-filter" key="license-filter">
              <FormField label="License">
                <LicenseFilter
                  selectedOption={this.state.currentButtonState.License}
                  setSelectedOption={(selectedOption) =>
                    this.setState({
                      currentButtonState: {
                        ...this.state.currentButtonState,
                        License: selectedOption,
                      },
                      clickButton: true,
                    })
                  }
                  onFilterChange={this.handleFilterChange}
                />
              </FormField>
            </div>
              <div className="select-filter" key="deployment-option-filter">
                <FormField label="Deployment Option">
                  <DeploymentOptionFilter
                    selectedOption={this.state.currentButtonState.DeploymentOption}
                    setSelectedOption={(selectedOption) =>
                      this.setState({
                        currentButtonState: {
                          ...this.state.currentButtonState,
                          DeploymentOption: selectedOption,
                        },
                        clickButton: true,
                      })
                    }
                    onFilterChange={this.handleFilterChange}
                  />
                </FormField>
              </div>
              <div className="input-filter" key="memory-filter">
                <FormField label="Memory">
                  <MemoryFilter
                    filteringText={this.state.currentButtonState.Memory}
                    setFilteringText={filteringText => this.setState({ currentButtonState: { ...this.state.currentButtonState, Memory: filteringText }, clickButton: true })}
                    onFilterChange={this.handleFilterChange}
                  />
                </FormField>
              </div>
              <div className="input-filter" key="vcpu-filter">
                <FormField label="vCPU">
                  <VCPUFilter
                    filteringText={this.state.currentButtonState.vCPU}
                    setFilteringText={filteringText => this.setState({ currentButtonState: { ...this.state.currentButtonState, vCPU: filteringText }, clickButton: true })}
                    onFilterChange={this.handleFilterChange}
                  />
                </FormField>
              </div>
              <div className="input-filter" key="instance-type-filter">
                <FormField label="Instance Type">
                  <InstanceTypeFilter
                    filteringText={this.state.currentButtonState.InstanceType}
                    setFilteringText={filteringText => this.setState({ currentButtonState: { ...this.state.currentButtonState, InstanceType: filteringText }, clickButton: true })}
                    onFilterChange={this.handleFilterChange}
                  />
                </FormField>
              </div>
              <Header
        counter=""
        actions={
          <SpaceBetween
            direction="horizontal"
            size="xs"
          >
          </SpaceBetween>
        }
      >
        Utilization (Required)
      </Header>
      <div className="EBS-filter-row">
        <div /* className="utilization-input-filter" key="utilization-filter" */ className="EBS-select-filter" key="utilization-filter">
          <FormField label="Utilization">
            <UtilizationFilter
              value={this.state.ebsFilters.utilization.value}
              unit={this.state.ebsFilters.utilization.unit}
              onFilterChange={(value) => this.handleEBSFiltersChange({
                ...this.state.ebsFilters,
                utilization: value
              })}
            />
          </FormField>
        </div>
      </div>
      <Header
      counter=""
      actions={
        <SpaceBetween
          direction="horizontal"
          size="xs"
        >
        </SpaceBetween>
      }
    >
      RDS Proxy (Required)
    </Header>
    <div className="EBS-filter-row">
      <div className="EBS-select-filter" key="rds-proxy-filter">
        <FormField label="Create an RDS Proxy with the database">
          <RDSProxyFilter
            selectedOption={this.state.rdsProxy}
            onFilterChange={(option) => this.setState({ rdsProxy: option })}
          />
        </FormField>
      </div>
    </div>
              <Header
      counter=""
      actions={
        <SpaceBetween
          direction="horizontal"
          size="xs"
        >
        </SpaceBetween>
      }
    >
      Storage (Required)
    </Header>

    <div className="EBS-filter-row">
      <div className="EBS-select-filter" key="volume-type-filter">
        <FormField label="Storage for each RDS instance">
          <EBSVolumeTypeFilter
            selectedOption={ebsvolumeTypeOptions.find((option) => option.value === this.state.ebsFilters.volumeType)}
            currentButtonState={this.state.currentButtonState}
            onFilterChange={(value) => this.handleEBSFiltersChange({
              ...this.state.ebsFilters,
              volumeType: value
            })}
          />
        </FormField>
      </div>
    </div>

    <div className="EBS-input-filter" key="storage-amount-filter">
      <FormField label="Storage amount">
        <div className="EBS-storage-amount-filter">
          <EBSStorageAmountFilter
            filteringText={this.state.ebsFilters.volumeSize}
            volumeType={this.state.ebsFilters.volumeType}
            onFilterChange={(value) => this.handleEBSFiltersChange({
              ...this.state.ebsFilters,
              volumeSize: value
            })}
          />
        </div>
      </FormField>
    </div>

    <div className="EBS-filter-row">
      {this.shouldShowProvisioningIOPSFilter() && (
        <div className="EBS-input-filter" key="provisioning-iops-filter">
          <FormField label="Provisioning IOPS">
            <EBSProvisioningIOPSFilter
              filteringText={this.state.ebsFilters.provisioningIOPS}
              volumeType={this.state.ebsFilters.volumeType}
              onFilterChange={(value) => this.handleEBSFiltersChange({
                ...this.state.ebsFilters,
                provisioningIOPS: value
              })}
            />
          </FormField>
        </div>
      )}
      {this.shouldShowThroughputFilter() && (
        <div className="EBS-input-filter" key="throughput-filter">
          <FormField label="General Purpose SSD (gp3) - Throughput (MiBps)">
            <EBSThroughputFilter
              filteringText={this.state.ebsFilters.throughput}
              onFilterChange={(value) => this.handleEBSFiltersChange({
                ...this.state.ebsFilters,
                throughput: value
              })}
            />
          </FormField>
        </div>
      )}
    </div> 
    <Header
  counter=""
  actions={
    <SpaceBetween
      direction="horizontal"
      size="xs"
    >
    </SpaceBetween>
  }
>
  Backup Storage (Optional)
</Header>

<div className="EBS-input-filter" key="backup-storage-filter">
  <FormField label="Additional backup storage (GB)">
    <BackupStorageFilter
      filteringText={this.state.ebsFilters.backupStorage}
      validateBackupStorage={this.validateBackupStorage}
      onFilterChange={(value) => this.handleEBSFiltersChange({
        ...this.state.ebsFilters,
        backupStorage: value
      })}
    />
  </FormField>
</div>
            </div>
          }
          header={
            <Header
              counter={
                this.state.selectedItems.length
                  ? "(" + this.state.selectedItems.length + "/" + this.state.filteredLength + ")"
                  : "(" + this.state.filteredLength + ")" //display the entire length of the filtered instances
              }
              actions={
                <SpaceBetween
                  direction="horizontal"
                  size="xs"
                >
                  <Button variant="primary" onClick={this.handleAddCart} disabled={!allFiltersValid}>
                    Add to Cart
                  </Button>
                  <Link to="/cart">
                    <Button variant="primary">
                      Go to Cart
                    </Button>
                  </Link>
                </SpaceBetween>
              }
            >
              Amazon RDSforSQLServer
            </Header>
          }

          pagination={
            <div className="App-pagination">
              <span>Show Rows &nbsp;</span>
              <ItemsPerPageDropdown
                itemsPerPageOptions={this.state.itemsPerPageOptions}
                itemsPerPage={this.state.itemsPerPage}
                setItemsPerPage={this.handleItemsPerPageChange}
              />
              <PaginationSetting
                currentPageIndex={this.state.currentPageIndex}
                setCurrentPageIndex={(currentPageIndex) => this.handleNextPage(currentPageIndex)}
                nextPage={this.nextPage}
                totalItems={this.state.filteredLength}
                itemsPerPage={this.state.itemsPerPage}
                onPageChange={this.handlePageChange}
              />
            </div> //add instance display drop down and the show rows text label
          }
        />
      </Layout>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addToCart: (item) => dispatch(addToCart(item)),
});

export default connect(null, mapDispatchToProps)(SQLServerProductTable);