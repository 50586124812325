import { CART_ADD_ITEM, CART_CLEAR, CART_REMOVE_ITEM, CART_UPDATE_ITEM_QUANTITY } from "../constants/cartConstants";

function cartReducer(state = { cartItems: JSON.parse(localStorage.getItem("cartItems") || "[]") }, action) {
  switch (action.type) {
    case CART_ADD_ITEM:
      const newItem = action.payload;
      const existingItemIndex = state.cartItems.findIndex(item => item.sku === newItem['sku']);

      if (existingItemIndex !== -1) {
        const updatedCartItems = state.cartItems.map((item, index) =>
          index === existingItemIndex ? {
            ...item,
            quantity: item.quantity + newItem.quantity // 仅合并数量
          } : item
        );
        state = { cartItems: updatedCartItems };
      } else {
        state = { cartItems: [...state.cartItems, newItem] };
      }
      return state;
    case CART_REMOVE_ITEM:
      return { cartItems: state.cartItems.filter(x => x['sku'] !== action.payload) };
    case CART_UPDATE_ITEM_QUANTITY:
      return {
        cartItems: state.cartItems.map(item =>
          item['sku'] === action.payload.sku
            ? { ...item, quantity: action.payload.quantity }
            : item
        )
      };
    case CART_CLEAR:
      return { cartItems: [] };
    default:
      return state;
  }
}

export { cartReducer }