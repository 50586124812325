import { parse, v4 as uuidv4 } from 'uuid';

const convertStorageAmountToGB = (amount, unit) => {
    switch (unit) {
        case "TB":
            return amount * 1024;
        case "MB":
            return amount * 0.0009765625;
        case "GB":
        default:
            return amount;
    }
};

const convertIORateToPerHour = (rate, unit) => {
    switch (unit) {
        case "per day":
            return rate / 24;
        case "per week":
            return rate / (24 * 7);
        case "per month":
            return rate / 730;
        case "per second":
            return rate * 3600;
        case "per hour":
        default:
            return rate;
    }
};

export const auroraPostgreSQLCalculatePrices = (item) => {
    console.log('item in price calculating: ', item);

    const nodes = item.nodes;
    const utilizationValue = item.utilizationValue;
    const utilizationUnit = item.utilizationUnit?.value || 'N/A';

    let onDemandPricePerHour = parseFloat(item.terms.OnDemand.price);
    let onDemandPrice = "N/A";
    let upfrontFee = 0;

    switch (utilizationUnit) {
        case "%Utilized/Month":
            onDemandPrice = (nodes * onDemandPricePerHour * (utilizationValue / 100) * 730).toFixed(2);
            break;
        case "Hours/Day":
            onDemandPrice = (nodes * onDemandPricePerHour * (utilizationValue / 24) * 730).toFixed(2);
            break;
        case "Hours/Week":
            onDemandPrice = (nodes * onDemandPricePerHour * (utilizationValue / 168) * 730).toFixed(2);
            break;
        case "Hours/Month":
            onDemandPrice = (nodes * onDemandPricePerHour * utilizationValue).toFixed(2);
            break;
        default:
            break;
    }

    // 不是OD选项，默认OD价格为当前Nodes的百分百使用率价格
    if (onDemandPrice === "N/A") {
        onDemandPrice = (nodes * onDemandPricePerHour * 730).toFixed(2);
    }
    console.log('onDemandPrice:', onDemandPrice);

    let reservedNoUpfrontMonthly = 'N/A';
    let partialUpfrontMonthly = 'N/A';
    let partialUpfrontFee = 'N/A';
    let allUpfrontPrice = 'N/A';

    let RI_All_1yr = ""; // RI 1 all
    let UF_RI_All_1yr = "";
    let RI_All_3yr = ""; // RI 3 all
    let UF_RI_All_3yr = "";
    let RI_Pt_1yr = "";
    let UF_RI_Pt_1yr = "";
    let RI_Pt_3yr = "";
    let UF_RI_Pt_3yr = "";
    let RI_No_1yr = "";
    let UF_RI_No_1yr = "";
    let RI_No_3yr = "";
    let UF_RI_No_3yr = "";
    let RDS_Proxy = "";
    let Database_storage = "";
    let Serverless = "";

    let CP_RI_All_1yr = ""; 
    let CP_RI_All_3yr = ""; 
    let CP_RI_Pt_1yr = ""; 
    let CP_RI_Pt_3yr = "";
    let CP_RI_No_1yr = ""; 
    let CP_RI_No_3yr = ""; 

    const vcpu = parseFloat(item.vcpu);
    const RDS_Proxy_Price_Float = parseFloat(item.RDSProxyPrice?.pricePerHour) || 0.0;
    if (item.RDSProxy === "Yes") {
        RDS_Proxy = nodes * vcpu * 730 * RDS_Proxy_Price_Float;
    } else {
        RDS_Proxy = 0;
    }

    // Database Storage
    const storageAmountGB = convertStorageAmountToGB(parseFloat(item.DatabaseStorage.storageAmount), item.DatabaseStorage.storageUnit);
    const baselineIORatePerHour = convertIORateToPerHour(parseFloat(item.DatabaseStorage.baselineIORate), item.DatabaseStorage.baselineIOUnit);
    const peakIORatePerHour = convertIORateToPerHour(parseFloat(item.DatabaseStorage.peakIORate), item.DatabaseStorage.peakIOUnit);
    const peakIODurationHoursPerMonth = parseFloat(item.DatabaseStorage.durationOfPeakIO);
    const storagePricePerGB = parseFloat(item.DatabaseStoragePrice?.storagePrice) || 0.0;
    const ioPricePerIO = parseFloat(item.DatabaseStoragePrice?.baselineIoPrice) || 0.0;

    const databaseStorageCost = storageAmountGB * storagePricePerGB;

    const baselineHoursPerMonth = 730 - peakIODurationHoursPerMonth;
    const monthlyBaselineIOs = baselineIORatePerHour * baselineHoursPerMonth;
    const baselineIOCost = monthlyBaselineIOs * ioPricePerIO;

    const monthlyPeakIOs = peakIORatePerHour * peakIODurationHoursPerMonth;
    const peakIOCost = monthlyPeakIOs * ioPricePerIO;

    const iosRateCost = baselineIOCost + peakIOCost;
    const totalStorageCost = databaseStorageCost + iosRateCost;
    Database_storage = parseFloat(totalStorageCost).toFixed(2);

    //serverless
    if (item.instanceType === "Aurora Serverless v1" || item.instanceType === "Aurora Serverless v2") {
        const acu = item?.Serverless?.value || 0.0;
        const pricePerACUHour = parseFloat(item.terms.OnDemand.price);
        Serverless = (acu * 730 * pricePerACUHour).toFixed(2);
        onDemandPrice = 0.0;
    }

    //RI
    console.log('price item: ', item);
    if (item.terms && item.terms.Reserved) {
        const terms = item.terms.Reserved;
        console.log('Reserved terms', terms);

        terms.forEach(term => {
            const option = term.purchaseOption;
            let reservedMonthlyPrice = parseFloat(term.hrsPrice) * 730;
            let reservedNoUpfrontMonthly = reservedMonthlyPrice.toFixed(2);
            console.log('Reserved option:', option, 'Class:', term.offeringClass, 'Length:', term.leaseContractLength, 'Price:', reservedNoUpfrontMonthly);

            let leaseLength = "";
            switch (option) {
                case 'No Upfront':
                    reservedNoUpfrontMonthly = (nodes * reservedMonthlyPrice).toFixed(2);
                    if (term.leaseContractLength === '1yr') {
                        RI_No_1yr = reservedNoUpfrontMonthly;
                        UF_RI_No_1yr = 0;
                        CP_RI_No_1yr = (parseFloat(reservedNoUpfrontMonthly) / parseFloat(onDemandPrice)).toFixed(2);
                    } else {
                        RI_No_3yr = reservedNoUpfrontMonthly;
                        UF_RI_No_3yr = 0;
                        CP_RI_No_3yr = (parseFloat(reservedNoUpfrontMonthly) / parseFloat(onDemandPrice)).toFixed(2);
                    }
                    break;
                case 'Partial Upfront':
                    partialUpfrontMonthly = (nodes * reservedMonthlyPrice).toFixed(2);
                    partialUpfrontFee = nodes * parseFloat(term.quantityPrice);
                    leaseLength = term.leaseContractLength === '1yr' ? 1 : 3;
                    const partialUpfrontFeePerMonth = (partialUpfrontFee / (12 * leaseLength)).toFixed(2);
                    const partialTotalMonth = (parseFloat(partialUpfrontMonthly) + parseFloat(partialUpfrontFeePerMonth)).toFixed(2);
                    console.log('Partial Upfront:', partialTotalMonth, 'Fee:', partialUpfrontFee);

                    if (term.leaseContractLength === '1yr') {
                        RI_Pt_1yr = partialTotalMonth;
                        UF_RI_Pt_1yr = partialUpfrontFee;
                        CP_RI_Pt_1yr = (parseFloat(partialTotalMonth) / parseFloat(onDemandPrice)).toFixed(2);
                    } else {
                        RI_Pt_3yr = partialTotalMonth;
                        UF_RI_Pt_3yr = partialUpfrontFee;
                        CP_RI_Pt_3yr = (parseFloat(partialTotalMonth) / parseFloat(onDemandPrice)).toFixed(2);
                    }
                    break;
                case 'All Upfront':
                    allUpfrontPrice = nodes * parseFloat(term.quantityPrice);
                    leaseLength = term.leaseContractLength === '1yr' ? 1 : 3;
                    const allUpfrontPricePerMonth = (allUpfrontPrice / (12 * leaseLength)).toFixed(2);
                    console.log('All Upfront:', allUpfrontPricePerMonth, 'Total:', allUpfrontPrice);

                    if (term.leaseContractLength === '1yr') {
                        RI_All_1yr = allUpfrontPricePerMonth;
                        UF_RI_All_1yr = allUpfrontPrice;
                        CP_RI_All_1yr = (parseFloat(allUpfrontPricePerMonth) / parseFloat(onDemandPrice)).toFixed(2);
                    } else {
                        RI_All_3yr = allUpfrontPricePerMonth;
                        UF_RI_All_3yr = allUpfrontPrice;
                        CP_RI_All_3yr = (parseFloat(allUpfrontPricePerMonth) / parseFloat(onDemandPrice)).toFixed(2);
                    }
                    break;
            }
        });
    }

    const itemObject = {
        sku: uuidv4(),
        sku_details: `${item.instanceType}-${item.regionCode}-${item.operatingSystem}`,
        product: {
            attributes: {
                region: item.regionCode,
                instanceType: item.instanceType,
                vcpu: item.vcpu,
                memory: item.memory,
                storage: item.storage,
                networkPerformance: item.Network,
                operatingSystem: item.operatingSystem,
                clusterConfig: item.clusterConfig
            }
        },
        quantity: 1,
        ServiceName: "AuroraPostgreSQL",
        terms: item.terms,
        upfrontFee: item.upfrontFee,
        Reserved_All_Upfront_1yr_per_Month: RI_All_1yr, // RI 1 all
        UF_Reserved_All_Upfront_1yr: UF_RI_All_1yr, //per month
        Reserved_All_Upfront_3yr_per_Month: RI_All_3yr, // RI 3 all
        UF_Reserved_All_Upfront_3yr: UF_RI_All_3yr,
        Reserved_Partial_Upfront_1yr_per_Month: RI_Pt_1yr,
        UF_Reserved_Partial_Upfront_1yr: UF_RI_Pt_1yr,
        Reserved_Partial_Upfront_3yr_per_Month: RI_Pt_3yr,
        UF_Reserved_Partial_Upfront_3yr: UF_RI_Pt_3yr,
        Reserved_No_Upfront_1yr_per_Month: RI_No_1yr,
        UF_Reserved_No_Upfront_1yr: UF_RI_No_1yr,
        Reserved_No_Upfront_3yr_per_Month: RI_No_3yr,
        UF_Reserved_No_Upfront_3yr: UF_RI_No_3yr,
        OnDemand_1Month: onDemandPrice,
        RDS_Proxy_Price: RDS_Proxy,
        ServerlessPrice: Serverless,
        Total_Storage_Cost: Database_storage,
        Compare_CP_RI_All_1yr: CP_RI_All_1yr, 
        Compare_CP_RI_All_3yr: CP_RI_All_3yr, 
        Compare_CP_RI_Pt_1yr: CP_RI_Pt_1yr, 
        Compare_CP_RI_Pt_3yr: CP_RI_Pt_3yr,
        Compare_CP_RI_No_1yr: CP_RI_No_1yr, 
        Compare_CP_RI_No_3yr: CP_RI_No_3yr 
    };

    console.log('check price calculating page itemObject: ', itemObject);
    return {
        ...itemObject,
    };
}