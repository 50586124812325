import "./SelectFilter.css";
import React, { useEffect, useState } from "react";
import Layout from "../layout/appLayout";
import { FormField } from '@cloudscape-design/components';
import Table from "@cloudscape-design/components/table";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import TextFilter from "@cloudscape-design/components/text-filter";
import Header from "@cloudscape-design/components/header";
import Pagination from "@cloudscape-design/components/pagination";
import Select from "@cloudscape-design/components/select";
import Alert from "@cloudscape-design/components/alert";
import { connect } from 'react-redux';
import { addToCart } from "../redux/actions/cartActions";
import { Link } from 'react-router-dom';
import Modal from "@cloudscape-design/components/modal";
import { calculatePrices } from '../common/PriceCalculating.jsx';

// 页面组件
const PaginationSetting = (props) => {
  return (
    <Pagination
      currentPageIndex={props.currentPageIndex}
      onChange={({ detail }) => {
        props.setCurrentPageIndex(detail.currentPageIndex);
        props.onPageChange(detail.currentPageIndex); // 确保调用父组件传递的翻页回调
      }}
      openEnd
      pagesCount={Math.ceil(props.totalItems / props.itemsPerPage)} // 动态计算总页数
    />
  );
};

// 区域下拉菜单选项
const regionOptions = [
  { label: "US East (N. Virginia)", value: "US%20East%20(N.%20Virginia)" },
  { label: "US East (Ohio)", value: "US%20East%20(Ohio)" },
  { label: "US West (N. California)", value: "US%20West%20(N.%20California)" },
  { label: "US West (Oregon)", value: "US%20West%20(Oregon)" },
  { label: "China (Beijing)", value: "China%20(Beijing)" },
  { label: "China (Ningxia)", value: "China%20(Ningxia)" },
  { label: "Africa (Cape Town)", value: "Africa%20(Cape%20Town)" },
  { label: "Asia Pacific (Hong Kong)", value: "Asia%20Pacific%20(Hong%20Kong)" },
  { label: "Asia Pacific (Hyderabad)", value: "Asia%20Pacific%20(Hyderabad)" },
  { label: "Asia Pacific (Jakarta)", value: "Asia%20Pacific%20(Jakarta)" },
  { label: "Asia Pacific (Melbourne)", value: "Asia%20Pacific%20(Melbourne)" },
  { label: "Asia Pacific (Mumbai)", value: "Asia%20Pacific%20(Mumbai)" },
  { label: "Asia Pacific (Osaka)", value: "Asia%20Pacific%20(Osaka)" },
  { label: "Asia Pacific (Seoul)", value: "Asia%20Pacific%20(Seoul)" },
  { label: "Asia Pacific (Singapore)", value: "Asia%20Pacific%20(Singapore)" },
  { label: "Asia Pacific (Malaysia)", value: "Asia%20Pacific%20(Malaysia)" },
  { label: "Asia Pacific (Sydney)", value: "Asia%20Pacific%20(Sydney)" },
  { label: "Asia Pacific (Tokyo)", value: "Asia%20Pacific%20(Tokyo)" },
  { label: "Canada (Central)", value: "Canada%20(Central)" },
  { label: "Canada West (Calgary)", value: "Canada%20West%20(Calgary)" },
  { label: "EU (Frankfurt)", value: "EU%20(Frankfurt)" },
  { label: "EU (Ireland)", value: "EU%20(Ireland)" },
  { label: "EU (London)", value: "EU%20(London)" },
  { label: "EU (Milan)", value: "EU%20(Milan)" },
  { label: "EU (Paris)", value: "EU%20(Paris)" },
  { label: "EU (Spain)", value: "EU%20(Spain)" },
  { label: "EU (Stockholm)", value: "EU%20(Stockholm)" },
  { label: "EU (Zurich)", value: "EU%20(Zurich)" },
  { label: "Israel (Tel Aviv)", value: "Israel%20(Tel%20Aviv)" },
  { label: "Middle East (Bahrain)", value: "Middle%20East%20(Bahrain)" },
  { label: "Middle East (UAE)", value: "Middle%20East%20(UAE)" },
  { label: "South America (Sao Paulo)", value: "South%20America%20(Sao%20Paulo)" },
];

// 购买选项
const purchaseOptionOptions = [
  { label: "On Demand", value: "OnDemand" },
  { label: "1Yr Standard - no upfront", value: "1StdNUf" },
  { label: "1Yr Convertible - no upfront", value: "1CvtNUf" },
  { label: "1Yr Standard - partial upfront", value: "1StdPUf" },
  { label: "1Yr Convertible - partial upfront", value: "1CvtPUf" },
  { label: "1Yr Standard - all upfront", value: "1StdAUf" },
  { label: "1Yr Convertible - all upfront", value: "1CvtAUf" },
  { label: "3Yr Standard - no upfront", value: "3StdNUf" },
  { label: "3Yr Convertible - no upfront", value: "3CvtNUf" },
  { label: "3Yr Standard - partial upfront", value: "3StdPUf" },
  { label: "3Yr Convertible - partial upfront", value: "3CvtPUf" },
  { label: "3Yr Standard - all upfront", value: "3StdAUf" },
  { label: "3Yr Convertible - all upfront", value: "3CvtAUf" },
  { label: "ComputeSavingsPlans 1 year No Upfront", value: "SpC1Nuf" },
  { label: "ComputeSavingsPlans 1 year Partial Upfront", value: "SpC1Puf" },
  { label: "ComputeSavingsPlans 1 year All Upfront", value: "SpC1Auf" },
  { label: "ComputeSavingsPlans 3 year No Upfront", value: "SpC3Nuf" },
  { label: "ComputeSavingsPlans 3 year Partial Upfront", value: "SpC3Puf" },
  { label: "ComputeSavingsPlans 3 year All Upfront", value: "SpC3Auf" },
  { label: "EC2InstanceSavingsPlans 1 year No Upfront", value: "SpI1Nuf" },
  { label: "EC2InstanceSavingsPlans 1 year Partial Upfront", value: "SpI1Puf" },
  { label: "EC2InstanceSavingsPlans 1 year All Upfront", value: "SpI1Auf" },
  { label: "EC2InstanceSavingsPlans 3 year No Upfront", value: "SpI3Nuf" },
  { label: "EC2InstanceSavingsPlans 3 year Partial Upfront", value: "SpI3Puf" },
  { label: "EC2InstanceSavingsPlans 3 year All Upfront", value: "SpI3Auf" },
];

const operatingSystemOptions = [
  { label: "Linux", value: "Linux" },
  { label: "Windows", value: "Windows" },
  { label: "Ubuntu Pro", value: "Ubuntu Pro" },
  { label: "SUSE", value: "SUSE" },
  { label: "Red Hat Enterprise Linux", value: "RHEL" },
  { label: "Red Hat Enterprise Linux with HA", value: "Red Hat Enterprise Linux with HA" },
];

const preinstalledSoftwareOptions = {
  default: [
    { label: "None", value: "NA" },
    { label: "SQL Enterprise", value: "SQL Ent" },
    { label: "SQL Standard", value: "SQL Std" },
    { label: "SQL Web", value: "SQL Web" },
  ],
  "Red Hat Enterprise Linux with HA": [
    { label: "None", value: "NA" },
    { label: "SQL Enterprise", value: "SQL Ent" },
    { label: "SQL Standard", value: "SQL Std" },
  ]
};

const ebsvolumeTypeOptions = [
  { label: "General Purpose SSD (gp2)", value: "Storage General Purpose gp2 GB Mo" },
  { label: "General Purpose SSD (gp3)", value: "Storage General Purpose gp3 GB Mo" },
  { label: "Provisioned IOPS SSD (io1)", value: "Storage Provisioned IOPS io1 GB Mo" },
  { label: "Provisioned IOPS SSD (io2)", value: "Storage Provisioned IOPS io2 GB month" },
  { label: "Throughput Optimized HDD (st1)", value: "Storage Throughput Optimized HDD st1 GB Mo" },
  { label: "Cold HDD (sc1)", value: "Storage Cold HDD sc1 GB Mo" },
  { label: "Magnetic (standard)", value: "Storage Magnetic standard GB Mo" },
];

const snapshotFrequencyOptions = [
  { label: "No Snapshot Storage", value: "no-snapshot" },
  { label: "Hourly", value: "hourly" },
  { label: "Daily", value: "daily" },
  { label: "2x Daily", value: "2x-daily" },
  { label: "3x Daily", value: "3x-daily" },
  { label: "4x Daily", value: "4x-daily" },
  { label: "6x Daily", value: "6x-daily" },
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" },
];



// 过滤器组件
function RegionFilter(props) {
  return (
    <Select
      selectedAriaLabel="Selected"
      selectedOption={props.selectedOption}
      onChange={({ detail }) => {
        props.setSelectedOption({
          ...detail.selectedOption,
          label: decodeURIComponent(detail.selectedOption.label),
        });
        props.onFilterChange();
      }}
      options={regionOptions}
      ariaDescribedby={null}
      expandToViewport={true}
    />
  );
}

function PurchaseOptionFilter(props) {
  return (
    <Select
      selectedAriaLabel="Selected"
      selectedOption={props.selectedOption}
      onChange={({ detail }) => {
        props.setSelectedOption(detail.selectedOption);
        props.onFilterChange();
      }}
      options={purchaseOptionOptions}
      ariaDescribedby={null}
      expandToViewport={true}
    />
  );
}

function OSFilter(props) {
  return (
    <Select
      selectedAriaLabel="Selected"
      selectedOption={props.selectedOption}
      onChange={({ detail }) => {
        props.setSelectedOption(detail.selectedOption);
        props.onFilterChange();
      }}
      options={operatingSystemOptions}
      ariaDescribedby={null}
      expandToViewport={true}
    />
  );
}

function PreinstalledSoftwareFilter(props) {
  const options = preinstalledSoftwareOptions[props.operatingSystem] || preinstalledSoftwareOptions.default;
  return (
    <Select
      selectedAriaLabel="Selected"
      selectedOption={props.selectedOption}
      onChange={({ detail }) => {
        props.setSelectedOption(detail.selectedOption);
        props.setClickButton(true);
        props.onFilterChange();
      }}
      options={options}
      ariaDescribedby={null}
      expandToViewport={true}
    />
  );
}

function InstanceFamilyFilter(props) {
  return (
    <Select
      selectedAriaLabel="Selected"
      selectedOption={props.selectedOption}
      onChange={({ detail }) => {
        props.setSelectedOption(detail.selectedOption);
        props.onFilterChange();
      }}
      options={[
        { label: "All", value: "All" },
        { label: "General purpose", value: "General purpose" },
        { label: "Compute optimized", value: "Compute optimized" },
        { label: "Memory optimized", value: "Memory optimized" },
        { label: "Storage optimized", value: "Storage optimized" },
        { label: "GPU instance", value: "GPU instance" },
        { label: "FPGA Instances", value: "FPGA Instances" },
        { label: "Machine Learning ASIC Instances", value: "Machine Learning ASIC Instances" },
        { label: "Media Accelerator Instances", value: "Media Accelerator Instances" },
        { label: "Micro instances", value: "Micro instances" },
      ]}
      ariaDescribedby={null}
      expandToViewport={true}
    />
  );
}

const MemoryFilter = (props) => {
  const [filteringText, setFilteringText] = React.useState(props.filteringText);

  const handleFilterChange = (newFilteringText) => {
    setFilteringText(newFilteringText);
    props.setFilteringText(newFilteringText);
    props.onFilterChange();
  };

  return (
    <TextFilter
      filteringText={filteringText}
      filteringPlaceholder="Memory equals to <input> GiB"
      filteringAriaLabel="Filter Memory"
      onChange={({ detail }) => handleFilterChange(detail.filteringText)}
    />
  );
}

function VCPUFilter(props) {
  const [filteringText, setFilteringText] = React.useState(props.filteringText);

  const handleFilterChange = (newFilteringText) => {
    setFilteringText(newFilteringText);
    props.setFilteringText(newFilteringText);
    props.onFilterChange();
  };

  return (
    <TextFilter
      filteringText={filteringText}
      filteringPlaceholder="Number of vCPU equals to <input>"
      filteringAriaLabel="Filter vCPU"
      onChange={({ detail }) => handleFilterChange(detail.filteringText)}
    />
  );
}


function InstanceTypeFilter(props) {
  const [filteringText, setFilteringText] = React.useState(props.filteringText);

  const handleFilterChange = (newFilteringText) => {
    setFilteringText(newFilteringText);
    props.setFilteringText(newFilteringText);
    props.onFilterChange();
  };

  return (
    <TextFilter
      filteringText={filteringText}
      filteringPlaceholder="Input your instance type"
      filteringAriaLabel="Filter Instance Type"
      onChange={({ detail }) => handleFilterChange(detail.filteringText)}
    />
  );
}

function ItemsPerPageDropdown(props) {
  return (
    <Select
      selectedAriaLabel="Selected"
      selectedOption={{ label: props.itemsPerPage, value: props.itemsPerPage }}
      onChange={({ detail }) => props.setItemsPerPage(detail.selectedOption.value)}
      options={props.itemsPerPageOptions.map(option => ({ label: option, value: option }))}
      ariaDescribedby={null}
      expandToViewport={true}
    />
  );
}

const AddEBSFilter = (props) => {
  const options = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
  ];

  return (
    <Select
      selectedAriaLabel="Selected"
      selectedOption={options.find((option) => option.value === props.selectedOption)}
      onChange={({ detail }) => props.onFilterChange(detail.selectedOption.value)}
      options={options}
      ariaDescribedby={null}
      expandToViewport={true}
    />
  );
};

const EBSVolumeTypeFilter = (props) => {
  const [selectedOption, setSelectedOption] = React.useState(props.selectedOption);
  const { Region } = props.currentButtonState;

  // List of regions that don't support io2 volume type
  const regionsWithoutIo2 = [
    "Canada West (Calgary)",
    "Africa (Cape Town)",
    "Asia Pacific (Hyderabad)",
    "Asia Pacific (Jakarta)",
    "Asia Pacific (Malaysia)",
    "Asia Pacific (Melbourne)",
    "Asia Pacific (Osaka)",
    "EU (Ireland)",
    "EU (Milan)",
    "EU (Paris)",
    "EU (Spain)",
    "EU (Zurich)",
    "Israel (Tel Aviv)",
    "Middle East (UAE)",
    "South America (São Paulo)",
    "AWS GovCloud (US-East)",
    "AWS GovCloud (US)",
  ];

  // Filter out the io2 option if the selected region doesn't support it
  const filteredOptions = ebsvolumeTypeOptions.filter(
    (option) => !(regionsWithoutIo2.includes(Region.label) && option.value === "Storage Provisioned IOPS io2 GB month")
  );

  const handleChange = ({ detail }) => {
    setSelectedOption(detail.selectedOption);
    props.onFilterChange(detail.selectedOption.value);
  };


  return (
    <Select
      selectedAriaLabel="Selected"
      selectedOption={selectedOption}
      onChange={handleChange}
      options={filteredOptions}
      ariaDescribedby={null}
      expandToViewport={true}
    />
  );
};


// Number of Volumes filter
const EBSNumberOfVolumesFilter = (props) => {
  const [filteringText, setFilteringText] = React.useState(props.filteringText);
  const [warningMessage, setWarningMessage] = React.useState('');

  const handleInputChange = ({ detail }) => {
    const numericValue = detail.filteringText.replace(/[^0-9.]/g, '');// Allow only numbers and decimal point
    const numericValueWithoutDecimals = numericValue.replace(/\./g, '');

    const minValue = 1;
    const maxValue = 1000000000;

    if (numericValueWithoutDecimals < minValue && numericValueWithoutDecimals !== '') {
      setWarningMessage('Number of Volumes can\'t be less than 1');
    } else if (numericValueWithoutDecimals > maxValue) {
      setWarningMessage('Number of Volumes can\'t be more than 1000000000');
    } else {
      setWarningMessage('');
    }

    setFilteringText(numericValue);
    props.onFilterChange(numericValue || '');
  };

  return (
    <div>
      <TextFilter
        filteringText={filteringText}
        filteringPlaceholder="Enter amount"
        filteringAriaLabel="Filter Number of Volumes"
        onChange={handleInputChange}
      />
      {warningMessage && <div style={{ color: 'red' }}>{warningMessage}</div>}
    </div>
  );
};



// AverageDurationFilter
const EBSAverageDurationFilter = (props) => {
  const [filteringText, setFilteringText] = React.useState(props.filteringText);
  const [warningMessage, setWarningMessage] = React.useState('');

  const handleInputChange = ({ detail }) => {
    const numericValue = detail.filteringText.replace(/[^0-9.]/g, '');// Allow only numbers and decimal point
    const numericValueWithoutDecimals = numericValue.replace(/\./g, '');

    const minValue = 1;
    const maxValue = 730;

    if (numericValueWithoutDecimals < minValue && numericValueWithoutDecimals !== '') {
      setWarningMessage('Average duration each instance runs can\'t be less than 1');
    } else if (numericValueWithoutDecimals > maxValue) {
      setWarningMessage('Average duration each instance runs can\'t be more than 730');
    } else {
      setWarningMessage('');
    }

    /* setFilteringText(numericValue);
    if (numericValue !== '') {
        props.onFilterChange(numericValue);
    } */
    setFilteringText(numericValue);
    props.onFilterChange(numericValue);
  };


  return (
    <div>
      <TextFilter
        filteringText={filteringText}
        filteringPlaceholder="Enter amount"
        filteringAriaLabel="Filter Average Duration"
        onChange={handleInputChange}
      />
      {warningMessage && <div style={{ color: 'red' }}>{warningMessage}</div>}
    </div>
  );
};

// StorageAmountFilter
const EBSStorageAmountFilter = (props) => {
  const [filteringText, setFilteringText] = React.useState(props.filteringText.amount);
  const [unit, setUnit] = React.useState(props.filteringText.unit);
  const [warningMessage, setWarningMessage] = React.useState('');

  const handleUnitChange = ({ detail }) => {
    setUnit(detail.selectedOption.value);
    validateAndNotify({ amount: filteringText, unit: detail.selectedOption.value }, props.volumeType);
  };

  const handleInputChange = ({ detail }) => {
    const numericValue = detail.filteringText.replace(/[^0-9.]/g, ''); // Allow only numbers and decimal points
    setFilteringText(numericValue);
    validateAndNotify({ amount: numericValue, unit }, props.volumeType);
  };

  useEffect(() => {
    validateAndNotify({ amount: filteringText, unit }, props.volumeType);
  }, [filteringText, unit, props.volumeType]);


  const validateAndNotify = (filterValue, volumeType) => {
    const { amount, unit } = filterValue;
    const volumeSizeInGB = convertToGB(amount, unit);

    const minLimit = getMinLimit(volumeType);
    const maxLimit = getMaxLimit(volumeType);

    if (volumeSizeInGB < minLimit) {
      setWarningMessage(`Storage amount per volume can't be less than ${minLimit} GB.`);
      props.onFilterChange({ amount, unit });
    } else if (volumeSizeInGB > maxLimit) {
      setWarningMessage(`Storage amount per volume can't be more than ${maxLimit} GB.`);
      props.onFilterChange({ amount, unit });
    } else {
      setWarningMessage('');
      props.onFilterChange({ amount, unit });
    }
  };

  const convertToGB = (amount, unit) => {
    const numericAmount = parseFloat(amount);
    switch (unit) {
      case 'MB':
        return numericAmount / 1000;
      case 'TB':
        return numericAmount * 1000;
      case 'GB':
      default:
        return numericAmount;
    }
  };

  return (
    <div className="EBS-storage-amount-filter">
      <TextFilter
        filteringText={filteringText}
        filteringPlaceholder="Enter amount"
        filteringAriaLabel="Filter Storage Amount"
        onChange={handleInputChange}
      />
      <Select
        selectedAriaLabel="Selected"
        selectedOption={{ label: unit, value: unit }}
        onChange={handleUnitChange}
        options={[
          { label: "MB", value: "MB" },
          { label: "GB", value: "GB" },
          { label: "TB", value: "TB" },
        ]}
        ariaDescribedby={null}
        expandToViewport={true}
      />
      {warningMessage && <div style={{ color: 'red' }}>{warningMessage}</div>}
    </div>
  );
};



const getMinLimit = (volumeType) => {
  switch (volumeType) {
    case "Storage General Purpose gp2 GB Mo":
    case "Storage General Purpose gp3 GB Mo":
    case "Storage Magnetic standard GB Mo":
      return 1;
    case "Storage Provisioned IOPS io1 GB Mo":
      return 4;
    case "Storage Provisioned IOPS io2 GB month":
      return 4;
    case "Storage Throughput Optimized HDD st1 GB Mo":
    case "Storage Cold HDD sc1 GB Mo":
      return 125;
    default:
      return 1;
  }
};



const getMaxLimit = (volumeType) => {
  switch (volumeType) {
    case "Storage General Purpose gp2 GB Mo":
    case "Storage General Purpose gp3 GB Mo":
    case "Storage Provisioned IOPS io1 GB Mo":
    case "Storage Magnetic standard GB Mo":
      return 16384;
    case "Storage Throughput Optimized HDD st1 GB Mo":
    case "Storage Cold HDD sc1 GB Mo":
      return 16384;
    case "Storage Provisioned IOPS io2 GB month":
      return 65536;
    default:
      return 16384;
  }
};

// Pop up Provisioning IOPS per volume filter
const EBSProvisioningIOPSFilter = (props) => {
  const [filteringText, setFilteringText] = React.useState(props.filteringText);
  const [warningMessage, setWarningMessage] = React.useState('');

  const handleInputChange = ({ detail }) => {
    const numericValue = detail.filteringText.replace(/[^0-9]/g, '');
    const volumeType = ebsvolumeTypeOptions.find((option) => option.value === props.volumeType).label;

    const minLimit = getMinLimit(volumeType);
    const maxLimit = getMaxLimit(volumeType);

    if (numericValue < minLimit) {
      setWarningMessage(`Provisioning IOPS per volume can't be less than ${minLimit}.`);
    } else if (numericValue > maxLimit) {
      setWarningMessage(`Provisioning IOPS per volume can't be more than ${maxLimit}.`);
    } else {
      setWarningMessage('');
    }

    setFilteringText(numericValue);
    props.onFilterChange(numericValue || '');
  };

  const getMinLimit = (volumeType) => {
    switch (volumeType) {
      case 'General Purpose SSD (gp3)':
        return 3000;
      case 'Provisioned IOPS SSD (io1)':
        return 100;
      case 'Provisioned IOPS SSD (io2)':
        return 100;
      default:
        return 0;
    }
  };

  const getMaxLimit = (volumeType) => {
    switch (volumeType) {
      case 'General Purpose SSD (gp3)':
        return 16000;
      case 'Provisioned IOPS SSD (io1)':
        return 64000;
      case 'Provisioned IOPS SSD (io2)':
        return 256000;
      default:
        return 0;
    }
  };


  const getExplanation = (volumeType) => {
    switch (volumeType) {
      case 'General Purpose SSD (gp3)':
        return 'gp3 supports 3,000 IOPS included per volume with the option to provision up to maximum of 16,000 IOPS per volume.';
      case 'Provisioned IOPS SSD (io1)':
        return 'io1 supports from 100 IOPS to 64,000 IOPS per volume.';
      case 'Provisioned IOPS SSD (io2)':
        return 'io2 supports from 100 IOPS to 64,000 IOPS per volume. Only io2 block express supports volumes up to 64 TB and 256,000 IOPS. Available in limited regions.';
      default:
        return '';
    }
  };


  return (
    <div>
      <TextFilter
        filteringText={filteringText}
        filteringPlaceholder="Enter amount"
        filteringAriaLabel={`Filter Provisioning IOPS per volume (${ebsvolumeTypeOptions.find((option) => option.value === props.volumeType).label})`}
        onChange={handleInputChange}
      />
      <div style={{ color: 'gray', marginTop: '0.5rem' }}>
        {getExplanation(ebsvolumeTypeOptions.find((option) => option.value === props.volumeType).label)}
      </div>
      {warningMessage && <div style={{ color: 'red' }}>{warningMessage}</div>}
    </div>
  );
};


// Throughput filter for General Purpose SSD (gp3)
const EBSThroughputFilter = (props) => {
  const [filteringText, setFilteringText] = React.useState(props.filteringText);
  const [warningMessage, setWarningMessage] = React.useState('');

  const handleInputChange = ({ detail }) => {
    const numericValue = detail.filteringText.replace(/[^0-9]/g, ''); // Allow only numbers

    const minLimit = 125;
    const maxLimit = 1000;

    if (numericValue < minLimit) {
      setWarningMessage('General Purpose SSD (gp3) - Throughput can\'t be less than 125 MBps.');
    } else if (numericValue > maxLimit) {
      setWarningMessage('General Purpose SSD (gp3) - Throughput can\'t be more than 1000 MBps.');
    } else {
      setWarningMessage('');
    }

    setFilteringText(numericValue);
    props.onFilterChange(numericValue);
  };

  return (
    <div>
      <TextFilter
        filteringText={filteringText}
        filteringPlaceholder="Enter amount"
        filteringAriaLabel="Filter General Purpose SSD (gp3) - Throughput (MBps)"
        onChange={handleInputChange}
      />
      <div style={{ color: 'gray', marginTop: '0.5rem' }}>
        gp3 supports 125 MB/s included per volume with the option to provision up to a maximum of 1000 MB/s per volume.
      </div>
      {warningMessage && <div style={{ color: 'red' }}>{warningMessage}</div>}
    </div>
  );
};

// SnapshotFrequencyFilter
const EBSSnapshotFrequencyFilter = (props) => {
  const [selectedOption, setSelectedOption] = React.useState(props.selectedOption);

  return (
    <Select
      selectedAriaLabel="Selected"
      selectedOption={selectedOption}
      onChange={({ detail }) => {
        setSelectedOption(detail.selectedOption);
        props.onFilterChange(detail.selectedOption.value);
      }}
      options={snapshotFrequencyOptions}
      ariaDescribedby={null}
      expandToViewport={true}
    />
  );
};

// AmountChangedPerSnapshotFilter
const EBSAmountChangedPerSnapshotFilter = (props) => {
  const [filteringText, setFilteringText] = React.useState(props.filteringText.amount);
  const [unit, setUnit] = React.useState(props.filteringText.unit);
  const [warningMessage, setWarningMessage] = React.useState('');

  const handleUnitChange = ({ detail }) => {
    setUnit(detail.selectedOption.value);
    validateAndNotify({ amount: filteringText, unit: detail.selectedOption.value });
  };

  const handleInputChange = ({ detail }) => {
    const numericValue = detail.filteringText.replace(/[^0-9.]/g, ''); // Allow only numbers and decimal points
    setFilteringText(numericValue);
    validateAndNotify({ amount: numericValue, unit });
  };

  const validateAndNotify = (filterValue) => {
    const { amount, unit } = filterValue;
    const volumeSizeInGB = convertToGB(amount, unit);

    const maxLimit = 16384;

    if (volumeSizeInGB > maxLimit) {
      setWarningMessage(`Amount Changed per Snapshot can't be more than ${maxLimit} GB.`);
      props.onFilterChange({ amount: amount || null, unit });
    } else {
      setWarningMessage('');
      props.onFilterChange({ amount: amount || null, unit });
    }
  };

  const convertToGB = (amount, unit) => {
    const numericAmount = parseFloat(amount);
    switch (unit) {
      case 'MB':
        return numericAmount / 1000;
      case 'TB':
        return numericAmount * 1000;
      case 'GB':
      default:
        return numericAmount;
    }
  };

  return (
    <div className="EBS-amount-changed-per-snapshot-filter">
      <TextFilter
        filteringText={filteringText}
        filteringPlaceholder="Enter amount"
        filteringAriaLabel="Amount Changed per Snapshot"
        onChange={handleInputChange}
      />
      <Select
        selectedAriaLabel="Selected"
        selectedOption={{ label: unit, value: unit }}
        onChange={handleUnitChange}
        options={[
          { label: "MB", value: "MB" },
          { label: "GB", value: "GB" },
          { label: "TB", value: "TB" },
        ]}
        ariaDescribedby={null}
        expandToViewport={true}
      />
      {warningMessage && <div style={{ color: 'red' }}>{warningMessage}</div>}
    </div>
  );
};


// 主Table类
class ProductTable extends React.Component {
  constructor(props) {
    super(props);
    // 状态初始化
    this.state = {
      currentButtonState: {
        Region: {
          label: decodeURIComponent("US%20East%20(N.%20Virginia)"),
          value: "US%20East%20(N.%20Virginia)",
        },
        PurchaseOption: {
          label: "On Demand",
          value: "OnDemand",
        },
        OperatingSystem: {
          label: "Linux",
          value: "Linux",
        },
        PreinstalledSoftware: {
          label: "None",
          value: "NA",
        },
        InstanceFamily: {
          label: "All",
          value: "All",
        },
        errorMsg: "",
        showModal: false,
        LeaseContractLength: "0", // only for RI
        OfferingClass: "", // only for RI
        Upfront: "", // only for RI
        Memory: "",
        vCPU: "",
        InstanceType: "",
      },
      sortByPrice: null,
      isLoading: false,
      selectedItems: [],
      productItems: [],
      allProductItems: [],
      filteredProductItems: [],
      hasNext: false,
      clickButton: true,
      updatePurchaseOption: false,
      paginator: false,
      currentPageIndex: 1,
      itemsPerPageOptions: [10, 25, 50], //Offer Selections of number of instances displayed per page
      itemsPerPage: 10,
      ebsFilters: {
        /*  region: ebsregionOptions[0].value, */
        volumeType: ebsvolumeTypeOptions[0].value,
        numberOfVolumes: '',
        volumeSize: {
          amount: '',
          unit: 'MB',
        },
        averageDuration: '',
        snapshotFrequency: 'no-snapshot',
        amountChangedPerSnapshot: {
          amount: '',
          unit: 'MB',
        },
        provisioningIOPS: '',
        throughput: '',
        totalprice: 0,
      },
      /* selectedRegion: ebsregionOptions[0].value, */ // for the region filter
      allFiltersValid: false, //need to check this part of the code
      /* handleSubmit: null, */
      showEBSFilters: false, //display the EBS filters
      ebsData: null,
      isEBSLoading: true,
      ebsError: null,
    };
    this.nextPage = this.nextPage.bind(this);
    this.handleNextPage = this.handleNextPage.bind(this);
  }

  // 价格排序组件
  handleSortByPrice = () => {
    this.setState(
      prevState => {
        let newSortOrder;
        if (prevState.sortByPrice === "asc") {
          newSortOrder = "desc";
        } else {
          newSortOrder = "asc";
        }
        return { sortByPrice: newSortOrder };
      },
      () => {
        this.sortAndFilterProducts();
      }
    );
  };

  // 排序
  sortAndFilterProducts = () => {
    let sortedItems = [...this.state.allProductItems];
    if (this.state.sortByPrice) {
      sortedItems.sort((a, b) => {
        const priceA = a.terms.OnDemand.price;
        const priceB = b.terms.OnDemand.price;
        if (this.state.sortByPrice === "asc") {
          return priceA - priceB;
        } else {
          return priceB - priceA;
        }
      });
    }
    // 更新排序后
    this.setState({ allProductItems: sortedItems }, () => {
      this.filterProducts();
    });
  };

  /** EBS逻辑 */
  handleSubmit = () => {
    const decodedRegionLabel = decodeURIComponent(this.state.currentButtonState.Region.label);
    const selectedValues = {
      ...this.state.ebsFilters,
      region: decodedRegionLabel,/* ebsregionOptions.find((option) => option.value === this.state.ebsFilters.region).label, */
      volumeType: ebsvolumeTypeOptions.find((option) => option.value === this.state.ebsFilters.volumeType).label,
    };

    console.log('Selected Values:', selectedValues);
    console.log('ebs', this.state.ebsFilters);
  };

  handleShowEBSFilters = (value) => {
    this.setState({ showEBSFilters: value === "Yes" });
    console.log("ebsFilter", this.state.showEBSFilters);
  };


  handleEBSFiltersChange = (newFilters) => {
    const { ebsFilters, currentButtonState } = this.state;
    const { Region } = currentButtonState;
    this.setState({ ebsFilters: newFilters, selectedRegion: Region.label });

    const isNumberOfVolumesValid = newFilters.numberOfVolumes === '' || this.validateNumberOfVolumes(newFilters.numberOfVolumes);
    const isAverageDurationValid = newFilters.averageDuration === '' || this.validateAverageDuration(newFilters.averageDuration);
    const isStorageAmountValid = newFilters.volumeSize.amount !== '' && this.validateStorageAmount(newFilters.volumeSize, newFilters.volumeType);

    const volumeType = newFilters.volumeType;
    let isProvisioningIOPSValid = true;
    let isThroughputValid = true;
    let isAmountChangedPerSnapshotValid = newFilters.snapshotFrequency === 'no-snapshot' || (newFilters.amountChangedPerSnapshot.amount !== '' && this.validateAmountChangedPerSnapshot(newFilters.amountChangedPerSnapshot));

    if (['Storage General Purpose gp3 GB Mo', 'Storage Provisioned IOPS io1 GB Mo', 'Storage Provisioned IOPS io2 GB month'].includes(volumeType)) {
      isProvisioningIOPSValid = newFilters.provisioningIOPS === '' || this.validateProvisioningIOPS(newFilters.provisioningIOPS, volumeType);
    }

    if (volumeType === 'Storage General Purpose gp3 GB Mo') {
      isThroughputValid = newFilters.throughput === '' || this.validateThroughput(newFilters.throughput);
    }

    const allFiltersValid =
      isNumberOfVolumesValid &&
      isAverageDurationValid &&
      isStorageAmountValid &&
      isProvisioningIOPSValid &&
      isThroughputValid &&
      isAmountChangedPerSnapshotValid;

    this.setState({ allFiltersValid });
  };

  validateNumberOfVolumes = (numberOfVolumes) => {
    const numericValue = parseInt(numberOfVolumes, 10);
    const minValue = 1;
    const maxValue = 1000000000;

    return numericValue >= minValue && numericValue <= maxValue;
  };

  validateAverageDuration = (averageDuration) => {
    const numericValue = parseInt(averageDuration, 10);
    const minValue = 1;
    const maxValue = 730;

    return numericValue >= minValue && numericValue <= maxValue;
  };

  validateStorageAmount = (volumeSize, volumeType) => {
    const { amount, unit } = volumeSize;
    const volumeSizeInGB = this.convertToGB(amount, unit);

    const minLimit = this.getMinLimit(volumeType);
    const maxLimit = this.getMaxLimit(volumeType);

    // console.log("max", maxLimit);
    // console.log("min", minLimit)
    // console.log("current", volumeSizeInGB)

    return volumeSizeInGB >= minLimit && volumeSizeInGB <= maxLimit;
  };

  validateProvisioningIOPS = (provisioningIOPS, volumeType) => {
    const numericValue = parseInt(provisioningIOPS, 10);
    const volumeLabel = ebsvolumeTypeOptions.find((option) => option.value === volumeType).label;

    const minLimit = this.getMinIOPSLimit(volumeLabel);
    const maxLimit = this.getMaxIOPSLimit(volumeLabel);

    // console.log("max", maxLimit);
    // console.log("min", minLimit)
    // console.log("current", numericValue)


    return numericValue >= minLimit && numericValue <= maxLimit;
  };

  validateThroughput = (throughput) => {
    const numericValue = parseInt(throughput, 10);
    const minLimit = 125;
    const maxLimit = 1000;

    return numericValue >= minLimit && numericValue <= maxLimit;
  };

  validateAmountChangedPerSnapshot = (amountChangedPerSnapshot) => {
    const { amount, unit } = amountChangedPerSnapshot;
    const volumeSizeInGB = this.convertToGB(amount, unit);

    const maxLimit = 16384;

    return volumeSizeInGB <= maxLimit;
  };

  convertToGB = (amount, unit) => {
    const numericAmount = parseFloat(amount);
    switch (unit) {
      case 'MB':
        return numericAmount / 1000;
      case 'TB':
        return numericAmount * 1000;
      case 'GB':
      default:
        return numericAmount;
    }
  };

  getMinLimit = (volumeType) => {
    switch (volumeType) {
      case "Storage General Purpose gp2 GB Mo":
      case "Storage General Purpose gp3 GB Mo":
      case "Storage Magnetic standard GB Mo":
        return 1;
      case "Storage Provisioned IOPS io1 GB Mo":
        return 4;
      case "Storage Provisioned IOPS io2 GB month":
        return 4;
      case "Storage Throughput Optimized HDD st1 GB Mo":
      case "Storage Cold HDD sc1 GB Mo":
        return 125;
      default:
        return 1;
    }
  };

  getMaxLimit = (volumeType) => {
    switch (volumeType) {
      case "Storage General Purpose gp2 GB Mo":
      case "Storage General Purpose gp3 GB Mo":
      case "Storage Provisioned IOPS io1 GB Mo":
      case "Storage Magnetic standard GB Mo":
        return 16384;
      case "Storage Throughput Optimized HDD st1 GB Mo":
      case "Storage Cold HDD sc1 GB Mo":
        return 16384;
      case "Storage Provisioned IOPS io2 GB month":
        return 65536;
      default:
        return 16384;
    }
  };

  getMinIOPSLimit = (volumeType) => {
    switch (volumeType) {
      case 'General Purpose SSD (gp3)':
        return 3000;
      case 'Provisioned IOPS SSD (io1)':
        return 100;
      case 'Provisioned IOPS SSD (io2)':
        return 100;
      default:
        return 0;
    }
  };

  getMaxIOPSLimit = (volumeType) => {
    switch (volumeType) {
      case 'General Purpose SSD (gp3)':
        return 16000;
      case 'Provisioned IOPS SSD (io1)':
        return 64000;
      case 'Provisioned IOPS SSD (io2)':
        return 256000;
      default:
        return 0;
    }
  };

  shouldShowProvisioningIOPSFilter = () => {
    const volumeType = ebsvolumeTypeOptions.find((option) => option.value === this.state.ebsFilters.volumeType).label;
    return ['General Purpose SSD (gp3)', 'Provisioned IOPS SSD (io1)', 'Provisioned IOPS SSD (io2)'].includes(volumeType);
  };

  shouldShowThroughputFilter = () => {
    const volumeType = ebsvolumeTypeOptions.find((option) => option.value === this.state.ebsFilters.volumeType).label;
    return volumeType === 'General Purpose SSD (gp3)';
  };

  shouldShowAmountChangedPerSnapshotFilter = () => {
    return this.state.ebsFilters.snapshotFrequency !== 'no-snapshot';
  };

  handleItemsPerPageChange = (newItemsPerPage) => {
    const newPageIndex = this.getCorrectPageIndex(newItemsPerPage);
    this.setState({ itemsPerPage: newItemsPerPage, currentPageIndex: newPageIndex }, () => {
      this.filterProducts();
    });
  };// Add the method handle Items Per Page Change


  getCorrectPageIndex = (newItemsPerPage) => {
    const { filteredLength, currentPageIndex, itemsPerPage } = this.state;
    const currentIndex = (currentPageIndex - 1) * itemsPerPage;

    const newPageIndex = Math.ceil((currentIndex + 1) / newItemsPerPage);
    return Math.min(newPageIndex, Math.ceil(filteredLength / newItemsPerPage));
  };// When the display row changes, the page will jump to the new page that includes the same instances that is displayed previously

  nextPage() {
    const { currentPageIndex } = this.state;
    const nextPageIndex = currentPageIndex + 1;
    this.setState({ currentPageIndex: nextPageIndex }, () => {
      this.filterProducts();
    });
  }

  handleNextPage = (currentPageIndex) => {
    this.setState({ currentPageIndex }, () => {
      this.filterProducts();
    });
  };

  handlePageChange = (pageIndex) => {
    this.setState({ currentPageIndex: pageIndex }, () => {
      this.filterProducts();
    });
  };

  // 发起API请求的OD，RI实例URL
  generateODRIApiURL() {
    const { Region, OperatingSystem, PreinstalledSoftware, InstanceType } = this.state.currentButtonState;
    const purchaseOptions = ["OnDemand", "1StdNUf", "1CvtNUf", "1StdPUf", "1CvtPUf", "1StdAUf", "1CvtAUf", "3StdNUf", "3CvtNUf", "3StdPUf", "3CvtPUf", "3StdAUf", "3CvtAUf"];

    const isChinaRegion = Region.value.includes("China");
    const currency = isChinaRegion ? 'CNY' : 'USD';
    const awscn = isChinaRegion ? 'aws-cn' : '';

    const urls = purchaseOptions.map(option => {
      let url = `/pricing/2.0/meteredUnitMaps/${awscn}/ec2/${currency}/current/ec2-calc/${Region.value}/`;

      if (option === 'OnDemand' || OperatingSystem.value === 'Ubuntu Pro') {
        url += `OnDemand/Shared/${OperatingSystem.value}/${PreinstalledSoftware.value === "NA" ? "NA" : encodeURIComponent(PreinstalledSoftware.value)}/No%20License%20required/Yes`;
      } else {
        url += `Reserved/Shared/${OperatingSystem.value}/${PreinstalledSoftware.value === "NA" ? "NA" : encodeURIComponent(PreinstalledSoftware.value)}/No%20License%20required/`;
        const { years, upfront, offeringClass } = this.mapPurchaseOptionToParams(option);
        url += `${years}/${encodeURIComponent(upfront)}/${offeringClass}/Yes`; // 确保Current Generation为Yes
      }

      url = url.replace(/\/+/g, '/'); // 去除多余的斜杠
      url += '/index.json';
      console.log("Fetching ODRI URL: ", url); // 打印请求URL以进行调试
      return url;
    });

    return urls;
  }

  // 发起API请求的Saving Plnas实例URL
  generateSavingPlansApiURL(instanceType = null) {
    const { Region, OperatingSystem, PreinstalledSoftware } = this.state.currentButtonState;
    let { InstanceType } = this.state.currentButtonState;
    if (!InstanceType) {
      if (this.state.selectedItems.length > 0) {
        InstanceType = this.state.selectedItems[0].instanceType;
      } else {
        this.setState({
          errorMsg: "Please select an instance type, or enter an instance type (eg: t4g.nano) in Instance Type Filter to query Saving Plans",
          showModal: true
        });
        return null;
      }
    }

    const isChinaRegion = Region.value.includes("China");
    const currency = isChinaRegion ? 'CNY' : 'USD';
    const awscn = isChinaRegion ? 'aws-cn' : '';

    let url = `/pricing/2.0/meteredUnitMaps/${awscn}/computesavingsplan/${currency}/current/compute-instance-savings-plan-ec2-calc/${InstanceType}/${Region.value}/`;
    url += `${OperatingSystem.value}/${PreinstalledSoftware.value === "NA" ? "NA" : encodeURIComponent(PreinstalledSoftware.value)}/Shared/index.json`;

    url = url.replace(/\/+/g, '/');
    console.log("Fetching Saving Plan URL: ", url);
    return url;
  }

  mapPurchaseOptionToParams(option) {
    let years = "1yr";
    let upfront = "No Upfront";
    let offeringClass = "standard";

    if (option.startsWith("3")) {
      years = "3yr";
    }

    if (option.includes("NUf")) {
      upfront = "No Upfront";
    } else if (option.includes("PUf")) {
      upfront = "Partial Upfront";
    } else if (option.includes("AUf")) {
      upfront = "All Upfront";
    }

    if (option.includes("Cvt")) {
      offeringClass = "convertible";
    }

    return { years, upfront, offeringClass };
  }

  // 并行获取批量URL数据
  async fetchAllData(urls) {
    if(Array.isArray(urls)){
      const promises=[];
      for (const url of urls) {
        promises.push(this.fetchData(url));
      }
      let results = await Promise.all(promises);
      return results;
    }
    else
      return await this.fetchData(urls);
  }

  // 获取单个URL数据
  async fetchData(url) {
    try {
      console.log("Fetching URL: ", url);
      const response = await fetch(url);
      if (!response.ok) {
        if (response.status === 403) {
          console.error('403 Forbidden error: ', url);
          return null; // 如果是 403 错误，返回 null
        }
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log("Response JSON:", data);
      return data;
    } catch (error) {
      console.error('Fetching error:', error);
      return null;
    }
  }

  // 处理接收到的数据
  processAllData(allData) {
    const result_jsonlist = [];
    const regionLabel = decodeURIComponent(this.state.currentButtonState.Region.label);
    const ebsFilters = this.state.ebsFilters;

    // 用于临时存储每个实例类型的价格信息
    const tempResult = {};

    allData.forEach(data => {
      let regionData = null;

      // 查找匹配的区域数据
      for (const region in data.regions) {
        if (region.includes(regionLabel)) {
          regionData = data.regions[region];
          break;
        }
      }

      if (!regionData) {
        console.error("No data found for the selected region:", regionLabel);
        return;
      }

      for (const key in regionData) {
        const item = regionData[key];
        const instanceType = item["Instance Type"]?.toLowerCase() || item["ec2:InstanceType"]?.toLowerCase();

        // 初始化存储结构
        if (!tempResult[instanceType]) {
          tempResult[instanceType] = {
            instanceType: item["Instance Type"] || item["ec2:InstanceType"],
            instanceFamily: item["Instance Family"] || item["InstanceFamily"] || "Unknown",
            regionCode: regionLabel,
            vcpu: item.vCPU || "Unknown",
            memory: item.Memory || "Unknown",
            storage: item.Storage || "Unknown",
            networkPerformance: item["Network Performance"] || "Unknown",
            operatingSystem: item["Operating System"] || item["ec2:OperatingSystem"],
            terms: {
              OnDemand: null,
              Reserved: [],
              SavingPlans: []
            },
            upfrontFee: 0.0,
            perUnitPrice: "N/A",
            ebsselection: ebsFilters,
          };
        }

        if (item.TermType === "Reserved") {
          let reservedTerm = tempResult[instanceType].terms.Reserved.find(term =>
            term.purchaseOption === item["PurchaseOption"] &&
            term.offeringClass === item["OfferingClass"] &&
            term.leaseContractLength === item["LeaseContractLength"]
          );

          if (!reservedTerm) {
            reservedTerm = {
              purchaseOption: item["PurchaseOption"],
              offeringClass: item["OfferingClass"],
              leaseContractLength: item["LeaseContractLength"],
              hrsPrice: null,
              quantityPrice: null
            };
            tempResult[instanceType].terms.Reserved.push(reservedTerm);
          }

          if (item.Unit === "Hrs") {
            reservedTerm.hrsPrice = item.price;
          }

          if (item.Unit === "Quantity") {
            reservedTerm.quantityPrice = item.price;
          }
        } else if (item.TermType === "OnDemand") {
          tempResult[instanceType].terms.OnDemand = { price: item.price };
        } else if (item["ec2:InstanceType"]) {
          tempResult[instanceType].terms.SavingPlans.push({
            savingPlans: key,
            purchaseOption: item["PurchaseOption"],
            leaseContractLength: item["LeaseContractLength"],
            price: item["price"],
            pricePerUnit: item["ec2:PricePerUnit"]
          });
        }

      }
    });

    // 将临时存储对象转换为结果数组
    for (const key in tempResult) {
      result_jsonlist.push(tempResult[key]);
    }

    console.log("Processed all data:", result_jsonlist);
    this.setState({ productItems: result_jsonlist, clickButton: false, allProductItems: result_jsonlist });
  }

  // EBS价格计算逻辑
  calculateEbsPrice = () => {
    const { volumeType, numberOfVolumes, volumeSize } = this.state.ebsFilters;
    let price = 0;
    // 默认值设定
    this.state.ebsFilters.numberOfVolumes = 1;
    this.state.ebsFilters.averageDuration = 730;
    if (this.state.ebsFilters.provisioningIOPS === '' && volumeType === 'Storage General Purpose gp3 GB Mo') {
      this.state.ebsFilters.provisioningIOPS = 3000;
    } else if (this.state.ebsFilters.provisioningIOPS === '' && (volumeType === 'Storage Provisioned IOPS io1 GB Mo' || volumeType === 'Storage Provisioned IOPS io2 GB month')) {
      this.state.ebsFilters.provisioningIOPS = '';
    }
    if (this.state.ebsFilters.throughput === '' && volumeType === 'Storage General Purpose gp3 GB Mo') {
      this.state.ebsFilters.throughput = 125;
    }

    const sizeInGb = this.convertToGB(volumeSize.amount, volumeSize.unit);
    const sizeInGbSnap = this.convertToGB(this.state.ebsFilters.amountChangedPerSnapshot.amount, this.state.ebsFilters.amountChangedPerSnapshot.unit);

    // 检查 ebsData 和 regions 是否存在
    if (!this.state.ebsData || !this.state.ebsData.regions) {
      console.error('ebsData 或 regions 未定义');
      return 0;
    }

    const regionData = this.state.ebsData.regions[this.state.currentButtonState.Region.label];

    // 检查 regionData 是否存在
    if (!regionData) {
      console.error(`未找到区域数据: ${this.state.currentButtonState.Region.label}`);
      return 0;
    }

    // 检查 volumeType 是否存在于 regionData 中
    if (!regionData[volumeType]) {
      console.error(`未找到 volumeType 数据: ${volumeType}`);
      return 0;
    }

    price = regionData[volumeType].price;

    price = price * this.state.ebsFilters.numberOfVolumes * this.state.ebsFilters.averageDuration / 730 * sizeInGb;

    let snapshotPrice = regionData["Storage Snapshot Amazon S3 GB Mo"] ? regionData["Storage Snapshot Amazon S3 GB Mo"].price : 0;
    let snapshotFreq = 0;

    if (sizeInGbSnap != 0) {
      price += parseFloat(snapshotPrice) * sizeInGb;
      switch (this.state.ebsFilters.snapshotFrequency) {
        case "hourly":
          snapshotFreq = 729;
          break;
        case "daily":
          snapshotFreq = 30;
          break;
        case "2x-daily":
          snapshotFreq = 59.83;
          break;
        case "3x-daily":
          snapshotFreq = 90.25;
          break;
        case "4x-daily":
          snapshotFreq = 120.67;
          break;
        case "6x-daily":
          snapshotFreq = 181.5;
          break;
        case "weekly":
          snapshotFreq = 3;
          break;
        case "monthly":
          snapshotFreq = 1;
          break;
        default:
          snapshotFreq = 0;
      }
      price += parseFloat(snapshotPrice) * parseFloat(sizeInGbSnap) * 0.5 * snapshotFreq;
    }

    switch (volumeType) {
      case 'Storage General Purpose gp2 GB Mo':
        break;
      case 'Storage General Purpose gp3 GB Mo':
        let iopsPrice = regionData["Provisioned EBS IOPS gp3 Volumes per IOPS Mo"] ? regionData["Provisioned EBS IOPS gp3 Volumes per IOPS Mo"].price : 0;
        price += (this.state.ebsFilters.provisioningIOPS - 3000) * iopsPrice;
        let throughPutPrice = regionData["Provisioned Throughput gp3 per GiBps mo"] ? regionData["Provisioned Throughput gp3 per GiBps mo"].price : 0;
        price += (this.state.ebsFilters.throughput - 125) / 1024 * throughPutPrice;
        break;
      case 'Storage Provisioned IOPS io1 GB Mo':
        if (this.state.ebsFilters.provisioningIOPS === '') break;
        let iopsPriceIO1 = regionData["Provisioned EBS IOPS io1 Volumes per IOPS Mo"] ? regionData["Provisioned EBS IOPS io1 Volumes per IOPS Mo"].price : 0;
        let maxIOPS = sizeInGb * 50;
        price += this.state.ebsFilters.provisioningIOPS > maxIOPS ? maxIOPS * iopsPriceIO1 : this.state.ebsFilters.provisioningIOPS * iopsPriceIO1;
        break;
      case 'Storage Provisioned IOPS io2 GB month':
        let iopsPriceIO2Tier1 = regionData["Provisioned EBS IOPS io2 Volumes per IOPS Mo"] ? regionData["Provisioned EBS IOPS io2 Volumes per IOPS Mo"].price : 0;
        let iopsPriceIO2Tier2 = regionData["Provisioned EBS IOPS Tier 2 io2 Volumes per IOPS Mo"] ? regionData["Provisioned EBS IOPS Tier 2 io2 Volumes per IOPS Mo"].price : 0;
        let iopsPriceIO2Tier3 = regionData["Provisioned EBS IOPS Tier 3 io2 Volumes per IOPS Mo"] ? regionData["Provisioned EBS IOPS Tier 3 io2 Volumes per IOPS Mo"].price : 0;

        if (this.state.ebsFilters.provisioningIOPS <= 32000) price += this.state.ebsFilters.provisioningIOPS * iopsPriceIO2Tier1;
        else if (this.state.ebsFilters.provisioningIOPS > 32000 && this.state.ebsFilters.provisioningIOPS <= 64000) price += 32000 * iopsPriceIO2Tier1 + (this.state.ebsFilters.provisioningIOPS - 32000) * iopsPriceIO2Tier2;
        else price += 32000 * iopsPriceIO2Tier1 + 32000 * iopsPriceIO2Tier2 + (this.state.ebsFilters.provisioningIOPS - 64000) * iopsPriceIO2Tier3;
        break;
      case 'Storage Throughput Optimized HDD st1 GB Mo':
        break;
      case 'Storage Cold HDD sc1 GB Mo':
        break;
      case 'Storage Magnetic standard GB Mo':
        break;
      default:
        price = 0;
    }

    console.log(price);
    return price;
  };



  convertToGB = (amount, unit) => {
    switch (unit) {
      case 'TB':
        return amount * 1024;
      case 'GB':
        return amount;
      case 'MB':
        return amount / 1024;
      default:
        return amount;
    }
  }

  // 过滤器本地过滤
  filterProducts() {
    const { allProductItems, currentButtonState, currentPageIndex, itemsPerPage } = this.state;
    const filterText = currentButtonState.InstanceType.toLowerCase();
    const instanceFamilyFilter = currentButtonState.InstanceFamily.value.toLowerCase();

    let filteredItems = allProductItems.filter(item => {
      const memory = parseFloat(item.memory);
      const vcpu = parseInt(item.vcpu);
      const instanceType = item.instanceType.toLowerCase();
      const instanceFamily = item.instanceFamily.toLowerCase();

      return (
        (!currentButtonState.Memory || memory === parseFloat(currentButtonState.Memory)) &&
        (!currentButtonState.vCPU || vcpu === parseInt(currentButtonState.vCPU)) &&
        (filterText === '' || instanceType.includes(filterText)) &&
        (instanceFamilyFilter === 'all' || instanceFamily === instanceFamilyFilter)
      );
    });

    console.log('Filtered Items after initial filtering: ', filteredItems);
    // Purchase Option 非savingplans的过滤逻辑
    if (!currentButtonState.PurchaseOption.value.startsWith("Sp")) {
      const purchaseOption = currentButtonState.PurchaseOption.label.toLowerCase().replace(/\s+/g, ' ').trim();

      console.log('Purchase Option: ', purchaseOption);

      filteredItems = filteredItems.filter(item => {
        if (currentButtonState.PurchaseOption.value === "OnDemand") {
          return item.terms.OnDemand;
        } else {
          return item.terms.Reserved.some(term => {
            const termString = `${term.leaseContractLength.toLowerCase()} ${term.offeringClass.toLowerCase()} - ${term.purchaseOption.toLowerCase()}`.replace(/\s+/g, ' ').trim();
            console.log('Checking term: ', termString);
            console.log('purchaseOption === termString: ', purchaseOption === termString);
            return termString === purchaseOption;
          });
        }
      });
    }

    console.log('Filtered Items after purchase option filtering: ', filteredItems);

    const startIndex = (currentPageIndex - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedItems = filteredItems.slice(startIndex, endIndex);

    this.setState({ productItems: paginatedItems, filteredLength: filteredItems.length });
  }

  // 发起数据请求
  async getData() {

    this.setState({ isLoading: true });
    const urls = this.generateODRIApiURL();

    const allData = await this.fetchAllData(urls);

    if (allData.length) {
      this.processAllData(allData);
    }
    this.setState({ isLoading: false });
  }

  async fetchEBSData() {
    const regionLabel = this.state.currentButtonState.Region.label;
    const isChinaRegion = regionLabel.includes("China");
    const ebsUrl = isChinaRegion
      ? '/pricing/2.0/meteredUnitMaps/aws-cn/ec2/CNY/current/ebs-calculator.json'
      : '/pricing/2.0/meteredUnitMaps/ec2/USD/current/ebs-calculator.json';

    try {
      const response = await fetch(ebsUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const ebsData = await response.json();
      console.log('Fetched EBS Data:', ebsData); // 日志记录获取的 EBS 数据
      this.setState({ ebsData, isEBSLoading: false });
    } catch (error) {
      console.error('Fetching EBS data error:', error);
      this.setState({ ebsError: error, isEBSLoading: false });
    }
  }

  handleFilterChange = () => {
    this.setState({ clickButton: true, currentPageIndex: 1, errorMsg: "" }); //when ever the filter is updated, the page is set to 1
  };

  async componentDidMount() {
    await this.getData();
    this.filterProducts();
    this.fetchEBSData();
  }


  // 组件状态更新
  async componentDidUpdate(prevProps, prevState) {
    // 检查是否需要发送API请求
    const needApiRequest = (
      prevState.currentButtonState.Region !== this.state.currentButtonState.Region ||
      prevState.currentButtonState.OperatingSystem !== this.state.currentButtonState.OperatingSystem ||
      prevState.currentButtonState.PreinstalledSoftware !== this.state.currentButtonState.PreinstalledSoftware
    );

    if (this.state.clickButton && needApiRequest) {
      await this.getData();
      this.fetchEBSData();
      this.setState({ clickButton: false });
    }

    // 处理Purchase Option是Saving Plans
    const isSavingPlanSelected = this.state.currentButtonState.PurchaseOption.value.startsWith("Sp");
    if (isSavingPlanSelected && prevState.currentButtonState.PurchaseOption !== this.state.currentButtonState.PurchaseOption) {
      if (!this.state.currentButtonState.InstanceType && this.state.selectedItems.length === 0) {
        // 没有选择实例类型，弹出提示框
        this.setState({
          errorMsg: "Please select an instance type, or enter an instance type (e.g., t4g.nano) in Instance Type Filter to query Saving Plans",
          showModal: true,
          clickButton: false
        });
        return;
      } else {
        await this.getSavingPlansData();
        this.setState({ clickButton: false });
      }
    }

    if (isSavingPlanSelected && this.state.updatePurchaseOption && prevState.currentButtonState.PurchaseOption !== this.state.currentButtonState.PurchaseOption) {
      if (!this.state.currentButtonState.InstanceType && this.state.selectedItems.length === 0) {
        // 没有选择实例类型，弹出提示框
        this.setState({
          errorMsg: "Please select an instance type, or enter an instance type (e.g., t4g.nano) in Instance Type Filter to query Saving Plans",
          showModal: true,
          clickButton: false
        });
        return;
      } else {
        await this.getSavingPlansData();
        this.setState({ clickButton: false });
      }
    }

    // 处理本地筛选
    if (
      prevState.currentButtonState.InstanceFamily.value !== this.state.currentButtonState.InstanceFamily.value ||
      prevState.currentButtonState.Memory !== this.state.currentButtonState.Memory ||
      prevState.currentButtonState.vCPU !== this.state.currentButtonState.vCPU ||
      prevState.currentButtonState.InstanceType !== this.state.currentButtonState.InstanceType ||
      prevState.currentPageIndex !== this.state.currentPageIndex ||
      prevState.allProductItems !== this.state.allProductItems ||
      prevState.currentButtonState.PurchaseOption !== this.state.currentButtonState.PurchaseOption && !isSavingPlanSelected
    ) {
      this.filterProducts();
      const pagesCount = Math.ceil(this.state.filteredLength / this.state.itemsPerPage);
      if (this.state.currentPageIndex > pagesCount) {
        this.setState({ currentPageIndex: 1 });
      }
    }
  }

  // 获取Saving Plans数据
  getSavingPlansData = async () => {
    try {
      const savingPlansUrl = this.generateSavingPlansApiURL();
      if (!savingPlansUrl) return;
      const savingPlansData = await this.fetchAllData(savingPlansUrl);

      console.log('fetched savingPlansData: ', savingPlansData);
      const updatedItems = this.state.allProductItems.map((item) => {
        const regionLabel = decodeURIComponent(this.state.currentButtonState.Region.label);
        const regionData = savingPlansData.regions[regionLabel];

        if (!regionData) {
          console.error("No data found for the selected region:", regionLabel);
          return item;
        }

        for (const key in regionData) {
          const planItem = regionData[key];
          if (planItem["ec2:InstanceType"].toLowerCase() === item.instanceType.toLowerCase()) {
            if (!item.terms.SavingPlans) {
              item.terms.SavingPlans = [];
            }

            // 检查是否已经存在相同的SavingPlan
            const existingPlan = item.terms.SavingPlans.find(
              (plan) =>
                plan.purchaseOption === planItem["PurchaseOption"] &&
                plan.leaseContractLength === planItem["LeaseContractLength"] &&
                plan.price === planItem["price"]
            );
            // console.log('existingPlan: ', existingPlan);
            if (!existingPlan) {
              item.terms.SavingPlans.push({
                savingPlans: key,
                purchaseOption: planItem["PurchaseOption"],
                leaseContractLength: planItem["LeaseContractLength"],
                price: planItem["price"],
                pricePerUnit: planItem["ec2:PricePerUnit"]
              });
            }
            // console.log("Merged item with Saving Plans:", item);
          }
        }
        return item;
      });

      const filterText = this.state.currentButtonState.InstanceType.toLowerCase();
      const selectedInstanceTypes = this.state.selectedItems.map(item => item.instanceType.toLowerCase());

      const filteredItems = updatedItems.filter(item =>
        (filterText === '' || item.instanceType.toLowerCase().includes(filterText)) &&
        (selectedInstanceTypes.length === 0 || selectedInstanceTypes.includes(item.instanceType.toLowerCase()))
      );

      console.log('get saving plans function Filtered Items:', filteredItems);

      this.setState({ allProductItems: updatedItems, filteredProductItems: filteredItems }, this.filterProducts);
    } catch (error) {
      console.error('Fetching Saving Plans error:', error);
      return null;
    }
  };

  // 添加购物车逻辑
  handleAddCart = async () => {
    console.log('handleAddCart selectedItems: ', this.state.selectedItems);

    const selectedItems = await Promise.all(this.state.selectedItems.map(async (item) => {
      this.state.ebsFilters.totalprice = this.calculateEbsPrice();
      if (this.state.showEBSFilters === true) item.ebsselection = this.state.ebsFilters;
      else item.ebsselection = null;
      console.log("handleAddCartFirstitem: ", item);
      console.log('ebs', this.state.ebsFilters);

      // 检查是否需要获取 Saving Plans 数据
      if (!item.terms.SavingPlans || item.terms.SavingPlans.length === 0) {
        const savingPlansData = await this.getSavingPlansDataForItem(item.instanceType);
        if (savingPlansData) {
          const regionLabel = decodeURIComponent(this.state.currentButtonState.Region.label);
          const regionData = savingPlansData.regions[regionLabel];

          if (regionData) {
            for (const key in regionData) {
              const planItem = regionData[key];
              if (planItem["ec2:InstanceType"].toLowerCase() === item.instanceType.toLowerCase()) {
                if (!item.terms.SavingPlans) {
                  item.terms.SavingPlans = [];
                }

                // 检查是否已经存在相同的 SavingPlan
                const existingPlan = item.terms.SavingPlans.find(
                  (plan) =>
                    plan.purchaseOption === planItem["PurchaseOption"] &&
                    plan.leaseContractLength === planItem["LeaseContractLength"] &&
                    plan.price === planItem["price"]
                );
                if (!existingPlan) {
                  item.terms.SavingPlans.push({
                    savingPlans: key,
                    purchaseOption: planItem["PurchaseOption"],
                    leaseContractLength: planItem["LeaseContractLength"],
                    price: planItem["price"],
                    pricePerUnit: planItem["ec2:PricePerUnit"]
                  });
                }
              }
            }
          }
        }
      }

      const itemObject = calculatePrices(item, this.state.currentButtonState);
      console.log('After calculatePrices: ', itemObject);
      return {
        ...itemObject,
      };
    }));

    selectedItems.forEach(item => this.props.addToCart(item));
    this.setState({ selectedItems: [] });
  };

  // 获取Saving Plans数据
  getSavingPlansDataForItem = async (instanceType) => {
    try {
      const savingPlansUrl = this.generateSavingPlansApiURL(instanceType);
      if (!savingPlansUrl) return null;
      const savingPlansData = await this.fetchAllData(savingPlansUrl);

      console.log('fetched savingPlansData: ', savingPlansData);
      return savingPlansData;
    } catch (error) {
      console.error('Fetching Saving Plans error:', error);
      return null;
    }
  };

  // 渲染
  render() {
    const { PurchaseOption } = this.state.currentButtonState;
    const { ebsData, isEBSLoading, ebsError } = this.state;

    //动态设置 perUnitPrice 和 upfrontFee
    let updatedItems = this.state.productItems.map(item => {
      let perUnitPrice = "N/A";
      let upfrontFee = "N/A";

      if (PurchaseOption.value === "OnDemand" && item.terms.OnDemand) {
        perUnitPrice = item.terms.OnDemand.price + " USD per Hrs";
      } else if (PurchaseOption.value.startsWith("Sp")) {
        const matchingPlan = item.terms.SavingPlans.find(plan => plan.savingPlans === PurchaseOption.label);
        if (matchingPlan) {
          perUnitPrice = matchingPlan.price + " USD per Hrs";
        }
      } else {
        const matchingReservedTerm = item.terms.Reserved.find(term => {
          const termString = `${term.leaseContractLength.toLowerCase()} ${term.offeringClass.toLowerCase()} - ${term.purchaseOption.toLowerCase()}`.replace(/\s+/g, ' ').trim();
          return termString === PurchaseOption.label.toLowerCase().replace(/\s+/g, ' ').trim();
        }
        );
        if (matchingReservedTerm) {
          if (matchingReservedTerm.hrsPrice) {
            perUnitPrice = matchingReservedTerm.hrsPrice + " USD per Hrs";
          }
          if (matchingReservedTerm.quantityPrice) {
            upfrontFee = matchingReservedTerm.quantityPrice + " USD upfront";
          }
        }
      }

      return { ...item, perUnitPrice, upfrontFee };
    });

    if (this.state.currentButtonState.PurchaseOption.value.startsWith("Sp") && this.state.currentButtonState.InstanceType) {
      console.log('render check filterProductItems: ', this.state.filteredProductItems)
      updatedItems = this.state.filteredProductItems;
    }

    return (
      <Layout>
        <Modal
          visible={this.state.showModal}
          onDismiss={() => this.setState({ showModal: false })}
          header="Notes"
          footer={
            <Button onClick={() => this.setState({ showModal: false })}>Close</Button>
          }
        >
          <Box>{this.state.errorMsg}</Box>
        </Modal>
        <Table
          onSelectionChange={({ detail }) => {
            const updatedSelectedItems = detail.selectedItems.map(item => ({
              ...item,
              ServiceName: "EC2",
              quantity: 1
            }));
            console.log('Table Render updatedSelectedItems: ', updatedSelectedItems);
            this.setState({ selectedItems: updatedSelectedItems });
          }}
          selectedItems={this.state.selectedItems}
          ariaLabels={{
            selectionGroupLabel: "Items selection",
            allItemsSelectionLabel: ({ selectedItems }) =>
              `${selectedItems.length} ${selectedItems.length === 1 ? "item" : "items"} selected`,
            itemSelectionLabel: ({ selectedItems }, item) =>
              item.InstanceType
          }}
          columnDefinitions={[
            {
              id: "instanceType",
              header: "Instance Type",
              cell: item => item.instanceType,
              sortingField: "instanceType",
              isRowHeader: true
            },
            {
              id: "instanceFamily",
              header: "Instance Family",
              cell: item => item.instanceFamily,
            },
            {
              id: "regionCode",
              header: "Region Code",
              cell: item => item.regionCode,
              sortingField: "regionCode"
            },
            {
              id: "vcpu",
              header: "vCPU",
              cell: item => item.vcpu,
              sortingField: "vcpu"
            },
            {
              id: "memory",
              header: "Memory",
              cell: item => item.memory,
              sortingField: "memory"
            },
            {
              id: "storage",
              header: "Storage",
              cell: item => item.storage,
            },
            {
              id: "networkPerformance",
              header: "Network Performance",
              cell: item => item.networkPerformance,
            },
            {
              id: "operatingSystem",
              header: "Operating System",
              cell: item => item.operatingSystem + ((this.state.currentButtonState.PreinstalledSoftware.value === "NA") ? "" : (" with " + this.state.currentButtonState.PreinstalledSoftware.label)),
            },
            {
              id: "pricePerUnit-onDemand",
              header: "OnDemand Price",
              cell: item => item.terms.OnDemand.price,
            },
            {
              id: "pricePerUnit",
              header: "Price Per Unit",
              cell: item => {
                if (this.state.currentButtonState.PurchaseOption.value.startsWith("Sp")) {
                  if (!item.terms || !item.terms.SavingPlans) {
                    return "N/A";
                  }
                  const matchingPlan = item.terms.SavingPlans.find(plan =>
                    plan.savingPlans === this.state.currentButtonState.PurchaseOption.label
                  );
                  return matchingPlan ? matchingPlan.price + " USD per Hrs" : "N/A";
                }
                return item.perUnitPrice;
              },
            },
            {
              id: "upfrontFee",
              header: "Upfront Fee",
              cell: item => item.upfrontFee,
            }
          ]}
          columnDisplay={[
            { id: "instanceType", visible: true },
            { id: "instanceFamily", visible: !this.state.currentButtonState.PurchaseOption.value.startsWith("Sp") },
            { id: "regionCode", visible: true },
            { id: "vcpu", visible: !this.state.currentButtonState.PurchaseOption.value.startsWith("Sp") },
            { id: "memory", visible: !this.state.currentButtonState.PurchaseOption.value.startsWith("Sp") },
            { id: "gpuMemory", visible: true },
            { id: "storage", visible: !this.state.currentButtonState.PurchaseOption.value.startsWith("Sp") },
            {
              id: "networkPerformance",
              visible: !this.state.currentButtonState.PurchaseOption.value.startsWith("Sp")
            },
            { id: "operatingSystem", visible: true },
            { id: "pricePerUnit-onDemand", visible: true },
            { id: "pricePerUnit", visible: this.state.currentButtonState.PurchaseOption.value !== "OnDemand" },
            {
              id: "upfrontFee",
              visible: this.state.currentButtonState.PurchaseOption.value !== "OnDemand" && this.state.currentButtonState.Upfront !== "No Upfront" && !this.state.currentButtonState.PurchaseOption.value.startsWith("Sp")
            }
          ]}
          enableKeyboardNavigation
          // items={this.state.productItems}
          items={updatedItems}
          loading={this.state.isLoading}
          loadingText="Loading..."
          selectionType="multi"
          stickyColumns={{ first: 0, last: 1 }}
          trackBy="instanceType"

          empty={
            <Box
              margin={{ vertical: "xs" }}
              textAlign="center"
              color="inherit"
            >
              <SpaceBetween size="m">
                <b>No Instances based on your input</b>
                {/* <Button>Create resource</Button> */}
              </SpaceBetween>
            </Box>
          }
          filter={
            <div className="filter-container" key="filter-container">
              <div className="select-filter" key="region-filter">
                <FormField label="Region">
                  <RegionFilter
                    selectedOption={this.state.currentButtonState.Region}
                    setSelectedOption={selectedOption => this.setState({
                      currentButtonState: {
                        ...this.state.currentButtonState,
                        Region: selectedOption
                      }, clickButton: true
                    })}
                    onFilterChange={this.handleFilterChange}
                  />
                </FormField>
              </div>
              <div className="select-filter" key="instance-family-filter-filter">
                <FormField label="Instance Family">
                  <InstanceFamilyFilter
                    selectedOption={this.state.currentButtonState.InstanceFamily}
                    setSelectedOption={selectedOption => this.setState({
                      currentButtonState: {
                        ...this.state.currentButtonState,
                        InstanceFamily: selectedOption
                      }, clickButton: true
                    })}
                    onFilterChange={this.handleFilterChange}
                  />
                </FormField>
              </div>
              <div className="select-filter" key="purchase-option-filter">
                <FormField label="Purchase Option">
                  <PurchaseOptionFilter
                    selectedOption={this.state.currentButtonState.PurchaseOption}
                    setSelectedOption={selectedOption => {
                      console.log('Setting Purchase Option: ', selectedOption);
                      this.setState({
                        currentButtonState: {
                          ...this.state.currentButtonState,
                          PurchaseOption: selectedOption
                        }, updatePurchaseOption: true
                      });
                    }}
                    onFilterChange={this.handleFilterChange}
                  />
                </FormField>
              </div>
              <div className="select-filter" key="operating-system-filter">
                <FormField label="Operating System">
                  <OSFilter
                    selectedOption={this.state.currentButtonState.OperatingSystem}
                    setSelectedOption={selectedOption => this.setState({
                      currentButtonState: {
                        ...this.state.currentButtonState,
                        OperatingSystem: selectedOption
                      }, clickButton: true
                    })}
                    onFilterChange={this.handleFilterChange}
                  />
                </FormField>
              </div>
              <div className="select-filter" key="pre-installed-software-filter">
                <FormField label="Preinstalled Software">
                  <PreinstalledSoftwareFilter
                    selectedOption={this.state.currentButtonState.PreinstalledSoftware}
                    setSelectedOption={selectedOption => this.setState({
                      currentButtonState: {
                        ...this.state.currentButtonState,
                        PreinstalledSoftware: selectedOption
                      }, clickButton: true
                    })}
                    operatingSystem={this.state.currentButtonState.OperatingSystem.value}
                    onFilterChange={this.handleFilterChange}
                    setClickButton={(clickButton) => this.setState({ clickButton })}
                  />
                </FormField>
              </div>
              <div className="input-filter" key="vcpu-filter">
                <FormField label="vCPU (Optional)">
                  <VCPUFilter
                    filteringText={this.state.currentButtonState.vCPU}
                    setFilteringText={filteringText => this.setState({
                      currentButtonState: {
                        ...this.state.currentButtonState,
                        vCPU: filteringText
                      }, clickButton: true
                    })}
                    onFilterChange={this.handleFilterChange}
                  />
                </FormField>
              </div>
              <div className="input-filter" key="memory-filter">
                <FormField label="Memory (Optional)">
                  <MemoryFilter
                    filteringText={this.state.currentButtonState.Memory}
                    setFilteringText={filteringText => this.setState({
                      currentButtonState: {
                        ...this.state.currentButtonState,
                        Memory: filteringText
                      }, clickButton: true
                    })}
                    onFilterChange={this.handleFilterChange}
                  />
                </FormField>
              </div>
              <div className="input-filter" key="instance-type-filter">
                <FormField label="Instance Type (Optional)">
                  <InstanceTypeFilter
                    filteringText={this.state.currentButtonState.InstanceType}
                    setFilteringText={filteringText => this.setState({
                      currentButtonState: {
                        ...this.state.currentButtonState,
                        InstanceType: filteringText
                      }, clickButton: true
                    })}
                    onFilterChange={this.handleFilterChange}
                  />
                </FormField>
              </div>
              <div className="select-filter" key="add-ebs-filter">
                <FormField label="Add Elastic Block Store (EBS) - optional">
                  <AddEBSFilter
                    selectedOption={this.state.showEBSFilters ? "Yes" : "No"}
                    onFilterChange={this.handleShowEBSFilters}
                  />
                </FormField>
              </div>

              {this.state.showEBSFilters && (
                <>
                  <Header
                    counter=""
                    actions={
                      <SpaceBetween
                        direction="horizontal"
                        size="xs"
                      >
                        {/*<Button*/}
                        {/*    variant="primary"*/}
                        {/*    onClick={this.handleSubmit}*/}
                        {/*    disabled={!this.state.allFiltersValid}*/}
                        {/*>*/}
                        {/*  Calculate Price*/}
                        {/*</Button>*/}
                      </SpaceBetween>
                    }
                  >
                    Amazon EBS
                  </Header>

                  <div className="filter-container" key="filter-container">
                    {/* First row */}
                    <div className="EBS-filter-row">
                      {/* <div className="EBS-select-filter" key="region-filter">
                <FormField label="Region">
                  <EBSRegionFilter
                    selectedOption={ebsregionOptions.find((option) => option.value === this.state.ebsFilters.region)}
                    onFilterChange={(value) => this.handleEBSFiltersChange({ ...this.state.ebsFilters, region: value })}
                  />
                </FormField>
              </div> */}
                      <div className="EBS-select-filter" key="volume-type-filter">
                        <FormField label="EBS Volume Type">
                          <EBSVolumeTypeFilter
                            selectedOption={ebsvolumeTypeOptions.find((option) => option.value === this.state.ebsFilters.volumeType)}
                            /* selectedRegion={this.state.selectedRegion} */
                            currentButtonState={this.state.currentButtonState}
                            onFilterChange={(value) => this.handleEBSFiltersChange({
                              ...this.state.ebsFilters,
                              volumeType: value
                            })}
                          />
                        </FormField>
                      </div>
                      {/*<div className="EBS-input-filter" key="number-of-volumes-filter">*/}
                      {/*  <FormField label="Number of Volumes">*/}
                      {/*    <EBSNumberOfVolumesFilter*/}
                      {/*      filteringText={this.state.ebsFilters.numberOfVolumes}*/}
                      {/*      onFilterChange={(value) => this.handleEBSFiltersChange({*/}
                      {/*        ...this.state.ebsFilters,*/}
                      {/*        numberOfVolumes: value*/}
                      {/*      })}*/}
                      {/*    />*/}
                      {/*  </FormField>*/}
                      {/*</div>*/}
                    </div>


                    {/* Second row */}
                    <div className="EBS-filter-row">
                      {/*<div className="EBS-input-filter" key="average-duration-filter">*/}
                      {/*  <FormField label="Average Duration each Instance runs (hours per month)">*/}
                      {/*    <EBSAverageDurationFilter*/}
                      {/*      filteringText={this.state.ebsFilters.averageDuration}*/}
                      {/*      onFilterChange={(value) => this.handleEBSFiltersChange({*/}
                      {/*        ...this.state.ebsFilters,*/}
                      {/*        averageDuration: value*/}
                      {/*      })}*/}
                      {/*    />*/}
                      {/*  </FormField>*/}
                      {/*</div>*/}
                      <div className="EBS-input-filter" key="storage-amount-filter">
                        <FormField label="Storage amount per volume">
                          <div className="EBS-storage-amount-filter">
                            <EBSStorageAmountFilter
                              filteringText={this.state.ebsFilters.volumeSize}
                              volumeType={this.state.ebsFilters.volumeType}
                              onFilterChange={(value) => this.handleEBSFiltersChange({
                                ...this.state.ebsFilters,
                                volumeSize: value
                              })}
                            />
                          </div>
                        </FormField>
                      </div>
                    </div>


                    {/* Third row */}
                    <div className="EBS-filter-row">
                      {this.shouldShowProvisioningIOPSFilter() && (
                        <div className="EBS-input-filter" key="provisioning-iops-filter">
                          <FormField label="Provisioning IOPS per volume - Optional">
                            <EBSProvisioningIOPSFilter
                              filteringText={this.state.ebsFilters.provisioningIOPS}
                              volumeType={this.state.ebsFilters.volumeType}
                              onFilterChange={(value) => this.handleEBSFiltersChange({
                                ...this.state.ebsFilters,
                                provisioningIOPS: value
                              })}
                            />
                          </FormField>
                        </div>
                      )}
                      {this.shouldShowThroughputFilter() && (
                        <div className="EBS-input-filter" key="throughput-filter">
                          <FormField label="General Purpose SSD (gp3) - Throughput (MBps) - Optional">
                            <EBSThroughputFilter
                              filteringText={this.state.ebsFilters.throughput}
                              onFilterChange={(value) => this.handleEBSFiltersChange({
                                ...this.state.ebsFilters,
                                throughput: value
                              })}
                            />
                          </FormField>
                        </div>
                      )}
                    </div>

                    {/* Fourth row */}
                    <div className="EBS-filter-row">
                      <div className="EBS-select-filter" key="snapshot-frequency-filter">
                        <FormField label="Snapshot Frequency">
                          <EBSSnapshotFrequencyFilter
                            selectedOption={snapshotFrequencyOptions.find((option) => option.value === this.state.ebsFilters.snapshotFrequency)}
                            onFilterChange={(value) => this.handleEBSFiltersChange({
                              ...this.state.ebsFilters,
                              snapshotFrequency: value
                            })}
                          />
                        </FormField>
                      </div>
                      {this.shouldShowAmountChangedPerSnapshotFilter() && (
                        <div className="EBS-input-filter" key="amount-changed-per-snapshot-filter">
                          <FormField label="Amount Changed per Snapshot">
                            <div className="EBS-amount-changed-per-snapshot-filter">
                              <EBSAmountChangedPerSnapshotFilter
                                filteringText={this.state.ebsFilters.amountChangedPerSnapshot}
                                onFilterChange={(value) => this.handleEBSFiltersChange({
                                  ...this.state.ebsFilters,
                                  amountChangedPerSnapshot: value
                                })}
                              />
                            </div>
                          </FormField>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
              <Alert
                statusIconAriaLabel="Info"
                header="Notice."
              >
                The first instance call may take 3 - 6 seconds, please be patient.
              </Alert>
            </div>

          }
          header={
            <Header
              counter={
                this.state.selectedItems.length
                  ? "(" + this.state.selectedItems.length + "/" + this.state.filteredLength + ")"
                  : "(" + this.state.filteredLength + ")" //display the entire length of the filtered instances
              }
              actions={
                <SpaceBetween
                  direction="horizontal"
                  size="xs"
                >
                  <Button onClick={this.handleSortByPrice}>
                    Sort by Price {this.state.sortByPrice === "asc" ? "↑" : "↓"}
                  </Button>
                  <Button variant="primary"
                    onClick={this.handleAddCart}
                    disabled={!this.state.allFiltersValid && this.state.showEBSFilters === true}
                  >
                    Add to Cart
                  </Button>
                  <Link to="/cart">
                    <Button variant="primary">
                      Go to Cart
                    </Button>
                  </Link>
                </SpaceBetween>
              }
            >
              AmazonEC2
            </Header>
          }

          pagination={
            <div className="App-pagination">
              <span>Show Rows &nbsp;</span>
              <ItemsPerPageDropdown
                itemsPerPageOptions={this.state.itemsPerPageOptions}
                itemsPerPage={this.state.itemsPerPage}
                setItemsPerPage={this.handleItemsPerPageChange}
              />
              <PaginationSetting
                currentPageIndex={this.state.currentPageIndex}
                setCurrentPageIndex={(currentPageIndex) => this.handleNextPage(currentPageIndex)}
                nextPage={this.nextPage}
                totalItems={this.state.filteredLength}
                itemsPerPage={this.state.itemsPerPage}
                onPageChange={this.handlePageChange}
              />
            </div> //add instance display drop down and the show rows text label
          }
        />
        {/*<div>*/}
        {/*  <h2>JSON Data:</h2>*/}
        {/*  {ebsData && ebsData.regions && ebsData.regions["Africa (Cape Town)"] ? (*/}
        {/*      <pre>{JSON.stringify(ebsData.regions["Africa (Cape Town)"], null, 2)}</pre>*/}
        {/*  ) : (*/}
        {/*      <p>Loading data or region not found...</p>*/}
        {/*  )}*/}
        {/*</div>*/}
      </Layout>


    )
      ;
  }
}

const mapDispatchToProps = (dispatch) => ({
  addToCart: (item) => dispatch(addToCart(item)),
});

export default connect(null, mapDispatchToProps)(ProductTable);