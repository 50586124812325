import "./SelectFilter.css";
import React, { useEffect, useState } from "react";
import Layout from "../layout/appLayout";
import { FormField } from '@cloudscape-design/components';
import Table from "@cloudscape-design/components/table";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import TextFilter from "@cloudscape-design/components/text-filter";
import Header from "@cloudscape-design/components/header";
import Pagination from "@cloudscape-design/components/pagination";
import Select from "@cloudscape-design/components/select";
import { connect } from 'react-redux';
import { addToCart } from "../redux/actions/cartActions";
import { Link } from 'react-router-dom';
import Modal from "@cloudscape-design/components/modal";
import { calculatePrices } from '../common/PriceCalculating.jsx';
import { redshiftCalculatePrices } from '../common/RedshiftPriceCalculating.jsx';
import Input from "@cloudscape-design/components/input";

// 分页组件
const PaginationSetting = (props) => {
  return (
    <Pagination
      currentPageIndex={props.currentPageIndex}
      onChange={({ detail }) => {
        props.setCurrentPageIndex(detail.currentPageIndex);
        props.onPageChange(detail.currentPageIndex); // 确保调用父组件传递的翻页回调
      }}
      openEnd
      pagesCount={Math.ceil(props.totalItems / props.itemsPerPage)} // 动态计算总页数
    />
  );
};

// 区域选项
const regionOptions = [
  { label: "US East (N. Virginia)", value: "us-east-1" },
  { label: "US East (Ohio)", value: "us-east-2" },
  { label: "US West (N. California)", value: "us-west-1" },
  { label: "US West (Oregon)", value: "us-west-2" },
  { label: "Canada (Central)", value: "ca-central-1" },
  { label: "Canada West (Calgary)", value: "ca-west-1" },
  { label: "Africa (Cape Town)", value: "af-south-1" },
  { label: "Asia Pacific (Hong Kong)", value: "ap-east-1" },
  { label: "Asia Pacific (Hyderabad)", value: "ap-south-2" },
  { label: "Asia Pacific (Jakarta)", value: "ap-southeast-3" },
  { label: "Asia Pacific (Melbourne)", value: "ap-southeast-4" },
  { label: "Asia Pacific (Malaysia)", value: "ap-southeast-5" },
  { label: "Asia Pacific (Mumbai)", value: "ap-south-1" },
  { label: "Asia Pacific (Osaka)", value: "ap-northeast-3" },
  { label: "Asia Pacific (Seoul)", value: "ap-northeast-2" },
  { label: "Asia Pacific (Singapore)", value: "ap-southeast-1" },
  { label: "Asia Pacific (Sydney)", value: "ap-southeast-2" },
  { label: "Asia Pacific (Tokyo)", value: "ap-northeast-1" },
  { label: "EU (Frankfurt)", value: "eu-central-1" },
  { label: "EU (Ireland)", value: "eu-west-1" },
  { label: "EU (London)", value: "eu-west-2" },
  { label: "EU (Milan)", value: "eu-south-1" },
  { label: "EU (Paris)", value: "eu-west-3" },
  { label: "EU (Spain)", value: "eu-south-2" },
  { label: "EU (Stockholm)", value: "eu-north-1" },
  { label: "EU (Zurich)", value: "eu-central-2" },
  { label: "Israel (Tel Aviv)", value: "il-central-1" },
  { label: "Middle East (Bahrain)", value: "me-south-1" },
  { label: "Middle East (UAE)", value: "me-central-1" },
  { label: "South America (Sao Paulo)", value: "sa-east-1" },
  { label: "AWS GovCloud (US-East)", value: "us-gov-east-1" },
  { label: "AWS GovCloud (US-West)", value: "us-gov-west-1" }
];

// 利用率选项
const utilizationUnitOptions = [
  { label: "%Utilized/Month", value: "%Utilized/Month" },
  { label: "Hours/Day", value: "Hours/Day" },
  { label: "Hours/Week", value: "Hours/Week" },
  { label: "Hours/Month", value: "Hours/Month" }
];

/**
 * 筛选器组件
 */
function RegionFilter(props) {
  return (
    <Select
      selectedAriaLabel="Selected"
      selectedOption={props.selectedOption}
      onChange={({ detail }) => {
        props.setSelectedOption({
          ...detail.selectedOption,
          label: decodeURIComponent(detail.selectedOption.label),
        });
        props.onFilterChange();
      }}
      options={regionOptions}
      ariaDescribedby={null}
      expandToViewport={true}
    />
  );
}


function InstanceFamilyFilter(props) {
  return (
    <Select
      selectedAriaLabel="Selected"
      selectedOption={props.selectedOption}
      onChange={({ detail }) => {
        props.setSelectedOption(detail.selectedOption);
        props.onFilterChange();
      }}
      options={[
        { label: "All", value: "All" },
        { label: "General purpose", value: "General purpose" },
        { label: "Compute optimized", value: "Compute optimized" },
        { label: "Memory optimized", value: "Memory optimized" },
        { label: "Storage optimized", value: "Storage optimized" },
        { label: "GPU instance", value: "GPU instance" },
        { label: "FPGA Instances", value: "FPGA Instances" },
        { label: "Machine Learning ASIC Instances", value: "Machine Learning ASIC Instances" },
        { label: "Media Accelerator Instances", value: "Media Accelerator Instances" },
        { label: "Micro instances", value: "Micro instances" },
        { label: "N/A", value: "N/A" }
      ]}
      ariaDescribedby={null}
      expandToViewport={true}
    />
  );
}

const MemoryFilter = (props) => {
  const [filteringText, setFilteringText] = React.useState(props.filteringText);

  const handleFilterChange = (newFilteringText) => {
    setFilteringText(newFilteringText);
    props.setFilteringText(newFilteringText);
    props.onFilterChange();
  };

  return (
    <TextFilter
      filteringText={filteringText}
      filteringPlaceholder="Memory equals to <input> GiB"
      filteringAriaLabel="Filter Memory"
      onChange={({ detail }) => handleFilterChange(detail.filteringText)}
    />
  );
}

function VCPUFilter(props) {
  const [filteringText, setFilteringText] = React.useState(props.filteringText);

  const handleFilterChange = (newFilteringText) => {
    setFilteringText(newFilteringText);
    props.setFilteringText(newFilteringText);
    props.onFilterChange();
  };

  return (
    <TextFilter
      filteringText={filteringText}
      filteringPlaceholder="Number of vCPU equals to <input>"
      filteringAriaLabel="Filter vCPU"
      onChange={({ detail }) => handleFilterChange(detail.filteringText)}
    />
  );
}


function InstanceTypeFilter(props) {
  const [filteringText, setFilteringText] = React.useState(props.filteringText);

  const handleFilterChange = (newFilteringText) => {
    setFilteringText(newFilteringText);
    props.setFilteringText(newFilteringText);
    props.onFilterChange();
  };

  return (
    <TextFilter
      filteringText={filteringText}
      filteringPlaceholder="Input your instance type"
      filteringAriaLabel="Filter Instance Type"
      onChange={({ detail }) => handleFilterChange(detail.filteringText)}
    />
  );
}

function UtilizationFilter(props) {
  const [inputValue, setInputValue] = useState(props.utilizationValue);
  const [isInputValid, setIsInputValid] = useState(true);

  const handleInputChange = ({ detail }) => {
    const value = parseInt(detail.value, 10);
    const isValid = value >= 1 && value <= 100;
    setInputValue(value);
    setIsInputValid(isValid);
    if (isValid) {
      props.setUtilizationValue(value);
      props.onFilterChange();
    }
  };

  return (
    <div className="utilization-filter">
      <FormField label="Utilization (On-Demand only)">
        <div className="utilization-filter-inputs">
          <div>
            <Input
              type="number"
              min="1"
              max="100"
              value={inputValue}
              onChange={handleInputChange}
            />
            {!isInputValid && <span className="error-text">Value must be between 1 and 100</span>}
          </div>
          <div>
            <Select
              selectedAriaLabel="Selected"
              selectedOption={props.utilizationUnit}
              onChange={({ detail }) => {
                props.setUtilizationUnit(detail.selectedOption);
                props.onFilterChange();
              }}
              options={utilizationUnitOptions}
              ariaDescribedby={null}
              expandToViewport={true}
            />
          </div>
        </div>
      </FormField>
    </div>
  );
}

function NodesFilter(props) {
  const [inputValue, setInputValue] = useState(props.nodesValue || 1);

  const handleInputChange = ({ detail }) => {
    const value = parseInt(detail.value, 10);
    if (value >= 1) {
      setInputValue(value);
      props.setNodesValue(value);
      props.onFilterChange();
    }
  };

  return (
    <div className="nodes-filter">
      <FormField lable="Nodes">
        <Input
          type="number"
          min="1"
          value={inputValue}
          onChange={handleInputChange}
        // style={{ width: '50px' }}
        />
      </FormField>
    </div>
  )
}

const baseRPUOptions = Array.from({ length: 64 }, (_, i) => {
  const value = (i + 1) * 8;
  return { label: `${value}`, value: `${value}` };
});

function RedshiftServerlessSettings({ serverlessSettings, setServerlessSettings }) {
  const handleWorkloadChange = (event) => {
    const workloadSize = event.detail.selectedOption.value;
    let defaultBaseRPU;

    if (workloadSize === 'small') {
      defaultBaseRPU = '64';
    } else if (workloadSize === 'medium') {
      defaultBaseRPU = '128';
    } else if (workloadSize === 'large') {
      defaultBaseRPU = '256';
    }

    setServerlessSettings({
      ...serverlessSettings,
      workloadSize,
      baseRPU: defaultBaseRPU
    });
  };

  const handleBaseRPUChange = (event) => {
    setServerlessSettings({ ...serverlessSettings, baseRPU: event.detail.selectedOption.value });
  };

  const handleDailyRuntimeChange = (event) => {
    setServerlessSettings({ ...serverlessSettings, dailyRuntime: event.detail.value });
  };

  return (
    <div>
      <FormField label="Workload Size">
        <Select
          selectedOption={{ label: serverlessSettings.workloadSize, value: serverlessSettings.workloadSize }}
          onChange={handleWorkloadChange}
          options={[
            { label: 'Small', value: 'small' },
            { label: 'Medium', value: 'medium' },
            { label: 'Large', value: 'large' }
          ]}
          ariaDescribedby={null}
          expandToViewport={true}
        />
      </FormField>
      <FormField label="Base RPU">
        <Select
          selectedOption={{ label: serverlessSettings.baseRPU, value: serverlessSettings.baseRPU }}
          onChange={handleBaseRPUChange}
          options={baseRPUOptions}
          ariaDescribedby={null}
          expandToViewport={true}
        />
      </FormField>
      <FormField label="Estimated Daily Runtime (Hours)">
        <Input
          type="number"
          min="1"
          max="24"
          value={serverlessSettings.dailyRuntime}
          onChange={handleDailyRuntimeChange}
        />
      </FormField>
    </div>
  );
}


function ItemsPerPageDropdown(props) {
  return (
    <Select
      selectedAriaLabel="Selected"
      selectedOption={{ label: props.itemsPerPage, value: props.itemsPerPage }}
      onChange={({ detail }) => props.setItemsPerPage(detail.selectedOption.value)}
      options={props.itemsPerPageOptions.map(option => ({ label: option, value: option }))}
      ariaDescribedby={null}
      expandToViewport={true}
    />
  );
}

// 主Table类
class RedshiftProductTable extends React.Component {
  constructor(props) {
    super(props);
    // 初始化状态
    this.state = {
      currentButtonState: {
        Region: {
          label: "US East (N. Virginia)",
          value: "us-east-1",
        },
        // PurchaseOption: {
        //   label: "On Demand",
        //   value: "OnDemand",
        // },
        InstanceFamily: {
          label: "All",
          value: "All",
        },
        utilizationValue: 100,
        utilizationUnit: {
          label: "%Utilized/Month",
          value: "%Utilized/Month"
        },
        errorMsg: "",
        showModal: false,
        LeaseContractLength: "0", // only for RI
        Memory: "",
        vCPU: "",
        InstanceType: "",
        nodes: 1,
        serverlessSettings: {
          workloadSize: 'small',
          baseRPU: '64',
          baseRPUOptions: [{ label: '64', value: '64' }],
          dailyRuntime: '1',
        },
      },
      selectedItems: [],
      sortByPrice: null,
      productItems: [],
      allProductItems: [],
      hasNext: false,
      clickButton: true,
      // updatePurchaseOption: false,
      paginator: false,
      currentPageIndex: 1,
      itemsPerPageOptions: [10, 25, 50], //Offer Selections of number of instances displayed per page
      itemsPerPage: 10,
    };
    this.nextPage = this.nextPage.bind(this);
    this.handleNextPage = this.handleNextPage.bind(this);
    this.getInstanceFamily = this.getInstanceFamily.bind(this);
    this.onSelectionChange = this.onSelectionChange.bind(this);
  }

  // 价格排序
  handleSortByPrice = () => {
    this.setState(
      prevState => {
        let newSortOrder;
        if (prevState.sortByPrice === "asc") {
          newSortOrder = "desc";
        } else {
          newSortOrder = "asc";
        }
        return { sortByPrice: newSortOrder };
      },
      () => {
        this.sortAndFilterProducts();
      }
    )
  }

  sortAndFilterProducts = () => {
    let sortedItems = [...this.state.allProductItems];
    if (this.state.sortByPrice) {
      sortedItems.sort((a, b) => {
        const priceA = a.terms.OnDemand.price;
        const priceB = b.terms.OnDemand.price;
        if (this.state.sortByPrice === "asc") {
          return priceA - priceB;
        } else {
          return priceB - priceA;
        }
      });
    }
    this.setState( { allProductItems: sortedItems }, () => {
      this.filterProducts();
    });
  };

  handleServerlessChange = (selectedOption) => {
    this.setState({
      currentButtonState: {
        ...this.state.currentButtonState,
        isServerless: selectedOption.value
      }
    });
  };

  handleItemsPerPageChange = (newItemsPerPage) => {
    const newPageIndex = this.getCorrectPageIndex(newItemsPerPage);
    this.setState({ itemsPerPage: newItemsPerPage, currentPageIndex: newPageIndex }, () => {
      this.filterProducts();
    });
  };// Add the method handle Items Per Page Change

  onSelectionChange({ detail }) {
    const updatedSelectedItems = detail.selectedItems.map(item => ({
      ...item,
      ServiceName: "Redshift",
      quantity: 1
    }));

    const isServerless = updatedSelectedItems.length > 0 && updatedSelectedItems[0].instanceType === 'Redshift Serverless';

    console.log('updatedSelectedItems: ', updatedSelectedItems);
    this.setState({
      selectedItems: updatedSelectedItems,
      currentButtonState: {
        ...this.state.currentButtonState,
        isServerless,
      }
    });
  }

  getCorrectPageIndex = (newItemsPerPage) => {
    const { filteredLength, currentPageIndex, itemsPerPage } = this.state;
    const currentIndex = (currentPageIndex - 1) * itemsPerPage;

    const newPageIndex = Math.ceil((currentIndex + 1) / newItemsPerPage);
    return Math.min(newPageIndex, Math.ceil(filteredLength / newItemsPerPage));
  };// When the display row changes, the page will jump to the new page that includes the same instances that is displayed previously

  nextPage() {
    const { currentPageIndex } = this.state;
    const nextPageIndex = currentPageIndex + 1;
    this.setState({ currentPageIndex: nextPageIndex }, () => {
      this.filterProducts();
    });
  }

  handleNextPage = (currentPageIndex) => {
    this.setState({ currentPageIndex }, () => {
      this.filterProducts();
    });
  };

  handlePageChange = (pageIndex) => {
    this.setState({ currentPageIndex: pageIndex }, () => {
      this.filterProducts();
    });
  };

  //API请求的URL
  generateApiURL() {
    let url = `/pricing/2.0/meteredUnitMaps/redshift/USD/current/redshift.json`;

    url = url.replace(/\/+/g, '/'); // 去除多余的斜杠
    return url;
  }

  // 发送接收数据
  async fetchAllData(url) {
    try {
      console.log("Fetching URL: ", url);
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log("Response JSON:", data);
      return data;
    } catch (error) {
      console.error('Fetching error:', error);
      return null;
    }
  }

  // 处理数据
  processData(data) {
    const result_jsonlist = [];
    const regionLabel = this.state.currentButtonState.Region.label;
    let regionData = null;

    // 查找匹配的区域数据
    for (const region in data.regions) {
      if (region.includes(regionLabel)) {
        regionData = data.regions[region];
        break;
      }
    }

    if (!regionData) {
      console.error("No data found for the selected region:", regionLabel);
      return;
    }

    const tempResult = {};

    for (const key in regionData) {
      if (key.includes("Previous")) { //跳过之前版本实例
        continue;
      }
      if (key.includes("Concurrency Scaling")) { //跳过并发实例
        continue;
      }

      const item = regionData[key];

      const instanceType = item["Instance Type"] ? item["Instance Type"].toLowerCase() : "Redshift Serverless";

      if (key.includes("Serverless")) {
        tempResult[instanceType] = {
          instanceType: "Redshift Serverless",
          instanceFamily: "Serverless",
          regionCode: regionLabel,
          vcpu: "N/A",
          memory: "N/A",
          storage: "N/A",
          nodes: "N/A",
          terms: {
            OnDemand: { price: item.price },
            Reserved: []
          },
          upfrontFee: "N/A",
          ServerlessPrice: item.price,
          perUnitPrice: "N/A",
          utilizationValue: "N/A",
          utilizationUnit: "N/A",
        };
      } else {
        // 初始化存储结构
        if (!tempResult[instanceType]) {
          tempResult[instanceType] = {
            instanceType: item["Instance Type"],
            instanceFamily: item["Instance Family"] || this.getInstanceFamily(instanceType),
            regionCode: regionLabel,
            vcpu: item.vCPU,
            memory: item.Memory,
            storage: item.Storage,
            nodes: this.state.currentButtonState.nodes,
            terms: {
              OnDemand: null,
              Reserved: []
            },
            upfrontFee: "N/A",
            perUnitPrice: item.price,
            ServerlessPrice: "N/A",
            utilizationValue: this.state.currentButtonState.utilizationValue,
            utilizationUnit: this.state.currentButtonState.utilizationUnit.value,
          };
        }

        // ondemand
        tempResult[instanceType].terms.OnDemand = { price: item.price };
        tempResult[instanceType].perUnitPrice = item.price + " USD per Hrs";
      }
    }

    // 将临时存储对象转换为结果数组
    for (const key in tempResult) {
      result_jsonlist.push(tempResult[key]);
    }

    console.log("Processed data:", result_jsonlist);
    this.setState({ productItems: result_jsonlist, clickButton: false, allProductItems: result_jsonlist });
  }

  // 实例Family逻辑
  getInstanceFamily(instanceType) {
    if (!instanceType) return "N/A";

    const typePrefix = instanceType.split(".")[0];
    const typePrefixLetter = typePrefix.charAt(0);

    switch (typePrefixLetter) {
      case 't':
        return "General purpose";
      case 'm':
        return "General purpose";
      case 'c':
        return "Compute optimized";
      case 'r':
        return "Memory optimized";
      case 'x':
        return "Memory optimized";
      case 'i':
        return "Storage optimized";
      case 'd':
        return "Storage optimized";
      case 'g':
        return "GPU instance";
      case 'p':
        return "GPU instance";
      case 'f':
        return "FPGA Instances";
      case 'inf':
        return "Machine Learning ASIC Instances";
      case 'vt':
        return "Media Accelerator Instances";
      case 'a1':
      case 't4g':
        return "Micro instances";
      default:
        return "N/A";
    }
  }

  // 过滤器本地过滤
  filterProducts() {
    const { allProductItems, currentButtonState, currentPageIndex, itemsPerPage } = this.state;
    const filterText = currentButtonState.InstanceType.toLowerCase();
    const instanceFamilyFilter = currentButtonState.InstanceFamily.value.toLowerCase();
    const utilizationValue = currentButtonState.utilizationValue;
    const utilizationUnit = currentButtonState.utilizationUnit.value;

    const filteredItems = allProductItems.filter(item => {
      const memory = parseFloat(item.memory);
      const vcpu = parseInt(item.vcpu);
      const instanceType = item.instanceType.toLowerCase();
      const instanceFamily = item.instanceFamily.toLowerCase();
      const isServerless = instanceType === 'redshift serverless';

      // 添加 Utilization 过滤逻辑，只针对 On-Demand
      const isOnDemand = item.terms.OnDemand !== null;

      // if (currentButtonState.PurchaseOption.value === 'Serverless' && !isServerless) {
      //   return false;
      // } else if (currentButtonState.PurchaseOption.value !== 'Serverless' && isServerless) {
      //   return false;
      // }
      if (isServerless) {
        return true;
      }

      return (
        (!currentButtonState.Memory || memory === parseFloat(currentButtonState.Memory)) &&
        (!currentButtonState.vCPU || vcpu === parseInt(currentButtonState.vCPU)) &&
        (filterText === '' || instanceType.includes(filterText)) &&
        (instanceFamilyFilter === 'all' || instanceFamily === instanceFamilyFilter) &&
        (!isOnDemand || (utilizationValue >= 1 && utilizationValue <= 100))
      );
    });

    const startIndex = (currentPageIndex - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedItems = filteredItems.slice(startIndex, endIndex);

    this.setState({ productItems: paginatedItems, filteredLength: filteredItems.length });
  }


  // 请求数据
  async getData() {
    console.log('reach getdata()');
    const url = this.generateApiURL();

    if (!url) {
      this.setState({ errorMsg: "Please input a valid Instance Type in the Instance Type filter (example: t4g.nano)." });
      return;
    }

    const data = await this.fetchAllData(url);
    this.processData(data);
  }



  // RI实例的URl
  generateRIURL(instanceType = null) {
    const { Region } = this.state.currentButtonState;
    console.log('generateRIURL Region label: ', Region.label);
    console.log('selected items, ', this.state.selectedItems);
    const vCPU = this.state.selectedItems[0].vcpu;
    const Memory = this.state.selectedItems[0].memory;
    console.log('generateRIURL Memory: ', Memory);
    console.log('generateRIURL vCPU: ', vCPU);
    let { InstanceType } = this.state.currentButtonState;
    console.log('generateRIURL InstanceType1: ', InstanceType);
    if (!InstanceType) {
      if (this.state.selectedItems.length > 0) {
        InstanceType = this.state.selectedItems[0].instanceType;
        console.log('generateRIURL InstanceType2: ', InstanceType);
      } else {
        this.setState({
          errorMsg: "Please select an instance type, or enter an instance type (eg: t4g.nano) in Instance Type Filter to query Saving Plans",
          showModal: true
        });
        return null;
      }
    }
    const encodedRegion = this.encodeUrlComponent(Region.label);
    const encodedMemory = this.encodeUrlComponent(Memory);
    console.log('generateRIURL encodedRegion: ', encodedRegion);
    console.log('generateRIURL encodedMemory: ', encodedMemory);

    let url = `/pricing/2.0/meteredUnitMaps/redshift/USD/current/redshift-calc-new/${encodedRegion}/${InstanceType}/${vCPU}/${encodedMemory}/Reserved/index.json`;

    url = url.replace(/\/+/g, '/');
    console.log("Fetching RI URL: ", url);
    return url;
  }

  // 获取RI实例数据
  getRIData = async () => {
    try {
      const reservedUrl = this.generateRIURL();
      if (!reservedUrl) return;
      const reservedData = await this.fetchAllData(reservedUrl);

      console.log('fetched ReservedData url: ', reservedUrl);
      console.log('getRIData reservedData: ', reservedData);
      const updatedItems = this.state.allProductItems.map((item) => {
        const regionLabel = decodeURIComponent(this.state.currentButtonState.Region.label);
        console.log('getRIData regionLabel: ', regionLabel);
        const regionData = reservedData.regions[regionLabel];

        console.log('regionData: ', regionData);
        if (!regionData) {
          console.error("No data found for the selected region:", regionLabel);
          return item;
        }

        for (const key in regionData) {
          const RIItem = regionData[key];
          console.log('RIItem: ', RIItem);
          if (RIItem["Instance Type"] && RIItem["Instance Type"].toLowerCase() === item.instanceType.toLowerCase()) {
            if (!item.terms.Reserved) {
              item.terms.Reserved = [];
            }

            let reservedTerm = item.terms.Reserved.find(term =>
              term.purchaseOption === RIItem["PurchaseOption"] &&
              term.leaseContractLength === RIItem["LeaseContractLength"]
            );

            if (!reservedTerm) {
              reservedTerm = {
                purchaseOption: RIItem["PurchaseOption"],
                leaseContractLength: RIItem["LeaseContractLength"],
                hrsPrice: null,
                quantityPrice: null
              };
              item.terms.Reserved.push(reservedTerm);
            }

            if (RIItem.Unit === "Hrs") {
              reservedTerm.hrsPrice = RIItem.price;
            }

            if (RIItem.Unit === "Quantity") {
              reservedTerm.quantityPrice = RIItem.price;
            }
          }
        }
        console.log('what is RI data: ', item);
        return item;
      });

      const filterText = this.state.currentButtonState.InstanceType.toLowerCase();
      const selectedInstanceTypes = this.state.selectedItems.map(item => item.instanceType.toLowerCase());

      const filteredItems = updatedItems.filter(item =>
        (filterText === '' || item.instanceType.toLowerCase().includes(filterText)) &&
        (selectedInstanceTypes.length === 0 || selectedInstanceTypes.includes(item.instanceType.toLowerCase()))
      );

      console.log('get RI data filtered Items:', filteredItems);

      this.setState({ allProductItems: updatedItems, filteredProductItems: filteredItems }, this.filterProducts);
    } catch (error) {
      console.error('Fetching Saving Plans error:', error);
      return null;
    }
  };

  encodeUrlComponent(component) {
    return encodeURIComponent(component).replace(/%20/g, " ");
  }

  handleFilterChange = () => {
    const { PurchaseOption } = this.state.currentButtonState;

    this.setState({ clickButton: true, currentPageIndex: 1, errorMsg: "" }, () => {
      // if (PurchaseOption.value !== "OnDemand" && PurchaseOption.value !== "Serverless") {
      //   this.getRIData(); // 获取 Reserved 信息
      // } else {
      //   this.getData();
      // }
      this.getData();
    });
  };

  // 组件初始化
  async componentDidMount() {
    await this.getData();
    this.filterProducts();
  }

  // 组件状态更新
  async componentDidUpdate(prevProps, prevState) {
    // 检查是否需要发送API请求
    const needApiRequest = (
      prevState.currentButtonState.Region !== this.state.currentButtonState.Region
    );

    if (this.state.clickButton && needApiRequest) {
      await this.getData();
      this.setState({ clickButton: false });
    }

    // 处理本地筛选
    if (
      prevState.currentButtonState.InstanceFamily.value !== this.state.currentButtonState.InstanceFamily.value ||
      prevState.currentButtonState.Memory !== this.state.currentButtonState.Memory ||
      prevState.currentButtonState.vCPU !== this.state.currentButtonState.vCPU ||
      prevState.currentButtonState.InstanceType !== this.state.currentButtonState.InstanceType ||
      prevState.currentPageIndex !== this.state.currentPageIndex ||
      prevState.allProductItems !== this.state.allProductItems
    ) {
      this.filterProducts();
      const pagesCount = Math.ceil(this.state.filteredLength / this.state.itemsPerPage);
      if (this.state.currentPageIndex > pagesCount) {
        this.setState({ currentPageIndex: 1 });
      }
    }
  }

  handleSelectionChange = ({ detail }) => {
    const selectedItems = detail.selectedItems;
    const isServerless = selectedItems.length > 0 && selectedItems[0].instanceType === 'Redshift Serverless';

    this.setState({
      selectedItems,
      currentButtonState: {
        ...this.state.currentButtonState,
        isServerless,
      }
    });
  };

  // 添加购物车
  handleAddCart = async () => {
    const updatedItems = await Promise.all(this.state.selectedItems.map(async (item) => {
      console.log("handleAddCartFirstitem: ", item);

      item.nodes = this.state.currentButtonState.nodes;

      if (item.instanceType === 'Redshift Serverless') {
        item.baseRPU = this.state.currentButtonState.serverlessSettings.baseRPU;
        item.dailyRuntime = this.state.currentButtonState.serverlessSettings.dailyRuntime;
      } else {
        if (!item.terms.Reserved || item.terms.Reserved.length === 0) {
          const newItem = await this.getRIDataForItem(item);
          item = { ...item, ...newItem };
        }
      }

      console.log('handleAddCart after merge: ', item);
      const itemObject = redshiftCalculatePrices(item);

      console.log('After calculatePrices: ', itemObject);

      return {
        ...itemObject,
      };
    }));

    updatedItems.forEach(item => this.props.addToCart(item));
    this.setState({ selectedItems: [] });
  };

  // 获取RI数据
  async getRIDataForItem(item) {
    const reservedUrl = this.generateRIURL(item.instanceType);
    if (!reservedUrl) return item;

    const reservedData = await this.fetchAllData(reservedUrl);

    const regionLabel = decodeURIComponent(this.state.currentButtonState.Region.label);
    const regionData = reservedData.regions[regionLabel];

    if (!regionData) {
      console.error("No data found for the selected region:", regionLabel);
      return item;
    }

    for (const key in regionData) {
      const RIItem = regionData[key];
      if (RIItem["Instance Type"] && RIItem["Instance Type"].toLowerCase() === item.instanceType.toLowerCase()) {
        if (!item.terms.Reserved) {
          item.terms.Reserved = [];
        }

        let reservedTerm = item.terms.Reserved.find(term =>
          term.purchaseOption === RIItem["PurchaseOption"] &&
          term.leaseContractLength === RIItem["LeaseContractLength"]
        );

        if (!reservedTerm) {
          reservedTerm = {
            purchaseOption: RIItem["PurchaseOption"],
            leaseContractLength: RIItem["LeaseContractLength"],
            hrsPrice: null,
            quantityPrice: null
          };
          item.terms.Reserved.push(reservedTerm);
        }

        if (RIItem.Unit === "Hrs") {
          reservedTerm.hrsPrice = RIItem.price;
        }

        if (RIItem.Unit === "Quantity") {
          reservedTerm.quantityPrice = RIItem.price;
        }
      }
    }
    return item;
  }


  //渲染
  render() {
    const { isServerless } = this.state.currentButtonState;

    return (
      <Layout>
        <Modal
          visible={this.state.showModal}
          onDismiss={() => this.setState({ showModal: false })}
          header="Notes"
          footer={
            <Button onClick={() => this.setState({ showModal: false })}>Close</Button>
          }
        >
          <Box>{this.state.errorMsg}</Box>
        </Modal>
        <Table
          onSelectionChange={this.onSelectionChange}
          selectedItems={this.state.selectedItems}
          ariaLabels={{
            selectionGroupLabel: "Items selection",
            allItemsSelectionLabel: ({ selectedItems }) =>
              `${selectedItems.length} ${selectedItems.length === 1 ? "item" : "items"} selected`,
            itemSelectionLabel: ({ selectedItems }, item) =>
              item.InstanceType
          }}
          columnDefinitions={[
            {
              id: "instanceType",
              header: "Instance Type",
              cell: item => item.instanceType,
              sortingField: "instanceType",
              isRowHeader: true
            },
            {
              id: "instanceFamily",
              header: "Instance Family",
              cell: item => item.instanceFamily,
            },
            {
              id: "regionCode",
              header: "Region Code",
              cell: item => item.regionCode,
              sortingField: "regionCode"
            },
            {
              id: "vcpu",
              header: "vCPU",
              cell: item => item.vcpu,
              sortingField: "vcpu"
            },
            {
              id: "memory",
              header: "Memory",
              cell: item => item.memory,
              sortingField: "memory"
            },
            {
              id: "storage",
              header: "Storage",
              cell: item => item.storage,
            },
            {
              id: "pricePerUnit",
              header: "OnDemand Price",
              cell: item => {
                let price = "N/A";
                if (item.terms && item.terms.OnDemand) {
                  price = item.terms.OnDemand.price;
                } else if (isServerless) {
                  price = item.ServerlessPrice;
                }
                return price !== "N/A" ? parseFloat(price).toFixed(2) : price;  // 保留两位小数
              },
            },
            {
              id: "upfrontFee",
              header: "Upfront Fee",
              cell: item => {
                let price = "N/A";
                if (item.terms && item.terms.Reserved && item.terms.Reserved.length > 0) {
                  price = item.terms.Reserved[0].quantityPrice;
                }
                return price !== "N/A" ? parseFloat(price).toFixed(2) : price;  // 保留两位小数
              },
            }
          ]}
          columnDisplay={[
            { id: "instanceType", visible: true },
            { id: "instanceFamily", visible: true },
            { id: "regionCode", visible: true },
            { id: "vcpu", visible: true },
            { id: "memory", visible: true },
            { id: "gpuMemory", visible: true },
            { id: "storage", visible: true },
            { id: "pricePerUnit", visible: this.state.currentButtonState.Upfront !== "All Upfront" },
            { id: "upfrontFee", visible: false }
          ]}
          enableKeyboardNavigation
          items={this.state.productItems}
          // loading={this.state.productItems.length === 0}
          loadingText="No Instances based on your input"
          selectionType="multi"
          stickyColumns={{ first: 0, last: 1 }}
          trackBy="instanceType"

          empty={
            <Box
              margin={{ vertical: "xs" }}
              textAlign="center"
              color="inherit"
            >
              <SpaceBetween size="m">
                <b>No Instances based on your input</b>
                {/*<Button>Create resource</Button>*/}
              </SpaceBetween>
            </Box>
          }
          filter={
            <div className="filter-container" key="filter-container">
              <div className="select-filter" key="region-filter">
                <FormField label="Region">
                  <RegionFilter
                    selectedOption={this.state.currentButtonState.Region}
                    setSelectedOption={selectedOption => this.setState({ currentButtonState: { ...this.state.currentButtonState, Region: selectedOption }, clickButton: true })}
                    onFilterChange={this.handleFilterChange}
                  />
                </FormField>
              </div>
              <div className="select-filter" key="instance-family-filter-filter">
                <FormField label="Instance Family">
                  <InstanceFamilyFilter
                    selectedOption={this.state.currentButtonState.InstanceFamily}
                    setSelectedOption={selectedOption => this.setState({ currentButtonState: { ...this.state.currentButtonState, InstanceFamily: selectedOption }, clickButton: true })}
                    onFilterChange={this.handleFilterChange}
                  />
                </FormField>
              </div>
              {/* <div className="select-filter" key="purchase-option-filter">
                <FormField label="Purchase Option">
                  <PurchaseOptionFilter
                    selectedOption={this.state.currentButtonState.PurchaseOption}
                    setSelectedOption={selectedOption => {
                      console.log('Setting PurchaseOption:', selectedOption);
                      this.setState({
                        currentButtonState: {
                          ...this.state.currentButtonState,
                          PurchaseOption: selectedOption
                        },
                        clickButton: true,
                        updatePurchaseOption: true
                      }, () => {
                        console.log('State after setSelectedOption', this.state);
                        this.handleFilterChange(); //添加这行解决报错
                      });
                    }}
                    onFilterChange={this.handleFilterChange}
                    disableRIOptions={!this.state.currentButtonState.InstanceType && this.state.selectedItems.length === 0}
                  />
                </FormField>
              </div> */}
              <div className="input-filter" key="vcpu-filter">
                <FormField label="vCPU (Optional)">
                  <VCPUFilter
                    filteringText={this.state.currentButtonState.vCPU}
                    setFilteringText={filteringText => this.setState({ currentButtonState: { ...this.state.currentButtonState, vCPU: filteringText }, clickButton: true })}
                    onFilterChange={this.handleFilterChange}
                  />
                </FormField>
              </div>
              <div className="input-filter" key="memory-filter">
                <FormField label="Memory (Optional)">
                  <MemoryFilter
                    filteringText={this.state.currentButtonState.Memory}
                    setFilteringText={filteringText => this.setState({ currentButtonState: { ...this.state.currentButtonState, Memory: filteringText }, clickButton: true })}
                    onFilterChange={this.handleFilterChange}
                  />
                </FormField>
              </div>

              <div className="input-filter" key="instance-type-filter">
                <FormField label="Instance Type (Optional)">
                  <InstanceTypeFilter
                    filteringText={this.state.currentButtonState.InstanceType}
                    setFilteringText={filteringText => this.setState({ currentButtonState: { ...this.state.currentButtonState, InstanceType: filteringText }, clickButton: true })}
                    onFilterChange={this.handleFilterChange}
                  />
                </FormField>
              </div>
              <div className="input-filter" key="utilization-filter">
                <UtilizationFilter
                  utilizationValue={this.state.currentButtonState.utilizationValue}
                  setUtilizationValue={(value) => this.setState({ currentButtonState: { ...this.state.currentButtonState, utilizationValue: value }, clickButton: true })}
                  utilizationUnit={this.state.currentButtonState.utilizationUnit}
                  setUtilizationUnit={(unit) => this.setState({ currentButtonState: { ...this.state.currentButtonState, utilizationUnit: unit }, clickButton: true })}
                  onFilterChange={this.handleFilterChange}
                />
              </div>
              <div className="input-filter" key="nodes-filter">
                <FormField label="Nodes">
                  <NodesFilter
                    nodesValue={this.state.currentButtonState.nodes}
                    setNodesValue={(value) => this.setState({ currentButtonState: { ...this.state.currentButtonState, nodes: value }, clickButton: true })}
                    onFilterChange={this.handleFilterChange}
                  />
                </FormField>
              </div>
              {isServerless && (
                <div className="serverless-settings" key="serverless-settings">
                  <RedshiftServerlessSettings
                    serverlessSettings={this.state.currentButtonState.serverlessSettings}
                    setServerlessSettings={(newSettings) => this.setState({
                      currentButtonState: {
                        ...this.state.currentButtonState,
                        serverlessSettings: newSettings
                      }
                    })}
                  />
                </div>
              )}
            </div>
          }
          header={
            <Header
              counter={
                this.state.selectedItems.length
                  ? "(" + this.state.selectedItems.length + "/" + this.state.filteredLength + ")"
                  : "(" + this.state.filteredLength + ")" //display the entire length of the filtered instances
              }
              actions={
                <SpaceBetween
                  direction="horizontal"
                  size="xs"
                >
                  <Button onClick={this.handleSortByPrice}>
                    Sort by price {this.state.sortByPrice === "asc" ? "↑" : "↓"}
                  </Button>
                  <Button variant="primary" onClick={this.handleAddCart}>
                    Add to Cart
                  </Button>
                  <Link to="/cart">
                    <Button variant="primary">
                      Go to Cart
                    </Button>
                  </Link>
                </SpaceBetween>
              }
            >
              Amazon Redshift
            </Header>
          }

          pagination={
            <div className="App-pagination">
              <span>Show Rows &nbsp;</span>
              <ItemsPerPageDropdown
                itemsPerPageOptions={this.state.itemsPerPageOptions}
                itemsPerPage={this.state.itemsPerPage}
                setItemsPerPage={this.handleItemsPerPageChange}
              />
              <PaginationSetting
                currentPageIndex={this.state.currentPageIndex}
                setCurrentPageIndex={(currentPageIndex) => this.handleNextPage(currentPageIndex)}
                nextPage={this.nextPage}
                totalItems={this.state.filteredLength}
                itemsPerPage={this.state.itemsPerPage}
                onPageChange={this.handlePageChange}
              />
            </div> //add instance display drop down and the show rows text label
          }
        />
      </Layout>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addToCart: (item) => dispatch(addToCart(item)),
});

export default connect(null, mapDispatchToProps)(RedshiftProductTable);