import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { thunk } from 'redux-thunk';
import Cookie from 'js-cookie';
import { cartReducer } from './reducers/cartReducers';

const cartItems = JSON.parse((localStorage.getItem("cartItem")) || '[]');
// const cartItems = [];
// console.log("cookie");
// console.log(cartItems);
const initialState = {
  cart: { cartItems: cartItems },
};
const reducer = combineReducers({
  cart: cartReducer,
});
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);
export default store;