import { v4 as uuidv4 } from 'uuid';

export const redshiftCalculatePrices = (item) => {
    console.log('item in price calculating: ', item);

    const nodes = item.nodes;
    const utilizationValue = item.utilizationValue;
    const utilizationUnit = item.utilizationUnit.value;

    let onDemandPricePerHour = parseFloat(item.terms.OnDemand.price);
    let onDemandPrice = "N/A";
    let upfrontFee = 0;

    switch (utilizationUnit) {
        case "%Utilized/Month":
            onDemandPrice = (nodes * onDemandPricePerHour * (utilizationValue / 100) * 730).toFixed(2);
            break;
        case "Hours/Day":
            onDemandPrice = (nodes * onDemandPricePerHour * (utilizationValue / 24) * 730).toFixed(2);
            break;
        case "Hours/Week":
            onDemandPrice = (nodes * onDemandPricePerHour * (utilizationValue / 168) * 730).toFixed(2);
            break;
        case "Hours/Month":
            onDemandPrice = (nodes * onDemandPricePerHour * utilizationValue).toFixed(2);
            break;
        default:
            break;
    }

    //不是OD选项，默认OD价格为当前Nodes的百分百使用率价格
    if (onDemandPrice === "N/A") {
        onDemandPrice = (nodes * onDemandPricePerHour * 730).toFixed(2);
    }
    console.log('onDemandPrice:', onDemandPrice);

    let reservedNoUpfrontMonthly = 'N/A';
    let partialUpfrontMonthly = 'N/A';
    let partialUpfrontFee = 'N/A';
    let allUpfrontPrice = 'N/A';
    let sku_tail = "";

    let RI_All_1yr = ""; // RI 1 all
    let UF_RI_All_1yr = "";
    let RI_All_3yr = ""; // RI 3 all
    let UF_RI_All_3yr = "";
    let RI_Pt_1yr = "";
    let UF_RI_Pt_1yr = "";
    let RI_Pt_3yr = "";
    let UF_RI_Pt_3yr = "";
    let RI_No_1yr = "";
    let UF_RI_No_1yr = "";
    let RI_No_3yr = "";
    let UF_RI_No_3yr = "";
    let Serverless = "";

    let CP_RI_All_1yr = "";
    let CP_RI_All_3yr = "";
    let CP_RI_Pt_1yr = "";
    let CP_RI_Pt_3yr = "";
    let CP_RI_No_1yr = "";
    let CP_RI_No_3yr = "";

    if (item.instanceType === 'Redshift Serverless') {
        const baseRPU = parseFloat(item.baseRPU);
        const dailyRuntime = parseFloat(item.dailyRuntime);
        const perUnitPrice = parseFloat(item.ServerlessPrice);
        Serverless = (baseRPU * dailyRuntime * perUnitPrice * 30.5).toFixed(2);
    }

    console.log('Serverless Price: ', Serverless);

    console.log('price item: ', item);
    if (item.terms && item.terms.Reserved) {
        const terms = item.terms.Reserved;
        console.log('Reserved terms', terms);

        terms.forEach(term => {
            const option = term.purchaseOption;
            let reservedMonthlyPrice = parseFloat(term.hrsPrice) * 730;
            let reservedNoUpfrontMonthly = reservedMonthlyPrice.toFixed(2);
            console.log('Reserved option:', option, 'Class:', term.offeringClass, 'Length:', term.leaseContractLength, 'Price:', reservedNoUpfrontMonthly);

            let leaseLength = "";
            switch (option) {
                case 'No Upfront':
                    reservedNoUpfrontMonthly = (nodes * reservedMonthlyPrice).toFixed(2);
                    if (term.leaseContractLength === '1yr') {
                        RI_No_1yr = reservedNoUpfrontMonthly;
                        UF_RI_No_1yr = 0;
                        CP_RI_No_1yr = (parseFloat(reservedNoUpfrontMonthly) / parseFloat(onDemandPrice)).toFixed(2);
                    } else {
                        RI_No_3yr = reservedNoUpfrontMonthly;
                        UF_RI_No_3yr = 0;
                        CP_RI_No_3yr = (parseFloat(reservedNoUpfrontMonthly) / parseFloat(onDemandPrice)).toFixed(2);
                    }
                    break;
                case 'Partial Upfront':
                    partialUpfrontMonthly = (nodes * reservedMonthlyPrice).toFixed(2);
                    partialUpfrontFee = nodes * parseFloat(term.quantityPrice);
                    leaseLength = term.leaseContractLength === '1yr' ? 1 : 3;
                    const partialUpfrontFeePerMonth = (partialUpfrontFee / (12 * leaseLength)).toFixed(2);
                    const partialTotalMonth = (parseFloat(partialUpfrontMonthly) + parseFloat(partialUpfrontFeePerMonth)).toFixed(2);
                    console.log('Partial Upfront:', partialTotalMonth, 'Fee:', partialUpfrontFee);

                    if (term.leaseContractLength === '1yr') {
                        RI_Pt_1yr = partialTotalMonth;
                        UF_RI_Pt_1yr = partialUpfrontFee;
                        CP_RI_Pt_1yr = (parseFloat(partialTotalMonth) / parseFloat(onDemandPrice)).toFixed(2);
                    } else {
                        RI_Pt_3yr = partialTotalMonth;
                        UF_RI_Pt_3yr = partialUpfrontFee;
                        CP_RI_Pt_3yr = (parseFloat(partialTotalMonth) / parseFloat(onDemandPrice)).toFixed(2);
                    }
                    break;
                case 'All Upfront':
                    allUpfrontPrice = nodes * parseFloat(term.quantityPrice);
                    leaseLength = term.leaseContractLength === '1yr' ? 1 : 3;
                    const allUpfrontPricePerMonth = (allUpfrontPrice / (12 * leaseLength)).toFixed(2);
                    console.log('All Upfront:', allUpfrontPricePerMonth, 'Total:', allUpfrontPrice);

                    if (term.leaseContractLength === '1yr') {
                        RI_All_1yr = allUpfrontPricePerMonth;
                        UF_RI_All_1yr = allUpfrontPrice;
                        CP_RI_All_1yr = (parseFloat(allUpfrontPricePerMonth) / parseFloat(onDemandPrice)).toFixed(2);
                    } else {
                        RI_All_3yr = allUpfrontPricePerMonth;
                        UF_RI_All_3yr = allUpfrontPrice;
                        CP_RI_All_3yr = (parseFloat(allUpfrontPricePerMonth) / parseFloat(onDemandPrice)).toFixed(2);
                    }
                    break;
            }
        });
    }

    if (item.instanceType === 'Redshift Serverless') {
        onDemandPrice = 0.0;
    }
    const itemObject = {
        sku: uuidv4(),
        sku_details: `${item.instanceType}-${item.regionCode}-${item.operatingSystem}-${sku_tail}`,
        product: {
            attributes: {
                region: item.regionCode,
                instanceType: item.instanceType,
                vcpu: item.vcpu,
                memory: item.memory,
                storage: item.storage,
                networkPerformance: item.networkPerformance,
                operatingSystem: item.operatingSystem,
            }
        },
        quantity: 1,
        ServiceName: "Redshift",
        terms: item.terms,
        upfrontFee: item.upfrontFee,
        Reserved_All_Upfront_1yr_per_Month: RI_All_1yr, // RI 1 all
        UF_Reserved_All_Upfront_1yr: UF_RI_All_1yr,
        Reserved_All_Upfront_3yr_per_Month: RI_All_3yr, // RI 3 all
        UF_Reserved_All_Upfront_3yr: UF_RI_All_3yr,
        Reserved_Partial_Upfront_1yr_per_Month: RI_Pt_1yr,
        UF_Reserved_Partial_Upfront_1yr: UF_RI_Pt_1yr,
        Reserved_Partial_Upfront_3yr_per_Month: RI_Pt_3yr,
        UF_Reserved_Partial_Upfront_3yr: UF_RI_Pt_3yr,
        Reserved_No_Upfront_1yr_per_Month: RI_No_1yr,
        UF_Reserved_No_Upfront_1yr: UF_RI_No_1yr,
        Reserved_No_Upfront_3yr_per_Month: RI_No_3yr,
        UF_Reserved_No_Upfront_3yr: UF_RI_No_3yr,
        ServerlessPrice: Serverless,
        OnDemand_1Month: onDemandPrice,
        Compare_CP_RI_All_1yr: CP_RI_All_1yr,
        Compare_CP_RI_All_3yr: CP_RI_All_3yr,
        Compare_CP_RI_Pt_1yr: CP_RI_Pt_1yr,
        Compare_CP_RI_Pt_3yr: CP_RI_Pt_3yr,
        Compare_CP_RI_No_1yr: CP_RI_No_1yr,
        Compare_CP_RI_No_3yr: CP_RI_No_3yr
    };

    console.log('check price calculating page itemObject: ', itemObject);
    return {
        ...itemObject,
    };
};
