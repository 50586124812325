import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import ProductTable from "./components/ProductTable";
import CartPage from "./components/CartPage";
import RedshiftProductTable from './components/RedshiftProductTable';
import ElastiCache from "./components/ElastiCache";
import AuroraMySQLProductTable from "./components/AuroraMySQLProductTable";
import AuroraPostgreSQLProductTable from "./components/AuroraPostgreSQLProductTable";
import HomePage from './components/HomePage';
import EC2RegionTable from "./components/EC2RegionTable";
import MySQL from "./components/MySQL";
import PostgreSQL from "./components/PostgreSQL";
import MariaDB from "./components/MariaDB";
import Oracle from "./components/Oracle";
import SQLServer from "./components/SQLServer";
import Sagemaker from "./components/Sagemaker";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/ec2" element={<ProductTable />} />
        <Route path = "/ec2-region" element={<EC2RegionTable />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/redshift" element={<RedshiftProductTable />} />
        <Route path="/elastiCache" element={<ElastiCache />} />
        <Route path="/aurora-mysql" element={<AuroraMySQLProductTable />} />
        <Route path="/aurora-postgresql" element={<AuroraPostgreSQLProductTable />} />
        <Route path="/rds-mysql" element={<MySQL/>} />
        <Route path="/rds-postgresql" element={<PostgreSQL/>} />
        <Route path="/rds-mariadb" element={<MariaDB/>} />
        <Route path="/rds-oracle" element={<Oracle/>} />
        <Route path="/rds-sqlserver" element={<SQLServer/>} />
        <Route path="/sagemaker" element={<Sagemaker />} />
        <Route path="/index.html?" element={<HomePage />} />
      </Routes>
    </Router>
  );
}

export default App;