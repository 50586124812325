import "./SelectFilter.css";
// import Modal from 'react-modal';
import React, { useEffect, useState } from "react";
import Layout from "../layout/appLayout";
import { FormField, Container, SpaceBetween } from '@cloudscape-design/components';
import Table from "@cloudscape-design/components/table";
import Box from "@cloudscape-design/components/box";
// import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import TextFilter from "@cloudscape-design/components/text-filter";
import Header from "@cloudscape-design/components/header";
import ButtonDropdown from "@cloudscape-design/components/button-dropdown";
import Pagination from "@cloudscape-design/components/pagination";
import CollectionPreferences from "@cloudscape-design/components/collection-preferences";
import Select from "@cloudscape-design/components/select";
import { PricingClient, GetProductsCommand, paginateGetProducts } from "@aws-sdk/client-pricing";
import { connect } from 'react-redux';
import { addToCart } from "../redux/actions/cartActions";
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Toggle from "@cloudscape-design/components/toggle";

//Region选项
const regionOptions = [
    { label: "US East (N. Virginia)", value: "US East (N. Virginia)" },
    { label: "US East (Ohio)", value: "US East (Ohio)" },
    { label: "US West (N. California)", value: "US West (N. California)" },
    { label: "US West (Oregon)", value: "US West (Oregon)" },
    { label: "Canada (Central)", value: "Canada (Central)" },
    { label: "Canada West (Calgary)", value: "Canada West (Calgary)" },
    { label: "Africa (Cape Town)", value: "Africa (Cape Town)" },
    { label: "Asia Pacific (Hong Kong)", value: "Asia Pacific (Hong Kong)" },
    { label: "Asia Pacific (Hyderabad)", value: "Asia Pacific (Hyderabad)" },
    { label: "Asia Pacific (Jakarta)", value: "Asia Pacific (Jakarta)" },
    { label: "Asia Pacific (Melbourne)", value: "Asia Pacific (Melbourne)" },
    { label: "Asia Pacific (Mumbai)", value: "Asia Pacific (Mumbai)" },
    { label: "Asia Pacific (Osaka)", value: "Asia Pacific (Osaka)" },
    { label: "Asia Pacific (Seoul)", value: "Asia Pacific (Seoul)" },
    { label: "Asia Pacific (Singapore)", value: "Asia Pacific (Singapore)" },
    { label: "Asia Pacific (Sydney)", value: "Asia Pacific (Sydney)" },
    { label: "Asia Pacific (Tokyo)", value: "Asia Pacific (Tokyo)" },
    { label: "EU (Frankfurt)", value: "EU (Frankfurt)" },
    { label: "EU (Ireland)", value: "EU (Ireland)" },
    { label: "EU (London)", value: "EU (London)" },
    { label: "EU (Milan)", value: "EU (Milan)" },
    { label: "EU (Paris)", value: "EU (Paris)" },
    { label: "EU (Spain)", value: "EU (Spain)" },
    { label: "EU (Stockholm)", value: "EU (Stockholm)" },
    { label: "EU (Zurich)", value: "EU (Zurich)" },
    { label: "Israel (Tel Aviv)", value: "Israel (Tel Aviv)" },
    { label: "Middle East (Bahrain)", value: "Middle East (Bahrain)" },
    { label: "Middle East (UAE)", value: "Middle East (UAE)" },
    { label: "South America (Sao Paulo)", value: "South America (Sao Paulo)" },
    { label: "AWS GovCloud (US-West)", value: "AWS GovCloud (US)" },
    { label: "AWS GovCloud (US-East)", value: "AWS GovCloud (US-East)" },
];


//Region下拉框
const SagemakerRegionFilter = (props) => {
    const [selectedOption, setSelectedOption] = React.useState(props.selectedOption);


    return (
        <Select
            selectedAriaLabel="Selected"
            selectedOption={selectedOption}
            onChange={({ detail }) => {
                setSelectedOption(detail.selectedOption);
                props.onFilterChange(detail.selectedOption.value); // Update the region in the parent component
            }}
            options={regionOptions}
            ariaDescribedby={null}
            expandToViewport={true}
        />
    );
};


//Number of Data Scientists输入框
const NumberOfDataScientists = (props) => {
    const [filteringText, setFilteringText] = React.useState(props.filteringText);
    const [warningMessage, setWarningMessage] = React.useState('');

    const handleInputChange = ({ detail }) => {
        const numericValue = detail.filteringText.replace(/[^0-9]/g, ''); // 只允许数字
        const parsedValue = parseInt(numericValue, 10);

        if (parsedValue <= 0 || isNaN(parsedValue)) {
            setWarningMessage('Number of data scientist(s) is required.');
        } else if (parsedValue > 10000000000000) {
            setWarningMessage('Number of data scientist(s) can\'t be more than 10000000000000.');
        } else {
            setWarningMessage('');
        }

        setFilteringText(numericValue);
        props.onFilterChange(numericValue);
    };

    return (
        <div>
            <TextFilter
                filteringText={filteringText}
                filteringPlaceholder="Enter the number of data scientist(s) per month"
                filteringAriaLabel="Filter Number of Data Scientist(s)"
                onChange={handleInputChange}
            />
            {warningMessage && <div style={{ color: 'red' }}>{warningMessage}</div>}
        </div>
    );
};

//Number of Studio Notebook输入框
const NumberOfStudioNotebook = (props) => {
    const [filteringText, setFilteringText] = React.useState(props.filteringText);
    const [warningMessage, setWarningMessage] = React.useState('');

    const handleInputChange = ({ detail }) => {
        const numericValue = detail.filteringText.replace(/[^0-9]/g, ''); // 只允许数字
        const parsedValue = parseInt(numericValue, 10);

        if (parsedValue <= 0 || isNaN(parsedValue)) {
            setWarningMessage('Number of Studio Notebook instances per data scientist is required.');
        } else if (parsedValue > 10000000000000) {
            setWarningMessage('Number of Studio Notebook instances per data scientist can\'t be more than 10000000000000.');
        } else {
            setWarningMessage('');
        }

        setFilteringText(numericValue);
        props.onFilterChange(numericValue);
    };

    return (
        <div>
            <TextFilter
                filteringText={filteringText}
                filteringPlaceholder="Enter the number of Studio Notebook instances per data scientist per month"
                filteringAriaLabel="Filter Number of Studio Notebook instances per data scientist"
                onChange={handleInputChange}
            />
            {warningMessage && <div style={{ color: 'red' }}>{warningMessage}</div>}
        </div>
    );
};

//Number of Notebook hours输入框
const NumberOfNotebookHours = (props) => {
    const [filteringText, setFilteringText] = React.useState(props.filteringText);
    const [warningMessage, setWarningMessage] = React.useState('');

    const handleInputChange = ({ detail }) => {
        const numericValue = detail.filteringText.replace(/[^0-9.]/g, ''); // 只允许数字
        const parsedValue = parseFloat(numericValue);

        if (parsedValue < 0.01) {
            setWarningMessage('Studio Notebook hour(s) per day can\'t be less than 0.01.');
        } else if (isNaN(parsedValue)){
            setWarningMessage('Studio Notebook hour(s) per day is required.');
        }
        else if (parsedValue > 24) {
            setWarningMessage('Studio Notebook hour(s) per day can\'t be more than 24.');
        } else {
            setWarningMessage('');
        }

        setFilteringText(numericValue);
        props.onFilterChange(numericValue);
    };

    return (
        <div>
            <TextFilter
                filteringText={filteringText}
                filteringPlaceholder="Enter the number of hour(s) Studio Notebooks are used per day"
                filteringAriaLabel="Filter Notebook hour(s) per day"
                onChange={handleInputChange}
            />
            {warningMessage && <div style={{ color: 'red' }}>{warningMessage}</div>}
        </div>
    );
};

//Number of Notebook days per month输入框
const NumberOfNotebookDays = (props) => {
    const [filteringText, setFilteringText] = React.useState(props.filteringText);
    const [warningMessage, setWarningMessage] = React.useState('');

    const handleInputChange = ({ detail }) => {
        const numericValue = detail.filteringText.replace(/[^0-9]/g, ''); // 只允许数字
        const parsedValue = parseInt(numericValue, 10);

        if (parsedValue < 1) {
            setWarningMessage('Studio Notebook day(s) per month can\'t be less than 1.');
        } else if (isNaN(parsedValue)){
            setWarningMessage('Studio Notebook day(s) per month is required.');
        }
        else if (parsedValue > 31) {
            setWarningMessage('Studio Notebook day(s) per month can\'t be more than 31.');
        } else {
            setWarningMessage('');
        }
        setFilteringText(numericValue);
        props.onFilterChange(numericValue);
    };

    return (
        <div>
            <TextFilter
                filteringText={filteringText}
                filteringPlaceholder="Enter the number of days Studio Notebooks are used per month"
                filteringAriaLabel="Filter Notebook day(s) per month"
                onChange={handleInputChange}
            />
            {warningMessage && <div style={{ color: 'red' }}>{warningMessage}</div>}
        </div>
    );
};


//Studio Notebooks的Instance Type下拉选择框
const InstanceTypeFilter = ({ termType, cacheEngine, region, onInstanceTypeChange, onInstanceSelected}) => {
    const [instanceOptions, setInstanceOptions] = useState([]);
    const [selectedInstance, setSelectedInstance] = useState('');

    useEffect(() => {
        const fetchInstanceTypes = async () => {
            // Assuming the URL needs to reflect the region dynamically
            const response = await fetch(`/pricing/2.0/meteredUnitMaps/sagemaker/USD/current/sagemaker-studionotebook-instances-calc/${encodeURIComponent(region)}/primary-selector-aggregations.json`);
            const data = await response.json();
            const filteredInstances = data.aggregations.map(item => ({
                label: `${item.selectors['Instance Name']}`,
                value: item.selectors['Instance Name'],
                tags: [`vCPU:  ${item.selectors['vCPU']}`, `Memory:  ${item.selectors['Memory']}`, `Clock Speed:  ${item.selectors['Clock Speed']}`, `GPU:  ${item.selectors['GPU']}\n`, `Network Performance:  ${item.selectors['Network Performance']}`, `Storage:  ${item.selectors['Storage']}`, `GPU Memory:  ${item.selectors['GPU Memory']}`],
                selectors: item.selectors,
                totalcount: item.total_count
            }));
            // console.log(filteredInstances)
            setInstanceOptions(filteredInstances);
            if (filteredInstances.length > 0) {
                setSelectedInstance(filteredInstances[0].value);
                onInstanceTypeChange(filteredInstances[0].value);
                onInstanceSelected(filteredInstances[0]);
            }
        };
        // // console.log(1)
        fetchInstanceTypes();
    }, [region]);  // Dependencies adjusted

    const handleInstanceChange = ({ detail }) => {
        setSelectedInstance(detail.selectedOption.value);
        onInstanceTypeChange(detail.selectedOption.value);
        onInstanceSelected(detail.selectedOption);
    };

    return (
        <Select
            selectedOption={selectedInstance ? { label: instanceOptions.find(opt => opt.value === selectedInstance).label, value: selectedInstance } : null}
            options={instanceOptions}
            onChange={handleInstanceChange}
            ariaDescribedby={null}
            expandToViewport={true}
        />
    );
};



//On-Demand Notebook中Number of data scientists输入框
const NumberOfOnDemandDataScientists = (props) => {
    const [filteringText, setFilteringText] = React.useState(props.filteringText);
    const [warningMessage, setWarningMessage] = React.useState('');

    const handleInputChange = ({ detail }) => {
        const numericValue = detail.filteringText.replace(/[^0-9]/g, ''); // 只允许数字
        const parsedValue = parseInt(numericValue, 10);

        if (parsedValue <= 0 || isNaN(parsedValue)) {
            setWarningMessage('Number of data scientist(s) is required.');
        } else if (parsedValue > 10000000000000) {
            setWarningMessage('Number of data scientist(s) can\'t be more than 10000000000000.');
        } else {
            setWarningMessage('');
        }

        setFilteringText(numericValue);
        props.onFilterChange(numericValue);
    };

    return (
        <div>
            <TextFilter
                filteringText={filteringText}
                filteringPlaceholder="Enter the number of data scientist(s) per month"
                filteringAriaLabel="Filter Number of Data Scientist(s)"
                onChange={handleInputChange}
            />
            {warningMessage && <div style={{ color: 'red' }}>{warningMessage}</div>}
        </div>
    );
};

//On-Demand Notebook中Number of Notebook输入框
const NumberOfOnDemandNotebook = (props) => {
    const [filteringText, setFilteringText] = React.useState(props.filteringText);
    const [warningMessage, setWarningMessage] = React.useState('');

    const handleInputChange = ({ detail }) => {
        const numericValue = detail.filteringText.replace(/[^0-9]/g, ''); // 只允许数字
        const parsedValue = parseInt(numericValue, 10);

        if (parsedValue <= 0 || isNaN(parsedValue)) {
            setWarningMessage('Number of Studio Notebook instances per data scientist is required.');
        } else if (parsedValue > 10000000000000) {
            setWarningMessage('Number of Studio Notebook instances per data scientist can\'t be more than 10000000000000.');
        } else {
            setWarningMessage('');
        }

        setFilteringText(numericValue);
        props.onFilterChange(numericValue);
    };

    return (
        <div>
            <TextFilter
                filteringText={filteringText}
                filteringPlaceholder="Enter the number of Studio Notebook instances per data scientist per month"
                filteringAriaLabel="Filter Number of Studio Notebook instances per data scientist"
                onChange={handleInputChange}
            />
            {warningMessage && <div style={{ color: 'red' }}>{warningMessage}</div>}
        </div>
    );
};

//On-Demand Notebook中Number of Notebook hours输入框
const NumberOfOnDemandNotebookHours = (props) => {
    const [filteringText, setFilteringText] = React.useState(props.filteringText);
    const [warningMessage, setWarningMessage] = React.useState('');

    const handleInputChange = ({ detail }) => {
        const numericValue = detail.filteringText.replace(/[^0-9.]/g, ''); // 只允许数字
        const parsedValue = parseFloat(numericValue);

        if (parsedValue < 0.01) {
            setWarningMessage('Studio Notebook hour(s) per day can\'t be less than 0.01.');
        } else if (isNaN(parsedValue)){
            setWarningMessage('Studio Notebook hour(s) per day is required.');
        }
        else if (parsedValue > 24) {
            setWarningMessage('Studio Notebook hour(s) per day can\'t be more than 24.');
        } else {
            setWarningMessage('');
        }

        setFilteringText(numericValue);
        props.onFilterChange(numericValue);
    };

    return (
        <div>
            <TextFilter
                filteringText={filteringText}
                filteringPlaceholder="Enter the number of hour(s) Studio Notebooks are used per day"
                filteringAriaLabel="Filter Notebook hour(s) per day"
                onChange={handleInputChange}
            />
            {warningMessage && <div style={{ color: 'red' }}>{warningMessage}</div>}
        </div>
    );
};

//On-Demand Notebook中Days per month输入框
const NumberOfOnDemandNotebookDays = (props) => {
    const [filteringText, setFilteringText] = React.useState(props.filteringText);
    const [warningMessage, setWarningMessage] = React.useState('');

    const handleInputChange = ({ detail }) => {
        const numericValue = detail.filteringText.replace(/[^0-9]/g, ''); // 只允许数字
        const parsedValue = parseInt(numericValue, 10);

        if (parsedValue < 1) {
            setWarningMessage('Studio Notebook day(s) per month can\'t be less than 1.');
        } else if (isNaN(parsedValue)){
            setWarningMessage('Studio Notebook day(s) per month is required.');
        }
        else if (parsedValue > 31) {
            setWarningMessage('Studio Notebook day(s) per month can\'t be more than 31.');
        } else {
            setWarningMessage('');
        }
        setFilteringText(numericValue);
        props.onFilterChange(numericValue);
    };

    return (
        <div>
            <TextFilter
                filteringText={filteringText}
                filteringPlaceholder="Enter the number of days Studio Notebooks are used per month"
                filteringAriaLabel="Filter Notebook day(s) per month"
                onChange={handleInputChange}
            />
            {warningMessage && <div style={{ color: 'red' }}>{warningMessage}</div>}
        </div>
    );
};

//On-Demand Notebook中Instance Type下拉选择框
const OnDemandInstanceTypeFilter = ({region, onInstanceTypeChange, onInstanceSelected}) => {
    const [instanceOptions, setInstanceOptions] = useState([]);
    const [selectedInstance, setSelectedInstance] = useState('');

    useEffect(() => {
        const fetchInstanceTypes = async () => {
            // Assuming the URL needs to reflect the region dynamically
            const response = await fetch(`/pricing/2.0/meteredUnitMaps/sagemaker/USD/current/sagemaker-notebook-instances-calc/${encodeURIComponent(region)}/primary-selector-aggregations.json`);
            const data = await response.json();
            const filteredInstances = data.aggregations.map(item => ({
                label: `${item.selectors['Instance Name']}`,
                value: item.selectors['Instance Name'],
                tags: [`vCPU:  ${item.selectors['vCPU']}`, `Memory:  ${item.selectors['Memory']}`, `Clock Speed:  ${item.selectors['Clock Speed']}`, `GPU:  ${item.selectors['GPU']}\n`, `Network Performance:  ${item.selectors['Network Performance']}`, `Storage:  ${item.selectors['Storage']}`, `GPU Memory:  ${item.selectors['GPU Memory']}`],
                selectors: item.selectors,
                totalcount: item.total_count
            }));
            // console.log(filteredInstances)
            setInstanceOptions(filteredInstances);
            if (filteredInstances.length > 0) {
                setSelectedInstance(filteredInstances[0].value);
                onInstanceTypeChange(filteredInstances[0].value);
                onInstanceSelected(filteredInstances[0]);
            }
        };
        // // console.log(1)
        fetchInstanceTypes();
    }, [region]);  // Dependencies adjusted

    const handleInstanceChange = ({ detail }) => {
        setSelectedInstance(detail.selectedOption.value);
        onInstanceTypeChange(detail.selectedOption.value);
        onInstanceSelected(detail.selectedOption);
    };

    return (
        <Select
            selectedOption={selectedInstance ? { label: instanceOptions.find(opt => opt.value === selectedInstance).label, value: selectedInstance } : null}
            options={instanceOptions}
            onChange={handleInstanceChange}
            ariaDescribedby={null}
            expandToViewport={true}
        />
    );
};

//On-Demand Notebook中Inference Instance的Yes/No选择框
const ElasticInference = (props) => {
    const [selectedOption, setSelectedOption] = React.useState(props.selectedOption);

    const handleEngineChange = ({ detail }) => {
        setSelectedOption(detail.selectedOption);
        props.onEngineChange(detail.selectedOption.value);
    };

    return (
        <Select
            selectedOption={selectedOption}
            options={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' }
            ]}
            onChange={handleEngineChange}
            ariaDescribedby={null}
            expandToViewport={true}
        />
    );
};

//On-Demand Notebook中Inference Instance Type下拉选择框
const ElasticInferenceInstance = ({region, onInstanceTypeChange, onInstanceSelected}) => {
    const [instanceOptions, setInstanceOptions] = useState([]);
    const [selectedInstance, setSelectedInstance] = useState('');

    useEffect(() => {
        const fetchInstanceTypes = async () => {
            // Assuming the URL needs to reflect the region dynamically
            const response = await fetch(`/pricing/2.0/meteredUnitMaps/sagemaker/USD/current/sagemaker-inference-notebook-calc/${encodeURIComponent(region)}/primary-selector-aggregations.json`);
            const data = await response.json();
            const filteredInstances = data.aggregations.map(item => ({
                label: `${item.selectors['Instance Name']}`,
                value: item.selectors['Instance Name'],
                // tags: [`vCPU:  ${item.selectors['vCPU']}`, `Memory:  ${item.selectors['Memory']}`, `Clock Speed:  ${item.selectors['Clock Speed']}`, `GPU:  ${item.selectors['GPU']}\n`, `Network Performance:  ${item.selectors['Network Performance']}`, `Storage:  ${item.selectors['Storage']}`, `GPU Memory:  ${item.selectors['GPU Memory']}`],
                selectors: item.selectors,
                totalcount: item.total_count
            }));
            // console.log(filteredInstances)
            setInstanceOptions(filteredInstances);
            if (filteredInstances.length > 0) {
                setSelectedInstance(filteredInstances[0].value);
                onInstanceTypeChange(filteredInstances[0].value);
                onInstanceSelected(filteredInstances[0]);
            }
        };
        // // console.log(1)
        fetchInstanceTypes();
    }, [region]);  // Dependencies adjusted

    const handleInstanceChange = ({ detail }) => {
        setSelectedInstance(detail.selectedOption.value);
        onInstanceTypeChange(detail.selectedOption.value);
        onInstanceSelected(detail.selectedOption);
    };

    return (
        <Select
            selectedOption={selectedInstance ? { label: instanceOptions.find(opt => opt.value === selectedInstance).label, value: selectedInstance } : null}
            options={instanceOptions}
            onChange={handleInstanceChange}
            ariaDescribedby={null}
            expandToViewport={true}
        />
    );
};

//On-Demand Notebook中ML Storage的Value输入框以及Unit下拉选择框
const ValueUnitFilter = (props) => {
    const [value, setValue] = React.useState(props.value);
    const [unit, setUnit] = React.useState(props.unit);
    const [warningMessage, setWarningMessage] = React.useState('');

    const units = [
        {label: 'GB Per Hour', value: 'GB Per Hour'},
        {label: 'GB Per Month', value: 'GB Per Month'},
        {label: 'TB Per Hour', value: 'TB Per Hour'},
        {label: 'TB Per Month', value: 'TB Per Month'}
    ];

    const validateValue = (inputValue, selectedUnit) => {
        let maxLimit = 0;
        switch (selectedUnit) {
            case 'GB Per Hour':
                maxLimit = 100000000000000000000 / 730;
                break;
            case 'GB Per Month':
                maxLimit = 100000000000000000000;
                break;
            case 'TB Per Hour':
                maxLimit = (100000000000000000000 / 730) / 1024;
                break;
            case 'TB Per Month':
                maxLimit = 100000000000000000000 / 1024;
                break;
            default:
                maxLimit = 100; // Default case, if needed
        }
        const parsedValue = parseFloat(inputValue);
        if (parsedValue < 0 || parsedValue > maxLimit) {
            setWarningMessage(`Storage amount can't be more than ${maxLimit} ${selectedUnit}. .`);
        } else {
            setWarningMessage('');
        }
    };
    const handleInputChange = ({ detail }) => {
        validateValue(detail.filteringText, unit.value);
        setValue(detail.filteringText.replace(/[^0-9.]/g, ''));
        props.onValueChange(detail.filteringText, unit);
    };

    const handleUnitChange = ({ detail }) => {
        setUnit(detail.selectedOption);
        validateValue(value, detail.selectedOption.value);
        props.onValueChange(value, detail.selectedOption);
    };

    return (
        <div>
            <TextFilter
                filteringText={value}
                filteringPlaceholder="Enter value"
                filteringAriaLabel={`Filter Value for ${unit.label}`}
                onChange={handleInputChange}
            />
            <Select
                selectedOption={unit}
                options={units}
                onChange={handleUnitChange}
                ariaDescribedby={null}
                expandToViewport={true}
            />
            {warningMessage && <div style={{ color: 'red' }}>{warningMessage}</div>}
        </div>
    );
}


//主函数
const SageMaker = (props) => {
    const [filters, setFilters] = useState({
        region: regionOptions[0].value,
        NumberOfDataScientists: '',
        NumberOfStudioNotebook: '',
        NumberOfNotebookHours: '',
        NumberOfNotebookDays: '',
        NumberOfOnDemandDataScientists : '',
        NumberOfOnDemandNotebook : '',
        NumberOfOnDemandNotebookHours : '',
        NumberOfOnDemandNotebookDays : '',
        ElasticInferenceSelector : { label: 'No', value: 'No' },
        value: '',
        unit: { label: 'GB Per Month', value: 'GB Per Month' },




    });

    //如果Filters有变化，用心的filters替代旧的
    const handleFiltersChange = (newFilters) => {
        setFilters(newFilters);
    };

    const [studioEnable, setStudioEnable] = useState(true); // 控制数据层选项的显示
    const [inferenceEnable, setInferenceEnable] = useState(true); // 控制数据层选项的显示
    const [storage, setStorage] = useState();


    //如果Region有变化，从API获取Studio以及On-Demand Instance Type的选项
    const handleRegionChange = (newType) => {
        const fetchInstanceTypes = async () => {
            try {
                const response = await fetch(`/pricing/2.0/meteredUnitMaps/sagemaker/USD/current/sagemaker-studionotebook-instances-calc/${encodeURIComponent(newType)}/primary-selector-aggregations.json`);
                if (!response.ok) throw new Error('Network response was not ok');
                setStudioEnable(true)
            } catch (error) {
                console.error('Failed to fetch instance types:', error);
                setStudioEnable(false)
            }
        }
        const fetchInferenceInstanceTypes = async () => {
            try {
                const response = await fetch(`/pricing/2.0/meteredUnitMaps/sagemaker/USD/current/sagemaker-inference-notebook-calc/${encodeURIComponent(newType)}/primary-selector-aggregations.json`);
                if (!response.ok) throw new Error('Network response was not ok');
                setInferenceEnable(true)
            } catch (error) {
                console.error('Failed to fetch instance types:', error);
                setInferenceEnable(false)
            }
        }
        fetchInstanceTypes();
        fetchInferenceInstanceTypes();
        console.log(studioEnable)
    };

    //首次渲染的时候获取所有的Storage的价格
    useEffect(() => {
        const fetchStorage = async () => {
            try {
                const response = await fetch(`/pricing/2.0/meteredUnitMaps/sagemaker/USD/current/sagemaker-storage.json`);
                const data = await response.json();
                if (!response.ok) throw new Error('Network response was not ok');
                setStorage(data);
            } catch (error) {
                console.error('Failed to fetch storage data:', error);
            }
        };

        // 调用fetchStorage函数
        fetchStorage();

        // 首次渲染时触发区域更改相关的函数
        handleRegionChange(filters.region);
    }, []); // 确保依赖数组为空，这样这个useEffect只会在组件首次渲染时执行

    const [instanceType, setInstanceType] = useState('');


    const handleInstanceTypeChange = (newType) => {
        setInstanceType(newType);
    };

    const [onDemandInstanceType, setOnDemandInstanceType] = useState('');


    const handleOnDemandInstanceTypeChange = (newType) => {
        setOnDemandInstanceType(newType);
    };

    const [inferenceInstanceType, setInferenceInstanceType] = useState('');


    const handleInferenceInstanceTypeChange = (newType) => {
        setInferenceInstanceType(newType);
    };

    const [instanceDetails, setInstanceDetails] = useState({});

    //选择了instance type后获取这个instance type的价格
    const handleInstanceSelected = async (instance) => {
        const url = `/pricing/2.0/meteredUnitMaps/sagemaker/USD/current/sagemaker-studionotebook-instances-calc/${encodeURIComponent(filters.region)}/${instance.selectors['Instance Name']}/${instance.selectors['Compute Type']}/${instance.selectors['Clock Speed']}/${instance.selectors['Instance Type']}/${instance.selectors['Component']}/${instance.selectors['V CPU']}/${instance.selectors['Memory']}/${instance.selectors['GPU Memory']}/${instance.selectors['GPU']}/${instance.selectors['Storage']}/${instance.selectors['Network Performance']}/${instance.selectors['Unit']}/index.json`;
        // // console.log(instance.totalcount);
        try {
            const response = await fetch(url);
            const data = await response.json();
            setInstanceDetails(data);
        } catch (error) {
            console.error('Failed to fetch instance details:', error);
            setInstanceDetails({});  // 清空或设置错误信息
        }
    };

    const [onDemandInstanceDetails, setOnDemandInstanceDetails] = useState({});
    //同理，获取On-Deamnd Notebook的Instance Type价格
    const handleOnDemandInstanceSelected = async (instance) => {
        const url = `/pricing/2.0/meteredUnitMaps/sagemaker/USD/current/sagemaker-notebook-instances-calc/${encodeURIComponent(filters.region)}/${instance.selectors['Instance Name']}/${instance.selectors['Compute Type']}/${instance.selectors['Instance Type']}/${instance.selectors['Component']}/${instance.selectors['V CPU']}/${instance.selectors['Memory']}/${instance.selectors['GPU Memory']}/${instance.selectors['GPU']}/${instance.selectors['Storage']}/${instance.selectors['Network Performance']}/${instance.selectors['Unit']}/index.json`;
        // // console.log(instance.totalcount);
        try {
            const response = await fetch(url);
            const data = await response.json();
            setOnDemandInstanceDetails(data);
        } catch (error) {
            console.error('Failed to fetch instance details:', error);
            setOnDemandInstanceDetails({});  // 清空或设置错误信息
        }
    };

    const [inferenceInstanceDetails, setInferenceInstanceDetails] = useState({});
    //获取Inference Instance Type的价格
    const handleInferenceInstanceSelected = async (instance) => {
        const url = `/pricing/2.0/meteredUnitMaps/sagemaker/USD/current/sagemaker-inference-notebook-calc/${encodeURIComponent(filters.region)}/${instance.selectors['Instance Name']}/${instance.selectors['Compute Type']}/${instance.selectors['Clock Speed']}/${instance.selectors['Instance Type']}/${instance.selectors['Component']}/${instance.selectors['V CPU']}/${instance.selectors['Memory']}/${instance.selectors['GPU Memory']}/${instance.selectors['GPU']}/${instance.selectors['Storage']}/${instance.selectors['Network Performance']}/${instance.selectors['Unit']}/index.json`;
        // // console.log(instance.totalcount);
        try {
            const response = await fetch(url);
            const data = await response.json();
            setInferenceInstanceDetails(data);
        } catch (error) {
            console.error('Failed to fetch instance details:', error);
            setInferenceInstanceDetails({});  // 清空或设置错误信息
        }
    };

    const [studioEnabled, setStudioEnabled] = useState(false); // 控制 Studio Notebooks 的开关
    const [onDemandEnabled, setOnDemandEnabled] = useState(false); // 控制 On-Demand Notebook Instances 的开关

    const handleStudioToggle = () => {
        setStudioEnabled(!studioEnabled);
    };

    const handleOnDemandToggle = () => {
        setOnDemandEnabled(!onDemandEnabled);
    };

    const [allFiltersValid, setAllFiltersValid] = useState(false);

    //决定什么时候Add to Cart按钮可以被点亮
    const validateFilters = () => {
        let isValid = true;
        if((studioEnable && studioEnabled === false) && onDemandEnabled == false) isValid = false;
        if(studioEnable && studioEnabled === true){
            if(filters.NumberOfDataScientists <= 0 || filters.NumberOfDataScientists > 10000000000000 || filters.NumberOfDataScientists === ''){
                isValid = false;
            }
            if(filters.NumberOfStudioNotebook <= 0 || filters.NumberOfStudioNotebook > 10000000000000 || filters.NumberOfStudioNotebook === ''){
                isValid = false;
            }
            if(filters.NumberOfNotebookHours < 0.01 || filters.NumberOfNotebookHours > 24 || filters.NumberOfNotebookHours === ''){
                isValid = false;
            }
            if(filters.NumberOfNotebookDays < 1 || filters.NumberOfNotebookDays > 31 || filters.NumberOfNotebookDays === ''){
                isValid = false;
            }
        }
        if(onDemandEnabled === true){
            if(filters.NumberOfOnDemandDataScientists <= 0 || filters.NumberOfOnDemandDataScientists > 10000000000000 || filters.NumberOfOnDemandDataScientists === ''){
                isValid = false;
            }
            if(filters.NumberOfOnDemandNotebook <= 0 || filters.NumberOfOnDemandNotebook > 10000000000000 || filters.NumberOfOnDemandNotebook === ''){
                isValid = false;
            }
            if(filters.NumberOfOnDemandNotebookHours < 0.01 || filters.NumberOfOnDemandNotebookHours > 24 || filters.NumberOfOnDemandNotebookHours === ''){
                isValid = false;
            }
            if(filters.NumberOfOnDemandNotebookDays < 1 || filters.NumberOfOnDemandNotebookDays > 31 || filters.NumberOfOnDemandNotebookDays === ''){
                isValid = false;
            }
            switch (filters.unit.value) {
                case 'GB Per Hour':
                    if(filters.value > 100000000000000000000 / 730) isValid = false;
                    break;
                case 'GB Per Month':
                    if(filters.value > 100000000000000000000) isValid = false;
                    break;
                case 'TB Per Hour':
                    if(filters.value > (100000000000000000000 / 730) / 1024) isValid = false;
                    break;
                case 'TB Per Month':
                    if(filters.value > 100000000000000000000 / 1024) isValid = false;
                    break;
                default:// Default case, if needed
            }
        }
        // // console.log(isValid)
        // // console.log(showDataTierOptions)
        setAllFiltersValid(isValid);
    };

    useEffect(() => {
        validateFilters();
    }, [filters, studioEnable, studioEnabled, onDemandEnabled]);


    //点击Add to Cart后进行的操作
    const handleAddCart = () => {
        //传递到Cart的数据
        const cartItem = {
            sku: uuidv4(),
            region: filters.region,
            NumberOfDataScientists: filters.NumberOfDataScientists,
            NumberOfStudioNotebook: filters.NumberOfStudioNotebook,
            NumberOfNotebookHours: filters.NumberOfNotebookHours,
            NumberOfNotebookDays: filters.NumberOfNotebookDays,
            InstanceType: instanceType,
            NumberOfOnDemandDataScientists : filters.NumberOfOnDemandDataScientists,
            NumberOfOnDemandNotebook : filters.NumberOfOnDemandNotebook,
            NumberOfOnDemandNotebookHours : filters.NumberOfOnDemandNotebookHours,
            NumberOfOnDemandNotebookDays : filters.NumberOfOnDemandNotebookDays,
            OnDemandInstanceType: onDemandInstanceType,
            ElasticInferenceSelector : filters.ElasticInferenceSelector.value,
            InferenceInstanceType: inferenceInstanceType,
            Storage: 'General Purpose SSD(GP2)',
            value: filters.value,
            unit: filters.unit.value,
            StudioPrice : 0,
            OnDemandNotebookPrice : 0,
            InferencePrice: 0,
            MLStoragePrice : 0,
            ServiceName: 'SageMaker'
        }

        if(studioEnable && studioEnabled === false){
            cartItem.NumberOfDataScientists = 'N/A';
            cartItem.NumberOfStudioNotebook = 'N/A';
            cartItem.NumberOfNotebookHours = 'N/A';
            cartItem.NumberOfNotebookDays = 'N/A';
            cartItem.StudioPrice = 'N/A';
            cartItem.InstanceType = 'N/A';
        }
        else{
            const price = instanceDetails ? Object.values(instanceDetails.regions[filters.region])[0].price : 0;
            cartItem.StudioPrice = cartItem.NumberOfDataScientists * cartItem.NumberOfStudioNotebook * cartItem.NumberOfNotebookHours * cartItem.NumberOfNotebookDays;
            cartItem.StudioPrice *= price;
        }

        if(onDemandEnabled === false){
            cartItem.NumberOfOnDemandDataScientists = 'N/A';
            cartItem.NumberOfOnDemandNotebook = 'N/A';
            cartItem.NumberOfOnDemandNotebookHours = 'N/A';
            cartItem.NumberOfOnDemandNotebookDays = 'N/A';
            cartItem.OnDemandInstanceType = 'N/A';
            cartItem.ElasticInferenceSelector = 'No';
            cartItem.InferenceInstanceType = 'N/A';
            cartItem.OnDemandNotebookPrice = 'N/A';
            cartItem.InferencePrice = 'N/A';
            cartItem.Storage = 'N/A';
            cartItem.value = 'N/A';
            cartItem.unit = 'N/A';
            cartItem.MLStoragePrice = 'N/A';
        }
        else{
            if(inferenceEnable === false){
                const price = onDemandInstanceDetails ? Object.values(onDemandInstanceDetails.regions[filters.region])[0].price : 0;
                cartItem.OnDemandNotebookPrice = cartItem.NumberOfOnDemandDataScientists * cartItem.NumberOfOnDemandNotebook * cartItem.NumberOfOnDemandNotebookHours * cartItem.NumberOfOnDemandNotebookDays;
                cartItem.OnDemandNotebookPrice *= price;
                cartItem.ElasticInferenceSelector = 'No';
                cartItem.InferenceInstanceType = 'N/A';
                cartItem.InferencePrice = 'N/A';
                if(cartItem.value === '' || cartItem.value === 0){
                    cartItem.value = 'N/A';
                    cartItem.unit = 'N/A';
                    cartItem.MLStoragePrice = 'N/A';
                    cartItem.Storage = 'N/A';
                }
                else{
                    const MLPrice = storage ? storage.regions[filters.region]['Storage General PurposeHosting per GB-Mo'].price : 0;
                    switch (cartItem.unit) {
                        case 'GB Per Hour':
                            cartItem.MLStoragePrice = cartItem.value * 730 * MLPrice;
                            break;
                        case 'GB Per Month':
                            cartItem.MLStoragePrice = cartItem.value * MLPrice;
                            break;
                        case 'TB Per Hour':
                            cartItem.MLStoragePrice = cartItem.value * 1024 * 730 * MLPrice;
                            break;
                        case 'TB Per Month':
                            cartItem.MLStoragePrice = cartItem.value * 1024 * MLPrice;
                            break;
                        default:
                            cartItem.MLStoragePrice = 0; // Default case, if needed
                    }
                }
            }
            else{
                const price = onDemandInstanceDetails ? Object.values(onDemandInstanceDetails.regions[filters.region])[0].price : 0;
                cartItem.OnDemandNotebookPrice = cartItem.NumberOfOnDemandDataScientists * cartItem.NumberOfOnDemandNotebook * cartItem.NumberOfOnDemandNotebookHours * cartItem.NumberOfOnDemandNotebookDays;
                cartItem.OnDemandNotebookPrice *= price;
                if(cartItem.ElasticInferenceSelector === 'No'){
                    cartItem.InferenceInstanceType = 'N/A';
                }
                else{
                    const iPrice = inferenceInstanceDetails ? Object.values(inferenceInstanceDetails.regions[filters.region])[0].price : 0;
                    cartItem.InferencePrice = cartItem.NumberOfOnDemandDataScientists * cartItem.NumberOfOnDemandNotebook * cartItem.NumberOfOnDemandNotebookHours * cartItem.NumberOfOnDemandNotebookDays;
                    cartItem.InferencePrice *= iPrice;
                }
                if(cartItem.value === '' || cartItem.unit === 0){
                    cartItem.value = 'N/A';
                    cartItem.unit = 'N/A';
                    cartItem.MLStoragePrice = 'N/A';
                }
                else{
                    const MLPrice = storage ? storage.regions[filters.region]['Storage General PurposeHosting per GB-Mo'].price : 0;
                    switch (cartItem.unit) {
                        case 'GB Per Hour':
                            cartItem.MLStoragePrice = cartItem.value * 730 * MLPrice;
                            break;
                        case 'GB Per Month':
                            cartItem.MLStoragePrice = cartItem.value * MLPrice;
                            break;
                        case 'TB Per Hour':
                            cartItem.MLStoragePrice = cartItem.value * 1024 * 730 * MLPrice;
                            break;
                        case 'TB Per Month':
                            cartItem.MLStoragePrice = cartItem.value * 1024 * MLPrice;
                            break;
                        default:
                            cartItem.MLStoragePrice = 0; // Default case, if needed
                    }
                }
            }
        }
        console.log(cartItem);
        props.addToCart(cartItem);



    }
    //页面渲染
    return (
        <Layout>
            <Container>
                <SpaceBetween direction="vertical" size="s">
                    <Header
                        counter=""
                        actions={
                            <SpaceBetween
                                direction="horizontal"
                                size="xs"
                            >
                                <Button variant="primary"
                                    onClick={handleAddCart}
                                    disabled={!allFiltersValid}
                                >
                                    Add to Cart
                                </Button>
                                <Link to="/cart">
                                    <Button variant="primary">
                                        Go to Cart
                                    </Button>
                                </Link>
                            </SpaceBetween>
                        }
                    >
                        Amazon SageMaker
                    </Header>
                    <SpaceBetween direction="horizontal" size="s">
                        <div className="EBS-select-filter">
                            <Toggle
                                checked={studioEnable && studioEnabled}
                                onChange={handleStudioToggle}
                                disabled = {!studioEnable}
                            >
                                SageMaker Studio Notebooks
                            </Toggle>
                        </div>
                        <div className="EBS-select-filter">
                            <Toggle
                                checked={onDemandEnabled}
                                onChange={handleOnDemandToggle}
                            >
                                SageMaker On-Demand Notebook Instances
                            </Toggle>
                        </div>
                        <div className="EBS-select-filter">
                            <FormField label="Region">
                                <SagemakerRegionFilter
                                    selectedOption={regionOptions.find((option) => option.value === filters.region)}
                                    onFilterChange={(value) => {
                                        handleFiltersChange({...filters, region: value});
                                        handleRegionChange(value);
                                    }}
                                />
                            </FormField>
                        </div>
                    </SpaceBetween>
                    {(studioEnable && studioEnabled) && (
                        <Container
                            header={
                                <Header variant="h2" description="">
                                    SageMaker Studio Notebooks
                                </Header>
                            }
                        >
                            <div className="EBS-filter-row">
                                <div className="EBS-input-filter" key="number-of-data-scientists">
                                    <FormField label="Number of data scientist(s)">
                                        <NumberOfDataScientists
                                            filteringText={filters.NumberOfDataScientists}
                                            onFilterChange={(value) => handleFiltersChange({
                                                ...filters,
                                                NumberOfDataScientists: value
                                            })}
                                        />
                                    </FormField>
                                </div>
                                <div className="EBS-input-filter" key="number-of-studio-notebooks">
                                    <FormField label="Number of Studio Notebook instances per data scientist">
                                        <NumberOfStudioNotebook
                                            filteringText={filters.NumberOfStudioNotebook}
                                            onFilterChange={(value) => handleFiltersChange({
                                                ...filters,
                                                NumberOfStudioNotebook: value
                                            })}
                                        />
                                    </FormField>
                                </div>
                                <div className="EBS-input-filter" key="number-of-notebook-hours">
                                    <FormField label="Number of Studio Notebook instances per data scientist">
                                        <NumberOfNotebookHours
                                            filteringText={filters.NumberOfNotebookHours}
                                            onFilterChange={(value) => handleFiltersChange({
                                                ...filters,
                                                NumberOfNotebookHours: value
                                            })}
                                        />
                                    </FormField>
                                </div>
                                <div className="EBS-input-filter" key="number-of-notebook-days">
                                    <FormField label="Studio Notebook day(s) per month">
                                        <NumberOfNotebookDays
                                            filteringText={filters.NumberOfNotebookDays}
                                            onFilterChange={(value) => handleFiltersChange({
                                                ...filters,
                                                NumberOfNotebookDays: value
                                            })}
                                        />
                                    </FormField>
                                </div>

                                <div className="EBS-select-filter" key="instance-type-filter">
                                    <FormField label="Instance Type">
                                        <InstanceTypeFilter
                                            // termType={filters.pricingModel}
                                            // cacheEngine={filters.cacheEngine}
                                            region={filters.region}
                                            onInstanceTypeChange={handleInstanceTypeChange}
                                            onInstanceSelected={handleInstanceSelected}
                                        />
                                    </FormField>
                                </div>
                            </div>
                        </Container>
                    )}
                    {onDemandEnabled &&
                        <Container
                            header={
                                <Header variant="h2" description="">
                                    SageMaker On-Demand Notebook Instances feature
                                </Header>
                            }
                        >
                            <div className="EBS-filter-row">
                                <div className="EBS-input-filter" key="number-of-data-scientists">
                                    <FormField label="Number of data scientist(s)">
                                        <NumberOfOnDemandDataScientists
                                            filteringText={filters.NumberOfOnDemandDataScientists}
                                            onFilterChange={(value) => handleFiltersChange({
                                                ...filters,
                                                NumberOfOnDemandDataScientists: value
                                            })}
                                        />
                                    </FormField>
                                </div>
                                <div className="EBS-input-filter" key="number-of-studio-notebooks">
                                    <FormField label="Number of Studio Notebook instances per data scientist">
                                        <NumberOfOnDemandNotebook
                                            filteringText={filters.NumberOfOnDemandNotebook}
                                            onFilterChange={(value) => handleFiltersChange({
                                                ...filters,
                                                NumberOfOnDemandNotebook: value
                                            })}
                                        />
                                    </FormField>
                                </div>
                                <div className="EBS-input-filter" key="number-of-notebook-hours">
                                    <FormField label="Number of Studio Notebook instances per data scientist">
                                        <NumberOfOnDemandNotebookHours
                                            filteringText={filters.NumberOfOnDemandNotebookHours}
                                            onFilterChange={(value) => handleFiltersChange({
                                                ...filters,
                                                NumberOfOnDemandNotebookHours: value
                                            })}
                                        />
                                    </FormField>
                                </div>
                                <div className="EBS-input-filter" key="number-of-notebook-days">
                                    <FormField label="Studio Notebook day(s) per month">
                                        <NumberOfOnDemandNotebookDays
                                            filteringText={filters.NumberOfOnDemandNotebookDays}
                                            onFilterChange={(value) => handleFiltersChange({
                                                ...filters,
                                                NumberOfOnDemandNotebookDays: value
                                            })}
                                        />
                                    </FormField>
                                </div>

                                <div className="EBS-select-filter" key="instance-type-filter">
                                    <FormField label="Instance Type">
                                        <OnDemandInstanceTypeFilter
                                            // termType={filters.pricingModel}
                                            // cacheEngine={filters.cacheEngine}
                                            region={filters.region}
                                            onInstanceTypeChange={handleOnDemandInstanceTypeChange}
                                            onInstanceSelected={handleOnDemandInstanceSelected}
                                        />
                                    </FormField>
                                </div>
                            </div>
                            {inferenceEnable && (<div className="EBS-filter-row">
                                <div className="EBS-select-filter" key="elastic-inference-selector">
                                    <FormField label="Add Elastic Inference Accelerator instances">
                                        <ElasticInference
                                            selectedOption={filters.ElasticInferenceSelector}
                                            onEngineChange={(value) => handleFiltersChange({
                                                ...filters,
                                                ElasticInferenceSelector: {value : value, label: value}
                                            })}
                                        />
                                    </FormField>
                                </div>
                                <div className="EBS-select-filter" key="instance-type-filter">
                                    <FormField label="Instance Type">
                                        <ElasticInferenceInstance
                                            // termType={filters.pricingModel}
                                            // cacheEngine={filters.cacheEngine}
                                            region={filters.region}
                                            onInstanceTypeChange={handleInferenceInstanceTypeChange}
                                            onInstanceSelected={handleInferenceInstanceSelected}
                                        />
                                    </FormField>
                                </div>
                            </div>)}
                            <div className="EBS-filter-row">
                                <FormField label="ML Storage Value and Unit">
                                    <ValueUnitFilter
                                        value={filters.value}
                                        unit={filters.unit || {label: 'GB Per Month', value: 'GB Per Month'}}
                                        onValueChange={(newValue, newUnit) => handleFiltersChange({
                                            ...filters,
                                            value: newValue,
                                            unit: newUnit
                                        })}
                                    />
                                </FormField>
                            </div>
                        </Container>}
                </SpaceBetween>
            </Container>
        </Layout>
    );
};

const mapDispatchToProps = (dispatch) => ({
    addToCart: (item) => dispatch(addToCart(item)),
});

export default connect(null, mapDispatchToProps)(SageMaker);
