import "./SelectFilter.css";
// import Modal from 'react-modal';
import React, { useEffect, useState } from "react";
import Layout from "../layout/appLayout";
import { FormField, Container, SpaceBetween } from '@cloudscape-design/components';
import Table from "@cloudscape-design/components/table";
import Box from "@cloudscape-design/components/box";
// import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import TextFilter from "@cloudscape-design/components/text-filter";
import Header from "@cloudscape-design/components/header";
import ButtonDropdown from "@cloudscape-design/components/button-dropdown";
import Pagination from "@cloudscape-design/components/pagination";
import CollectionPreferences from "@cloudscape-design/components/collection-preferences";
import Select from "@cloudscape-design/components/select";
import { PricingClient, GetProductsCommand, paginateGetProducts } from "@aws-sdk/client-pricing";
import { connect } from 'react-redux';
import { addToCart } from "../redux/actions/cartActions";
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';


// new code starts here

//Region选项
const regionOptions = [
    { label: "US East (N. Virginia)", value: "US East (N. Virginia)" },
    { label: "US East (Ohio)", value: "US East (Ohio)" },
    { label: "US West (N. California)", value: "US West (N. California)" },
    { label: "US West (Oregon)", value: "US West (Oregon)" },
    { label: "Canada (Central)", value: "Canada (Central)" },
    { label: "Canada West (Calgary)", value: "Canada West (Calgary)" },
    { label: "Africa (Cape Town)", value: "Africa (Cape Town)" },
    { label: "Asia Pacific (Hong Kong)", value: "Asia Pacific (Hong Kong)" },
    { label: "Asia Pacific (Hyderabad)", value: "Asia Pacific (Hyderabad)" },
    { label: "Asia Pacific (Jakarta)", value: "Asia Pacific (Jakarta)" },
    { label: "Asia Pacific (Melbourne)", value: "Asia Pacific (Melbourne)" },
    { label: "Asia Pacific (Mumbai)", value: "Asia Pacific (Mumbai)" },
    { label: "Asia Pacific (Osaka)", value: "Asia Pacific (Osaka)" },
    { label: "Asia Pacific (Seoul)", value: "Asia Pacific (Seoul)" },
    { label: "Asia Pacific (Singapore)", value: "Asia Pacific (Singapore)" },
    { label: "Asia Pacific (Malaysia)", value: "Asia Pacific (Malaysia)" },
    { label: "Asia Pacific (Sydney)", value: "Asia Pacific (Sydney)" },
    { label: "Asia Pacific (Tokyo)", value: "Asia Pacific (Tokyo)" },
    { label: "EU (Frankfurt)", value: "EU (Frankfurt)" },
    { label: "EU (Ireland)", value: "EU (Ireland)" },
    { label: "EU (London)", value: "EU (London)" },
    { label: "EU (Milan)", value: "EU (Milan)" },
    { label: "EU (Paris)", value: "EU (Paris)" },
    { label: "EU (Spain)", value: "EU (Spain)" },
    { label: "EU (Stockholm)", value: "EU (Stockholm)" },
    { label: "EU (Zurich)", value: "EU (Zurich)" },
    { label: "Israel (Tel Aviv)", value: "Israel (Tel Aviv)" },
    { label: "Middle East (Bahrain)", value: "Middle East (Bahrain)" },
    { label: "Middle East (UAE)", value: "Middle East (UAE)" },
    { label: "South America (Sao Paulo)", value: "South America (Sao Paulo)" },
    { label: "AWS GovCloud (US-West)", value: "AWS GovCloud (US)" },
    { label: "AWS GovCloud (US-East)", value: "AWS GovCloud (US-East)" },
];



//Region选项
const EBSRegionFilter = (props) => {
    const [selectedOption, setSelectedOption] = React.useState(props.selectedOption);


    return (
        <Select
            selectedAriaLabel="Selected"
            selectedOption={selectedOption}
            onChange={({ detail }) => {
                setSelectedOption(detail.selectedOption);
                props.onFilterChange(detail.selectedOption.value); // Update the region in the parent component
            }}
            options={regionOptions}
            ariaDescribedby={null}
            expandToViewport={true}
        />
    );
};

//Average Cache Data Size输入框
const AverageCacheDataSizeFilter = (props) => {
    const [filteringText, setFilteringText] = React.useState(props.filteringText);
    const [warningMessage, setWarningMessage] = React.useState('');

    const handleInputChange = ({ detail }) => {
        const numericValue = detail.filteringText.replace(/[^0-9]/g, ''); // 只允许数字
        const parsedValue = parseInt(numericValue, 10);

        if (parsedValue < 0) {
            setWarningMessage('Average cache data size cannot be less than 0 GB.');
        } else if (parsedValue > 1000000000000) {
            setWarningMessage('Average cache data size cannot be more than 1000000000000 GB.');
        } else {
            setWarningMessage('');
        }

        setFilteringText(numericValue);
        props.onFilterChange(numericValue);
    };

    return (
        <div>
            <TextFilter
                filteringText={filteringText}
                filteringPlaceholder="Enter size in GB"
                filteringAriaLabel="Filter Average Cache Data Size"
                onChange={handleInputChange}
            />
            {warningMessage && <div style={{ color: 'red' }}>{warningMessage}</div>}
        </div>
    );
};

//Average Request Rate输入框以及单位选项
const AverageRequestRateFilter = (props) => {
    const [filteringText, setFilteringText] = React.useState(props.filteringText);
    // 使用 props.unit 设置默认值或默认为 'per second'
    const [unit, setUnit] = React.useState(props.unit || { label: 'per second', value: 'per second' });
    const [warningMessage, setWarningMessage] = React.useState('');

    const handleInputChange = ({ detail }) => {
        const numericValue = detail.filteringText.replace(/[^0-9.]/g, ''); // 只允许数字和小数点
        if (parseFloat(numericValue) <= 0) {
            setWarningMessage('Average simple request rate must be greater than 0.');
        } else {
            setWarningMessage('');
        }
        setFilteringText(numericValue);
        props.onFilterChange(numericValue, unit.value);
    };

    const handleUnitChange = ({ detail }) => {
        const selectedOption = {
            label: detail.selectedOption.label,
            value: detail.selectedOption.value
        };
        setUnit(selectedOption);
        props.onFilterChange(filteringText, selectedOption.value);
    };

    return (
        <div>
            <TextFilter
                filteringText={filteringText}
                filteringPlaceholder="Enter rate"
                filteringAriaLabel="Filter Average Simple Request Rate"
                onChange={handleInputChange}
            />
            <Select
                selectedOption={unit}
                options={[
                    { label: 'per hour', value: 'per hour' },
                    { label: 'per second', value: 'per second' }
                ]}
                onChange={handleUnitChange}
                ariaDescribedby={null}
                expandToViewport={true}
            />
            {warningMessage && <div style={{ color: 'red' }}>{warningMessage}</div>}
        </div>
    );
};

//Average Data Transferred Per Request输入框
const AverageDataTransferredPerRequestFilter = (props) => {
    const [filteringText, setFilteringText] = React.useState(props.filteringText);
    const [warningMessage, setWarningMessage] = React.useState('');

    const handleInputChange = ({ detail }) => {
        const numericValue = detail.filteringText.replace(/[^0-9]/g, ''); // Allow only numbers
        const parsedValue = parseInt(numericValue, 10);

        if (parsedValue <= 0) {
            setWarningMessage('Average data transferred per request must be greater than 0 KB.');
        } else if (parsedValue > 1000000000000) {
            setWarningMessage('Average data transferred per request cannot exceed 1,000,000,000,000 KB.');
        } else {
            setWarningMessage('');
        }

        setFilteringText(numericValue);
        props.onFilterChange(numericValue);
    };

    return (
        <div>
            <TextFilter
                filteringText={filteringText}
                filteringPlaceholder="Enter size in KB"
                filteringAriaLabel="Filter Average Data Transferred Per Request"
                onChange={handleInputChange}
            />
            {warningMessage && <div style={{ color: 'red' }}>{warningMessage}</div>}
        </div>
    );
};

//Cluster Nodes输入框
const NodesInputFilter = (props) => {
    const [filteringText, setFilteringText] = React.useState(props.filteringText);
    const [warningMessage, setWarningMessage] = React.useState('');

    const handleInputChange = ({ detail }) => {
        const numericValue = detail.filteringText.replace(/[^0-9]/g, ''); // 只允许数字
        const parsedValue = parseInt(numericValue, 10);

        if (parsedValue < 0) {
            setWarningMessage('Number of Nodes cannot be less than 0.');
        } else if (parsedValue > 250) {
            setWarningMessage('Number of Nodes cannot be more than 250.');
        } else {
            setWarningMessage('');
        }

        setFilteringText(numericValue);
        props.onFilterChange(numericValue);
    };

    return (
        <div>
            <TextFilter
                filteringText={filteringText}
                filteringPlaceholder="Enter number of nodes"
                filteringAriaLabel="Filter Number of Nodes"
                onChange={handleInputChange}
            />
            {warningMessage && <div style={{ color: 'red' }}>{warningMessage}</div>}
        </div>
    );
};

//Cluster Value输入框以及Unit选项
const ValueUnitFilter = (props) => {
    const [value, setValue] = React.useState(props.value);
    const [unit, setUnit] = React.useState(props.unit);
    const [warningMessage, setWarningMessage] = React.useState('');

    const units = [
        { label: '% Utilized/Month', value: '% Utilized/Month' },
        { label: 'Hours/Day', value: 'Hours/Day' },
        { label: 'Hours/Week', value: 'Hours/Week' },
        { label: 'Hours/Month', value: 'Hours/Month' }
    ];

    const validateValue = (inputValue, selectedUnit) => {
        let maxLimit = 0;
        switch (selectedUnit) {
            case '% Utilized/Month':
                maxLimit = 100;
                break;
            case 'Hours/Day':
                maxLimit = 24;
                break;
            case 'Hours/Week':
                maxLimit = 168;
                break;
            case 'Hours/Month':
                maxLimit = 730;
                break;
            default:
                maxLimit = 100; // Default case, if needed
        }
        const parsedValue = parseFloat(inputValue);
        if (parsedValue <= 0 || parsedValue > maxLimit) {
            setWarningMessage(`Value must be greater than 0 and less than or equal to ${maxLimit}.`);
        } else {
            setWarningMessage('');
        }
    };

    const handleInputChange = ({ detail }) => {
        validateValue(detail.filteringText, unit.value);
        setValue(detail.filteringText);
        props.onValueChange(detail.filteringText, unit);
    };

    const handleUnitChange = ({ detail }) => {
        setUnit(detail.selectedOption);
        validateValue(value, detail.selectedOption.value);
        props.onValueChange(value, detail.selectedOption);
    };

    return (
        <div>
            <TextFilter
                filteringText={value}
                filteringPlaceholder="Enter value"
                filteringAriaLabel={`Filter Value for ${unit.label}`}
                onChange={handleInputChange}
            />
            <Select
                selectedOption={unit}
                options={units}
                onChange={handleUnitChange}
                ariaDescribedby={null}
                expandToViewport={true}
            />
            {warningMessage && <div style={{ color: 'red' }}>{warningMessage}</div>}
        </div>
    );
};

//Cluster Cache Engine选项
const CacheEngineFilter = (props) => {
    const [selectedOption, setSelectedOption] = React.useState(props.selectedOption);

    const handleEngineChange = ({ detail }) => {
        setSelectedOption(detail.selectedOption);
        props.onEngineChange(detail.selectedOption.value);
    };

    return (
        <Select
            selectedOption={selectedOption}
            options={[
                { label: 'Redis', value: 'Redis' },
                { label: 'Memcached', value: 'Memcached' }
            ]}
            onChange={handleEngineChange}
            ariaDescribedby={null}
            expandToViewport={true}
        />
    );
};

//Cluster Pricing Model选项
const PricingModelFilter = (props) => {
    const [selectedOption, setSelectedOption] = React.useState(props.selectedOption);

    const handlePricingModelChange = ({ detail }) => {
        setSelectedOption(detail.selectedOption);
        props.onPricingModelChange(detail.selectedOption.value);
    };



    return (
        <Select
            selectedOption={selectedOption}
            options={[
                { label: 'OnDemand', value: 'OnDemand' },
                { label: 'Reserved', value: 'Reserved' }
            ]}
            onChange={handlePricingModelChange}
            ariaDescribedby={null}
            expandToViewport={true}
        />
    );
};

//假如Pricing Model为Reserved，Term选项
const TermFilter = (props) => {
    const [selectedOption, setSelectedOption] = React.useState(props.selectedOption || { label: '1yr', value: '1yr' });
    const {onTermChange, options } = props;

    const handleTermChange = ({ detail }) => {
        setSelectedOption(detail.selectedOption);
        props.onTermChange(detail.selectedOption.value);
    };

    return (
        <Select
            selectedOption={selectedOption}
            options={options}
            onChange={onTermChange}
            ariaDescribedby={null}
            expandToViewport={true}
        />
    );
};

//假如Pricing Model为Reserved，Purchase Option选项
const PurchaseOptionFilter = (props) => {
    const [selectedOption, setSelectedOption] = React.useState(props.selectedOption || { label: 'All Upfront', value: 'All Upfront' });

    const handlePurchaseOptionChange = ({ detail }) => {
        setSelectedOption(detail.selectedOption);
        props.onPurchaseOptionChange(detail.selectedOption.value);
    };

    return (
        <Select
            selectedOption={selectedOption}
            options={[
                { label: 'All Upfront', value: 'All Upfront' },
                { label: 'Partial Upfront', value: 'Partial Upfront' },
                { label: 'No Upfront', value: 'No Upfront' }
            ]}
            onChange={handlePurchaseOptionChange}
            ariaDescribedby={null}
            expandToViewport={true}
        />
    );
};


//Data Tier的Node输入框
const DataTierNodesFilter = (props) => {
    const [filteringText, setFilteringText] = React.useState(props.filteringText);
    const [warningMessage, setWarningMessage] = React.useState('');

    const handleInputChange = ({ detail }) => {
        const numericValue = detail.filteringText.replace(/[^0-9]/g, ''); // 只允许数字
        const parsedValue = parseInt(numericValue, 10);

        if (parsedValue < 0) {
            setWarningMessage('Number of Nodes cannot be less than 0.');
        } else if (parsedValue > 250) {
            setWarningMessage('Number of Nodes cannot be more than 250.');
        } else {
            setWarningMessage('');
        }

        setFilteringText(numericValue);
        props.onFilterChange(numericValue);
    };

    return (
        <div>
            <TextFilter
                filteringText={filteringText}
                filteringPlaceholder="Enter number of nodes"
                filteringAriaLabel="Filter Number of Nodes for Data Tiering"
                onChange={handleInputChange}
            />
            {warningMessage && <div style={{ color: 'red' }}>{warningMessage}</div>}
        </div>
    );
};

//Data Tier的Value输入框以及Unit选项
const DataTierValueUnitFilter = (props) => {
    const [value, setValue] = React.useState(props.value);
    const [unit, setUnit] = React.useState(props.unit);
    const [warningMessage, setWarningMessage] = React.useState('');

    const units = [
        { label: '% Utilized/Month', value: '% Utilized/Month' },
        { label: 'Hours/Day', value: 'Hours/Day' },
        { label: 'Hours/Week', value: 'Hours/Week' },
        { label: 'Hours/Month', value: 'Hours/Month' }
    ];

    const validateValue = (inputValue, selectedUnit) => {
        let maxLimit = 0;
        switch (selectedUnit) {
            case '% Utilized/Month':
                maxLimit = 100;
                break;
            case 'Hours/Day':
                maxLimit = 24;
                break;
            case 'Hours/Week':
                maxLimit = 168;
                break;
            case 'Hours/Month':
                maxLimit = 730;
                break;
            default:
                maxLimit = 100; // Default case, if needed
        }
        const parsedValue = parseFloat(inputValue);
        if (parsedValue <= 0 || parsedValue > maxLimit) {
            setWarningMessage(`Value must be greater than 0 and less than or equal to ${maxLimit}.`);
        } else {
            setWarningMessage('');
        }
    };

    const handleInputChange = ({ detail }) => {
        validateValue(detail.filteringText, unit.value);
        setValue(detail.filteringText);
        props.onValueChange(detail.filteringText, unit);
    };

    const handleUnitChange = ({ detail }) => {
        setUnit(detail.selectedOption);
        validateValue(value, detail.selectedOption.value);
        props.onValueChange(value, detail.selectedOption);
    };

    return (
        <div>
            <TextFilter
                filteringText={value}
                filteringPlaceholder="Enter value"
                filteringAriaLabel={`Filter Value for Data Tiering with ${unit.label}`}
                onChange={handleInputChange}
            />
            <Select
                selectedOption={unit}
                options={units}
                onChange={handleUnitChange}
                ariaDescribedby={null}
                expandToViewport={true}
            />
            {warningMessage && <div style={{ color: 'red' }}>{warningMessage}</div>}
        </div>
    );
};

//Data Tier的Pricing Model选项
const DataTierPricingModelFilter = (props) => {
    const [selectedOption, setSelectedOption] = React.useState(props.selectedOption);

    const handlePricingModelChange = ({ detail }) => {
        setSelectedOption(detail.selectedOption);
        props.onPricingModelChange(detail.selectedOption.value);
    };

    return (
        <Select
            selectedOption={selectedOption}
            options={[
                { label: 'OnDemand', value: 'OnDemand' },
                { label: 'Reserved', value: 'Reserved' }
            ]}
            onChange={handlePricingModelChange}
            ariaDescribedby={null}
            expandToViewport={true}
        />
    );
};

//Data Tier的Term选项
const DataTierTermFilter = (props) => {
    const [selectedOption, setSelectedOption] = React.useState(props.selectedOption);
    const {onTermChange, options } = props;


    const handleTermChange = ({ detail }) => {
        setSelectedOption(detail.selectedOption);
        props.onTermChange(detail.selectedOption.value);
    };

    return (
        <Select
            selectedOption={selectedOption}
            options={options}
            onChange={onTermChange}
            ariaDescribedby={null}
            expandToViewport={true}
        />
    );
};

//Data Tier的Purchase Option选项
const DataTierPurchaseOptionFilter = (props) => {
    const [selectedOption, setSelectedOption] = React.useState(props.selectedOption);

    const handlePurchaseOptionChange = ({ detail }) => {
        setSelectedOption(detail.selectedOption);
        props.onPurchaseOptionChange(detail.selectedOption.value);
    };

    return (
        <Select
            selectedOption={selectedOption}
            options={[
                { label: 'All Upfront', value: 'All Upfront' },
                { label: 'Partial Upfront', value: 'Partial Upfront' },
                { label: 'No Upfront', value: 'No Upfront' }
            ]}
            onChange={handlePurchaseOptionChange}
            ariaDescribedby={null}
            expandToViewport={true}
        />
    );
};


//Cluster的Instance Type选项
const InstanceTypeFilter = ({ termType, cacheEngine, region, onInstanceTypeChange, onInstanceSelected }) => {
    const [instanceOptions, setInstanceOptions] = useState([]);
    const [selectedInstance, setSelectedInstance] = useState('');

    useEffect(() => {
        const fetchInstanceTypes = async () => {
            // Assuming the URL needs to reflect the region dynamically
            const response = await fetch(`/pricing/2.0/meteredUnitMaps/elasticache/USD/current/elasticache-calc/${encodeURIComponent(region)}/primary-selector-aggregations.json`);
            const data = await response.json();
            const filteredInstances = data.aggregations.filter(item =>
                item.selectors['TermType'] === termType &&
                item.selectors['Cache Engine'] === cacheEngine
            ).map(item => ({
                label: `${item.selectors['Instance Type']}`,
                value: item.selectors['Instance Type'],
                tags: [`vCPU:  ${item.selectors['vCPU']}`, `Memory:  ${item.selectors['Memory']}`, `Network:  ${item.selectors['Network Performance']}`],
                selectors: item.selectors,
                totalcount: item.total_count
            }));
            setInstanceOptions(filteredInstances);
            if (filteredInstances.length > 0) {
                setSelectedInstance(filteredInstances[0].value);
                onInstanceTypeChange(filteredInstances[0].value);
                onInstanceSelected(filteredInstances[0]);
            }
        };
        // // console.log(1)
        fetchInstanceTypes();
    }, [termType, cacheEngine, region]);  // Dependencies adjusted

    const handleInstanceChange = ({ detail }) => {
        setSelectedInstance(detail.selectedOption.value);
        onInstanceTypeChange(detail.selectedOption.value);
        onInstanceSelected(detail.selectedOption);
    };

    return (
        <Select
            selectedOption={selectedInstance ? { label: instanceOptions.find(opt => opt.value === selectedInstance).label, value: selectedInstance } : null}
            options={instanceOptions}
            onChange={handleInstanceChange}
            ariaDescribedby={null}
            expandToViewport={true}
        />
    );
};

//Data Tier的Instance Type选项
const DataTierInstanceTypeFilter = ({ termType1, cacheEngine1, region, onInstanceTypeChange, onError, onInstanceSelected, notError }) => {
    const [instanceOptions, setInstanceOptions] = useState([]);
    const [selectedInstance, setSelectedInstance] = useState('');
    const [isLoaded, setIsLoaded] = useState(true);

    useEffect(() => {
        const fetchInstanceTypes = async () => {
            setIsLoaded(true);
            try {
                const response = await fetch(`/pricing/2.0/meteredUnitMaps/elasticache/USD/current/elasticache-datatiering-calc/${encodeURIComponent(region)}/primary-selector-aggregations.json`);
                if (!response.ok) throw new Error('Network response was not ok');
                const data = await response.json();
                const filteredInstances = data.aggregations.filter(item =>
                    item.selectors['TermType'] === termType1 &&
                    item.selectors['Cache Engine'] === cacheEngine1
                ).map(item => ({
                    label: `${item.selectors['Instance Type']}`,
                    value: item.selectors['Instance Type'],
                    tags: [`vCPU:  ${item.selectors['vCPU']}`, `Memory:  ${item.selectors['Memory']}`, `Network:  ${item.selectors['Network Performance']}`, `SSD:  ${item.selectors['SSD']}`],
                    selectors: item.selectors,
                    totalcount: item.total_count,
                }));
                setInstanceOptions(filteredInstances);
                setSelectedInstance(filteredInstances.length > 0 ? filteredInstances[0].value : '');
                if (filteredInstances.length > 0) {
                    // setSelectedInstance(filteredInstances[0].value);
                    onInstanceTypeChange(filteredInstances[0].value);
                    onInstanceSelected(filteredInstances[0]);
                }
                // notError();
            } catch (error) {
                console.error('Failed to fetch instance types:', error);
                setIsLoaded(false);
                // onError(); // 调用错误处理回调
                onInstanceTypeChange('');
            }
        };
        fetchInstanceTypes();
    }, [termType1, cacheEngine1, region]);

    const handleInstanceChange = ({ detail }) => {
        setSelectedInstance(detail.selectedOption.value);
        onInstanceTypeChange(detail.selectedOption.value);
        onInstanceSelected(detail.selectedOption);
    };

    return (
        <Select
            selectedOption={selectedInstance ? { label: instanceOptions.find(opt => opt.value === selectedInstance).label, value: selectedInstance } : null}
            options={instanceOptions}
            onChange={handleInstanceChange}
            ariaDescribedby={null}
            expandToViewport={true}
        />
    )
};



//主函数
const ElastiCache = (props) => {
    //需要传输的数据以及各个filters的初始值
    const [filters, setFilters] = useState({
        region: regionOptions[0].value,
        averageCacheDataSize: '',
        averageRequestRate: '',
        requestRateUnit: { label: 'per second', value: 'per second' },
        nodes: '',
        value: '',
        unit: { label: '% Utilized/Month', value: '% Utilized/Month' },
        cacheEngine: 'Redis',
        cacheNodeType: 'Standard',
        pricingModel: 'OnDemand',
        term: '1yr',
        purchaseOption: 'All Upfront',
        dataTierNodes: '',
        dataTierValue: '',
        dataTierUnit: { label: '% Utilized/Month', value: '% Utilized/Month' },
        dataTierCacheEngine: 'Redis',
        dataTierCacheNodeType: 'Memory Optimized',
        dataTierPricingModel: 'OnDemand',
        dataTierTerm: '1yr',
        dataTierPurchaseOption: 'All Upfront',
        averageDataTransferred: ''
    });


    const [selectedRegion, setSelectedRegion] = useState(regionOptions[0].value); //for the region filter
    const [allFiltersValid, setAllFiltersValid] = useState(false);
    //判断设么时候Add to Cart可以被点亮
    const validateFilters = () => {
        const isValid = (
            filters.nodes > 0 &&
            filters.averageRequestRate > 0 &&
            filters.averageCacheDataSize > 0 &&
            filters.averageCacheDataSize < 1000000000000 &&
            filters.value > 0 &&
            filters.averageDataTransferred > 0 &&
            filters.averageDataTransferred < 1000000000000 &&
            ((filters.value <= 100 && filters.unit.value === '% Utilized/Month') ||
                (filters.value <= 24 && filters.unit.value === 'Hours/Day') ||
                (filters.value <= 168 && filters.unit.value === 'Hours/Week') ||
                (filters.value <= 730 && filters.unit.value === 'Hours/Month')) &&
            (!showDataTierOptions ||
                (showDataTierOptions && ((filters.dataTierNodes === '0' || filters.dataTierNodes === '') || (filters.dataTierValue > 0 &&
                    (((filters.dataTierValue <= 100 && filters.dataTierUnit.value === '% Utilized/Month') ||
                        (filters.dataTierValue <= 24 && filters.dataTierUnit.value === 'Hours/Day') ||
                        (filters.dataTierValue <= 168 && filters.dataTierUnit.value === 'Hours/Week') ||
                        (filters.dataTierValue <= 730 && filters.dataTierUnit.value === 'Hours/Month')))))))
        );
        // // console.log(isValid)
        // // console.log(showDataTierOptions)
        setAllFiltersValid(isValid);
    };


    const handleFiltersChange = (newFilters) => {
        setFilters(newFilters);

        const isNodesValid = newFilters.nodes !== '' && parseInt(newFilters.nodes, 10) > 0;
        const isValueValid = newFilters.value !== '' && parseFloat(newFilters.value) > 0;
        const isCacheDataSizeValid = newFilters.averageCacheDataSize !== '';
        const isRequestRateValid = newFilters.averageRequestRate !== '' && parseFloat(newFilters.averageRequestRate) > 0;

        const allFiltersValid = isNodesValid && isValueValid && isCacheDataSizeValid && isRequestRateValid;

        setAllFiltersValid(allFiltersValid);
    };

    const [data, setData] = useState(null);
    const [regionData, setRegionData] = useState(null);
    const [requestData, setRequestData] = useState(null);

    //ElastiCache的主Json文件
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('/pricing/2.0/meteredUnitMaps/elasticache/USD/current/elasticache.json');
                const json = await response.json();
                setData(json);
                updateRegionData(selectedRegion, json);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };

        fetchData();
    }, []);

    const updateRegionData = (region, allData) => {
        if (allData && allData.regions && allData.regions[region]) {
            setRegionData(allData.regions[region]['OnDemand ElastiCache Serverless Redis per GB-Hours']['price']);
            setRequestData(allData.regions[region]['OnDemand ElastiCache Serverless Memcached per ElastiCacheProcessingUnit']['price']);
        } else {
            setRegionData(null);
        }
    };

    const [instanceType, setInstanceType] = useState('');
    const [dataTierInstanceType, setDataTierInstanceType] = useState('');


    const [showDataTierOptions, setShowDataTierOptions] = useState(true); // 控制数据层选项的显示

    useEffect(() => {
        validateFilters();
    }, [filters, showDataTierOptions]);

    const handleInstanceTypeChange = (newType) => {
        setInstanceType(newType);
    };

    const handleDataTierInstanceTypeChange = (newType, instanceDetails) => {
        setDataTierInstanceType(newType)
    };

    //如果Region改变了获取Data Tier的选项，如果获取不到就让Data Tier消失
    const handleRegionChange = (newType) => {
        const fetchInstanceTypes = async () => {
            try {
                const response = await fetch(`/pricing/2.0/meteredUnitMaps/elasticache/USD/current/elasticache-datatiering-calc/${encodeURIComponent(newType)}/primary-selector-aggregations.json`);
                if (!response.ok) throw new Error('Network response was not ok');
                setShowDataTierOptions(true)
            } catch (error) {
                console.error('Failed to fetch instance types:', error);
                setShowDataTierOptions(false)
            }
        }
        fetchInstanceTypes();
    };

    const [instanceDetails, setInstanceDetails] = useState({});
    const [termOptions, setTermOptions] = useState([{ label: '1yr', value: '1yr' }]); // 默认为1年

    //如果选择了Cluster的Instance就获取Instance的报价
    const handleInstanceSelected = async (instance) => {
        const url = `/pricing/2.0/meteredUnitMaps/elasticache/USD/current/elasticache-calc/${encodeURIComponent(filters.region)}/${encodeURIComponent(filters.pricingModel)}/${encodeURIComponent(filters.cacheEngine)}/${encodeURIComponent(instance.value)}/${instance.selectors['Instance Family']}/${instance.selectors['vCPU']}/${encodeURIComponent(instance.selectors['Memory'])}/${encodeURIComponent(instance.selectors['Network Performance'])}/index.json`;
        // // console.log(instance.totalcount);
        try {
            const response = await fetch(url);
            const data = await response.json();
            // // console.log(data);
            if (instance.totalcount === 10) {
                setTermOptions([
                    { label: '1yr', value: '1yr' },
                    { label: '3yr', value: '3yr' }
                ]);
            } else if (instance.totalcount === 5) {
                setTermOptions([
                    { label: '1yr', value: '1yr' }
                ]);
            }
            setInstanceDetails(data);
        } catch (error) {
            console.error('Failed to fetch instance details:', error);
            setInstanceDetails({});  // 清空或设置错误信息
        }
    };

    const [dataTierInstanceDetails, setDataTierInstanceDetails] = useState({});
    const [dataTierTermOptions, setDataTierTermOptions] = useState([{ label: '1yr', value: '1yr' }]); // 默认为1年


    //如果选择了Data Tier的Instance就获取该Instance的报价
    const handleDataTierInstanceSelected = async (instance) => {
        const url = `/pricing/2.0/meteredUnitMaps/elasticache/USD/current/elasticache-datatiering-calc/${encodeURIComponent(filters.region)}/${encodeURIComponent(filters.dataTierPricingModel)}/Redis/${encodeURIComponent(instance.value)}/${instance.selectors['Instance Family']}/${instance.selectors['vCPU']}/${encodeURIComponent(instance.selectors['Memory'])}/${encodeURIComponent(instance.selectors['Network Performance'])}/${encodeURIComponent(instance.selectors['SSD'])}/index.json`;

        try {
            const response = await fetch(url);
            const data = await response.json();
            // // console.log(data);
            // // console.log(instance.totalcount);
            if (instance.totalcount === 10) {
                setDataTierTermOptions([
                    { label: '1yr', value: '1yr' },
                    { label: '3yr', value: '3yr' }
                ]);
            } else if (instance.totalcount === 5) {
                setDataTierTermOptions([
                    { label: '1yr', value: '1yr' }
                ]);
            }
            setDataTierInstanceDetails(data);
        } catch (error) {
            console.error('Failed to fetch instance details:', error);
            setDataTierInstanceDetails({});  // 清空或设置错误信息
        }

    };

    //如果惦记了Add to Cart进行的操作
    const handleAddCart = () => {
        // 检查是否所有的过滤器和选择都有效
        // if (!allFiltersValid) {
        //     console.error('Not all filters are valid.');
        //     return; // 如果过滤器无效或选择不完整，则不执行添加操作
        // }

        // 创建购物车条目对象
        const cartItem = {
            sku: uuidv4(), // 生成一个唯一ID
            region: filters.region,
            instanceType: instanceType,
            cacheEngine: filters.cacheEngine,
            pricingModel: filters.pricingModel,
            averageDataTransferred: filters.averageDataTransferred,
            nodes: filters.nodes,
            averageCacheDataSize: filters.averageCacheDataSize,
            averageRequestRate: filters.averageRequestRate + ' ' + filters.requestRateUnit,
            term: filters.pricingModel === 'Reserved' ? filters.term : 'N/A',
            purchaseOption: filters.pricingModel === 'Reserved' ? filters.purchaseOption : 'N/A',
            valueUnit: `${filters.value} ${filters.unit.label}`,
            // details: instanceDetails,
            serverlessPrice: 0,
            monthlyPrice: 0,
            upfrontPrice: 0,
            ServiceName: 'elastiCache',
            quantity: 1,
        };

        // 如果启用了Data Tier选项
        if (showDataTierOptions && filters.dataTierNodes != 0 && filters.dataTierNodes != '') {
            cartItem.dataTierDetails = {
                dataTierNodes: filters.dataTierNodes,
                dataTierInstanceType: dataTierInstanceType, // 假设实例类型与数据分层相同
                dataTierPricingModel: filters.dataTierPricingModel,
                dataTierTerm: filters.dataTierPricingModel === 'Reserved' ? filters.dataTierTerm : 'N/A',
                dataTierPurchaseOption: filters.dataTierPricingModel === 'Reserved' ? filters.dataTierPurchaseOption : 'N/A',
                dataTierValueUnit: `${filters.dataTierValue} ${filters.dataTierUnit.label}`,
                // dataTierInstanceDetails: dataTierInstanceDetails,
                dataTierMonthlyPrice: 0,
                dataTierUpfrontPrice: 0,
            };
        }
        else{
            cartItem.dataTierDetails = null;
        }


        //价格计算
        cartItem.serverlessPrice += filters.averageCacheDataSize * 730 * regionData;
        // console.log(requestData);
        if(filters.requestRateUnit === 'per second'){
            cartItem.serverlessPrice += filters.averageRequestRate * 2628000 * requestData * filters.averageDataTransferred;
        }
        // console.log(cartItem.serverlessPrice);

        if(filters.pricingModel === 'OnDemand'){
            const instancePrice = instanceDetails ? Object.values(instanceDetails.regions[filters.region])[0].price : 0;
            if(filters.unit.value === '% Utilized/Month'){
                cartItem.monthlyPrice += filters.value / 100 * 730 * filters.nodes * instancePrice;
                // console.log('monthlyPrice', cartItem.monthlyPrice)
            }
            else if(filters.unit.value === 'Hours/Day'){
                cartItem.monthlyPrice += filters.value / 24 * 730 * filters.nodes * instancePrice;
                // console.log('monthlyPrice', cartItem.monthlyPrice)
            }
            else if(filters.unit.value === 'Hours/Week'){
                cartItem.monthlyPrice += filters.value / 168 * 730 * filters.nodes * instancePrice;
                // console.log('monthlyPrice', cartItem.monthlyPrice)
            }
            else if(filters.unit.value === 'Hours/Month'){
                cartItem.monthlyPrice += filters.value * filters.nodes * instancePrice;
                // console.log('monthlyPrice', cartItem.monthlyPrice)
            }
            // console.log(instancePrice);
        }
        else if(filters.pricingModel === 'Reserved'){
            let result = 0;
            const temp = instanceDetails ? instanceDetails.regions[filters.region] : 0;
            // console.log(temp)
            for (const key in temp) {
                const s = filters.term + ' ' + filters.purchaseOption + ' Hrs'
                if (temp.hasOwnProperty(key) && key.includes(s)) {
                    // 将匹配的项目的详细信息添加到结果数组中
                    // console.log(s);
                    result = temp[key].price;
                }
                // console.log('result', result);
            }
            cartItem.monthlyPrice += filters.nodes * 730 * result;
            // console.log('monthlyPrice', cartItem.monthlyPrice)

            result = 0;
            for (const key in temp) {
                const s = filters.term + ' ' + filters.purchaseOption + ' Quantity'
                if (temp.hasOwnProperty(key) && key.includes(s)) {
                    // console.log(s);
                    result = temp[key].price;
                }
                // console.log('result', result);
            }

            cartItem.upfrontPrice += filters.nodes * result;
        }

        if (showDataTierOptions && filters.dataTierNodes != 0 && filters.dataTierNodes != ''){
            if(filters.dataTierPricingModel === 'OnDemand'){
                const instancePrice = dataTierInstanceDetails ? Object.values(dataTierInstanceDetails.regions[filters.region])[0].price : 0;
                if(filters.dataTierUnit.value === '% Utilized/Month'){
                    cartItem.dataTierDetails.dataTierMonthlyPrice += filters.dataTierValue / 100 * 730 * filters.dataTierNodes * instancePrice;
                    // console.log('dataTierMonthlyPrice',cartItem.dataTierDetails.dataTierMonthlyPrice)
                }
                else if(filters.dataTierUnit.value === 'Hours/Day'){
                    cartItem.dataTierDetails.dataTierMonthlyPrice += filters.dataTierValue / 24 * 730 * filters.dataTierNodes * instancePrice;
                    // console.log('dataTierMonthlyPrice',cartItem.dataTierDetails.dataTierMonthlyPrice)
                }
                else if(filters.dataTierUnit.value === 'Hours/Week'){
                    cartItem.dataTierDetails.dataTierMonthlyPrice += filters.dataTierValue / 168 * 730 * filters.dataTierNodes * instancePrice;
                    // console.log('dataTierMonthlyPrice',cartItem.dataTierDetails.dataTierMonthlyPrice)
                }
                else if(filters.dataTierUnit.value === 'Hours/Month'){
                    cartItem.dataTierDetails.dataTierMonthlyPrice += filters.dataTierValue / 730 * 730 * filters.dataTierNodes * instancePrice;
                    // console.log('dataTierMonthlyPrice',cartItem.dataTierDetails.dataTierMonthlyPrice)
                }
                // console.log(instancePrice);
            }
            else if(filters.dataTierPricingModel === 'Reserved'){
                let result = 0;
                const temp = dataTierInstanceDetails ? dataTierInstanceDetails.regions[filters.region] : 0;
                // console.log(temp)
                for (const key in temp) {
                    const s = filters.dataTierTerm + ' ' + filters.dataTierPurchaseOption + ' Hrs'
                    if (temp.hasOwnProperty(key) && key.includes(s)) {
                        // 将匹配的项目的详细信息添加到结果数组中
                        // console.log(s);
                        result = temp[key].price;
                    }
                    // console.log('datatierresult', result);
                }
                cartItem.dataTierDetails.dataTierMonthlyPrice += filters.dataTierNodes * 730 * result;
                // console.log('dataTierMonthlyPrice', cartItem.dataTierDetails.dataTierMonthlyPrice)

                result = 0;
                for (const key in temp) {
                    const s = filters.dataTierTerm + ' ' + filters.dataTierPurchaseOption + ' Quantity'
                    if (temp.hasOwnProperty(key) && key.includes(s)) {
                        // console.log(s);
                        result = temp[key].price;
                    }
                    // console.log('datatierresult', result);
                }

                cartItem.dataTierDetails.dataTierUpfrontPrice += filters.dataTierNodes * result;
            }
        }

        // cartItem.price =

        // 调用 Redux action
        props.addToCart(cartItem);

        // console.log('Added to cart:', cartItem);
    };








    //显示界面
    return (

        <Layout>
            <Container>
                <SpaceBetween direction="vertical" size="s">

                    <Header
                        counter=""
                        actions={
                            <SpaceBetween
                                direction="horizontal"
                                size="xs"
                            >
                                <Button variant="primary"
                                        onClick={handleAddCart}
                                        disabled={!allFiltersValid}
                                >
                                    Add to Cart
                                </Button>
                                <Link to="/cart">
                                    <Button variant="primary">
                                        Go to Cart
                                    </Button>
                                </Link>
                            </SpaceBetween>
                        }
                    >
                        Amazon ElastiCache
                    </Header>


                    <div className="filter-container" key="filter-container">
                        <SpaceBetween direction="vertical" size="s">
                            {/* First row */}
                            <Container
                                header={
                                    <Header variant="h2" description="">
                                        Region
                                    </Header>
                                }
                            >
                                <div className="EBS-filter-row">
                                    <div className="EBS-select-filter" key="region-filter">
                                        <FormField label="Region">
                                            <EBSRegionFilter
                                                selectedOption={regionOptions.find((option) => option.value === filters.region)}
                                                onFilterChange={(value) => {
                                                    handleFiltersChange({...filters, region: value});
                                                    handleRegionChange(value);
                                                }}
                                            />
                                        </FormField>
                                    </div>
                                </div>
                            </Container>


                            {/* Fourth row */}
                            <Container
                                header={
                                    <Header variant="h2" description="">
                                        Serverless Settings
                                    </Header>
                                }
                            >
                                <div className="filter-container" key="filter-container">

                                    <div className="EBS-filter-row">
                                        <div className="EBS-input-filter" key="average-cache-data-size-filter">
                                            <FormField label="Average Cache Data Size (in GB)">
                                                <AverageCacheDataSizeFilter
                                                    filteringText={filters.averageCacheDataSize}
                                                    onFilterChange={(value) => handleFiltersChange({
                                                        ...filters,
                                                        averageCacheDataSize: value
                                                    })}
                                                />
                                            </FormField>
                                        </div>
                                        <div className="EBS-input-filter" key="average-request-rate-filter">
                                            <FormField label="Average Simple Request Rate">
                                                <AverageRequestRateFilter
                                                    filteringText={filters.averageRequestRate}
                                                    unit={filters.requestRateUnit}
                                                    onFilterChange={(value, unit) => handleFiltersChange({
                                                        ...filters,
                                                        averageRequestRate: value,
                                                        requestRateUnit: unit
                                                    })}
                                                />
                                            </FormField>
                                        </div>


                                        <div className="EBS-input-filter" key="average-data-transferred-filter">
                                            <FormField label="Average Data Transferred Per Request (in KB)">
                                                <AverageDataTransferredPerRequestFilter
                                                    filteringText={filters.averageDataTransferred}
                                                    onFilterChange={(value) => handleFiltersChange({
                                                        ...filters,
                                                        averageDataTransferred: value
                                                    })}
                                                />
                                            </FormField>
                                        </div>

                                    </div>
                                </div>
                            </Container>


                            <Container
                                header={
                                    <Header variant="h2" description="">
                                        Cluster Settings
                                    </Header>
                                }
                            >

                                <div className="filter-container" key="filter-container">


                                    <div className="EBS-filter-row">
                                        <div className="EBS-input-filter" key="nodes-input-filter">
                                            <FormField label="Nodes">
                                                <NodesInputFilter
                                                    filteringText={filters.nodes}
                                                    onFilterChange={(value) => handleFiltersChange({...filters, nodes: value})}
                                                />
                                            </FormField>
                                        </div>

                                        <div className="EBS-select-filter" key="instance-type-filter">
                                            <FormField label="Instance Type">
                                                <InstanceTypeFilter
                                                    termType={filters.pricingModel}
                                                    cacheEngine={filters.cacheEngine}
                                                    region={filters.region}
                                                    onInstanceTypeChange={handleInstanceTypeChange}
                                                    onInstanceSelected={handleInstanceSelected}
                                                />
                                            </FormField>
                                        </div>

                                        <div className="EBS-input-filter" key="value-unit-filter">
                                            <FormField label="Value and Unit">
                                                <ValueUnitFilter
                                                    value={filters.value}
                                                    unit={filters.unit || {label: '% Utilized/Month', value: '% Utilized/Month'}}
                                                    onValueChange={(newValue, newUnit) => handleFiltersChange({
                                                        ...filters,
                                                        value: newValue,
                                                        unit: newUnit
                                                    })}
                                                />
                                            </FormField>
                                        </div>
                                        <div className="EBS-input-filter" key="cache-engine-filter">
                                            <FormField label="Cache Engine">
                                                <CacheEngineFilter
                                                    selectedOption={{label: 'Redis', value: filters.cacheEngine || 'Redis'}}
                                                    onEngineChange={(value) => handleFiltersChange({
                                                        ...filters,
                                                        cacheEngine: value
                                                    })}
                                                />
                                            </FormField>
                                        </div>
                                        <div className="EBS-input-filter" key="pricing-model-filter">
                                            <FormField label="Pricing Model">
                                                <PricingModelFilter
                                                    selectedOption={{label: 'OnDemand', value: filters.pricingModel || 'OnDemand'}}
                                                    onPricingModelChange={(value) => handleFiltersChange({
                                                        ...filters,
                                                        pricingModel: value
                                                    })}
                                                />
                                            </FormField>
                                            {filters.pricingModel === 'Reserved' && (
                                                <>
                                                    <FormField label="Term">
                                                        <TermFilter
                                                            selectedOption={{
                                                                label: filters.term || '1yr',
                                                                value: filters.term || '1yr'
                                                            }}
                                                            onTermChange={(value) => handleFiltersChange({...filters, term: value})}
                                                            options={termOptions}
                                                        />
                                                    </FormField>
                                                    <FormField label="Purchase Option">
                                                        <PurchaseOptionFilter
                                                            selectedOption={{
                                                                label: filters.purchaseOption || 'All Upfront',
                                                                value: filters.purchaseOption || 'All Upfront'
                                                            }}
                                                            onPurchaseOptionChange={(value) => handleFiltersChange({
                                                                ...filters,
                                                                purchaseOption: value
                                                            })}
                                                        />
                                                    </FormField>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Container>

                            {showDataTierOptions && (<Container
                                header={
                                    <Header variant="h2" description="">
                                        Data Tier Cluster Settings
                                    </Header>
                                }
                            >
                                <div className="filter-container" key="filter-container">


                                    <div className="EBS-filter-row">

                                        <div className="EBS-input-filter" key="data-tier-nodes-filter">
                                            <FormField label="Data Tier Nodes">
                                                <DataTierNodesFilter
                                                    filteringText={filters.dataTierNodes}
                                                    onFilterChange={(value) => handleFiltersChange({
                                                        ...filters,
                                                        dataTierNodes: value
                                                    })}
                                                />
                                            </FormField>
                                        </div>
                                        <div className="EBS-select-filter" key="data-tier-instance-type-filter">
                                            <FormField label="Instance Type">
                                                <DataTierInstanceTypeFilter
                                                    termType1={filters.dataTierPricingModel}
                                                    cacheEngine1={filters.dataTierCacheEngine}
                                                    region={filters.region}
                                                    onInstanceTypeChange={handleDataTierInstanceTypeChange}
                                                    onInstanceSelected={handleDataTierInstanceSelected}
                                                    // onError={() => setShowDataTierOptions(false)}  // 这里添加onError处理
                                                    // notError={() => setShowDataTierOptions(true)}
                                                />
                                            </FormField>
                                        </div>

                                        <div className="EBS-input-filter" key="data-tier-value-unit-filter">
                                            <FormField label="Data Tier Value and Unit">
                                                <DataTierValueUnitFilter
                                                    value={filters.dataTierValue}
                                                    unit={filters.dataTierUnit}
                                                    onValueChange={(newValue, newUnit) => handleFiltersChange({
                                                        ...filters,
                                                        dataTierValue: newValue,
                                                        dataTierUnit: newUnit
                                                    })}
                                                />
                                            </FormField>
                                        </div>
                                        <div className="EBS-select-filter" key="data-tier-pricing-model-filter">
                                            <FormField label="Data Tier Pricing Model">
                                                <DataTierPricingModelFilter
                                                    selectedOption={{
                                                        label: filters.dataTierPricingModel,
                                                        value: filters.dataTierPricingModel
                                                    }}
                                                    onPricingModelChange={(value) => handleFiltersChange({
                                                        ...filters,
                                                        dataTierPricingModel: value
                                                    })}
                                                />
                                                {filters.dataTierPricingModel === 'Reserved' && (
                                                    <>
                                                        <FormField label="Data Tier Term">
                                                            <DataTierTermFilter
                                                                selectedOption={{
                                                                    label: filters.term || '1yr',
                                                                    value: filters.term || '1yr'
                                                                }}
                                                                onTermChange={(value) => handleFiltersChange({
                                                                    ...filters,
                                                                    term: value
                                                                })}
                                                                options={dataTierTermOptions}
                                                            />
                                                        </FormField>
                                                        <FormField label="Data Tier Purchase Option">
                                                            <DataTierPurchaseOptionFilter
                                                                selectedOption={{
                                                                    label: filters.dataTierPurchaseOption,
                                                                    value: filters.dataTierPurchaseOption
                                                                }}
                                                                onPurchaseOptionChange={(value) => handleFiltersChange({
                                                                    ...filters,
                                                                    dataTierPurchaseOption: value
                                                                })}
                                                            />
                                                        </FormField>
                                                    </>
                                                )}
                                            </FormField>
                                        </div>

                                    </div>
                                </div>
                            </Container>)}
                        </SpaceBetween>

                    </div>
                    {/*<div>*/}
                    {/*    <h2>Instance Details:</h2>*/}
                    {/*    <pre>{JSON.stringify(instanceDetails, null, 2)}</pre>*/}
                    {/*</div>*/}

                    {/*<div>*/}
                    {/*    <h2>Data Tier Instance Details:</h2>*/}
                    {/*    <pre>{JSON.stringify(dataTierInstanceDetails, null, 2)}</pre>*/}
                    {/*</div>*/}
                </SpaceBetween>
            </Container>
        </Layout>
    );
};


const mapDispatchToProps = (dispatch) => ({
    addToCart: (item) => dispatch(addToCart(item)),
});

export default connect(null, mapDispatchToProps)(ElastiCache);
