import React from 'react';

export const calculateRDSMariaDBPrices = async (itemWithFilters, buttonState) => {
  const {
    rdsProxy,
    ebsFilters,
    instanceType,
    instanceFamily,
    memory,
    vcpu,
    networkPerformance,
    perUnitPrice,
    fullkey,
    region,
    deploymentOption,
  } = itemWithFilters;

  const getRegionUrlCode = (regionLabel) => {
    const regionMapping = {
      "US East (N. Virginia)": "US%20East%20(N.%20Virginia)",
      "US East (Ohio)": "US%20East%20(Ohio)",
      "US West (N. California)": "US%20West%20(N.%20California)",
      "US West (Oregon)": "US%20West%20(Oregon)",
      "Africa (Cape Town)": "Africa%20(Cape%20Town)",
      "Asia Pacific (Hong Kong)": "Asia%20Pacific%20(Hong%20Kong)",
      "Asia Pacific (Hyderabad)": "Asia%20Pacific%20(Hyderabad)",
      "Asia Pacific (Jakarta)": "Asia%20Pacific%20(Jakarta)",
      "Asia Pacific (Melbourne)": "Asia%20Pacific%20(Melbourne)",
      "Asia Pacific (Mumbai)": "Asia%20Pacific%20(Mumbai)",
      "Asia Pacific (Osaka)": "Asia%20Pacific%20(Osaka)",
      "Asia Pacific (Seoul)": "Asia%20Pacific%20(Seoul)",
      "Asia Pacific (Singapore)": "Asia%20Pacific%20(Singapore)",
      "Asia Pacific (Sydney)": "Asia%20Pacific%20(Sydney)",
      "Asia Pacific (Tokyo)": "Asia%20Pacific%20(Tokyo)",
      "Canada (Central)": "Canada%20(Central)",
      "Canada West (Calgary)": "Canada%20West%20(Calgary)",
      "EU (Frankfurt)": "Europe%20(Frankfurt)",
      "EU (Ireland)": "Europe%20(Ireland)",
      "EU (London)": "Europe%20(London)",
      "EU (Milan)": "Europe%20(Milan)",
      "EU (Paris)": "Europe%20(Paris)",
      "EU (Spain)": "Europe%20(Spain)",
      "EU (Stockholm)": "Europe%20(Stockholm)",
      "EU (Zurich)": "Europe%20(Zurich)",
      "Israel (Tel Aviv)": "Israel%20(Tel%20Aviv)",
      "Middle East (Bahrain)": "Middle%20East%20(Bahrain)",
      "Middle East (UAE)": "Middle%20East%20(UAE)",
      "South America (Sao Paulo)": "South%20America%20(Sao%20Paulo)",
      "AWS GovCloud (US-East)": "AWS%20GovCloud%20(US-East)",
      "AWS GovCloud (US)": "AWS%20GovCloud%20(US)",
    };

    return regionMapping[regionLabel] || '';
  };

  const regionUrlCode = getRegionUrlCode(region);
  const parsedVCPU = parseInt(vcpu, 10);
  const parsedMemory = parseInt(memory.replace(/\D/g, ''));

  const baseUrl = '/pricing/2.0/meteredUnitMaps/rds/USD/current/rds-mariadb-calc';
  const apiUrl = `${baseUrl}/${regionUrlCode}/${deploymentOption}/${instanceType}/${parsedVCPU}/${parsedMemory}%20GiB/Reserved/index.json`;

  const fetchRDSData = (url) => {
    return fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .catch((error) => {
        console.error('Fetching error:', error);
        return null;
      });
  };

  const determineReservedOption = (data) => {
    const result = {
      reservedOption: 'No',
      'Reserved 1yr All Upfront Hrs': 'N/A',
      'Reserved 1yr All Upfront Quantity': 'N/A',
      'Reserved 1yr No Upfront Hrs': 'N/A',
      'Reserved 3yr All Upfront Quantity': 'N/A',
      'Reserved 3yr Partial Upfront Hrs': 'N/A',
      'Reserved 3yr All Upfront Hrs': 'N/A',
      'Reserved 3yr Partial Upfront Quantity': 'N/A',
      'Reserved 1yr Partial Upfront Hrs': 'N/A',
      'Reserved 1yr Partial Upfront Quantity': 'N/A',
    };

    if (data && data.regions) {
      result.reservedOption = 'Yes';
      result.regionData = data.regions[region];

      const regionData = data.regions[region];
      const keyPatterns = [
        `${deploymentOption} ${instanceType} Reserved 1yr All Upfront Hrs`,
        `${deploymentOption} ${instanceType} Reserved 1yr All Upfront Quantity`,
        `${deploymentOption} ${instanceType} Reserved 1yr No Upfront Hrs`,
        `${deploymentOption} ${instanceType} Reserved 3yr All Upfront Quantity`,
        `${deploymentOption} ${instanceType} Reserved 3yr Partial Upfront Hrs`,
        `${deploymentOption} ${instanceType} Reserved 3yr All Upfront Hrs`,
        `${deploymentOption} ${instanceType} Reserved 3yr Partial Upfront Quantity`,
        `${deploymentOption} ${instanceType} Reserved 1yr Partial Upfront Hrs`,
        `${deploymentOption} ${instanceType} Reserved 1yr Partial Upfront Quantity`,
      ];

      for (const [key, value] of Object.entries(regionData)) {
        if (key === `${deploymentOption} ${instanceType} Reserved 1yr All Upfront Hrs`) {
          result['Reserved 1yr All Upfront Hrs'] = value.price;
        } else if (key === `${deploymentOption} ${instanceType} Reserved 1yr All Upfront Quantity`) {
          result['Reserved 1yr All Upfront Quantity'] = value.price;
        } else if (key === `${deploymentOption} ${instanceType} Reserved 1yr No Upfront Hrs`) {
          result['Reserved 1yr No Upfront Hrs'] = value.price;
        } else if (key === `${deploymentOption} ${instanceType} Reserved 3yr All Upfront Quantity`) {
          result['Reserved 3yr All Upfront Quantity'] = value.price;
        } else if (key === `${deploymentOption} ${instanceType} Reserved 3yr Partial Upfront Hrs`) {
          result['Reserved 3yr Partial Upfront Hrs'] = value.price;
        } else if (key === `${deploymentOption} ${instanceType} Reserved 3yr All Upfront Hrs`) {
          result['Reserved 3yr All Upfront Hrs'] = value.price;
        } else if (key === `${deploymentOption} ${instanceType} Reserved 3yr Partial Upfront Quantity`) {
          result['Reserved 3yr Partial Upfront Quantity'] = value.price;
        } else if (key === `${deploymentOption} ${instanceType} Reserved 1yr Partial Upfront Hrs`) {
          result['Reserved 1yr Partial Upfront Hrs'] = value.price;
        } else if (key === `${deploymentOption} ${instanceType} Reserved 1yr Partial Upfront Quantity`) {
          result['Reserved 1yr Partial Upfront Quantity'] = value.price;
        }
      }
    }

    return result;
  };


  const performAdditionalCalculation = (reservedOptionData) => {
    const { utilization } = ebsFilters;
    const { value: utilizationValue, unit } = utilization;

    // Calculate prices
    const reserved1yrAllUpfrontFee = reservedOptionData['Reserved 1yr All Upfront Quantity'];

    const reserved1yrPartialUpfrontMonthlyPrice = reservedOptionData['Reserved 1yr Partial Upfront Hrs'] * 730;
    const reserved1yrPartialUpfrontFee = reservedOptionData['Reserved 1yr Partial Upfront Quantity'];

    const reserved1yrNoUpfrontMonthlyPrice = reservedOptionData['Reserved 1yr No Upfront Hrs'] * 730;

    const reserved3yrAllUpfrontFee = reservedOptionData['Reserved 3yr All Upfront Quantity'];

    const reserved3yrPartialUpfrontMonthlyPrice = reservedOptionData['Reserved 3yr Partial Upfront Hrs'] * 730;
    const reserved3yrPartialUpfrontFee = reservedOptionData['Reserved 3yr Partial Upfront Quantity'];

    let onDemandMonthlyPrice;
    if (unit === '%Utilized/Month') {
      onDemandMonthlyPrice = (utilizationValue / 100) * 730 * perUnitPrice;
    } else if (unit === 'Hours/Day') {
      onDemandMonthlyPrice = (utilizationValue / 24) * 730 * perUnitPrice;
    } else if (unit === 'Hours/Week') {
      onDemandMonthlyPrice = (utilizationValue / 168) * 730 * perUnitPrice;
    } else if (unit === 'Hours/Month') {
      onDemandMonthlyPrice = utilizationValue * 730 * perUnitPrice;
    }

    return {
    /*   ...reservedOptionData, */
      reserved1yrAllUpfrontFee,
      reserved1yrPartialUpfrontMonthlyPrice,
      reserved1yrPartialUpfrontFee,
      reserved1yrNoUpfrontMonthlyPrice,
      reserved3yrAllUpfrontFee,
      reserved3yrPartialUpfrontMonthlyPrice,
      reserved3yrPartialUpfrontFee,
      onDemandMonthlyPrice,
    };
  };


    const fetchStorageData = async () => {
    const url = `/pricing/2.0/meteredUnitMaps/rds/USD/current/rds-mariadb-ondemand.json`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Fetching error:', error);
      return null;
    }
  };

  const processStorageData = (data) => {
    const regionLabel = decodeURIComponent(region);
    let regionData = null;

    // Find the matching region data
    for (const regionKey in data.regions) {
      if (regionKey === regionLabel) {
        regionData = data.regions[regionKey];
        break;
      }
    }

    if (!regionData) {
      console.error("No data found for the selected region:", regionLabel);
      return null;
    }

    // Construct the storage price key
    const deploymentOptionWithoutDash = deploymentOption.replace(/-/g, ' ');
    const storageKey = `Database Storage ${deploymentOptionWithoutDash} ${ebsFilters.volumeType}`;

    // Find the storage price from the regionData
    const storagePrice = regionData[storageKey]?.price || 0;

    // Calculate the storage cost
    const { amount, unit } = ebsFilters.volumeSize;
    let storageCost;

    if (unit === 'MB') {
      storageCost = (parseFloat(amount) / 1024) * storagePrice;
    } else if (unit === 'GB') {
      storageCost = parseFloat(amount) * storagePrice;
    } else if (unit === 'TB') {
      storageCost = (parseFloat(amount) * 1024) * storagePrice;
    }

    // Determine unitied volume size
    let unifiedVolumeSize;
  if (unit === 'MB') {
    unifiedVolumeSize = parseFloat(amount) / 1024;
  } else if (unit === 'GB') {
    unifiedVolumeSize = parseFloat(amount);
  } else if (unit === 'TB') {
    unifiedVolumeSize = parseFloat(amount) * 1024;
  }

  // Find Provisioning IOPS price and Throughput price based on volume type
  let provisioningIOPSPrice = 'N/A';
  let throughputPrice = 'N/A';
  let provisioningIOPSCost = 'N/A';
  let throughputCost = 'N/A';

  const volumeType = ebsFilters.volumeType;

  if (volumeType === 'General Purpose') {
    provisioningIOPSPrice = 'N/A';
    throughputPrice = 'N/A';
    provisioningIOPSCost = 'N/A';
    throughputCost = 'N/A';
  } else if (volumeType === 'General Purpose-GP3') {
    const provisioningIOPSKey = `Provisioned GP3 IOPS ${deploymentOptionWithoutDash}`;
    const throughputKey = `Provisioned Throughput ${deploymentOptionWithoutDash}`;

    provisioningIOPSPrice = regionData[provisioningIOPSKey]?.price || 0;
    throughputPrice = regionData[throughputKey]?.price || 0;

    if (unifiedVolumeSize < 400) {
      provisioningIOPSCost = 0;
      throughputCost = 0;
    } else if (unifiedVolumeSize >= 400 && unifiedVolumeSize <= 65536) {
      const provisioningIOPS = parseFloat(ebsFilters.provisioningIOPS);
      const throughput = parseFloat(ebsFilters.throughput);

      provisioningIOPSCost = (provisioningIOPS - 12000) * provisioningIOPSPrice;
      throughputCost = (throughput - 500) * throughputPrice;
    }
  } else if (volumeType === 'Provisioned IOPS') {
    const provisioningIOPSKey = `Provisioned IOPS ${deploymentOptionWithoutDash}`;
    provisioningIOPSPrice = regionData[provisioningIOPSKey]?.price || 0;
    throughputPrice = 'N/A';

    const provisioningIOPS = parseFloat(ebsFilters.provisioningIOPS);
    provisioningIOPSCost = provisioningIOPS * provisioningIOPSPrice;
    throughputCost = 'N/A';
  } else if (volumeType === 'Provisioned IOPS-IO2') {
    const provisioningIOPSKey = `Provisioned IO2 IOPS ${deploymentOptionWithoutDash}`;
    provisioningIOPSPrice = regionData[provisioningIOPSKey]?.price || 0;
    throughputPrice = 'N/A';

    const provisioningIOPS = parseFloat(ebsFilters.provisioningIOPS);
    provisioningIOPSCost = provisioningIOPS * provisioningIOPSPrice;
    throughputCost = 'N/A';
  } else if (volumeType === 'Magnetic') {
    provisioningIOPSPrice = 'N/A';
    throughputPrice = 'N/A';
    provisioningIOPSCost = 'N/A';
    throughputCost = 'N/A';
  }

  // Find Backup Storage price and Snapshot Export price
  const backupStoragePrice = regionData['Storage Snapshot']?.price || 0;
  const snapshotExportPrice = regionData['RDS Snapshot Export MariaDB']?.price || 0;

  // Calculate Backup Storage cost and Snapshot Export cost
  const backupStorageCost = backupStoragePrice * parseFloat(ebsFilters.backupStorage);
  const snapshotExportCost = snapshotExportPrice * parseFloat(ebsFilters.snapshotExport);

  // Calculate annual Backup Storage cost and annual Snapshot Export cost
  const annualBackupStorageCost = backupStorageCost * 12;
  const annualSnapshotExportCost = snapshotExportCost * 12;

    return { storageKey, 
     /*  storagePrice,  */
      storageCost, 
  /*     provisioningIOPSPrice,  */
     /*  throughputPrice,  */
      provisioningIOPSCost, 
      throughputCost, 
     /*  backupStoragePrice, */
      backupStorageCost,
     /*  snapshotExportPrice, */
      snapshotExportCost,
      annualBackupStorageCost,
      annualSnapshotExportCost, };
  };

  //Calculate RDSProxy
  const calculateRDSProxy = async (itemWithFilters, nodes) => {
    const {
      rdsProxy,
      vcpu,
      region,
    } = itemWithFilters;
  
    // Check if RDS Proxy is enabled
    if (rdsProxy.value === 'no') {
      return {
        RDSProxyPrice: 0,
        RDSProxyCost: 0,
        RDSProxyAnnualCost: 0,
      };
    }
  
    // Fetch RDS Proxy data
    const url = '/pricing/2.0/meteredUnitMaps/rds/USD/current/rds-proxy.json';
    const proxyData = await fetchData(url);
  
    if (!proxyData) {
      return null;
    }
  
    // Find the matching region data
    const regionLabel = decodeURIComponent(region);
    const regionData = proxyData.regions[regionLabel];
  
    if (!regionData) {
      console.error("No data found for the selected region:", regionLabel);
      return null;
    }
  
    // Find the RDS Proxy price from the regionData
    const RDSProxyPrice = regionData['RDSProxy']?.price || 0;
  
    // Calculate RDS Proxy cost
    const parsedVCPU = parseInt(vcpu, 10);
    const RDSProxyCost = nodes * parsedVCPU * 730 * RDSProxyPrice;
  
    // Calculate RDS Proxy annual cost
    const RDSProxyAnnualCost = RDSProxyCost * 12;
  
    return {
    /*   RDSProxyPrice, */
      RDSProxyCost,
      RDSProxyAnnualCost,
    };
  };
  
  // Helper function to fetch data from a URL
  const fetchData = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Fetching error:', error);
      return null;
    }
  };

  const StorageData = await fetchStorageData();
  const { storageKey, /* storagePrice,  */storageCost, /* provisioningIOPSPrice, */ /* throughputPrice, */ provisioningIOPSCost, throughputCost, /* backupStoragePrice, snapshotExportPrice, */ backupStorageCost, snapshotExportCost, annualBackupStorageCost, annualSnapshotExportCost, } = processStorageData(StorageData);
  const totalmonthlystoragecost = storageCost * ebsFilters.nodes + (provisioningIOPSCost === 'N/A' ? 0 : provisioningIOPSCost) + (throughputCost === 'N/A' ? 0 : throughputCost);
  const totalannualstoragecost = totalmonthlystoragecost * 12;

  //return RDSProxy
  const {
 /*    RDSProxyPrice, */
    RDSProxyCost,
    RDSProxyAnnualCost,
  } = await calculateRDSProxy(itemWithFilters, ebsFilters.nodes);

  const generateSKU = (instanceType, memory, vcpu, region, deploymentOption, volumeType, volumeSize, provisioningIOPS, throughput, rdsProxy) => {
    const skuParts = [
      "RDSforMariaDB",
      instanceType,
      `mem-${memory}`,
      `vcpu-${vcpu}`,
      region,
      deploymentOption,
      `vol-${volumeType}`,
      `size-${volumeSize.amount}${volumeSize.unit}`,
      `rdsProxy-${rdsProxy.value}`,
    ];
  
    if (['Provisioned IOPS', 'Provisioned IOPS-IO2', 'General Purpose-GP3'].includes(volumeType)) {
      skuParts.push(`iops-${provisioningIOPS}`);
    }
  
    if (volumeType === 'General Purpose-GP3') {
      skuParts.push(`throughput-${throughput}`);
    }
  
    return skuParts.join("-");
  };

  
  return fetchRDSData(apiUrl)
  .then(determineReservedOption)
  .then(performAdditionalCalculation)
  .then((calculatedData) => {
    const initialData = {
      ServiceName: "RDSforMariaDB",
      InstanceType: instanceType,
      InstanceFamily: instanceFamily,
      Memory: memory,
      vCPU: vcpu,
      Networkperformance: networkPerformance,
      Region: region,
      DeploymentOption: deploymentOption,
      rdsProxy: rdsProxy.value,
     /*  perUnitPrice: perUnitPrice, */
      filtervalue: ebsFilters,
      quantity: 1, // Set the quantity to 1 by default
    };

    const sku = generateSKU(
      instanceType,
      memory,
      vcpu,
      region,
      deploymentOption,
      ebsFilters.volumeType,
      ebsFilters.volumeSize,
      ebsFilters.provisioningIOPS,
      ebsFilters.throughput,
      rdsProxy
    );

    //test location
    const {
      onDemandMonthlyPrice,
      reserved1yrNoUpfrontMonthlyPrice,
      reserved1yrPartialUpfrontMonthlyPrice,
      reserved1yrPartialUpfrontFee,
      reserved1yrAllUpfrontFee,
      reserved3yrPartialUpfrontMonthlyPrice,
      reserved3yrPartialUpfrontFee,
      reserved3yrAllUpfrontFee,
    } = calculatedData;
  
    const OndemandTotalMonthlyCost = (onDemandMonthlyPrice * ebsFilters.nodes) + totalmonthlystoragecost + (backupStorageCost || 0) + (snapshotExportCost || 0) + (RDSProxyCost || 0);
    const OndemandTotalAnnualCost = (onDemandMonthlyPrice * ebsFilters.nodes * 12) + totalannualstoragecost + (annualBackupStorageCost || 0) +(annualSnapshotExportCost || 0) +(RDSProxyAnnualCost || 0);
  
    const Reserved1yrNoUpfrontTotalMonthlyCost = (reserved1yrNoUpfrontMonthlyPrice * ebsFilters.nodes) + totalmonthlystoragecost + (backupStorageCost || 0) + (snapshotExportCost || 0) + (RDSProxyCost || 0);
    const Reserved1yrNoUpfrontTotalAnnualCost = (reserved1yrNoUpfrontMonthlyPrice * ebsFilters.nodes * 12) + totalannualstoragecost + (annualBackupStorageCost || 0) +(annualSnapshotExportCost || 0) +(RDSProxyAnnualCost || 0);
  
    const Reserved1yrAllUpfrontTotalAnnualCost = (parseFloat(reserved1yrAllUpfrontFee) * ebsFilters.nodes) + totalannualstoragecost + (annualBackupStorageCost || 0) +(annualSnapshotExportCost || 0) +(RDSProxyAnnualCost || 0);
  
    const Reserved1yrPartialUpfrontTotalMonthlyCost = (reserved1yrPartialUpfrontMonthlyPrice * ebsFilters.nodes) + totalmonthlystoragecost + (backupStorageCost || 0) + (snapshotExportCost || 0) + (RDSProxyCost || 0);
    const Reserved1yrPartialUpfrontTotalAnnualCost = (reserved1yrPartialUpfrontMonthlyPrice * ebsFilters.nodes * 12) + (parseFloat(reserved1yrPartialUpfrontFee)* ebsFilters.nodes) + totalannualstoragecost + (annualBackupStorageCost || 0) +(annualSnapshotExportCost || 0) +(RDSProxyAnnualCost || 0);
  
    const Reserved3yrAllUpfrontTotalAnnualCost = ((parseFloat(reserved3yrAllUpfrontFee)/3) * ebsFilters.nodes) + totalannualstoragecost + (annualBackupStorageCost || 0) +(annualSnapshotExportCost || 0) +(RDSProxyAnnualCost || 0);
  
    const Reserved3yrPartialUpfrontTotalMonthlyCost = (reserved3yrPartialUpfrontMonthlyPrice * ebsFilters.nodes) + totalmonthlystoragecost + (backupStorageCost || 0) + (snapshotExportCost || 0) + (RDSProxyCost || 0);
    const Reserved3yrPartialUpfrontTotalAnnualCost = (reserved3yrPartialUpfrontMonthlyPrice * ebsFilters.nodes * 12) + ((parseFloat(reserved3yrPartialUpfrontFee)/3) * ebsFilters.nodes) + totalannualstoragecost + (annualBackupStorageCost || 0) +(annualSnapshotExportCost || 0) +(RDSProxyAnnualCost || 0);

    return { ...initialData,
      ...calculatedData,
      sku, 
      storageKey, 
    /*   storagePrice,  */
      storageCost, 
  /*     provisioningIOPSPrice,  */
  /*     throughputPrice,  */
      provisioningIOPSCost, 
      throughputCost, 
      totalmonthlystoragecost, 
      totalannualstoragecost, 
     /*  backupStoragePrice,
      snapshotExportPrice, */
      backupStorageCost,
      snapshotExportCost,
      annualBackupStorageCost,
      annualSnapshotExportCost,
  /*     RDSProxyPrice, */
      RDSProxyCost,
      RDSProxyAnnualCost,
      OndemandTotalMonthlyCost,
      OndemandTotalAnnualCost,
      Reserved1yrNoUpfrontTotalMonthlyCost,
      Reserved1yrNoUpfrontTotalAnnualCost,
      Reserved1yrAllUpfrontTotalAnnualCost,
      Reserved1yrPartialUpfrontTotalMonthlyCost,
      Reserved1yrPartialUpfrontTotalAnnualCost,
      Reserved3yrAllUpfrontTotalAnnualCost,
      Reserved3yrPartialUpfrontTotalMonthlyCost,
      Reserved3yrPartialUpfrontTotalAnnualCost };
  });
};