import { CART_ADD_ITEM, CART_REMOVE_ITEM, CART_UPDATE_ITEM_QUANTITY, CART_CLEAR } from "../constants/cartConstants";

const addToCart = (types) => (dispatch, getState) => {
  try {
    console.log("adding to cart:", types);
    dispatch({
      type: CART_ADD_ITEM, payload: {
       ...types
      }
    });
    const { cart } = getState();
    const cartItems = cart.cartItems;
    console.log("Cart items after adding:", cartItems);

    localStorage.setItem("cartItem", JSON.stringify(cartItems));
    console.log("Cart items in localStorage:", JSON.parse(localStorage.getItem("cartItems"))); // 调试信息
  } catch (error) {
    console.error("Failed to add item to cart:", error);
  }
}

// const clearCart = () => (dispatch, getState) => {
//   console.log("str");
//   try {
//   dispatch({
//     type: CART_CLEAR
//   })
//   } catch (e) {
//     console.log(e);
//   }
//   const { cart: { cartItems } } = getState();
//   localStorage.setItem("cartItem", JSON.stringify(cartItems));
// }

const removeFromCart = (sku) => (dispatch, getState) => {
  if (sku == "") {
    dispatch({type: CART_CLEAR});
  } else {
    dispatch({ type: CART_REMOVE_ITEM, payload: sku });
  }

  const { cart: { cartItems } } = getState();
  console.log("Removed cartItems");
  console.log(cartItems);
  localStorage.setItem("cartItem", JSON.stringify(cartItems));
}

const updateCartItemQuantity = (sku, quantity) => (dispatch, getState) => {
  dispatch({
    type: CART_UPDATE_ITEM_QUANTITY,
    payload: { sku, quantity }
  });
  const { cart: { cartItems } } = getState();
  console.log("updated cartItems");
  console.log(cartItems);
  localStorage.setItem("cartItem", JSON.stringify(cartItems));
}

export { addToCart, removeFromCart, updateCartItemQuantity }