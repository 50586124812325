import React from 'react';
import { Link } from 'react-router-dom';
import { AppLayout, SideNavigation, ContentLayout, Header } from '@cloudscape-design/components';
import { I18nProvider } from '@cloudscape-design/components/i18n';
import messages from '@cloudscape-design/components/i18n/messages/all.en';

const Layout = ({ children, headerTitle }) => {
  return (
    <I18nProvider locale="en" messages={[messages]}>
      <AppLayout
        navigation={
          <SideNavigation
            header={{
              href: '/',
              text: 'Services',
            }}
            items={[
              // { type: 'link', text: `Home`, href: `/` },
              { type: 'link', text: `EC2 (by instance)`, href: `/#/ec2` },
              { type: 'link', text: `EC2 available region`, href: `/#/ec2-region`},
              { type: 'link', text: `Redshift`, href: `/#/redshift` },
              { type: 'link', text: `ElastiCache`, href: `/#/elastiCache` },
              { type: 'link', text: `Aurora MySQL-Compatible`, href: `/#/aurora-mysql` },
              { type: 'link', text: `Aurora PostgreSQL-Compatible`, href: `/#/aurora-postgresql` },
              { type: 'link', text: `RDS for MySQL`, href: `/#/rds-mysql` },
              { type: 'link', text: `RDS for PostgreSQL`, href: `/#/rds-postgresql` },
              { type: 'link', text: `RDS for MariaDB`, href: `/#/rds-mariadb` },
              { type: 'link', text: `RDS for Oracle`, href: `/#/rds-oracle` },
              { type: 'link', text: `RDS for SQLServer`, href: `/#/rds-sqlserver` },
              { type: 'link', text: `SageMaker`, href: `/#/sagemaker` },

            ]}
          />
        }
        content={
          <ContentLayout
            header={
              headerTitle ? <Header variant="h1">{headerTitle}</Header> : null
            }
          >
            {children}
          </ContentLayout>
        }
        tools={null}
      />
    </I18nProvider>
  );
};

export default Layout;