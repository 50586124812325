import React, { useState } from 'react';
import Layout from '../layout/appLayout';
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Container from "@cloudscape-design/components/container";
import Input from "@cloudscape-design/components/input";
import Table from "@cloudscape-design/components/table";
import Button from "@cloudscape-design/components/button";

// 区域转换
const regionMapping = {
    "us-east-1": "US East (N. Virginia)",
    "us-east-2": "US East (Ohio)",
    "us-west-1": "US West (N. California)",
    "us-west-2": "US West (Oregon)",
    "af-south-1": "Africa (Cape Town)",
    "ap-east-1": "Asia Pacific (Hong Kong)",
    "ap-south-1": "Asia Pacific (Mumbai)",
    "ap-southeast-1": "Asia Pacific (Singapore)",
    "ap-southeast-2": "Asia Pacific (Sydney)",
    "ap-southeast-3": "Asia Pacific (Jakarta)",
    "ap-southeast-4": "Asia Pacific (Melbourne)",
    "ap-southeast-5": "Asia Pacific (Malaysia)",
    "ap-northeast-1": "Asia Pacific (Tokyo)",
    "ap-northeast-2": "Asia Pacific (Seoul)",
    "ap-northeast-3": "Asia Pacific (Osaka-Local)",
    "ca-central-1": "Canada (Central)",
    "eu-central-1": "Europe (Frankfurt)",
    "eu-west-1": "Europe (Ireland)",
    "eu-west-2": "Europe (London)",
    "eu-west-3": "Europe (Paris)",
    "eu-north-1": "Europe (Stockholm)",
    "me-south-1": "Middle East (Bahrain)",
    "sa-east-1": "South America (Sao Paulo)",
    "us-gov-east-1": "AWS GovCloud (US-East)",
    "us-gov-west-1": "AWS GovCloud (US-West)",
    "cn-north-1": "China (Beijing)",
    "cn-northwest-1": "China (Ningxia)",
    "us-iso-east-1": "US ISO East",
    "us-isob-east-1": "US ISOB East",
};

// 发起API Gateway请求
const fetchInstanceRegions = async (instanceType) => {
    const response = await fetch(`https://26dvflpbnl.execute-api.us-west-2.amazonaws.com?instanceType=${instanceType}`);
    const data = await response.json();
    console.log('fetchInstanceRegions data: ', data);
    return data;
};

// 解析实例类型
const parseInstanceType = (instanceType) => {
    const [prefix, size] = instanceType.split('.');
    const sizeMapping = {
        nano: 1,
        micro: 2,
        small: 3,
        medium: 4,
        large: 5,
        xlarge: 6,
        metal: 7,
    };

    const match = size.match(/(\d+)?(.*)/);
    const multiplier = match[1] ? parseInt(match[1], 10) : 1;
    const type = match[2];

    const order = sizeMapping[type] || 8;
    return { prefix, order, multiplier };
};

// 比较
const compareInstanceTypes = (a, b) => {
    const parsedA = parseInstanceType(a.instanceType);
    const parsedB = parseInstanceType(b.instanceType);

    if (parsedA.prefix < parsedB.prefix) return -1;
    if (parsedA.prefix > parsedB.prefix) return 1;

    if (parsedA.order !== parsedB.order) {
        return parsedA.order - parsedB.order;
    }

    return parsedA.multiplier - parsedB.multiplier;
};

const EC2RegionTable = () => {
    const [instanceType, setInstanceType] = useState('');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleFetch = async () => {
        if (instanceType.length < 2) {
            alert('Please enter at least 2 characters');
            return;
        }

        setLoading(true);
        const controller = new AbortController();
        const timeoutId = setTimeout(() => {
            controller.abort();
            setLoading(false);
            alert('Request timed out. Please enter a more specific instance type for faster results.');
        }, 30000);

        try {
            const data = await fetchInstanceRegions(instanceType, controller.signal);

            // 使用HashMap
            const instanceTypeMap = new Map();

            data.forEach(item => {
                if (!instanceTypeMap.has(item.instanceType)) {
                    instanceTypeMap.set(item.instanceType, new Set());
                }
                instanceTypeMap.get(item.instanceType).add(regionMapping[item.region] || item.region);
            });

            // 将HashMap转换为数组用于显示
            const uniqueResults = Array.from(instanceTypeMap, ([instanceType, regionsSet]) => ({
                instanceType,
                regions: Array.from(regionsSet),
                key: `${instanceType}-${Array.from(regionsSet).join(', ')}`
            })).sort(compareInstanceTypes);

            setResults(uniqueResults);
        } catch (error) {
            if (error.name !== 'AbortError') {
                console.error('Fetch error: ', error);
            }
        } finally {
            clearTimeout(timeoutId);
            setLoading(false);
        }
    };

    return (
        <Layout headerTitle="">
            <Box padding={{ top: 'm', horizontal: 'l' }}>
                <h2 className="main-title left-align">Instance Types Regions</h2>
                <SpaceBetween direction="vertical" size="l">
                    <Container header={<h3 className="sub-title">Query Instance Supported Regions</h3>}>
                        <p style={{ color: 'grey', marginTop: '-1rem', marginBottom: '1rem' }}>Note: Now has significantly reduced query time. Some specific instance may encounter longer query time for the first time.</p>
                        <SpaceBetween direction="horizontal" size="s" >
                            <Input
                                value={instanceType}
                                onChange={event => setInstanceType(event.detail.value)}
                                placeholder="Instnace Type"
                            />
                            <Button onClick={handleFetch} loading={loading}>Query</Button>
                        </SpaceBetween>
                        <Table
                            columnDefinitions={[
                                { id: 'instanceType', header: 'Instance Type', cell: item => item.instanceType },
                                {
                                    id: 'regions', header: 'Region', cell: item => (
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            {item.regions.map(region => (
                                                <span key={region}>{region}</span>
                                            ))}
                                        </div>
                                    )
                                }
                            ]}
                            items={results}
                            trackBy="key" // 唯一键
                            loading={loading}
                            loadingText="Loading..."
                            empty={
                                <Box textAlign="center" color="inherit">
                                    <b>No Data Found</b>
                                </Box>
                            }
                        />
                    </Container>
                </SpaceBetween>
            </Box>
        </Layout>
    );
};

export default EC2RegionTable;