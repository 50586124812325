import "./SelectFilter.css";
import React, { useEffect, useState } from "react";
import Layout from "../layout/appLayout.jsx";
import { FormField } from '@cloudscape-design/components';
import Table from "@cloudscape-design/components/table";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import TextFilter from "@cloudscape-design/components/text-filter";
import Header from "@cloudscape-design/components/header";
import Pagination from "@cloudscape-design/components/pagination";
import Select from "@cloudscape-design/components/select";
import { connect } from 'react-redux';
import { addToCart } from "../redux/actions/cartActions.jsx";
import { Link } from 'react-router-dom';
import Modal from "@cloudscape-design/components/modal";
import { calculatePrices } from '../common/PriceCalculating.jsx';
import { auroraMySQLCalculatePrices } from '../common/AuroraMySQLPriceCalculating.jsx';
import Input from "@cloudscape-design/components/input";

// 分页组件
const PaginationSetting = (props) => {
  return (
    <Pagination
      currentPageIndex={props.currentPageIndex}
      onChange={({ detail }) => {
        props.setCurrentPageIndex(detail.currentPageIndex);
        props.onPageChange(detail.currentPageIndex); // 确保调用父组件传递的翻页回调
      }}
      openEnd
      pagesCount={Math.ceil(props.totalItems / props.itemsPerPage)} // 动态计算总页数
    />
  );
};

// 区域选项
const regionOptions = [
  { label: "US East (N. Virginia)", value: "US%20East%20(N.%20Virginia)" },
  { label: "US East (Ohio)", value: "US%20East%20(Ohio)" },
  { label: "US West (N. California)", value: "US%20West%20(N.%20California)" },
  { label: "US West (Oregon)", value: "US%20West%20(Oregon)" },
  { label: "Canada (Central)", value: "Canada%20(Central)" },
  { label: "Canada West (Calgary)", value: "Canada%20West%20(Calgary)" },
  { label: "Africa (Cape Town)", value: "Africa%20(Cape%20Town)" },
  { label: "Asia Pacific (Hong Kong)", value: "Asia%20Pacific%20(Hong%20Kong)" },
  { label: "Asia Pacific (Hyderabad)", value: "Asia%20Pacific%20(Hyderabad)" },
  { label: "Asia Pacific (Jakarta)", value: "Asia%20Pacific%20(Jakarta)" },
  { label: "Asia Pacific (Melbourne)", value: "Asia%20Pacific%20(Melbourne)" },
  { label: "Asia Pacific (Mumbai)", value: "Asia%20Pacific%20(Mumbai)" },
  { label: "Asia Pacific (Osaka)", value: "Asia%20Pacific%20(Osaka)" },
  { label: "Asia Pacific (Seoul)", value: "Asia%20Pacific%20(Seoul)" },
  { label: "Asia Pacific (Singapore)", value: "Asia%20Pacific%20(Singapore)" },
  { label: "Asia Pacific (Malaysia)", value: "Asia%20Pacific%20(Malaysia)" },
  { label: "Asia Pacific (Sydney)", value: "Asia%20Pacific%20(Sydney)" },
  { label: "Asia Pacific (Tokyo)", value: "Asia%20Pacific%20(Tokyo)" },
  { label: "EU (Frankfurt)", value: "EU%20(Frankfurt)" },
  { label: "EU (Ireland)", value: "EU%20(Ireland)" },
  { label: "EU (London)", value: "EU%20(London)" },
  { label: "EU (Milan)", value: "EU%20(Milan)" },
  { label: "EU (Paris)", value: "EU%20(Paris)" },
  { label: "EU (Spain)", value: "EU%20(Spain)" },
  { label: "EU (Stockholm)", value: "EU%20(Stockholm)" },
  { label: "EU (Zurich)", value: "EU%20(Zurich)" },
  { label: "Israel (Tel Aviv)", value: "Israel%20(Tel%20Aviv)" },
  { label: "Middle East (Bahrain)", value: "Middle%20East%20(Bahrain)" },
  { label: "Middle East (UAE)", value: "Middle%20East%20(UAE)" },
  { label: "South America (Sao Paulo)", value: "South%20America%20(Sao%20Paulo)" },
  { label: "AWS GovCloud (US-East)", value: "AWS%20GovCloud%20(US-East)" },
  { label: "AWS GovCloud (US-West)", value: "AWS%20GovCloud%20(US-West)" }
];

// 利用率选项
const utilizationUnitOptions = [
  { label: "%Utilized/Month", value: "%Utilized/Month" },
  { label: "Hours/Day", value: "Hours/Day" },
  { label: "Hours/Week", value: "Hours/Week" },
  { label: "Hours/Month", value: "Hours/Month" }
];

/**
 * 筛选器组件
 */
function RegionFilter(props) {
  return (
    <Select
      selectedAriaLabel="Selected"
      selectedOption={props.selectedOption}
      onChange={({ detail }) => {
        props.setSelectedOption({
          ...detail.selectedOption,
          label: decodeURIComponent(detail.selectedOption.label),
        });
        props.onFilterChange();
      }}
      options={regionOptions}
      ariaDescribedby={null}
      expandToViewport={true}
    />
  );
}

function InstanceFamilyFilter(props) {
  return (
    <Select
      selectedAriaLabel="Selected"
      selectedOption={props.selectedOption}
      onChange={({ detail }) => {
        props.setSelectedOption(detail.selectedOption);
        props.onFilterChange();
      }}
      options={[
        { label: "All", value: "All" },
        { label: "General purpose", value: "General purpose" },
        { label: "Compute optimized", value: "Compute optimized" },
        { label: "Memory optimized", value: "Memory optimized" },
        { label: "Storage optimized", value: "Storage optimized" },
        { label: "GPU instance", value: "GPU instance" },
        { label: "FPGA Instances", value: "FPGA Instances" },
        { label: "Machine Learning ASIC Instances", value: "Machine Learning ASIC Instances" },
        { label: "Media Accelerator Instances", value: "Media Accelerator Instances" },
        { label: "Micro instances", value: "Micro instances" },
        { label: "N/A", value: "N/A" }
      ]}
      ariaDescribedby={null}
      expandToViewport={true}
    />
  );
}

const MemoryFilter = (props) => {
  const [filteringText, setFilteringText] = React.useState(props.filteringText);

  const handleFilterChange = (newFilteringText) => {
    setFilteringText(newFilteringText);
    props.setFilteringText(newFilteringText);
    props.onFilterChange();
  };

  return (
    <TextFilter
      filteringText={filteringText}
      filteringPlaceholder="Memory equals to <input> GiB"
      filteringAriaLabel="Filter Memory"
      onChange={({ detail }) => handleFilterChange(detail.filteringText)}
    />
  );
}

function VCPUFilter(props) {
  const [filteringText, setFilteringText] = React.useState(props.filteringText);

  const handleFilterChange = (newFilteringText) => {
    setFilteringText(newFilteringText);
    props.setFilteringText(newFilteringText);
    props.onFilterChange();
  };

  return (
    <TextFilter
      filteringText={filteringText}
      filteringPlaceholder="Number of vCPU equals to <input>"
      filteringAriaLabel="Filter vCPU"
      onChange={({ detail }) => handleFilterChange(detail.filteringText)}
    />
  );
}


function InstanceTypeFilter(props) {
  const [filteringText, setFilteringText] = React.useState(props.filteringText);

  const handleFilterChange = (newFilteringText) => {
    setFilteringText(newFilteringText);
    props.setFilteringText(newFilteringText);
    props.onFilterChange();
  };

  return (
    <TextFilter
      filteringText={filteringText}
      filteringPlaceholder="Input your instance type"
      filteringAriaLabel="Filter Instance Type"
      onChange={({ detail }) => handleFilterChange(detail.filteringText)}
    />
  );
}

function UtilizationFilter(props) {
  const [inputValue, setInputValue] = useState(props.utilizationValue);
  const [isInputValid, setIsInputValid] = useState(true);

  const handleInputChange = ({ detail }) => {
    const value = parseInt(detail.value, 10);
    const isValid = value >= 1 && value <= 100;
    setInputValue(value);
    setIsInputValid(isValid);
    if (isValid) {
      props.setUtilizationValue(value);
      props.onFilterChange();
    }
  };

  return (
    <div className="utilization-filter">
      <FormField label="Utilization (On-Demand only)">
        <div className="utilization-filter-inputs">
          <div>
            <Input
              type="number"
              min="1"
              max="100"
              value={inputValue}
              onChange={handleInputChange}
            />
            {!isInputValid && <span className="error-text">Value must be between 1 and 100</span>}
          </div>
          <div>
            <Select
              selectedAriaLabel="Selected"
              selectedOption={props.utilizationUnit}
              onChange={({ detail }) => {
                props.setUtilizationUnit(detail.selectedOption);
                props.onFilterChange();
              }}
              options={utilizationUnitOptions}
              ariaDescribedby={null}
              expandToViewport={true}
            />
          </div>
        </div>
      </FormField>
    </div>
  );
}

function NodesFilter(props) {
  const [inputValue, setInputValue] = useState(props.nodesValue || 1);

  const handleInputChange = ({ detail }) => {
    const value = parseInt(detail.value, 10);
    if (value >= 1) {
      setInputValue(value);
      props.setNodesValue(value);
      props.onFilterChange();
    }
  };

  return (
    <div className="nodes-filter">
      <FormField lable="Nodes">
        <Input
          type="number"
          min="1"
          value={inputValue}
          onChange={handleInputChange}
        // style={{ width: '50px' }}
        />
      </FormField>
    </div>
  )
}

const clusterConfigurationOptions = [
  { label: "Standard", value: "Standard" },
  { label: "Aurora I/O-Optimized", value: "Aurora I/O-Optimized" }
];

function ClusterConfigurationFilter(props) {
  return (
    <Select
      selectedAriaLabel="Selected"
      selectedOption={props.selectedOption}
      onChange={({ detail }) => {
        props.setSelectedOption(detail.selectedOption);
        props.onFilterChange();
      }}
      options={clusterConfigurationOptions}
      ariaDescribedby={null}
      expandToViewport={true}
    />
  );
}

const auroraUnitOptions = [
  { label: "per hour", value: "per hour" },
  { label: "per day", value: "per day" },
  { label: "per month", value: "per month" }
];

const AuroraServerlessSettings = ({ serverlessSettings, setServerlessSettings, onSelectionChange, selectedItems, allProductItems, isIOOptimized }) => {
  const handleACUChange = (event) => {
    const value = parseFloat(event.detail.value);
    setServerlessSettings({
      ...serverlessSettings,
      acu: value
    });
  };

  const handleUnitChange = (event) => {
    setServerlessSettings({
      ...serverlessSettings,
      unit: event.detail.selectedOption.value
    });
  };

  const handleVersionChange = (event) => {
    const newValue = event.target.value;
    const version = serverlessSettings.version === newValue ? "" : newValue;
    setServerlessSettings({
      ...serverlessSettings,
      version: version // 切换选择/取消选择
    });

    let updatedSelectedItems = [...selectedItems];
    const serverlessItem = allProductItems.find(item => item.instanceType === newValue);

    if (version && serverlessItem) {
      updatedSelectedItems = updatedSelectedItems.filter(item => item.instanceType !== "Aurora Serverless v1" && item.instanceType !== "Aurora Serverless v2");
      updatedSelectedItems.push(serverlessItem);
    } else if (!version) {
      updatedSelectedItems = updatedSelectedItems.filter(item => item.instanceType !== "Aurora Serverless v1" && item.instanceType !== "Aurora Serverless v2");
    }

    onSelectionChange({ detail: { selectedItems: updatedSelectedItems } });
  };

  return (
    <div>
      <FormField label="Aurora Serverless">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input
            type="number"
            min="0.5"
            max="128"
            step="0.5"
            value={serverlessSettings.acu}
            onChange={handleACUChange}
            style={{ marginRight: '10px' }}
          />
          <Select
            selectedOption={{ label: serverlessSettings.unit, value: serverlessSettings.unit }}
            onChange={handleUnitChange}
            options={auroraUnitOptions}
            ariaDescribedby={null}
            expandToViewport={true}
          />
        </div>
      </FormField>
      <FormField label="Add Aurora Serverless (Optional)">
        <div>
          <input
            type="radio"
            id="serverless-v1"
            name="auroraServerlessVersion"
            value="Aurora Serverless v1"
            checked={serverlessSettings.version === "Aurora Serverless v1"}
            onChange={handleVersionChange}
            disabled={isIOOptimized}
          />
          <label htmlFor="serverless-v1">Aurora Serverless v1</label>
        </div>
        <div>
          <input
            type="radio"
            id="serverless-v2"
            name="auroraServerlessVersion"
            value="Aurora Serverless v2"
            checked={serverlessSettings.version === "Aurora Serverless v2"}
            onChange={handleVersionChange}
          />
          <label htmlFor="serverless-v2">Aurora Serverless v2</label>
        </div>
      </FormField>
    </div>
  );
};

function RDSProxy({ rdsProxy, setRdsProxy }) {
  const handleRdsProxyChange = (event) => {
    setRdsProxy(event.detail.selectedOption.value);
  };

  return (
    <FormField label={<span className="rds-proxy-label">Would you be creating an RDS Proxy with the database?</span>}>
      <Select
        selectedOption={{ label: rdsProxy, value: rdsProxy }}
        onChange={handleRdsProxyChange}
        options={[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" }
        ]}
        ariaDescribedby={null}
        expandToViewport={true}
      />
    </FormField>
  );
}

function StorageAmount({ storageAmount, setStorageAmount, storageUnit, setStorageUnit, onFilterChange }) {
  const handleAmountChange = (event) => {
    setStorageAmount(parseInt(event.detail.value, 10));
    onFilterChange();
  };

  const handleUnitChange = ({ detail }) => {
    setStorageUnit(detail.selectedOption);
    onFilterChange();
  };

  return (
    <div className="storage-amount">
      <FormField label="Storage Amount">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input
            type="number"
            min="0"
            max="100000000"
            value={storageAmount}
            onChange={handleAmountChange}
            style={{ marginRight: '10px' }}
          />
          <Select
            selectedOption={storageUnit}
            onChange={handleUnitChange}
            options={[
              { label: "MB", value: "MB" },
              { label: "GB", value: "GB" },
              { label: "TB", value: "TB" }
            ]}
            ariaDescribedby={null}
            expandToViewport={true}
            style={{ width: '150px', marginRight: '10px' }}
          />
        </div>
      </FormField>
    </div>
  );
}

function BaselineIORate({ baselineIORate, setBaselineIORate, onFilterChange }) {
  const handleRateChange = (event) => {
    setBaselineIORate({ ...baselineIORate, value: parseInt(event.detail.value, 10) });
    onFilterChange();
  };

  const handleUnitChange = ({ detail }) => {
    setBaselineIORate({ ...baselineIORate, unit: detail.selectedOption });
    onFilterChange();
  };

  return (
    <div className="baseline-io-rate">
      <FormField label="Baseline IO Rate">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input
            type="number"
            value={baselineIORate.value}
            onChange={handleRateChange}
            style={{ marginRight: '10px' }}
          />
          <Select
            selectedOption={baselineIORate.unit}
            onChange={handleUnitChange}
            options={[
              { label: "per second", value: "per second" },
              { label: "per minute", value: "per minute" },
              { label: "per hour", value: "per hour" },
              { label: "per day", value: "per day" },
              { label: "per month", value: "per month" }
            ]}
            ariaDescribedby={null}
            expandToViewport={true}
            style={{ width: '150px', marginRight: '10px' }}
          />
        </div>
      </FormField>
    </div>
  );
}

function PeakIORate({ peakIORate, setPeakIORate, onFilterChange }) {
  const handleRateChange = (event) => {
    setPeakIORate({ ...peakIORate, value: parseInt(event.detail.value, 10) });
    onFilterChange();
  };

  const handleUnitChange = ({ detail }) => {
    setPeakIORate({ ...peakIORate, unit: detail.selectedOption });
    onFilterChange();
  };

  return (
    <div className="peak-io-rate">
      <FormField label="Peak IO Rate">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input
            type="number"
            value={peakIORate.value}
            onChange={handleRateChange}
            style={{ marginRight: '10px' }}
          />
          <Select
            selectedOption={peakIORate.unit}
            onChange={handleUnitChange}
            options={[
              { label: "per second", value: "per second" },
              { label: "per minute", value: "per minute" },
              { label: "per hour", value: "per hour" },
              { label: "per day", value: "per day" },
              { label: "per month", value: "per month" }
            ]}
            ariaDescribedby={null}
            expandToViewport={true}
            style={{ width: '150px', marginRight: '10px' }}
          />
        </div>
      </FormField>
    </div>
  );
}

function DurationOfPeakIO({ durationOfPeakIO, setDurationOfPeakIO, onFilterChange }) {
  const handleDurationChange = (event) => {
    setDurationOfPeakIO({ ...durationOfPeakIO, value: parseInt(event.detail.value, 10) });
    onFilterChange();
  };

  const handleUnitChange = ({ detail }) => {
    setDurationOfPeakIO({ ...durationOfPeakIO, unit: detail.selectedOption });
    onFilterChange();
  };

  return (
    <div className="duration-of-peak-io">
      <FormField label="Duration of Peak IO Activity">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input
            type="number"
            value={durationOfPeakIO.value}
            onChange={handleDurationChange}
            style={{ marginRight: '10px' }}
          />
          <Select
            selectedOption={durationOfPeakIO.unit}
            onChange={handleUnitChange}
            options={[
              { label: "hours per month", value: "hours per month" }
            ]}
            ariaDescribedby={null}
            expandToViewport={true}
            style={{ width: '150px', marginRight: '10px' }}
          />
        </div>
      </FormField>
    </div>
  );
}

const baseRPUOptions = Array.from({ length: 64 }, (_, i) => {
  const value = (i + 1) * 8;
  return { label: `${value}`, value: `${value}` };
});

function RedshiftServerlessSettings({ serverlessSettings, setServerlessSettings }) {
  const handleWorkloadChange = (event) => {
    const workloadSize = event.detail.selectedOption.value;
    let defaultBaseRPU;

    if (workloadSize === 'small') {
      defaultBaseRPU = '64';
    } else if (workloadSize === 'medium') {
      defaultBaseRPU = '128';
    } else if (workloadSize === 'large') {
      defaultBaseRPU = '256';
    }

    setServerlessSettings({
      ...serverlessSettings,
      workloadSize,
      baseRPU: defaultBaseRPU
    });
  };

  const handleBaseRPUChange = (event) => {
    setServerlessSettings({ ...serverlessSettings, baseRPU: event.detail.selectedOption.value });
  };

  const handleDailyRuntimeChange = (event) => {
    setServerlessSettings({ ...serverlessSettings, dailyRuntime: event.detail.value });
  };

  return (
    <div>
      <FormField label="Workload Size">
        <Select
          selectedOption={{ label: serverlessSettings.workloadSize, value: serverlessSettings.workloadSize }}
          onChange={handleWorkloadChange}
          options={[
            { label: 'Small', value: 'small' },
            { label: 'Medium', value: 'medium' },
            { label: 'Large', value: 'large' }
          ]}
          ariaDescribedby={null}
          expandToViewport={true}
        />
      </FormField>
      <FormField label="Base RPU">
        <Select
          selectedOption={{ label: serverlessSettings.baseRPU, value: serverlessSettings.baseRPU }}
          onChange={handleBaseRPUChange}
          options={baseRPUOptions}
          ariaDescribedby={null}
          expandToViewport={true}
        />
      </FormField>
      <FormField label="Estimated Daily Runtime (Hours)">
        <Input
          type="number"
          min="1"
          max="24"
          value={serverlessSettings.dailyRuntime}
          onChange={handleDailyRuntimeChange}
        />
      </FormField>
    </div>
  );
}


function ItemsPerPageDropdown(props) {
  return (
    <Select
      selectedAriaLabel="Selected"
      selectedOption={{ label: props.itemsPerPage, value: props.itemsPerPage }}
      onChange={({ detail }) => props.setItemsPerPage(detail.selectedOption.value)}
      options={props.itemsPerPageOptions.map(option => ({ label: option, value: option }))}
      ariaDescribedby={null}
      expandToViewport={true}
    />
  );
}

// 主Table
class AuroraMySQLProductTable extends React.Component {
  constructor(props) {
    super(props);
    // 状态初始化
    this.state = {
      currentButtonState: {
        Region: {
          label: "US East (N. Virginia)",
          value: "US%20East%20(N.%20Virginia)",
        },
        InstanceFamily: {
          label: "All",
          value: "All",
        },
        utilizationValue: 100,
        utilizationUnit: {
          label: "%Utilized/Month",
          value: "%Utilized/Month"
        },
        errorMsg: "",
        showModal: false,
        LeaseContractLength: "0", // only for RI
        Memory: "",
        vCPU: "",
        InstanceType: "",
        nodes: 1,
        serverlessSettings: {
          workloadSize: 'small',
          baseRPU: '64',
          baseRPUOptions: [{ label: '64', value: '64' }],
          dailyRuntime: '1',
        },
        clusterConfiguration: {
          label: "Standard",
          value: "Standard"
        },
        auroraServerlessSettings: {
          acu: 0.5,
          unit: "per hour",
          version: ""
        },
        rdsProxy: "Yes",
        storageAmount: 0,
        storageUnit: { label: "GB", value: "GB" },
        baselineIORate: { value: 1, unit: { label: "per second", value: "per second" } },
        peakIORate: { value: 1, unit: { label: "per second", value: "per second" } },
        durationOfPeakIO: { value: 1, unit: { label: "hours per month", value: "hours per month" } },
      },
      selectedItems: [],
      isLoading: false,
      productItems: [],
      allProductItems: [],
      hasNext: false,
      clickButton: true,
      // updatePurchaseOption: false,
      paginator: false,
      currentPageIndex: 1,
      itemsPerPageOptions: [10, 25, 50], //Offer Selections of number of instances displayed per page
      itemsPerPage: 10,
    };
    this.nextPage = this.nextPage.bind(this);
    this.handleNextPage = this.handleNextPage.bind(this);
    this.getInstanceFamily = this.getInstanceFamily.bind(this);
    this.onSelectionChange = this.onSelectionChange.bind(this);
    // this.fetchOnDemandData = this.fetchOnDemandData.bind(this);
  }
  // Serverless逻辑
  handleServerlessChange = (selectedOption) => {
    this.setState({
      currentButtonState: {
        ...this.state.currentButtonState,
        isServerless: selectedOption.value
      }
    });
  };

  handleItemsPerPageChange = (newItemsPerPage) => {
    const newPageIndex = this.getCorrectPageIndex(newItemsPerPage);
    this.setState({ itemsPerPage: newItemsPerPage, currentPageIndex: newPageIndex }, () => {
      this.filterProducts();
    });
  };// Add the method handle Items Per Page Change

  onSelectionChange({ detail }) {
    const updatedSelectedItems = detail.selectedItems;
    console.log('updatedSelectedItems: ', updatedSelectedItems);
    this.setState({
      selectedItems: updatedSelectedItems
    });
  }

  getCorrectPageIndex = (newItemsPerPage) => {
    const { filteredLength, currentPageIndex, itemsPerPage } = this.state;
    const currentIndex = (currentPageIndex - 1) * itemsPerPage;

    const newPageIndex = Math.ceil((currentIndex + 1) / newItemsPerPage);
    return Math.min(newPageIndex, Math.ceil(filteredLength / newItemsPerPage));
  };// When the display row changes, the page will jump to the new page that includes the same instances that is displayed previously

  nextPage() {
    const { currentPageIndex } = this.state;
    const nextPageIndex = currentPageIndex + 1;
    this.setState({ currentPageIndex: nextPageIndex }, () => {
      this.filterProducts();
    });
  }

  handleNextPage = (currentPageIndex) => {
    this.setState({ currentPageIndex }, () => {
      this.filterProducts();
    });
  };

  handlePageChange = (pageIndex) => {
    this.setState({ currentPageIndex: pageIndex }, () => {
      this.filterProducts();
    });
  };

  // 发起API请求的URL
  generateApiURL() {
    const { Region, clusterConfiguration } = this.state.currentButtonState;
    const regionValue = Region.value;
    const clusterConfigPath = clusterConfiguration.value === 'Standard' ? 'rds-aurora-mysql-compatible-calc' : 'rds-aurora-iooptimization-mysql-calc';
    console.log('generateApiURL Region:', regionValue);
    let url = `/pricing/2.0/meteredUnitMaps/rds/USD/current/${clusterConfigPath}/${regionValue}/primary-selector-aggregations.json`;

    url = url.replace(/\/+/g, '/'); // 去除多余的斜杠
    return url;
  }

  // 发起接收请求
  async fetchAllData(url) {
    try {
      console.log("Fetching URL: ", url);
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log("Response JSON:", data);
      return data;
    } catch (error) {
      console.error('Fetching error:', error);
      return null;
    }
  }

  // 数据处理
  async processData(data) {
    const result_jsonlist = [];
    console.log('processData before process: ', data);

    const tempResult = {};

    // Generate API URLs for RDS Proxy and Database Storage
    const rdsProxyUrl = '/pricing/2.0/meteredUnitMaps/rds/USD/current/rds-proxy.json';
    const dbStorageUrl = '/pricing/2.0/meteredUnitMaps/rds/USD/current/rds-aurora-storage.json';

    try {
      const [rdsProxyResponse, dbStorageResponse] = await Promise.all([
        fetch(rdsProxyUrl),
        fetch(dbStorageUrl)
      ]);

      const rdsProxyData = await rdsProxyResponse.json();
      const dbStorageData = await dbStorageResponse.json();

      const dbStorageKey = 'Aurora MySQL Year 1 Year 2 5 7';
      const ioOperationKey = 'System Operation Aurora I O Operation';
      const rdsProxyKey = 'RDSProxy';

      for (const key in data.aggregations) {
        const itemData = data.aggregations[key];
        const item = itemData["selectors"];

        const instanceType = item["Instance Type"] ? item["Instance Type"].toLowerCase() : "what is this";

        if (!tempResult[instanceType]) {
          tempResult[instanceType] = {
            instanceType: item["Instance Type"],
            instanceFamily: item["Instance Family"] || this.getInstanceFamily(instanceType),
            regionCode: this.state.currentButtonState.Region.label,
            vcpu: item.vCPU,
            memory: item.Memory,
            Network: item["Network Performance"],
            nodes: this.state.currentButtonState.nodes,
            terms: {
              OnDemand: null,
              Reserved: []
            },
            upfrontFee: "N/A",
            perUnitPrice: item.price,
            ServerlessPrice: "N/A",
            utilizationValue: this.state.currentButtonState.utilizationValue,
            utilizationUnit: this.state.currentButtonState.utilizationUnit.value,
            DatabaseStoragePrice: {},
            RDSProxyPrice: {},
            Serverless: {
              value: null,
              unit: null
            },
            clusterConfig: this.state.currentButtonState.clusterConfiguration.label,
          };
        }

        // ondemand
        tempResult[instanceType].terms.OnDemand = { price: item.price };
        tempResult[instanceType].perUnitPrice = item.price + " USD per Hrs";

        // Store Database Storage Price for Aurora MySQL
        const storagePrice = dbStorageData.regions[this.state.currentButtonState.Region.label][dbStorageKey]?.price || 0;
        const baselineIoPrice = dbStorageData.regions[this.state.currentButtonState.Region.label][ioOperationKey]?.price || 0;

        tempResult[instanceType].DatabaseStoragePrice = {
          storagePrice: parseFloat(storagePrice),
          baselineIoPrice: parseFloat(baselineIoPrice)
        };

        // Store RDS Proxy Price
        const rdsProxyPrice = rdsProxyData.regions[this.state.currentButtonState.Region.label][rdsProxyKey]?.price || 0;

        tempResult[instanceType].RDSProxyPrice = {
          pricePerHour: parseFloat(rdsProxyPrice)
        };
      }

      // Handle Aurora Serverless
      const currRegion = this.state.currentButtonState.Region.label;
      const serverlessV2Key = this.state.currentButtonState.clusterConfiguration.value === 'Standard' ? 'Aurora MySQL ServerlessV2' : 'Aurora MySQL ServerlessV2 Aurora IO Optimization Mode';

      const serverlessPrice = dbStorageData.regions[currRegion]?.["Aurora MySQL Serverless"]?.price || 0.0;
      const serverlessV2Price = dbStorageData.regions[currRegion]?.[serverlessV2Key]?.price || 0.0;
      console.log('serverlessPrice: ', serverlessPrice);
      console.log('serverlessV2Price: ', serverlessV2Price);

      // Get Database Storage Price for Aurora Serverless
      const serverlessStoragePrice = dbStorageData.regions[currRegion][dbStorageKey]?.price || 0;
      const serverlessBaselineIoPrice = dbStorageData.regions[currRegion][ioOperationKey]?.price || 0;

      // Get RDS Proxy Price for Aurora Serverless
      const serverlessRdsProxyPrice = rdsProxyData.regions[currRegion][rdsProxyKey]?.price || 0;

      tempResult["aurora-serverless-v1"] = {
        instanceType: "Aurora Serverless v1",
        regionCode: this.state.currentButtonState.Region.label,
        ServerlessPrice: serverlessPrice,
        terms: {
          OnDemand: { price: serverlessPrice }
        },
        Serverless: {
          value: this.state.currentButtonState.auroraServerlessSettings.acu,
          unit: this.state.currentButtonState.auroraServerlessSettings.unit
        },
        DatabaseStoragePrice: {
          storagePrice: parseFloat(serverlessStoragePrice),
          baselineIoPrice: parseFloat(serverlessBaselineIoPrice)
        },
        RDSProxyPrice: {
          pricePerHour: parseFloat(serverlessRdsProxyPrice)
        }
      };

      tempResult["aurora-serverless-v2"] = {
        instanceType: "Aurora Serverless v2",
        regionCode: currRegion,
        ServerlessPrice: serverlessV2Price,
        terms: {
          OnDemand: { price: serverlessV2Price }
        },
        Serverless: {
          value: this.state.currentButtonState.auroraServerlessSettings.acu,
          unit: this.state.currentButtonState.auroraServerlessSettings.unit
        },
        DatabaseStoragePrice: {
          storagePrice: parseFloat(serverlessStoragePrice),
          baselineIoPrice: parseFloat(serverlessBaselineIoPrice)
        },
        RDSProxyPrice: {
          pricePerHour: parseFloat(serverlessRdsProxyPrice)
        }
      };

      // Convert tempResult to result_jsonlist
      for (const key in tempResult) {
        result_jsonlist.push(tempResult[key]);
      }

      console.log("Processed data:", result_jsonlist);
      this.setState({ productItems: result_jsonlist, clickButton: false, allProductItems: result_jsonlist, isLoading: false });
    } catch (error) {
      console.error('Fetching error:', error);
      return null;
    }
  }

  getInstanceFamily(instanceType) {
    if (!instanceType) return "N/A";

    const typePrefix = instanceType.split(".")[0];
    const typePrefixLetter = typePrefix.charAt(0);

    switch (typePrefixLetter) {
      case 't':
        return "General purpose";
      case 'm':
        return "General purpose";
      case 'c':
        return "Compute optimized";
      case 'r':
        return "Memory optimized";
      case 'x':
        return "Memory optimized";
      case 'i':
        return "Storage optimized";
      case 'd':
        return "Storage optimized";
      case 'g':
        return "GPU instance";
      case 'p':
        return "GPU instance";
      case 'f':
        return "FPGA Instances";
      case 'inf':
        return "Machine Learning ASIC Instances";
      case 'vt':
        return "Media Accelerator Instances";
      case 'a1':
      case 't4g':
        return "Micro instances";
      default:
        return "N/A";
    }
  }

  // 本地过滤
  filterProducts() {
    const { allProductItems, currentButtonState, currentPageIndex, itemsPerPage } = this.state;
    const filterText = currentButtonState.InstanceType.toLowerCase();
    const instanceFamilyFilter = currentButtonState.InstanceFamily.value.toLowerCase();
    const utilizationValue = currentButtonState.utilizationValue;
    const utilizationUnit = currentButtonState.utilizationUnit.value;

    const filteredItems = allProductItems.filter(item => {
      const memory = parseFloat(item.memory);
      const vcpu = parseInt(item.vcpu);
      const instanceType = item.instanceType ? item.instanceType.toLowerCase() : '';
      const instanceFamily = item.instanceFamily ? item.instanceFamily.toLowerCase() : '';

      // 添加 Utilization 过滤逻辑，只针对 On-Demand
      const isOnDemand = item.terms.OnDemand !== null;

      return (
        (!currentButtonState.Memory || memory === parseFloat(currentButtonState.Memory)) &&
        (!currentButtonState.vCPU || vcpu === parseInt(currentButtonState.vCPU)) &&
        (filterText === '' || instanceType.includes(filterText)) &&
        (instanceFamilyFilter === 'all' || instanceFamily === instanceFamilyFilter) &&
        (!isOnDemand || (utilizationValue >= 1 && utilizationValue <= 100))
      );
    });

    const startIndex = (currentPageIndex - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedItems = filteredItems.slice(startIndex, endIndex);

    this.setState({ productItems: paginatedItems, filteredLength: filteredItems.length });
  }

  async getData() {
    this.setState({ isLoading: true });
    console.log('reach getdata()');
    const url = this.generateApiURL();

    if (!url) {
      this.setState({ errorMsg: "Please input a valid Instance Type in the Instance Type filter (example: t4g.nano)." });
      return;
    }

    const data = await this.fetchAllData(url);
    if (data) {
      await this.processData(data);
    }
    // this.setState({ isLoading: false });
  }
  // 生成RI实例的请求URL
  generateRIURL(instance) {
    const { Region, clusterConfiguration } = this.state.currentButtonState;
    const regionValue = Region.value;
    const instanceType = instance.instanceType;
    const instanceFamily = instance.instanceFamily;
    const vcpu = instance.vcpu;
    const memory = instance.memory;
    const network = instance.Network;

    const clusterConfigPath = clusterConfiguration.value === 'Standard' ? 'rds-aurora-mysql-compatible-calc' : 'rds-aurora-iooptimization-mysql-calc';

    if (!regionValue || !instanceFamily || !instanceType || !memory || !vcpu || !network) {
      console.error('Missing values to generate RI URL');
      return null;
    }

    const url = `/pricing/2.0/meteredUnitMaps/rds/USD/current/${clusterConfigPath}/${regionValue}/Reserved/${instanceFamily}/${instanceType}/${memory}/${vcpu}/${network}/index.json`;

    return url.replace(/\/+/g, '/');
  }

  encodeUrlComponent(component) {
    return encodeURIComponent(component);
  }

  handleFilterChange = () => {
    const { PurchaseOption } = this.state.currentButtonState;

    this.setState({ clickButton: true, currentPageIndex: 1, errorMsg: "" }, () => {
      this.getData();
    });
  };

  async componentDidMount() {
    await this.getData();
    this.filterProducts();
  }

  async componentDidUpdate(prevProps, prevState) {
    // 检查是否需要发送API请求
    const needApiRequest = (
      prevState.currentButtonState.Region !== this.state.currentButtonState.Region ||
      prevState.currentButtonState.clusterConfiguration !== this.state.currentButtonState.clusterConfiguration
    );

    if (this.state.clickButton && needApiRequest) {
      await this.getData();
      this.setState({ clickButton: false });
    }

    // 处理本地筛选
    if (
      prevState.currentButtonState.InstanceFamily.value !== this.state.currentButtonState.InstanceFamily.value ||
      prevState.currentButtonState.Memory !== this.state.currentButtonState.Memory ||
      prevState.currentButtonState.vCPU !== this.state.currentButtonState.vCPU ||
      prevState.currentButtonState.InstanceType !== this.state.currentButtonState.InstanceType ||
      prevState.currentPageIndex !== this.state.currentPageIndex ||
      prevState.allProductItems !== this.state.allProductItems
    ) {
      this.filterProducts();
      const pagesCount = Math.ceil(this.state.filteredLength / this.state.itemsPerPage);
      if (this.state.currentPageIndex > pagesCount) {
        this.setState({ currentPageIndex: 1 });
      }
    }
  }

  handleSelectionChange = ({ detail }) => {
    const selectedItems = detail.selectedItems;
    const isServerless = selectedItems.length > 0 && selectedItems[0].instanceType === 'Aurora Serverless';

    this.setState({
      selectedItems,
      currentButtonState: {
        ...this.state.currentButtonState,
        isServerless,
      }
    });
  };

  // 添加购物车
  handleAddCart = async () => {
    const { currentButtonState, selectedItems } = this.state;

    const updatedItems = await Promise.all(selectedItems.map(async (item) => {
      console.log("handleAddCartFirstitem: ", item);

      item.nodes = currentButtonState.nodes;

      if (item.instanceType === "Aurora Serverless v1" || item.instanceType === "Aurora Serverless v2") {
        item.regionCode = this.state.currentButtonState.Region.label;
        item.ServerlessPrice = item.terms.OnDemand.price;
        item.Serverless = {
          value: currentButtonState.auroraServerlessSettings.acu,
          unit: currentButtonState.auroraServerlessSettings.unit
        };
      } else {
        // Fetch On-Demand data
        const onDemandData = await this.fetchOnDemandData(item, currentButtonState.Region, currentButtonState.clusterConfiguration);
        if (onDemandData) {
          console.log('onDemandData: ', onDemandData);
          const regionLabel = currentButtonState.Region.label;
          const onDemandKey = Object.keys(onDemandData.regions[regionLabel])[0];
          const onDemandPrice = onDemandData.regions[regionLabel][onDemandKey].price;

          item.terms.OnDemand = { price: onDemandPrice };
          item.perUnitPrice = onDemandPrice + " USD per Hrs";
          console.log("after add OnDemand Price item.terms.OnDemand: ", item.terms.OnDemand);
        }

        if (currentButtonState.clusterConfiguration.value !== 'Aurora I/O-Optimized' && (!item.terms.Reserved || item.terms.Reserved.length === 0)) {
          const newItem = await this.getRIDataForItem(item);
          item = { ...item, ...newItem };
        }
      }

      item.DatabaseStorage = {
        storageAmount: currentButtonState.storageAmount,
        storageUnit: currentButtonState.storageUnit.value,
        baselineIORate: currentButtonState.baselineIORate.value,
        baselineIOUnit: currentButtonState.baselineIORate.unit.value,
        peakIORate: currentButtonState.peakIORate.value,
        peakIOUnit: currentButtonState.peakIORate.unit.value,
        durationOfPeakIO: currentButtonState.durationOfPeakIO.value,
        durationOfPeakIOUnit: currentButtonState.durationOfPeakIO.unit.value,
      };

      item.RDSProxy = currentButtonState.rdsProxy;

      console.log('handleAddCart after merge: ', item);
      const itemObject = auroraMySQLCalculatePrices(item);

      console.log('After calculatePrices: ', itemObject);

      return {
        ...itemObject,
      };
    }));

    updatedItems.forEach(item => this.props.addToCart(item));

    // 重置 Aurora Serverless Settings 状态
    this.setState({
      selectedItems: [],
      currentButtonState: {
        ...this.state.currentButtonState,
        auroraServerlessSettings: {
          acu: 0.5,
          unit: "per hour",
          version: ""
        }
      }
    });
  };

  // 获取OnDemand实例数据
  async fetchOnDemandData(instance, region, clusterConfig) {
    const instanceType = instance.instanceType;
    const instanceFamily = instance.instanceFamily;
    const vcpu = instance.vcpu;
    const memory = instance.memory;
    const network = instance.Network;

    const clusterConfigPath = clusterConfig.value === 'Standard' ? 'rds-aurora-mysql-compatible-calc' : 'rds-aurora-iooptimization-mysql-calc';
    const regionPath = encodeURIComponent(region.label);

    const url = `/pricing/2.0/meteredUnitMaps/rds/USD/current/${clusterConfigPath}/${regionPath}/OnDemand/${instanceFamily}/${instanceType}/${memory}/${vcpu}/${network}/index.json`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Fetching On-Demand data error:', error);
      return null;
    }
  };

  // 获取RI实例数据
  async getRIDataForItem(item) {
    console.log('getRIDataForItem item: ', item);
    const reservedUrl = this.generateRIURL(item);
    if (!reservedUrl) return item;

    const reservedData = await this.fetchAllData(reservedUrl);
    console.log('getRIDataForItem reservedData: ', reservedData);

    const regionLabel = this.state.currentButtonState.Region.label;
    const regionData = reservedData.regions[regionLabel];

    if (!regionData) {
      console.error("No data found for the selected region:", regionLabel);
      return item;
    }

    for (const key in regionData) {
      const RIItem = regionData[key];
      if (RIItem["Instance Type"] && RIItem["Instance Type"].toLowerCase() === item.instanceType.toLowerCase()) {
        if (!item.terms.Reserved) {
          item.terms.Reserved = [];
        }

        let reservedTerm = item.terms.Reserved.find(term =>
          term.purchaseOption === RIItem["PurchaseOption"] &&
          term.leaseContractLength === RIItem["LeaseContractLength"]
        );

        if (!reservedTerm) {
          reservedTerm = {
            purchaseOption: RIItem["PurchaseOption"],
            leaseContractLength: RIItem["LeaseContractLength"],
            hrsPrice: null,
            quantityPrice: null
          };
          item.terms.Reserved.push(reservedTerm);
        }

        if (RIItem.Unit === "Hrs") {
          reservedTerm.hrsPrice = RIItem.price;
        }

        if (RIItem.Unit === "Quantity") {
          reservedTerm.quantityPrice = RIItem.price;
        }
      }
    }
    console.log('after process RI price: ', item);
    return item;
  }

  // 渲染
  render() {
    const { isServerless, auroraServerlessSettings, rdsProxy, storageAmount, storageUnit, baselineIORate, peakIORate, durationOfPeakIO, clusterConfiguration } = this.state.currentButtonState;

    return (
      <Layout>
        <Modal
          visible={this.state.showModal}
          onDismiss={() => this.setState({ showModal: false })}
          header="Notes"
          footer={
            <Button onClick={() => this.setState({ showModal: false })}>Close</Button>
          }
        >
          <Box>{this.state.errorMsg}</Box>
        </Modal>
        <Table
          onSelectionChange={this.onSelectionChange}
          selectedItems={this.state.selectedItems}
          ariaLabels={{
            selectionGroupLabel: "Items selection",
            allItemsSelectionLabel: ({ selectedItems }) =>
              `${selectedItems.length} ${selectedItems.length === 1 ? "item" : "items"} selected`,
            itemSelectionLabel: ({ selectedItems }, item) =>
              item.InstanceType
          }}
          columnDefinitions={[
            {
              id: "instanceType",
              header: "Instance Type",
              cell: item => item.instanceType,
              sortingField: "instanceType",
              isRowHeader: true
            },
            {
              id: "instanceFamily",
              header: "Instance Family",
              cell: item => item.instanceFamily,
            },
            {
              id: "regionCode",
              header: "Region Code",
              cell: item => item.regionCode,
              sortingField: "regionCode"
            },
            {
              id: "vcpu",
              header: "vCPU",
              cell: item => item.vcpu,
              sortingField: "vcpu"
            },
            {
              id: "memory",
              header: "Memory",
              cell: item => item.memory,
              sortingField: "memory"
            },
            {
              id: "storage",
              header: "Storage",
              cell: item => item.storage,
            },
            {
              id: "network",
              header: "network performance",
              cell: item => item.Network,
            },
            {
              id: "pricePerUnit",
              header: "OnDemand Price",
              cell: item => item.terms.OnDemand.price,
            },
            {
              id: "upfrontFee",
              header: "Upfront Fee",
              cell: item => {
                let price = "N/A";
                if (item.terms && item.terms.Reserved && item.terms.Reserved.length > 0) {
                  price = item.terms.Reserved[0].quantityPrice;
                }
                return price !== "N/A" ? parseFloat(price).toFixed(2) : price;  // 保留两位小数
              },
            }
          ]}
          columnDisplay={[
            { id: "instanceType", visible: true },
            { id: "instanceFamily", visible: true },
            { id: "regionCode", visible: true },
            { id: "vcpu", visible: true },
            { id: "memory", visible: true },
            { id: "gpuMemory", visible: true },
            { id: "network", visible: true },
            { id: "storage", visible: false },
            { id: "pricePerUnit", visible: false },
            { id: "upfrontFee", visible: false }
          ]}
          enableKeyboardNavigation
          items={this.state.productItems}
          loading={this.state.isLoading}
          loadingText="Loading..."
          selectionType="multi"
          stickyColumns={{ first: 0, last: 1 }}
          trackBy="instanceType"

          empty={
            this.state.isLoading ? (
              <Box
                margin={{ vertical: "xs" }}
                textAlign="center"
                color="inherit"
              >
                <SpaceBetween size="m">
                  <b>Loading...</b>
                </SpaceBetween>
              </Box>
            ) : (
              <Box
                margin={{ vertical: "xs" }}
                textAlign="center"
                color="inherit"
              >
                <SpaceBetween size="m">
                  <b>No Instances based on your input</b>
                </SpaceBetween>
              </Box>
            )
          }
          filter={
            <div className="filter-container" key="filter-container">
              <div className="select-filter" key="region-filter">
                <FormField label="Region">
                  <RegionFilter
                    selectedOption={this.state.currentButtonState.Region}
                    setSelectedOption={selectedOption => this.setState({ currentButtonState: { ...this.state.currentButtonState, Region: selectedOption }, clickButton: true })}
                    onFilterChange={this.handleFilterChange}
                  />
                </FormField>
              </div>
              <div className="select-filter" key="instance-family-filter-filter">
                <FormField label="Instance Family">
                  <InstanceFamilyFilter
                    selectedOption={this.state.currentButtonState.InstanceFamily}
                    setSelectedOption={selectedOption => this.setState({ currentButtonState: { ...this.state.currentButtonState, InstanceFamily: selectedOption }, clickButton: true })}
                    onFilterChange={this.handleFilterChange}
                  />
                </FormField>
              </div>
              <div className="input-filter" key="vcpu-filter">
                <FormField label="vCPU (Optional)">
                  <VCPUFilter
                    filteringText={this.state.currentButtonState.vCPU}
                    setFilteringText={filteringText => this.setState({ currentButtonState: { ...this.state.currentButtonState, vCPU: filteringText }, clickButton: true })}
                    onFilterChange={this.handleFilterChange}
                  />
                </FormField>
              </div>
              <div className="input-filter" key="memory-filter">
                <FormField label="Memory (Optional)">
                  <MemoryFilter
                    filteringText={this.state.currentButtonState.Memory}
                    setFilteringText={filteringText => this.setState({ currentButtonState: { ...this.state.currentButtonState, Memory: filteringText }, clickButton: true })}
                    onFilterChange={this.handleFilterChange}
                  />
                </FormField>
              </div>

              <div className="input-filter" key="instance-type-filter">
                <FormField label="Instance Type (Optional)">
                  <InstanceTypeFilter
                    filteringText={this.state.currentButtonState.InstanceType}
                    setFilteringText={filteringText => this.setState({ currentButtonState: { ...this.state.currentButtonState, InstanceType: filteringText }, clickButton: true })}
                    onFilterChange={this.handleFilterChange}
                  />
                </FormField>
              </div>
              <div className="input-filter" key="utilization-filter">
                <UtilizationFilter
                  utilizationValue={this.state.currentButtonState.utilizationValue}
                  setUtilizationValue={(value) => this.setState({ currentButtonState: { ...this.state.currentButtonState, utilizationValue: value }, clickButton: true })}
                  utilizationUnit={this.state.currentButtonState.utilizationUnit}
                  setUtilizationUnit={(unit) => this.setState({ currentButtonState: { ...this.state.currentButtonState, utilizationUnit: unit }, clickButton: true })}
                  onFilterChange={this.handleFilterChange}
                />
              </div>
              <div className="input-filter" key="nodes-filter">
                <FormField label="Nodes">
                  <NodesFilter
                    nodesValue={this.state.currentButtonState.nodes}
                    setNodesValue={(value) => this.setState({ currentButtonState: { ...this.state.currentButtonState, nodes: value }, clickButton: true })}
                    onFilterChange={this.handleFilterChange}
                  />
                </FormField>
              </div>
              <div className="select-filter" key="cluster-configuration-filter">
                <FormField label="Aurora MySQL Cluster Configuration Option">
                  <ClusterConfigurationFilter
                    selectedOption={this.state.currentButtonState.clusterConfiguration}
                    setSelectedOption={selectedOption => this.setState({ currentButtonState: { ...this.state.currentButtonState, clusterConfiguration: selectedOption }, clickButton: true })}
                    onFilterChange={this.handleFilterChange}
                  />
                </FormField>
              </div>
              <div className="filter-section" key="RDS-proxy">
                <RDSProxy
                  rdsProxy={rdsProxy}
                  setRdsProxy={(newRdsProxy) => this.setState({
                    currentButtonState: {
                      ...this.state.currentButtonState,
                      rdsProxy: newRdsProxy
                    }
                  })}
                />
              </div>
              <h3>
                Database Storage (Optional)
              </h3>
              <div className="input-filter" key="storage-amount">
                <StorageAmount
                  storageAmount={this.state.currentButtonState.storageAmount}
                  setStorageAmount={(value) => this.setState({
                    currentButtonState: {
                      ...this.state.currentButtonState,
                      storageAmount: value
                    },
                    clickButton: true
                  })}
                  storageUnit={this.state.currentButtonState.storageUnit}
                  setStorageUnit={(value) => this.setState({
                    currentButtonState: {
                      ...this.state.currentButtonState,
                      storageUnit: value
                    },
                    clickButton: true
                  })}
                  onFilterChange={this.handleFilterChange}
                />
              </div>
              <div className="input-filter" key="baseline-io-rate">
                <BaselineIORate
                  baselineIORate={this.state.currentButtonState.baselineIORate}
                  setBaselineIORate={(value) => this.setState({
                    currentButtonState: {
                      ...this.state.currentButtonState,
                      baselineIORate: value
                    },
                    clickButton: true
                  })}
                  onFilterChange={this.handleFilterChange}
                />
              </div>
              <div className="input-filter" key="peak-io-rate">
                <PeakIORate
                  peakIORate={this.state.currentButtonState.peakIORate}
                  setPeakIORate={(value) => this.setState({
                    currentButtonState: {
                      ...this.state.currentButtonState,
                      peakIORate: value
                    },
                    clickButton: true
                  })}
                  onFilterChange={this.handleFilterChange}
                />
              </div>
              <div className="input-filter" key="duration-of-peak-io">
                <DurationOfPeakIO
                  durationOfPeakIO={this.state.currentButtonState.durationOfPeakIO}
                  setDurationOfPeakIO={(value) => this.setState({
                    currentButtonState: {
                      ...this.state.currentButtonState,
                      durationOfPeakIO: value
                    },
                    clickButton: true
                  })}
                  onFilterChange={this.handleFilterChange}
                />
              </div>
              <div className="filter-section" key="aurora-serverless-settings">
                <AuroraServerlessSettings
                  serverlessSettings={this.state.currentButtonState.auroraServerlessSettings}
                  setServerlessSettings={(newSettings) => this.setState({
                    currentButtonState: {
                      ...this.state.currentButtonState,
                      auroraServerlessSettings: newSettings
                    }
                  })}
                  onSelectionChange={this.onSelectionChange}
                  selectedItems={this.state.selectedItems}
                  allProductItems={this.state.allProductItems}
                  isIOOptimized={clusterConfiguration.value === 'Aurora I/O-Optimized'}
                />
              </div>
            </div>
          }
          header={
            <Header
              counter={
                this.state.selectedItems.length
                  ? "(" + this.state.selectedItems.length + "/" + this.state.filteredLength + ")"
                  : "(" + this.state.filteredLength + ")" //display the entire length of the filtered instances
              }
              actions={
                <SpaceBetween
                  direction="horizontal"
                  size="xs"
                >
                  <Button variant="primary" onClick={this.handleAddCart}>
                    Add to Cart
                  </Button>
                  <Link to="/cart">
                    <Button variant="primary">
                      Go to Cart
                    </Button>
                  </Link>
                </SpaceBetween>
              }
            >
              Amazon Aurora MySQL-Compatible
            </Header>
          }

          pagination={
            <div className="App-pagination">
              <span>Show Rows &nbsp;</span>
              <ItemsPerPageDropdown
                itemsPerPageOptions={this.state.itemsPerPageOptions}
                itemsPerPage={this.state.itemsPerPage}
                setItemsPerPage={this.handleItemsPerPageChange}
              />
              <PaginationSetting
                currentPageIndex={this.state.currentPageIndex}
                setCurrentPageIndex={(currentPageIndex) => this.handleNextPage(currentPageIndex)}
                nextPage={this.nextPage}
                totalItems={this.state.filteredLength}
                itemsPerPage={this.state.itemsPerPage}
                onPageChange={this.handlePageChange}
              />
            </div> //add instance display drop down and the show rows text label
          }
        />
      </Layout>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addToCart: (item) => dispatch(addToCart(item)),
});

export default connect(null, mapDispatchToProps)(AuroraMySQLProductTable);