import { v4 as uuidv4 } from 'uuid';
export const calculatePrices = (item, currentButtonState) => {
    console.log('item in price calculating: ', item);
    // const pricePerHourString = item.perUnitPrice.split(' ')[0];
    // const pricePerHour = parseFloat(pricePerHourString);
    // const pricePerMonth = (pricePerHour * 730);
    const onDemand_price = parseFloat(item.terms.OnDemand.price) * 730;
    const onDemand_price_1yr = onDemand_price * 12;
    console.log('onDemand_price:', onDemand_price);
    console.log('onDemand_price_1yr:', onDemand_price_1yr);

    let reservedNoUpfrontMonthly = 'N/A';
    let partialUpfrontMonthly = 'N/A';
    let partialUpfrontFee = 'N/A';
    let allUpfrontPrice = 'N/A';
    let savingPlansMonthly = 'N/A';
    let EC2SavingPlansMonthly = "N/A";
    let sku_tail = "";
    let compareOnDemand = "";

    let RI_All_Con_1yr = "";
    let UF_RI_All_Con_1yr = "";
    let RI_All_Con_3yr = "";
    let UF_RI_All_Con_3yr = "";
    let RI_All_Std_3yr = "";
    let UF_RI_All_Std_3yr = "";
    let RI_All_Std_1yr = "";
    let UF_RI_All_Std_1yr = "";
    let RI_Pt_Std_1yr = "";
    let UF_RI_Pt_Std_1yr = "";
    let RI_Pt_Std_3yr = "";
    let UF_RI_Pt_Std_3yr = "";
    let RI_Pt_Con_1yr = "";
    let UF_RI_Pt_Con_1yr = "";
    let RI_Pt_Con_3yr = "";
    let UF_RI_Pt_Con_3yr = "";
    let RI_No_Con_1yr = "";
    let UF_RI_No_Con_1yr = "";
    let RI_No_Std_1yr = "";
    let UF_RI_No_Std_1yr = "";
    let RI_No_Std_3yr = "";
    let UF_RI_No_Std_3yr = "";
    let RI_No_Con_3yr = "";
    let UF_RI_No_Con_3yr = "";
    let SP_No_CPT_3yr = "";
    let UF_SP_No_CPT_3yr = "";
    let SP_No_CPT_1yr = "";
    let UF_SP_No_CPT_1yr = "";
    let SP_All_CPT_3yr = "";
    let UF_SP_All_CPT_3yr = "";
    let SP_All_CPT_1yr = "";
    let UF_SP_All_CPT_1yr = "";
    let SP_Pt_CPT_3yr = "";
    let UF_SP_Pt_CPT_3yr = "";
    let SP_Pt_CPT_1yr = "";
    let UF_SP_Pt_CPT_1yr = "";
    let SP_No_EC2_3yr = "";
    let UF_SP_No_EC2_3yr = "";
    let SP_No_EC2_1yr = "";
    let UF_SP_No_EC2_1yr = "";
    let SP_All_EC2_3yr = "";
    let UF_SP_All_EC2_3yr = "";
    let SP_All_EC2_1yr = "";
    let UF_SP_All_EC2_1yr = "";
    let SP_Pt_EC2_3yr = "";
    let UF_SP_Pt_EC2_3yr = "";
    let SP_Pt_EC2_1yr = "";
    let UF_SP_Pt_EC2_1yr = "";
    let TT_RI_Pt_Con_1yr = "";
    let TT_RI_Pt_Con_3yr = "";
    let TT_RI_Pt_Std_1yr = "";
    let TT_RI_Pt_Std_3yr = "";
    let CP_RI_No_Con_1yr = "";
    let CP_RI_No_Con_3yr = "";
    let CP_RI_No_Std_1yr = "";
    let CP_RI_No_Std_3yr = "";
    let CP_RI_Pt_Con_1yr = "";
    let CP_RI_Pt_Con_3yr = "";
    let CP_RI_Pt_Std_1yr = "";
    let CP_RI_Pt_Std_3yr = "";
    let CP_RI_All_Con_1yr = "";
    let CP_RI_All_Con_3yr = "";
    let CP_RI_All_Std_1yr = "";
    let CP_RI_All_Std_3yr = "";

    let CP_SP_Pt_CPT_3yr = "";
    let CP_SP_Pt_CPT_1yr = "";
    let CP_SP_No_CPT_3yr = "";
    let CP_SP_All_CPT_3yr = "";
    let CP_SP_No_CPT_1yr = "";
    let CP_SP_All_CPT_1yr = "";
    let CP_SP_Pt_EC2_3yr = "";
    let CP_SP_No_EC2_3yr = "";
    let CP_SP_Pt_EC2_1yr = "";
    let CP_SP_All_EC2_3yr = "";
    let CP_SP_All_EC2_1yr = "";
    let CP_SP_No_EC2_1yr = "";    

    console.log('price item: ', item);
    if (item.terms && item.terms.Reserved) {
        const terms = item.terms.Reserved;
        console.log('Reserved terms', terms);

        terms.forEach(term => {
            const option = term.purchaseOption;
            const reservedMonthlyPrice = parseFloat(term.hrsPrice) * 730;
            const reservedNoUpfrontMonthly = reservedMonthlyPrice;
            console.log('Reserved option:', option, 'Class:', term.offeringClass, 'Length:', term.leaseContractLength, 'Price:', reservedNoUpfrontMonthly);

            let leaseLength = "";
            switch (option) {
                case 'No Upfront':
                    compareOnDemand = parseFloat(reservedNoUpfrontMonthly / onDemand_price);
                    switch (term.offeringClass) {
                        case 'convertible':
                            if (term.leaseContractLength === '1yr') {
                                RI_No_Con_1yr = reservedNoUpfrontMonthly;
                                UF_RI_No_Con_1yr = term.quantityPrice;
                                CP_RI_No_Con_1yr = compareOnDemand;
                            } else {
                                RI_No_Con_3yr = reservedNoUpfrontMonthly;
                                UF_RI_No_Con_3yr = term.quantityPrice;
                                CP_RI_No_Con_3yr = compareOnDemand;
                            }
                            break;
                        case 'standard':
                            if (term.leaseContractLength === '1yr') {
                                RI_No_Std_1yr = reservedNoUpfrontMonthly;
                                UF_RI_No_Std_1yr = term.quantityPrice;
                                CP_RI_No_Std_1yr = compareOnDemand;
                            } else {
                                RI_No_Std_3yr = reservedNoUpfrontMonthly;
                                UF_RI_No_Std_3yr = term.quantityPrice;
                                CP_RI_No_Std_3yr = compareOnDemand;
                            }
                            break;
                    }
                    break;
                case 'Partial Upfront':
                    partialUpfrontMonthly = reservedMonthlyPrice;
                    partialUpfrontFee = parseFloat(term.quantityPrice);
                    leaseLength = term.leaseContractLength === '1yr' ? 1 : 3;
                    const partialUpfrontFee_PerMonth = (partialUpfrontFee / (12 * leaseLength));
                    const partial_total_month = (parseFloat(partialUpfrontMonthly) + parseFloat(partialUpfrontFee_PerMonth));
                    console.log('Partial Upfront:', partial_total_month, 'Fee:', partialUpfrontFee);
                    compareOnDemand = parseFloat(partial_total_month / onDemand_price);

                    switch (term.offeringClass) {
                        case 'convertible':
                            if (term.leaseContractLength === '1yr') {
                                RI_Pt_Con_1yr = partialUpfrontMonthly;
                                UF_RI_Pt_Con_1yr = term.quantityPrice;
                                TT_RI_Pt_Con_1yr = partial_total_month;
                                CP_RI_Pt_Con_1yr = compareOnDemand;
                            } else {
                                RI_Pt_Con_3yr = partialUpfrontMonthly;
                                UF_RI_Pt_Con_3yr = term.quantityPrice;
                                TT_RI_Pt_Con_3yr = partial_total_month;
                                CP_RI_Pt_Con_3yr = compareOnDemand;
                            }
                            break;
                        case 'standard':
                            if (term.leaseContractLength === '1yr') {
                                RI_Pt_Std_1yr = partialUpfrontMonthly;
                                UF_RI_Pt_Std_1yr = term.quantityPrice;
                                TT_RI_Pt_Std_1yr = partial_total_month;
                                CP_RI_Pt_Std_1yr = compareOnDemand;
                            } else {
                                RI_Pt_Std_3yr = partialUpfrontMonthly;
                                UF_RI_Pt_Std_3yr = term.quantityPrice;
                                TT_RI_Pt_Std_3yr = partial_total_month;
                                CP_RI_Pt_Std_3yr = compareOnDemand;
                            }
                            break;
                    }
                    break;
                case 'All Upfront':
                    allUpfrontPrice = parseFloat(term.quantityPrice);
                    leaseLength = term.leaseContractLength === '1yr' ? 1 : 3;
                    const allUpfrontPrice_PerMonth = allUpfrontPrice / (12 * leaseLength);
                    console.log('All Upfront:', allUpfrontPrice_PerMonth, 'Total:', allUpfrontPrice);
                    compareOnDemand = parseFloat(allUpfrontPrice_PerMonth / onDemand_price);

                    switch (term.offeringClass) {
                        case 'convertible':
                            if (term.leaseContractLength === '1yr') {
                                RI_All_Con_1yr = allUpfrontPrice_PerMonth;
                                UF_RI_All_Con_1yr = term.quantityPrice;
                                CP_RI_All_Con_1yr = compareOnDemand;
                            } else {
                                RI_All_Con_3yr = allUpfrontPrice_PerMonth;
                                UF_RI_All_Con_3yr = term.quantityPrice;
                                CP_RI_All_Con_3yr = compareOnDemand;
                            }
                            break;
                        case 'standard':
                            if (term.leaseContractLength === '1yr') {
                                RI_All_Std_1yr = allUpfrontPrice_PerMonth;
                                UF_RI_All_Std_1yr = term.quantityPrice;
                                CP_RI_All_Std_1yr = compareOnDemand;
                            } else {
                                RI_All_Std_3yr = allUpfrontPrice_PerMonth;
                                UF_RI_All_Std_3yr = term.quantityPrice;
                                CP_RI_All_Std_3yr = compareOnDemand;
                            }
                            break;
                    }
                    break;
            }
        });
    }

    if (item.terms && item.terms.SavingPlans) {
        const terms = item.terms.SavingPlans;
        console.log('savingplan terms', terms);
        for (const key in terms) {
            const savingPlansMonthlyPrice = parseFloat(terms[key].price) * 730;
            const EC2MonthlyPrice = parseFloat(terms[key].pricePerUnit) * 730;
            savingPlansMonthly = savingPlansMonthlyPrice;
            // EC2SavingPlansMonthly = EC2MonthlyPrice;
            sku_tail = terms[key].savingPlans;
            let compareOnDemand = parseFloat(savingPlansMonthly / onDemand_price);
            // let compareOnDemandEC2 = parseFloat(EC2SavingPlansMonthly / onDemand_price);

            switch (terms[key].savingPlans) {
                case 'ComputeSavingsPlans 3 year Partial Upfront':
                    SP_Pt_CPT_3yr = parseFloat(savingPlansMonthly);
                    UF_SP_Pt_CPT_3yr = terms[key].quantityPrice;
                    CP_SP_Pt_CPT_3yr = compareOnDemand;
                    break;
                case 'ComputeSavingsPlans 1 year Partial Upfront':
                    SP_Pt_CPT_1yr = parseFloat(savingPlansMonthly);
                    UF_SP_Pt_CPT_1yr = terms[key].quantityPrice;
                    CP_SP_Pt_CPT_1yr = compareOnDemand;
                    break;
                case 'ComputeSavingsPlans 3 year No Upfront':
                    SP_No_CPT_3yr = parseFloat(savingPlansMonthly);
                    UF_SP_No_CPT_3yr = terms[key].quantityPrice;
                    CP_SP_No_CPT_3yr = compareOnDemand;
                    break;
                case 'ComputeSavingsPlans 3 year All Upfront':
                    SP_All_CPT_3yr = parseFloat(savingPlansMonthly);
                    UF_SP_All_CPT_3yr = terms[key].quantityPrice;
                    CP_SP_All_CPT_3yr = compareOnDemand;
                    break;
                case 'ComputeSavingsPlans 1 year No Upfront':
                    SP_No_CPT_1yr = parseFloat(savingPlansMonthly);
                    UF_SP_No_CPT_1yr = terms[key].quantityPrice;
                    CP_SP_No_CPT_1yr = compareOnDemand;
                    break;
                case 'ComputeSavingsPlans 1 year All Upfront':
                    SP_All_CPT_1yr = parseFloat(savingPlansMonthly);
                    UF_SP_All_CPT_1yr = terms[key].quantityPrice;
                    CP_SP_All_CPT_1yr = compareOnDemand;
                    break;
                case 'EC2InstanceSavingsPlans 3 year Partial Upfront':
                    SP_Pt_EC2_3yr = parseFloat(savingPlansMonthly);
                    UF_SP_Pt_EC2_3yr = terms[key].quantityPrice;
                    CP_SP_Pt_EC2_3yr = compareOnDemand;
                    console.log(savingPlansMonthly);
                    break;
                case 'EC2InstanceSavingsPlans 3 year No Upfront':
                    SP_No_EC2_3yr = parseFloat(savingPlansMonthly);
                    UF_SP_No_EC2_3yr = terms[key].quantityPrice;
                    CP_SP_No_EC2_3yr = compareOnDemand;
                    console.log("!!!!!");
                    console.log(savingPlansMonthly);
                    break;
                case 'EC2InstanceSavingsPlans 1 year Partial Upfront':
                    SP_Pt_EC2_1yr = parseFloat(savingPlansMonthly);
                    UF_SP_Pt_EC2_1yr = terms[key].quantityPrice;
                    CP_SP_Pt_EC2_1yr = compareOnDemand;
                    console.log("!!!!!");
                    console.log(savingPlansMonthly);
                    break;
                case 'EC2InstanceSavingsPlans 3 year All Upfront':
                    SP_All_EC2_3yr = parseFloat(savingPlansMonthly);
                    UF_SP_All_EC2_3yr = terms[key].quantityPrice;
                    CP_SP_All_EC2_3yr = compareOnDemand;
                    console.log("!!!!!");
                    console.log(savingPlansMonthly);
                    break;
                case 'EC2InstanceSavingsPlans 1 year All Upfront':
                    SP_All_EC2_1yr = parseFloat(savingPlansMonthly);
                    UF_SP_All_EC2_1yr = terms[key].quantityPrice;
                    CP_SP_All_EC2_1yr = compareOnDemand;
                    break;
                case 'EC2InstanceSavingsPlans 1 year No Upfront':
                    SP_No_EC2_1yr = parseFloat(savingPlansMonthly);
                    UF_SP_No_EC2_1yr = terms[key].quantityPrice;
                    CP_SP_No_EC2_1yr = compareOnDemand;
                    break;
            }
        }
    }
    // Modify sku to include EBS values
    const ebsValues = item.ebsselection || {};
    const ebsString = Object.entries(ebsValues)
        .map(([key, value]) => `${key}=${value}`)
        .join("|");

    const itemObject = {
        sku: uuidv4(),
        sku_details: `${item.instanceType}-${item.regionCode}-${item.operatingSystem}-${sku_tail}${ebsString ? `-EBS(${ebsString})` : ""}`,


        /* sku: `${item.instanceType}-${item.regionCode}-${item.operatingSystem}-${sku_tail}`, */
        product: {
            attributes: {
                region: item.regionCode,
                instanceType: item.instanceType,
                vcpu: item.vcpu,
                memory: item.memory,
                storage: item.storage,
                networkPerformance: item.networkPerformance,
                operatingSystem: item.operatingSystem,
                ebsselection: item.ebsselection
            }
        },
        quantity: 1,
        ServiceName: "EC2",
        terms: item.terms,
        upfrontFee: item.upfrontFee,
        Reserved_All_Upfront_convertible_1yr_per_Month: RI_All_Con_1yr,
        UF_Reserved_All_Upfront_convertible_1yr: UF_RI_All_Con_1yr,
        Reserved_All_Upfront_convertible_3yr_per_Month: RI_All_Con_3yr,
        UF_Reserved_All_Upfront_convertible_3yr: UF_RI_All_Con_3yr,
        Reserved_All_Upfront_standard_1yr_per_Month: RI_All_Std_1yr,
        UF_Reserved_All_Upfront_standard_1yr: UF_RI_All_Std_1yr,
        Reserved_All_Upfront_standard_3yr_per_Month: RI_All_Std_3yr,
        UF_Reserved_All_Upfront_standard_3yr: UF_RI_All_Std_3yr,
        Reserved_Partial_Upfront_convertible_1yr_per_Month: RI_Pt_Con_1yr,
        UF_Reserved_Partial_Upfront_convertible_1yr: UF_RI_Pt_Con_1yr,
        Reserved_Partial_Upfront_convertible_3yr_per_Month: RI_Pt_Con_3yr,
        UF_Reserved_Partial_Upfront_convertible_3yr: UF_RI_Pt_Con_3yr,
        Reserved_Partial_Upfront_standard_1yr_per_Month: RI_Pt_Std_1yr,
        UF_Reserved_Partial_Upfront_standard_1yr: UF_RI_Pt_Std_1yr,
        Reserved_Partial_Upfront_standard_3yr_per_Month: RI_Pt_Std_3yr,
        UF_Reserved_Partial_Upfront_standard_3yr: UF_RI_Pt_Std_3yr,
        Reserved_No_Upfront_convertible_1yr_per_Month: RI_No_Con_1yr,
        UF_Reserved_No_Upfront_convertible_1yr: UF_RI_No_Con_1yr,
        Reserved_No_Upfront_convertible_3yr_per_Month: RI_No_Con_3yr,
        UF_Reserved_No_Upfront_convertible_3yr: UF_RI_No_Con_3yr,
        Reserved_No_Upfront_standard_1yr_per_Month: RI_No_Std_1yr,
        UF_Reserved_No_Upfront_standard_1yr: UF_RI_No_Std_1yr,
        Reserved_No_Upfront_standard_3yr_per_Month: RI_No_Std_3yr,
        UF_Reserved_No_Upfront_standard_3yr: UF_RI_No_Std_3yr,
        ComputeSavingsPlans_3_year_No_Upfront_per_Month: SP_No_CPT_3yr,
        UF_ComputeSavingsPlans_3_year_No_Upfront: UF_SP_No_CPT_3yr,
        ComputeSavingsPlans_1_year_No_Upfront_per_Month: SP_No_CPT_1yr,
        UF_ComputeSavingsPlans_1_year_No_Upfront: UF_SP_No_CPT_1yr,
        ComputeSavingsPlans_3_year_Partial_Upfront_per_Month: SP_Pt_CPT_3yr,
        UF_ComputeSavingsPlans_3_year_Partial_Upfront: UF_SP_Pt_CPT_3yr,
        ComputeSavingsPlans_1_year_Partial_Upfront_per_Month: SP_Pt_CPT_1yr,
        UF_ComputeSavingsPlans_1_year_Partial_Upfront: UF_SP_Pt_CPT_1yr,
        ComputeSavingsPlans_3_year_All_Upfront_per_Month: SP_All_CPT_3yr,
        UF_ComputeSavingsPlans_3_year_All_Upfront: UF_SP_All_CPT_3yr,
        ComputeSavingsPlans_1_year_All_Upfront_per_Month: SP_All_CPT_1yr,
        UF_ComputeSavingsPlans_1_year_All_Upfront: UF_SP_All_CPT_1yr,
        EC2InstanceSavingsPlans_3_year_Partial_Upfront_per_Month: SP_Pt_EC2_3yr,
        UF_EC2InstanceSavingsPlans_3_year_Partial_Upfront: UF_SP_Pt_EC2_3yr,
        EC2InstanceSavingsPlans_3_year_No_Upfront_per_Month: SP_No_EC2_3yr,
        UF_EC2InstanceSavingsPlans_3_year_No_Upfront: UF_SP_No_EC2_3yr,
        EC2InstanceSavingsPlans_1_year_Partial_Upfront_per_Month: SP_Pt_EC2_1yr,
        UF_EC2InstanceSavingsPlans_1_year_Partial_Upfront: UF_SP_Pt_EC2_1yr,
        EC2InstanceSavingsPlans_3_year_All_Upfront_per_Month: SP_All_EC2_3yr,
        UF_EC2InstanceSavingsPlans_3_year_All_Upfront: UF_SP_All_EC2_3yr,
        EC2InstanceSavingsPlans_1_year_All_Upfront_per_Month: SP_All_EC2_1yr,
        UF_EC2InstanceSavingsPlans_1_year_All_Upfront: UF_SP_All_EC2_1yr,
        EC2InstanceSavingsPlans_1_year_No_Upfront_per_Month: SP_No_EC2_1yr,
        UF_EC2InstanceSavingsPlans_1_year_No_Upfront: UF_SP_No_EC2_1yr,
        OnDemand_1Month: onDemand_price,
        OnDemand_1Year: onDemand_price_1yr,
        TotalPrice_TT_RI_Pt_Con_1yr: TT_RI_Pt_Con_1yr,
        TotalPrice_TT_RI_Pt_Con_3yr: TT_RI_Pt_Con_3yr,
        TotalPrice_TT_RI_Pt_Std_1yr: TT_RI_Pt_Std_1yr,
        TotalPrice_TT_RI_Pt_Std_3yr: TT_RI_Pt_Std_3yr,
        Compare_CP_RI_No_Con_1yr: CP_RI_No_Con_1yr,
        Compare_CP_RI_No_Con_3yr: CP_RI_No_Con_3yr,
        Compare_CP_RI_No_Std_1yr: CP_RI_No_Std_1yr,
        Compare_CP_RI_No_Std_3yr: CP_RI_No_Std_3yr,
        Compare_CP_RI_Pt_Con_1yr: CP_RI_Pt_Con_1yr,
        Compare_CP_RI_Pt_Con_3yr: CP_RI_Pt_Con_3yr,
        Compare_CP_RI_Pt_Std_1yr: CP_RI_Pt_Std_1yr,
        Compare_CP_RI_Pt_Std_3yr: CP_RI_Pt_Std_3yr,
        Compare_CP_RI_All_Con_1yr: CP_RI_All_Con_1yr,
        Compare_CP_RI_All_Con_3yr: CP_RI_All_Con_3yr,
        Compare_CP_RI_All_Std_1yr: CP_RI_All_Std_1yr,
        Compare_CP_RI_All_Std_3yr: CP_RI_All_Std_3yr,
        Compare_CP_SP_Pt_CPT_3yr: CP_SP_Pt_CPT_3yr,
        Compare_CP_SP_Pt_CPT_1yr: CP_SP_Pt_CPT_1yr,
        Compare_CP_SP_No_CPT_3yr: CP_SP_No_CPT_3yr,
        Compare_CP_SP_All_CPT_3yr: CP_SP_All_CPT_3yr,
        Compare_CP_SP_No_CPT_1yr: CP_SP_No_CPT_1yr,
        Compare_CP_SP_All_CPT_1yr: CP_SP_All_CPT_1yr,
        Compare_CP_SP_Pt_EC2_3yr: CP_SP_Pt_EC2_3yr,
        Compare_CP_SP_No_EC2_3yr: CP_SP_No_EC2_3yr,
        Compare_CP_SP_Pt_EC2_1yr: CP_SP_Pt_EC2_1yr,
        Compare_CP_SP_All_EC2_3yr: CP_SP_All_EC2_3yr,
        Compare_CP_SP_All_EC2_1yr: CP_SP_All_EC2_1yr,
        Compare_CP_SP_No_EC2_1yr: CP_SP_No_EC2_1yr,
    };

    // console.log('check Reserved_All_Upfront_convertible_1yr_per_Month in calculating price: ', itemObject.Reserved_All_Upfront_convertible_1yr_per_Month);

    // console.log('check price region: ', itemObject.product.attributes.region);
    console.log('check price calculating page itemObject: ', itemObject);
    return {
        ...itemObject,
    };
};