import React from 'react';
import Layout from "../layout/appLayout";
import Tooltip from "@cloudscape-design/components/popover";
import Link from "@cloudscape-design/components/link";
import StatusIndicator from "@cloudscape-design/components/status-indicator";
import { connect } from 'react-redux';
import { removeFromCart, updateCartItemQuantity } from "../redux/actions/cartActions";
import { ondemandPrice, RIPrice } from "../common/PriceCalculating";
import { useState, useEffect } from 'react';
import * as FileSaver from "file-saver";
// import * as XLSX from 'xlsx';
import * as XLSX from "sheetjs-style";
import Select from "@cloudscape-design/components/select";
import {
  Header,
  Table,
  Container,
  SpaceBetween,
  Button,
  Input,
  TextContent,
  Box
} from '@cloudscape-design/components';
import { TunnelOptionFilterSensitiveLog } from '@aws-sdk/client-ec2';

const groupItemsByService = (items) => {
  // console.log('cartpage item check: ', items);
  return items.reduce((acc, item) => {
    if (!acc[item.ServiceName]) {
      acc[item.ServiceName] = [];
    }
    acc[item.ServiceName].push(item);
    return acc;
  }, {});
};

const groupByRegion = (items) => {
  // console.log('region:', items);
  return items.reduce((acc, item) => {
    if (!acc[item?.product?.attributes?.region]) {
      acc[item?.product?.attributes?.region] = [];
    }
    acc[item?.product?.attributes?.region].push(item);
    return acc;
  }, {});
};

const CartPage = ({ cartItems, removeFromCart, updateCartItemQuantity }) => {

  const [exportOption, setExportOption] = useState({ label: "All Option", value: "all" });
  const [exportOptionAuroraMySQL, setExportOptionAuroraMySQL] = useState({ label: "All Option", value: "all" });
  const [exportOptionAuroraPostgreSQL, setExportOptionAuroraPostgreSQL] = useState({ label: "All Option", value: "all" });
  const [exportOptionRDSforMySQL, setExportOptionRDSforMySQL] = useState({ label: "All Option", value: "all" });
  const [exportOptionRDSforPostgreSQL, setExportOptionRDSforPostgreSQL] = useState({ label: "All Option", value: "all" });
  const [exportOptionRDSforMariaDB, setExportOptionRDSforMariaDB] = useState({ label: "All Option", value: "all" });
  const [exportOptionRDSforOracle, setExportOptionRDSforOracle] = useState({ label: "All Option", value: "all" });
  const [exportOptionRDSforSQLServer, setExportOptionRDSforSQLServer] = useState({ label: "All Option", value: "all" });

  const exportOptions = [
    { label: "All Option", value: "all" },
    { label: "1yr - OnDemand + RI + Saving Plans", value: "1yr-ondemand-ri-saving" },
    { label: "1yr - OnDemand + RI", value: "1yr-ondemand-ri" },
    { label: "1yr - OnDemand + SavingPlans", value: "1yr-ondemand-saving" },
    { label: "3yr - OnDemand + RI + Saving Plans", value: "3yr-ondemand-ri-saving" },
    { label: "3yr - OnDemand + RI", value: "3yr-ondemand-ri" },
    { label: "3yr - OnDemand + SavingPlans", value: "3yr-ondemand-saving" },
  ];

  const exportOptionsAuroraMySQL = [
    { label: "All Option", value: "all" },
    { label: "1yr - OnDemand + RI", value: "1yr-ondemand-ri" },
    { label: "3yr - OnDemand + RI", value: "3yr-ondemand-ri" },
  ];

  const exportOptionsAuroraPostgreSQL = [
    { label: "All Option", value: "all" },
    { label: "1yr - OnDemand + RI", value: "1yr-ondemand-ri" },
    { label: "3yr - OnDemand + RI", value: "3yr-ondemand-ri" },
  ];

  const exportOptionsRDSforMySQL = [
    { label: "All Option", value: "all" },
    //dd more options
  ];

  const exportOptionsRDSforPostgreSQL = [
    { label: "All Option", value: "all" },
    //dd more options
  ];

  const exportOptionsRDSforMariaDB = [
    { label: "All Option", value: "all" },
    //dd more options
  ];

  const exportOptionsRDSforOracle = [
    { label: "All Option", value: "all" },
    //dd more options
  ];

  const exportOptionsRDSforSQLServer = [
    { label: "All Option", value: "all" },
    //dd more options
  ];

  const handleExportOptionChange = (event) => {
    setExportOption(event.detail.selectedOption);
  };

  const handleAuroraMySQLExportOptionChange = (event) => {
    setExportOptionAuroraMySQL(event.detail.selectedOption);
  };

  const handleAuroraPostgreSQLExportOptionChange = (event) => {
    setExportOptionAuroraPostgreSQL(event.detail.selectedOption);
  };

  const handleRDSforMySQLExportOptionChange = (event) => {
    setExportOptionRDSforMySQL(event.detail.selectedOption);
  };

  const handleRDSforPostgreSQLExportOptionChange = (event) => {
    setExportOptionRDSforPostgreSQL(event.detail.selectedOption);
  };

  const handleRDSforMariaDBExportOptionChange = (event) => {
    setExportOptionRDSforMariaDB(event.detail.selectedOption);
  };

  const handleRDSforOracleExportOptionChange = (event) => {
    setExportOptionRDSforMariaDB(event.detail.selectedOption);
  };

  const handleRDSforSQLServerExportOptionChange = (event) => {
    setExportOptionRDSforSQLServer(event.detail.selectedOption);
  };


  const [minSubtotals, setMinSubtotals] = useState([]);
  const [maxSubtotals, setMaxSubtotals] = useState([]);
  const [totalMinPricing, setTotalMinPricing] = useState(0.00);
  const [totalMaxPricing, setTotalMaxPricing] = useState(0.00);
  const groupedItems = groupItemsByService(cartItems);
  console.log('cart items: ', cartItems);
  console.log('grouped items: ', groupedItems);

  useEffect(() => {
    const newMinSubtotals = [];
    const newMaxSubtotals = [];

    Object.keys(groupedItems).forEach(ServiceName => {
      const items = groupedItems[ServiceName];
      console.log('cartpage items:', items);
      const { totalMinPrice, totalMaxPrice } = items.reduce((total, item) => {
        const { minPrice, maxPrice } = calculateItemPrice(item);
        return {
          totalMinPrice: total.totalMinPrice + minPrice,
          totalMaxPrice: total.totalMaxPrice + maxPrice,
        };
      }, { totalMinPrice: 0, totalMaxPrice: 0 });
      newMinSubtotals.push([ServiceName, totalMinPrice]);
      newMaxSubtotals.push([ServiceName, totalMaxPrice]);
    });
    setMinSubtotals(newMinSubtotals);
    setMaxSubtotals(newMaxSubtotals);
  }, [cartItems]);

  useEffect(() => {
    const totalMinPricing = minSubtotals.reduce((total, subtotal) => total + subtotal[1], 0);
    const totalMaxPricing = maxSubtotals.reduce((total, subtotal) => total + subtotal[1], 0);

    setTotalMinPricing(totalMinPricing);
    setTotalMaxPricing(totalMaxPricing);
  }, [minSubtotals, maxSubtotals]);

  const EC2ColumnDefinitions = [
    {
      id: "instanceType",
      header: "Instance Type",
      cell: item => item?.product?.attributes?.instanceType || 'N/A',
      sortingField: "instanceType",
      isRowHeader: true
    },
    {
      id: "ebsAttached",
      /* header: "EBS Attached", */
      header: (
        <div className="ebs-attached-header">
          <span>EBS Attached</span>
          <Tooltip
            content="Click on 'Yes' to see the EBS parameters"
            dismissible
            triggerType="custom"
          >
            <StatusIndicator type="info" />
          </Tooltip>
        </div>
      ),
      /* cell: item => (item?.product?.attributes?.ebsselection?.numberOfVolumes? "Yes" : "No"), */
      cell: item => {
        const hasEBS = item?.product?.attributes?.ebsselection?.numberOfVolumes;
        const ebsFilters = item?.product?.attributes?.ebsselection;

        if (!hasEBS) {
          return 'No';
        }

        const EBSTooltipContent = ({ ebsFilters }) => (
          <div>
            <p>Volume Type: {ebsFilters.volumeType}</p>
            <p>Number of Volumes: {ebsFilters.numberOfVolumes}</p>
            <p>Average Duration: {ebsFilters.averageDuration} hours</p>
            <p>Storage Amount: {ebsFilters.volumeSize.amount} {ebsFilters.volumeSize.unit}</p>
            <p>Snapshot Frequency: {ebsFilters.snapshotFrequency}</p>
            {ebsFilters.snapshotFrequency !== 'no-snapshot' && (
              <p>Amount Changed per Snapshot: {ebsFilters.amountChangedPerSnapshot.amount} {ebsFilters.amountChangedPerSnapshot.unit}</p>
            )}
            {ebsFilters.provisioningIOPS != '' && <p>Provisioning IOPS: {ebsFilters.provisioningIOPS}</p>}
            {ebsFilters.throughput && <p>Throughput: {ebsFilters.throughput} MBps</p>}
            <p>Total Price: ${ebsFilters.totalprice}</p>
          </div>
        );

        return (
          <Tooltip
            content={<EBSTooltipContent ebsFilters={ebsFilters} />}
            dismissible={true}
          >
            <span>Yes</span>
          </Tooltip>
        );
      },
      sortingField: "EBSAttached"
    },
    {
      id: "vcpu",
      header: "vCPU",
      cell: item => item?.product?.attributes?.vcpu === 'Unknown' ? 'N/A' : item?.product?.attributes?.vcpu || 'N/A',
      sortingField: "vcpu"
    },
    {
      id: "memory",
      header: "Memory",
      cell: item => item?.product?.attributes?.memory === 'Unknown' ? 'N/A' : item?.product?.attributes?.memory || 'N/A',
      sortingField: "memory"
    },
    {
      id: "gpuMemory",
      header: "GPU Memory",
      cell: item => item?.product?.attributes?.gpuMemory === 'Unknown' ? 'N/A' : item?.product?.attributes?.gpuMemory || 'N/A'
    },
    {
      id: "storage",
      header: "Storage",
      cell: item => item?.product?.attributes?.storage === 'Unknown' ? 'N/A' : item?.product?.attributes?.storage || 'N/A',
    },
    {
      id: "networkPerformance",
      header: "Network Performance",
      cell: item => item?.product?.attributes?.networkPerformance === 'Unknown' ? 'N/A' : item?.product?.attributes?.networkPerformance || 'N/A'
    },
    {
      id: "operatingSystem",
      header: "OperatingSystem",
      cell: item => item?.product?.attributes?.operatingSystem || 'N/A',
    },
    {
      id: "sku",
      header: "sku (including EBS selection)",
      cell: item => item?.sku || 'N/A',
    },
    {
      id: "ODHourlyPrice",
      header: "OnDemand hourly Price",
      cell: item => {
        console.log("Rendering OnDemand Hourly Price for item:", item); // 添加调试信息
        return parseFloat(item?.terms.OnDemand.price) || 'N/A';
      }
    },
    {
      id: "ODmonthlyPrice",
      header: "OnDemand Monthly Price",
      cell: item => {
        console.log("Rendering OnDemand Monthly Price for item:", item); // 添加调试信息
        return item?.OnDemand_1Month || 'N/A';
      }
    },
    {
      id: "RImonthlyPrice",
      header: "Reserved No Upfront Monthly Price",
      // cell: item => item?.["Reserved_No Upfront_standard_1yr_per Month"] || 'N/A'
      cell: item => item?.Reserved_No_Upfront_Monthly || 'N/A'
    },
    {
      id: "PartialUpfrontFee",
      header: "Reserved Partial Upfront Fee",
      cell: item => item?.Partial_Upfront_Fee || 'N/A'
    },
    {
      id: "RImonthlyPricePartialUpfront",
      header: "Reserved Partial Upfront Monthly Price",
      cell: item => item?.Partial_Upfront_Monthly || 'N/A'
    },
    {
      id: "RImonthlyPriceAllUpfront",
      header: "Reserved All Upfront Total Price",
      cell: item => item?.All_Upfront_Price || 'N/A'
    },
    {
      id: "SavingPlansMonthly",
      header: "Saving Plans Monthly Price",
      cell: item => item?.SavingPlans_Monthly || 'N/A'
    },
    {
      id: "quantity",
      header: "Quantity",
      cell: (item => (
        <SpaceBetween size="s">
          <Input
            type="number"
            value={item.quantity}
            onChange={event => handleInputChange(item.sku, event)}
            min="1"
          />
        </SpaceBetween>
      )),
      minWidth: 170
    },
    {
      id: "price",
      header: "price",
      cell: item => calculateItemPrice(item)
    },
    {
      id: 'delete',
      header: 'Delete',
      cell: item => (
        <Button onClick={() => handleRemoveItem(item.sku)}>
          Remove
        </Button>
      ),
      minWidth: 170
    }
  ];


  const ElastiCacheColumnDefinitions = [
    {
      id: "dataSize",
      header: "Serverless Average Cache Data Size",
      cell: item => item.averageCacheDataSize + ' GB' || 'N/A',
    },
    {
      id: "requestRate",
      header: "Serverless Average Simple Request Rate",
      cell: item => item.averageRequestRate || 'N/A',
    },
    {
      id: "dataTransferred",
      header: "Serverless Average Data Transferred Per Request",
      cell: item => item.averageDataTransferred + ' KB' || 'N/A'
    },
    {
      id: "nodes",
      header: "Nodes",
      cell: item => item.nodes || 'N/A',
    },
    {
      id: "instanceType",
      header: "Instance Type",
      cell: item => item.instanceType || 'N/A',
    },
    {
      id: "utilization",
      header: "Utilization",
      cell: item => item.valueUnit || 'N/A',
    },
    {
      id: "cacheEngine",
      header: "Cache Engine",
      cell: item => item.cacheEngine || 'N/A',
    },
    {
      id: "pricingModel",
      header: "Pricing Model",
      cell: item => item.pricingModel || 'N/A',
    },
    {
      id: "term",
      header: "Term",
      cell: item => item.term || 'N/A',
    },
    {
      id: "purchaseOption",
      header: "Purchase Option",
      cell: item => item.purchaseOption || 'N/A',
    },
    {
      id: "dataTierInstanceType",
      header: "Data Tier Instance Type",
      cell: item => item.dataTierDetails ? item.dataTierDetails.dataTierInstanceType : 'N/A',
    },
    {
      id: "dataTierNodes",
      header: "Data Tier Nodes",
      cell: item => item.dataTierDetails ? item.dataTierDetails.dataTierNodes : 'N/A',
    },
    {
      id: "dataTierValueUnit",
      header: "Data Tier Utilization",
      cell: item => item.dataTierDetails ? item.dataTierDetails.dataTierValueUnit : 'N/A',
    },
    {
      id: "dataTierPricingModel",
      header: "Data Tier Pricing Model",
      cell: item => item.dataTierDetails ? item.dataTierDetails.dataTierPricingModel : 'N/A',
    },
    {
      id: "dataTierTerm",
      header: "Data Tier Term",
      cell: item => item.dataTierDetails ? item.dataTierDetails.dataTierTerm : 'N/A',
    },
    {
      id: "dataTierPurchaseOption",
      header: "Data Tier Purchase Option",
      cell: item => item.dataTierDetails ? item.dataTierDetails.dataTierPurchaseOption : 'N/A',
    },
    {
      id: "serverlessPrice",
      header: "Serverless Price",
      cell: item => item.serverlessPrice || 'N/A',
    },
    {
      id: "monthlyPrice",
      header: "Monthly Price",
      cell: item => item.monthlyPrice || 'N/A',
    },
    {
      id: "upfrontPrice",
      header: "Upfront Price",
      cell: item => item.upfrontPrice || 'N/A',
    },
    {
      id: "dataTierMonthlyPrice",
      header: "Data Tier Monthly Price",
      cell: item => item.dataTierDetails ? item.dataTierDetails.dataTierMonthlyPrice : 'N/A',
    },
    {
      id: "dataTierUpfrontPrice",
      header: "Data Tier Upfront Price",
      cell: item => item.dataTierDetails ? item.dataTierDetails.dataTierUpfrontPrice : 'N/A',
    },
    {
      id: "quantity",
      header: "Quantity",
      cell: (item => (
        <SpaceBetween size="s">
          <Input
            type="number"
            value={item.quantity}
            onChange={event => handleInputChange(item.sku, event)}
            min="1"
          />
        </SpaceBetween>
      )),
      minWidth: 170
    },
    // {
    //   id: "price",
    //   header: "Price",
    //   cell: item => `$${item.price}` || 'N/A',
    // },
    {
      id: 'delete',
      header: 'Delete',
      cell: item => (
        <Button onClick={() => handleRemoveItem(item.sku)}>
          Remove
        </Button>
      ),
      minWidth: 170
    }
  ];

  const RedshiftColumnDefinitions = [
    {
      id: "instanceType",
      header: "Instance Type",
      cell: item => item?.product?.attributes?.instanceType || 'N/A',
      sortingField: "instanceType",
      isRowHeader: true
    },
    {
      id: "vcpu",
      header: "vCPU",
      cell: item => item?.product?.attributes?.vcpu === 'Unknown' ? 'N/A' : item?.product?.attributes?.vcpu || 'N/A',
      sortingField: "vcpu"
    },
    {
      id: "memory",
      header: "Memory",
      cell: item => item?.product?.attributes?.memory === 'Unknown' ? 'N/A' : item?.product?.attributes?.memory || 'N/A',
      sortingField: "memory"
    },
    {
      id: "storage",
      header: "Storage",
      cell: item => item?.product?.attributes?.storage === 'Unknown' ? 'N/A' : item?.product?.attributes?.storage || 'N/A',
    },
    {
      id: "sku",
      header: "sku",
      cell: item => item?.sku || 'N/A',
    },
    {
      id: "quantity",
      header: "Quantity",
      cell: (item => (
        <SpaceBetween size="s">
          <Input
            type="number"
            value={item.quantity}
            onChange={event => handleInputChange(item.sku, event)}
            min="1"
          />
        </SpaceBetween>
      )),
      minWidth: 170
    },
    {
      id: 'delete',
      header: 'Delete',
      cell: item => (
        <Button onClick={() => handleRemoveItem(item.sku)}>
          Remove
        </Button>
      ),
      minWidth: 170
    }
  ];

  const RDSColumnDefinitions = [
    {
      id: "instanceType",
      header: "Instance Type",
      cell: item => item.InstanceType,
      sortingField: "instanceType",
      isRowHeader: true
    },
    {
      id: "vcpu",
      header: "vCPU",
      cell: item => item.vCPU,
      sortingField: "vcpu"
    },
    {
      id: "memory",
      header: "Memory",
      cell: item => item.Memory,
      sortingField: "memory"
    },
    {
      id: "networkPerformance",
      header: "Network Performance",
      cell: item => item.Networkperformance,
    },
    {
      id: "deploymentOption",
      header: "Deployment Option",
      cell: item => `${item.filtervalue.nodes} Nodes ${item.DeploymentOption}`,
    },
    {
      id: "utilization",
      header: "Utilization (OnDemand Only)",
      cell: item => `${item.filtervalue.utilization.value} ${item.filtervalue.utilization.unit}`,
    },
    {
      id: "storageType",
      header: "Storage Type",
      cell: item => /* item.filtervalue.volumeType, */{
        const volumeType = item.filtervalue.volumeType;
        let storageTypeDisplay;
    
        switch (volumeType) {
          case "General Purpose":
            storageTypeDisplay = "General Purpose SSD (gp2)";
            break;
          case "General Purpose-GP3":
            storageTypeDisplay = "General Purpose SSD (gp3)";
            break;
          case "Provisioned IOPS":
            storageTypeDisplay = "Provisioned IOPS SSD (io1)";
            break;
          case "Provisioned IOPS-IO2":
            storageTypeDisplay = "Provisioned IOPS SSD (io2)";
            break;
          case "Magnetic":
            storageTypeDisplay = "Magnetic (previous generation)";
            break;
          default:
            storageTypeDisplay = volumeType || "N/A";
        }
    
        return storageTypeDisplay;
      },
    },
    {
      id: "storageSize",
      header: "Storage Size",
      cell: item => `${item.filtervalue.volumeSize.amount} ${item.filtervalue.volumeSize.unit}`,
    },
/*     {
      id: "region",
      header: "Region",
      cell: item => item.Region,
    }, */
    {
      id: "rdsProxy",
      header: "RDS Proxy",
      cell: item => item.rdsProxy,
    },
    {
      id: "provisioningIOPS",
      header: "Provisioning IOPS",
      cell: item => item.filtervalue.provisioningIOPS || 'N/A',
    },
    {
      id: "throughput",
      header: "Throughput",
      cell: item => item.filtervalue.throughput || 'N/A',
    },
    {
      id: "backupStorage",
      header: "Backup Storage (GB)",
      cell: item => item.filtervalue.backupStorage || 'N/A',
    },
    {
      id: "snapshotExport",
      header: "Snapshot Export (GB per Month)",
      cell: item => item.filtervalue.snapshotExport || 'N/A',
    },
    {
      id: "quantity",
      header: "Quantity",
      cell: (item => (
        <SpaceBetween size="s">
          <Input
            type="number"
            value={item.quantity}
            onChange={event => handleInputChange(item.sku, event)}
            min="1"
          />
        </SpaceBetween>
      )),
      minWidth: 170,
    },
    {
      id: 'delete',
      header: 'Delete',
      cell: item => (
        <Button onClick={() => handleRemoveItem(item.sku)}>
          Remove
        </Button>
      ),
      minWidth: 170
    }
  ];

  const RDSforPostgreSQLColumnDefinitions = [
    {
      id: "instanceType",
      header: "Instance Type",
      cell: item => item.InstanceType,
      sortingField: "instanceType",
      isRowHeader: true
    },
    {
      id: "vcpu",
      header: "vCPU",
      cell: item => item.vCPU,
      sortingField: "vcpu"
    },
    {
      id: "memory",
      header: "Memory",
      cell: item => item.Memory,
      sortingField: "memory"
    },
    {
      id: "networkPerformance",
      header: "Network Performance",
      cell: item => item.Networkperformance,
    },
    {
      id: "deploymentOption",
      header: "Deployment Option",
      cell: item => `${item.filtervalue.nodes} nodes ${item.DeploymentOption}`,
    },
    {
      id: "utilization",
      header: "Utilization (OnDemand Only)",
      cell: item => `${item.filtervalue.utilization.value} ${item.filtervalue.utilization.unit}`,
    },
    {
      id: "storageType",
      header: "Storage Type",
      cell: item => /* item.filtervalue.volumeType, */{
        const volumeType = item.filtervalue.volumeType;
        let storageTypeDisplay;
    
        switch (volumeType) {
          case "General Purpose":
            storageTypeDisplay = "General Purpose SSD (gp2)";
            break;
          case "General Purpose-GP3":
            storageTypeDisplay = "General Purpose SSD (gp3)";
            break;
          case "Provisioned IOPS":
            storageTypeDisplay = "Provisioned IOPS SSD (io1)";
            break;
          case "Provisioned IOPS-IO2":
            storageTypeDisplay = "Provisioned IOPS SSD (io2)";
            break;
          case "Magnetic":
            storageTypeDisplay = "Magnetic (previous generation)";
            break;
          default:
            storageTypeDisplay = volumeType || "N/A";
        }
    
        return storageTypeDisplay;
      },
    },
    {
      id: "storageSize",
      header: "Storage Size",
      cell: item => `${item.filtervalue.volumeSize.amount} ${item.filtervalue.volumeSize.unit}`,
    },
/*     {
      id: "region",
      header: "Region",
      cell: item => item.Region,
    }, */
    {
      id: "rdsProxy",
      header: "RDS Proxy",
      cell: item => item.rdsProxy,
    },
    {
      id: "provisioningIOPS",
      header: "Provisioning IOPS",
      cell: item => item.filtervalue.provisioningIOPS || 'N/A',
    },
    {
      id: "throughput",
      header: "Throughput",
      cell: item => item.filtervalue.throughput || 'N/A',
    },
    {
      id: "backupStorage",
      header: "Backup Storage (GB)",
      cell: item => item.filtervalue.backupStorage || 'N/A',
    },
    {
      id: "snapshotExport",
      header: "Snapshot Export (GB per Month)",
      cell: item => item.filtervalue.snapshotExport || 'N/A',
    },
    {
      id: "quantity",
      header: "Quantity",
      cell: (item => (
        <SpaceBetween size="s">
          <Input
            type="number"
            value={item.quantity}
            onChange={event => handleInputChange(item.sku, event)}
            min="1"
          />
        </SpaceBetween>
      )),
      minWidth: 170,
    },
    {
      id: 'delete',
      header: 'Delete',
      cell: item => (
        <Button onClick={() => handleRemoveItem(item.sku)}>
          Remove
        </Button>
      ),
      minWidth: 170
    }
  ];

  const RDSforMariaDBColumnDefinitions = [
    {
      id: "instanceType",
      header: "Instance Type",
      cell: item => item.InstanceType,
      sortingField: "instanceType",
      isRowHeader: true
    },
    {
      id: "vcpu",
      header: "vCPU",
      cell: item => item.vCPU,
      sortingField: "vcpu"
    },
    {
      id: "memory",
      header: "Memory",
      cell: item => item.Memory,
      sortingField: "memory"
    },
    {
      id: "networkPerformance",
      header: "Network Performance",
      cell: item => item.Networkperformance,
    },
    {
      id: "deploymentOption",
      header: "Deployment Option",
      cell: item => `${item.filtervalue.nodes} nodes ${item.DeploymentOption}`,
    },
    {
      id: "utilization",
      header: "Utilization (OnDemand Only)",
      cell: item => `${item.filtervalue.utilization.value} ${item.filtervalue.utilization.unit}`,
    },
    {
      id: "storageType",
      header: "Storage Type",
      cell: item => /* item.filtervalue.volumeType, */{
        const volumeType = item.filtervalue.volumeType;
        let storageTypeDisplay;
    
        switch (volumeType) {
          case "General Purpose":
            storageTypeDisplay = "General Purpose SSD (gp2)";
            break;
          case "General Purpose-GP3":
            storageTypeDisplay = "General Purpose SSD (gp3)";
            break;
          case "Provisioned IOPS":
            storageTypeDisplay = "Provisioned IOPS SSD (io1)";
            break;
          case "Provisioned IOPS-IO2":
            storageTypeDisplay = "Provisioned IOPS SSD (io2)";
            break;
          case "Magnetic":
            storageTypeDisplay = "Magnetic (previous generation)";
            break;
          default:
            storageTypeDisplay = volumeType || "N/A";
        }
    
        return storageTypeDisplay;
      },
    },
    {
      id: "storageSize",
      header: "Storage Size",
      cell: item => `${item.filtervalue.volumeSize.amount} ${item.filtervalue.volumeSize.unit}`,
    },
/*     {
      id: "region",
      header: "Region",
      cell: item => item.Region,
    }, */
    {
      id: "rdsProxy",
      header: "RDS Proxy",
      cell: item => item.rdsProxy,
    },
    {
      id: "provisioningIOPS",
      header: "Provisioning IOPS",
      cell: item => item.filtervalue.provisioningIOPS || 'N/A',
    },
    {
      id: "throughput",
      header: "Throughput",
      cell: item => item.filtervalue.throughput || 'N/A',
    },
    {
      id: "backupStorage",
      header: "Backup Storage (GB)",
      cell: item => item.filtervalue.backupStorage || 'N/A',
    },
    {
      id: "snapshotExport",
      header: "Snapshot Export (GB per Month)",
      cell: item => item.filtervalue.snapshotExport || 'N/A',
    },
    {
      id: "quantity",
      header: "Quantity",
      cell: (item => (
        <SpaceBetween size="s">
          <Input
            type="number"
            value={item.quantity}
            onChange={event => handleInputChange(item.sku, event)}
            min="1"
          />
        </SpaceBetween>
      )),
      minWidth: 170,
    },
    {
      id: 'delete',
      header: 'Delete',
      cell: item => (
        <Button onClick={() => handleRemoveItem(item.sku)}>
          Remove
        </Button>
      ),
      minWidth: 170
    }
  ];

  const RDSforOracleColumnDefinitions = [
    {
      id: "instanceType",
      header: "Instance Type",
      cell: item => item.InstanceType,
      sortingField: "instanceType",
      isRowHeader: true
    },
    {
      id: "vcpu",
      header: "vCPU",
      cell: item => item.vCPU,
      sortingField: "vcpu"
    },
    {
      id: "memory",
      header: "Memory",
      cell: item => item.Memory,
      sortingField: "memory"
    },
    {
      id: "networkPerformance",
      header: "Network Performance",
      cell: item => item.Networkperformance,
    },
    {
      id: "deploymentOption",
      header: "Deployment Option",
      cell: item => `${item.filtervalue.nodes} nodes ${item.DeploymentOption}`,
    },
    {
      id: "databaseEdition",
      header: "Database Edition",
      cell: item => item.DatabaseEdition,
    },
    {
      id: "license",
      header: "License",
      cell: item => item.License,
    },
    {
      id: "utilization",
      header: "Utilization (OnDemand Only)",
      cell: item => `${item.filtervalue.utilization.value} ${item.filtervalue.utilization.unit}`,
    },
    {
      id: "storageType",
      header: "Storage Type",
      cell: item => /* item.filtervalue.volumeType, */{
        const volumeType = item.filtervalue.volumeType;
        let storageTypeDisplay;
    
        switch (volumeType) {
          case "General Purpose":
            storageTypeDisplay = "General Purpose SSD (gp2)";
            break;
          case "General Purpose-GP3":
            storageTypeDisplay = "General Purpose SSD (gp3)";
            break;
          case "Provisioned IOPS":
            storageTypeDisplay = "Provisioned IOPS SSD (io1)";
            break;
          case "Provisioned IOPS-IO2":
            storageTypeDisplay = "Provisioned IOPS SSD (io2)";
            break;
          case "Magnetic":
            storageTypeDisplay = "Magnetic (previous generation)";
            break;
          default:
            storageTypeDisplay = volumeType || "N/A";
        }
    
        return storageTypeDisplay;
      },
    },
    {
      id: "storageSize",
      header: "Storage Size",
      cell: item => `${item.filtervalue.volumeSize.amount} ${item.filtervalue.volumeSize.unit}`,
    },
/*     {
      id: "region",
      header: "Region",
      cell: item => item.Region,
    }, */
    {
      id: "provisioningIOPS",
      header: "Provisioning IOPS",
      cell: item => item.filtervalue.provisioningIOPS || 'N/A',
    },
    {
      id: "throughput",
      header: "Throughput",
      cell: item => item.filtervalue.throughput || 'N/A',
    },
    {
      id: "backupStorage",
      header: "Backup Storage (GB)",
      cell: item => item.filtervalue.backupStorage || 'N/A',
    },
    {
      id: "quantity",
      header: "Quantity",
      cell: (item => (
        <SpaceBetween size="s">
          <Input
            type="number"
            value={item.quantity}
            onChange={event => handleInputChange(item.sku, event)}
            min="1"
          />
        </SpaceBetween>
      )),
      minWidth: 170,
    },
    {
      id: 'delete',
      header: 'Delete',
      cell: item => (
        <Button onClick={() => handleRemoveItem(item.sku)}>
          Remove
        </Button>
      ),
      minWidth: 170
    }
  ];

  const RDSforSQLServerColumnDefinitions = [
    {
      id: "instanceType",
      header: "Instance Type",
      cell: item => item.InstanceType,
      sortingField: "instanceType",
      isRowHeader: true
    },
    {
      id: "vcpu",
      header: "vCPU",
      cell: item => item.vCPU,
      sortingField: "vcpu"
    },
    {
      id: "memory",
      header: "Memory",
      cell: item => item.Memory,
      sortingField: "memory"
    },
    {
      id: "networkPerformance",
      header: "Network Performance",
      cell: item => item.Networkperformance,
    },
    {
      id: "deploymentOption",
      header: "Deployment Option",
      cell: item => `${item.filtervalue.nodes} nodes ${item.DeploymentOption}`,
    },
    {
      id: "databaseEdition",
      header: "Database Edition",
      cell: item => item.DatabaseEdition,
    },
    {
      id: "utilization",
      header: "Utilization (OnDemand Only)",
      cell: item => `${item.filtervalue.utilization.value} ${item.filtervalue.utilization.unit}`,
    },
    {
      id: "storageType",
      header: "Storage Type",
      cell: item => /* item.filtervalue.volumeType, */{
        const volumeType = item.filtervalue.volumeType;
        let storageTypeDisplay;
    
        switch (volumeType) {
          case "General Purpose":
            storageTypeDisplay = "General Purpose SSD (gp2)";
            break;
          case "General Purpose-GP3":
            storageTypeDisplay = "General Purpose SSD (gp3)";
            break;
          case "Provisioned IOPS":
            storageTypeDisplay = "Provisioned IOPS SSD (io1)";
            break;
          case "Provisioned IOPS-IO2":
            storageTypeDisplay = "Provisioned IOPS SSD (io2)";
            break;
          case "Magnetic":
            storageTypeDisplay = "Magnetic (previous generation)";
            break;
          default:
            storageTypeDisplay = volumeType || "N/A";
        }
    
        return storageTypeDisplay;
      },
    },
    {
      id: "storageSize",
      header: "Storage Size",
      cell: item => `${item.filtervalue.volumeSize.amount} ${item.filtervalue.volumeSize.unit}`,
    },
/*     {
      id: "region",
      header: "Region",
      cell: item => item.Region,
    }, */
    {
      id: "rdsProxy",
      header: "RDS Proxy",
      cell: item => item.rdsProxy,
    },
    {
      id: "provisioningIOPS",
      header: "Provisioning IOPS",
      cell: item => item.filtervalue.provisioningIOPS || 'N/A',
    },
    {
      id: "throughput",
      header: "Throughput",
      cell: item => item.filtervalue.throughput || 'N/A',
    },
    {
      id: "backupStorage",
      header: "Backup Storage (GB)",
      cell: item => item.filtervalue.backupStorage || 'N/A',
    },
    {
      id: "quantity",
      header: "Quantity",
      cell: (item => (
        <SpaceBetween size="s">
          <Input
            type="number"
            value={item.quantity}
            onChange={event => handleInputChange(item.sku, event)}
            min="1"
          />
        </SpaceBetween>
      )),
      minWidth: 170,
    },
    {
      id: 'delete',
      header: 'Delete',
      cell: item => (
        <Button onClick={() => handleRemoveItem(item.sku)}>
          Remove
        </Button>
      ),
      minWidth: 170
    }
  ];

  const SagemakerColumnDefinitions = [
    {
      id: "NumberOfDataScientists",
      header: "Number of data scientist(s)",
      cell: item => item.NumberOfDataScientists || 'N/A',
    },
    {
      id: "NumberOfStudioNotebook",
      header: "Number of Studio Notebook instances per data scientist",
      cell: item => item.NumberOfStudioNotebook || 'N/A',
    },
    {
      id: "NumberOfNotebookHours",
      header: "Studio Notebook hour(s) per day",
      cell: item => item.NumberOfNotebookHours || 'N/A'
    },
    {
      id: "NumberOfNotebookDays",
      header: "Studio Notebook day(s) per month",
      cell: item => item.NumberOfNotebookDays || 'N/A',
    },
    {
      id: "instanceType",
      header: "Instance Type",
      cell: item => item.InstanceType || 'N/A',
    },
    {
      id: "NumberOfOnDemandDataScientists",
      header: "Number of On-Demand data scientist(s)",
      cell: item => item.NumberOfOnDemandDataScientists || 'N/A',
    },
    {
      id: "NumberOfOnDemandNotebook",
      header: "Number of On-Demand Notebook instances per data scientist",
      cell: item => item.NumberOfOnDemandNotebook || 'N/A',
    },
    {
      id: "NumberOfOnDemandNotebookHours",
      header: "On-Demand Notebook hour(s) per day",
      cell: item => item.NumberOfOnDemandNotebookHours || 'N/A',
    },
    {
      id: "NumberOfOnDemandNotebookDays",
      header: "On-Demand Notebook day(s) per month",
      cell: item => item.NumberOfOnDemandNotebookDays || 'N/A',
    },
    {
      id: "OnDemandInstanceType",
      header: "On-Demand Instance Type",
      cell: item => item.OnDemandInstanceType || 'N/A',
    },
    {
      id: "ElasticInferenceSelector",
      header: "Add Elastic Inference Accelerator instances",
      cell: item => item.ElasticInferenceSelector,
    },
    {
      id: "InferenceInstanceType",
      header: "Elastic Inference Instance",
      cell: item => item.InferenceInstanceType,
    },
    {
      id: "Storage",
      header: "ML Storage Type",
      cell: item => item.Storage,
    },
    {
      id: "valueUnit",
      header: "ML Storage amount",
      cell: item => item.value + ' ' + item.unit,
    },
    {
      id: "StudioPrice",
      header: "SageMaker Studio Notebook price",
      cell: item => item.StudioPrice,
    },
    {
      id: "OnDemandNotebookPrice",
      header: "SageMake On-Demand Notebook price",
      cell: item => item.OnDemandNotebookPrice,
    },
    {
      id: "InferencePrice",
      header: "SageMaker On-Demand Inference Acceleration price",
      cell: item => item.InferencePrice || 'N/A',
    },
    {
      id: "MLStoragePrice",
      header: "SageMaker On-Demand ML Storage price",
      cell: item => item.MLStoragePrice || 'N/A',
    },
    // {
    //   id: "price",
    //   header: "Price",
    //   cell: item => `$${item.price}` || 'N/A',
    // },
    {
      id: 'delete',
      header: 'Delete',
      cell: item => (
        <Button onClick={() => handleRemoveItem(item.sku)}>
          Remove
        </Button>
      ),
      minWidth: 170
    }
  ];


  const EC2ColumnDisplay = [
    { id: "instanceType", visible: true },
    { id: "ebsAttached", visible: true },
    { id: "vcpu", visible: true },
    { id: "memory", visible: true },
    { id: "gpuMemory", visible: true },
    { id: "storage", visible: true },
    { id: "networkPerformance", visible: true },
    { id: "operatingSystem", visible: true },
    { id: "ODHourlyPrice", visible: true },
    { id: "ODmonthlyPrice", visible: false },
    { id: "RImonthlyPrice", visible: false },
    { id: "PartialUpfrontFee", visible: false },
    { id: "RImonthlyPricePartialUpfront", visible: false },
    { id: "RImonthlyPriceAllUpfront", visible: false },
    { id: "SavingPlansMonthly", visible: false },
    { id: "quantity", visible: true },
    { id: "delete", visible: true },
  ];

  const ElastiCacheColumnDisplay = [
    { id: "instanceType", visible: true },
    { id: "nodes", visible: true },
    { id: "utilization", visible: true },
    { id: "cacheEngine", visible: true },
    { id: "pricingModel", visible: true },
    { id: "term", visible: true },
    { id: "purchaseOption", visible: true },
    { id: "dataSize", visible: true },
    { id: "requestRate", visible: true },
    { id: "dataTransferred", visible: true },
    { id: "dataTierInstanceType", visible: true },
    { id: "dataTierNodes", visible: true },
    { id: "dataTierValueUnit", visible: true },
    { id: "dataTierPricingModel", visible: true },
    { id: "dataTierTerm", visible: true },
    { id: "dataTierPurchaseOption", visible: true },
    { id: "serverlessPrice", visible: true },
    { id: "monthlyPrice", visible: true },
    { id: "upfrontPrice", visible: true },
    { id: "dataTierMonthlyPrice", visible: true },
    { id: "dataTierUpfrontPrice", visible: true },
    { id: "quantity", visible: true },
    { id: "delete", visible: true },

  ];

  const RDSColumnDisplay = [
    { id: "instanceType", visible: true },
    { id: "vcpu", visible: true },
    { id: "memory", visible: true },
    { id: "networkPerformance", visible: true },
    { id: "deploymentOption", visible: true },
    { id: "utilization", visible: true },
    { id: "rdsProxy", visible: true },
    { id: "storageType", visible: true },
    { id: "storageSize", visible: true },
 /*    { id: "region", visible: true }, */
    { id: "provisioningIOPS", visible: true },
    { id: "throughput", visible: true },
    { id: "backupStorage", visible: true },
    { id: "snapshotExport", visible: true },
    { id: "quantity", visible: true },
    { id: "delete", visible: true },
  ];

  const RDSforPostgreSQLColumnDisplay = [
    { id: "instanceType", visible: true },
    { id: "vcpu", visible: true },
    { id: "memory", visible: true },
    { id: "networkPerformance", visible: true },
    { id: "deploymentOption", visible: true },
    { id: "utilization", visible: true },
    { id: "rdsProxy", visible: true },
    { id: "storageType", visible: true },
    { id: "storageSize", visible: true },
 /*    { id: "region", visible: true }, */
    { id: "provisioningIOPS", visible: true },
    { id: "throughput", visible: true },
    { id: "backupStorage", visible: true },
    { id: "snapshotExport", visible: true },
    { id: "quantity", visible: true },
    { id: "delete", visible: true },
  ];

  const RDSforMariaDBColumnDisplay = [
    { id: "instanceType", visible: true },
    { id: "vcpu", visible: true },
    { id: "memory", visible: true },
    { id: "networkPerformance", visible: true },
    { id: "deploymentOption", visible: true },
    { id: "utilization", visible: true },
    { id: "rdsProxy", visible: true },
    { id: "storageType", visible: true },
    { id: "storageSize", visible: true },
 /*    { id: "region", visible: true }, */
    { id: "provisioningIOPS", visible: true },
    { id: "throughput", visible: true },
    { id: "backupStorage", visible: true },
    { id: "snapshotExport", visible: true },
    { id: "quantity", visible: true },
    { id: "delete", visible: true },
  ];

  const RDSforOracleColumnDisplay = [
    { id: "instanceType", visible: true },
    { id: "vcpu", visible: true },
    { id: "memory", visible: true },
    { id: "networkPerformance", visible: true },
    { id: "deploymentOption", visible: true },
    { id: "license", visible: true },
    { id: "databaseEdition", visible: true },
    { id: "utilization", visible: true },
    { id: "storageType", visible: true },
    { id: "storageSize", visible: true },
 /*    { id: "region", visible: true }, */
    { id: "provisioningIOPS", visible: true },
    { id: "throughput", visible: true },
    { id: "backupStorage", visible: true },
    { id: "snapshotExport", visible: true },
    { id: "quantity", visible: true },
    { id: "delete", visible: true },
  ];

  const RDSforSQLServerColumnDisplay = [
    { id: "instanceType", visible: true },
    { id: "vcpu", visible: true },
    { id: "memory", visible: true },
    { id: "networkPerformance", visible: true },
    { id: "deploymentOption", visible: true },
    { id: "databaseEdition", visible: true },
    { id: "utilization", visible: true },
    { id: "rdsProxy", visible: true },
    { id: "storageType", visible: true },
    { id: "storageSize", visible: true },
 /*    { id: "region", visible: true }, */
    { id: "provisioningIOPS", visible: true },
    { id: "throughput", visible: true },
    { id: "backupStorage", visible: true },
    { id: "snapshotExport", visible: true },
    { id: "quantity", visible: true },
    { id: "delete", visible: true },
  ];

  const SagemakerColumnDisplay = [
    { id: "instanceType", visible: true },
    { id: "NumberOfDataScientists", visible: true },
    { id: "NumberOfStudioNotebook", visible: true },
    { id: "NumberOfNotebookHours", visible: true },
    { id: "NumberOfNotebookDays", visible: true },
    { id: "StudioPrice", visible: true },
    { id: "OnDemandInstanceType", visible: true },
    { id: "NumberOfOnDemandDataScientists", visible: true },
    { id: "NumberOfOnDemandNotebook", visible: true },
    { id: "NumberOfOnDemandNotebookHours", visible: true },
    { id: "NumberOfOnDemandNotebookDays", visible: true },
    { id: "OnDemandNotebookPrice", visible: true },
    { id: "ElasticInferenceSelector", visible: true },
    { id: "InferenceInstanceType", visible: true },
    { id: "InferencePrice", visible: true },
    { id: "Storage", visible: true },
    { id: "valueUnit", visible: true },
    { id: "MLStoragePrice", visible: true },
    { id: "delete", visible: true },

  ];


  const RedshiftColumnDisplay = [
    { id: "instanceType", visible: true },
    { id: "vcpu", visible: true },
    { id: "memory", visible: true },
    { id: "storage", visible: true },
    { id: "quantity", visible: true },
    { id: "delete", visible: true },
  ];

  const AuroraMySQLColumnDefinitions = [
    {
      id: "instanceType",
      header: "Instance Type",
      cell: item => item?.product?.attributes?.instanceType || 'N/A',
      sortingField: "instanceType",
      isRowHeader: true
    },
    {
      id: "vcpu",
      header: "vCPU",
      cell: item => item?.product?.attributes?.vcpu === 'Unknown' ? 'N/A' : item?.product?.attributes?.vcpu || 'N/A',
      sortingField: "vcpu"
    },
    {
      id: "memory",
      header: "Memory",
      cell: item => item?.product?.attributes?.memory === 'Unknown' ? 'N/A' : item?.product?.attributes?.memory || 'N/A',
      sortingField: "memory"
    },
    {
      id: "networkPerformance",
      header: "Network Performance",
      cell: item => item?.product?.attributes?.networkPerformance === 'Unknown' ? 'N/A' : item?.product?.attributes?.networkPerformance || 'N/A'
    },
    {
      id: "sku",
      header: "sku",
      cell: item => item?.sku || 'N/A',
    },
    {
      id: "quantity",
      header: "Quantity",
      cell: (item => (
        <SpaceBetween size="s">
          <Input
            type="number"
            value={item.quantity}
            onChange={event => handleInputChange(item.sku, event)}
            min="1"
          />
        </SpaceBetween>
      )),
      minWidth: 170
    },
    {
      id: 'delete',
      header: 'Delete',
      cell: item => (
        <Button onClick={() => handleRemoveItem(item.sku)}>
          Remove
        </Button>
      ),
      minWidth: 170
    }
  ];

  const AuroraMySQLColumnDisplay = [
    { id: "instanceType", visible: true },
    { id: "vcpu", visible: true },
    { id: "memory", visible: true },
    { id: "networkPerformance", visible: true },
    { id: "quantity", visible: true },
    { id: "delete", visible: true },
  ];

  const AuroraPostgreSQLColumnDefinitions = [
    {
      id: "instanceType",
      header: "Instance Type",
      cell: item => item?.product?.attributes?.instanceType || 'N/A',
      sortingField: "instanceType",
      isRowHeader: true
    },
    {
      id: "vcpu",
      header: "vCPU",
      cell: item => item?.product?.attributes?.vcpu === 'Unknown' ? 'N/A' : item?.product?.attributes?.vcpu || 'N/A',
      sortingField: "vcpu"
    },
    {
      id: "memory",
      header: "Memory",
      cell: item => item?.product?.attributes?.memory === 'Unknown' ? 'N/A' : item?.product?.attributes?.memory || 'N/A',
      sortingField: "memory"
    },
    {
      id: "networkPerformance",
      header: "Network Performance",
      cell: item => item?.product?.attributes?.networkPerformance === 'Unknown' ? 'N/A' : item?.product?.attributes?.networkPerformance || 'N/A'
    },
    {
      id: "sku",
      header: "sku",
      cell: item => item?.sku || 'N/A',
    },
    {
      id: "quantity",
      header: "Quantity",
      cell: (item => (
        <SpaceBetween size="s">
          <Input
            type="number"
            value={item.quantity}
            onChange={event => handleInputChange(item.sku, event)}
            min="1"
          />
        </SpaceBetween>
      )),
      minWidth: 170
    },
    {
      id: 'delete',
      header: 'Delete',
      cell: item => (
        <Button onClick={() => handleRemoveItem(item.sku)}>
          Remove
        </Button>
      ),
      minWidth: 170
    }
  ];

  const AuroraPostgreSQLColumnDisplay = [
    { id: "instanceType", visible: true },
    { id: "vcpu", visible: true },
    { id: "memory", visible: true },
    { id: "networkPerformance", visible: true },
    { id: "quantity", visible: true },
    { id: "delete", visible: true },
  ];

  const calculateItemPrice = (item) => {
    console.log("Calculating price for item:", item);
    let minPrice = Number.MAX_VALUE;
    let maxPrice = 0;

    // // 计算OnDemand价格的年总价
    // if (item.OnDemand_1Month && typeof item.OnDemand_1Month === 'string') {
    //   const pricePerMonth = parseFloat(item.OnDemand_1Month.split(' ')[0]);
    //   const pricePerYear = pricePerMonth * 12;
    //   item.OnDemand_1Year = (pricePerYear * item.quantity).toFixed(2);
    //   maxPrice = pricePerYear * item.quantity;
    // }

    // 计算Reserved价格的年总价
    if (item.terms && item.terms.Reserved) {
      Object.keys(item).forEach(key => {
        if (key.startsWith('Reserved_')) {
          const reservedPrice = parseFloat(item[key]);
          if (!isNaN(reservedPrice)) {
            const leaseLength = key.includes('1yr') ? 1 : key.includes('3yr') ? 3 : 1;
            const reservedAnnualPrice = reservedPrice * (leaseLength === 1 ? 12 : 36) / leaseLength;
            minPrice = Math.min(minPrice, reservedAnnualPrice * item.quantity);
          }
        }
      });
    }

    // 处理Partial Upfront的年总价
    if (item.Partial_Upfront_Monthly && item.Partial_Upfront_Fee) {
      const partialUpfrontMonthlyPrice = parseFloat(item.Partial_Upfront_Monthly.split(' ')[0]) * 12;
      const partialUpfrontFee = parseFloat(item.Partial_Upfront_Fee.split(' ')[0]);
      const partialUpfrontTotalPrice = partialUpfrontMonthlyPrice + partialUpfrontFee;
      maxPrice = Math.max(maxPrice, partialUpfrontTotalPrice * item.quantity);
    }

    // 处理All Upfront的总价
    if (item.All_Upfront_Price) {
      const allUpfrontPrice = parseFloat(item.All_Upfront_Price.split(' ')[0]);
      maxPrice = Math.max(maxPrice, allUpfrontPrice * item.quantity);
    }

    if (minPrice === Number.MAX_VALUE) {
      minPrice = maxPrice;
    }

    console.log("Calculated prices:", { minPrice, maxPrice });
    return { minPrice, maxPrice };
  };

  const handleQuantityChange = (sku, quantity) => {
    const validatedQuantity = Math.max(1, parseInt(quantity, 10));
    // dispatch(updateCartItemQuantity(sku, validatedQuantity));
    updateCartItemQuantity(sku, validatedQuantity);
  };

  const handleInputChange = (sku, event) => {
    const quantity = event.detail.value;
    if (!isNaN(quantity) && quantity !== '') {
      handleQuantityChange(sku, quantity);
    }
  };

  const handleRemoveItem = (sku) => {
    removeFromCart(sku);
  }

  const handleClearCart = () => {
    removeFromCart("");
    setMaxSubtotals([]);
    setMinSubtotals([]);
  };

  const generateColumnDefinitions = (ServiceName) => {
    switch (ServiceName) {
      case "EC2":
        return EC2ColumnDefinitions;
      case "elastiCache":
        return ElastiCacheColumnDefinitions;
      case "Redshift":
        return RedshiftColumnDefinitions;
      case "AuroraMySQL":
        return AuroraMySQLColumnDefinitions;
      case "AuroraPostgreSQL":
        return AuroraPostgreSQLColumnDefinitions;
      case "RDSforMySQL":
        return RDSColumnDefinitions;
      case "RDSforPostgreSQL":
        return RDSforPostgreSQLColumnDefinitions;
      case "RDSforMariaDB":
        return RDSforMariaDBColumnDefinitions;
      case "RDSforOracle":
        return RDSforOracleColumnDefinitions;
      case "RDSforSQLServer":
        return RDSforSQLServerColumnDefinitions;
      case "SageMaker":
        return SagemakerColumnDefinitions
      default:
        return []; // Default or a generic set of columns if no specific ones are found
    }
  };

  const generateColumnDisplay = (ServiceName) => {
    switch (ServiceName) {
      case "EC2":
        return EC2ColumnDisplay;
      case "elastiCache":
        return ElastiCacheColumnDisplay;
      case "Redshift":
        return RedshiftColumnDisplay;
      case "AuroraMySQL":
        return AuroraMySQLColumnDisplay;
      case "AuroraPostgreSQL":
        return AuroraPostgreSQLColumnDisplay;
      case "RDSforMySQL":
        return RDSColumnDisplay;
      case "RDSforPostgreSQL":
        return RDSforPostgreSQLColumnDisplay;
      case "RDSforMariaDB":
        return RDSforMariaDBColumnDisplay;
      case "RDSforOracle":
        return RDSforOracleColumnDisplay;
      case "RDSforSQLServer":
        return RDSforSQLServerColumnDisplay;
      case "SageMaker":
        return SagemakerColumnDisplay;
      default:
        return []; // Default or a generic set of columns if no specific ones are found
    }
  };

  // Generate tables for each group
  const renderTables = () => {
    return Object.keys(groupedItems).map(ServiceName => {
      const items = groupedItems[ServiceName];
      const subtotalIndex = minSubtotals.findIndex(entry => entry[0] === ServiceName);
      const totalMinPrice = subtotalIndex !== -1 ? minSubtotals[subtotalIndex][1] : 0;
      const totalMaxPrice = subtotalIndex !== -1 ? maxSubtotals[subtotalIndex][1] : 0;

      let selectedExportOption, handleOptionChange, exportOptionsList;

      if (ServiceName === "EC2") {
        selectedExportOption = exportOption;
        handleOptionChange = handleExportOptionChange;
        exportOptionsList = exportOptions;
      } else if (ServiceName === "AuroraMySQL") {
        selectedExportOption = exportOptionAuroraMySQL;
        handleOptionChange = handleAuroraMySQLExportOptionChange;
        exportOptionsList = exportOptionsAuroraMySQL;
      } else if (ServiceName === "AuroraPostgreSQL") {
        selectedExportOption = exportOptionAuroraPostgreSQL;
        handleOptionChange = handleAuroraPostgreSQLExportOptionChange;
        exportOptionsList = exportOptionsAuroraPostgreSQL;
      } else if (ServiceName === "RDSforMySQL") {
        selectedExportOption = exportOptionRDSforMySQL;
        handleOptionChange = handleRDSforMySQLExportOptionChange;
        exportOptionsList = exportOptionsRDSforMySQL;
      } else if (ServiceName === "RDSforPostgreSQL") {
        selectedExportOption = exportOptionRDSforPostgreSQL;
        handleOptionChange = handleRDSforPostgreSQLExportOptionChange;
        exportOptionsList = exportOptionsRDSforPostgreSQL;
      } else if (ServiceName === "RDSforMariaDB") {
        selectedExportOption = exportOptionRDSforMariaDB;
        handleOptionChange = handleRDSforMariaDBExportOptionChange;
        exportOptionsList = exportOptionsRDSforMariaDB;
      } else if (ServiceName === "RDSforOracle") {
        selectedExportOption = exportOptionRDSforOracle;
        handleOptionChange = handleRDSforOracleExportOptionChange;
        exportOptionsList = exportOptionsRDSforOracle;
      } else if (ServiceName === "RDSforSQLServer") {
        selectedExportOption = exportOptionRDSforSQLServer;
        handleOptionChange = handleRDSforSQLServerExportOptionChange;
        exportOptionsList = exportOptionsRDSforSQLServer;
      }

      if (ServiceName === "EC2") {
        const regions = groupByRegion(items); 
        console.log('region:',regions);
        return  (
            <Container key={`${ServiceName}`}>
            <Header
            variant="h2"
            actions={
                <SpaceBetween direction="horizontal" size="s">
                  <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', textAlign: 'center' }}>
                    <span>Export Option:</span>
                  </div>
                  <Select
                    selectedOption={selectedExportOption}
                    onChange={handleOptionChange}
                    options={exportOptionsList}
                    selectedAriaLabel="Selected"
                  />
                </SpaceBetween>
            }
          >
            {ServiceName}
          </Header>
              <Box margin={{ bottom: 's' }} color="text-body-secondary">
                  Notes: Some instances in the China Region do not offer saving plans.
              </Box>
              { Object.keys(regions).map((region) => (
                <React.Fragment key={`${ServiceName}-${region}`}>
                    <Header variant="h4" description={`Items for ${ServiceName} in ${region}`}>
                        {region}
                    </Header>
                    <Table
                        columnDefinitions={generateColumnDefinitions(ServiceName)}
                        columnDisplay={generateColumnDisplay(ServiceName)}
                        items={regions[region]}
                        trackBy="sku"
                        contentDensity="compact"
                    />
                </React.Fragment>
            ))}
            </Container>
          );
      }


      return (
        <Container key={ServiceName}>
          <Header
            variant="h2"
            description={`Items for ${ServiceName}`}
            actions={
              (ServiceName === "EC2" || ServiceName === "AuroraMySQL" || ServiceName === "AuroraPostgreSQL" || ServiceName === "RDSforMySQL" || ServiceName === "RDSforPostgreSQL" || ServiceName === "RDSforMariaDB" || ServiceName === "RDSforOracle" || ServiceName === "RDSforSQLServer") && (
                <SpaceBetween direction="horizontal" size="s">
                  <div style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', textAlign: 'center' }}>
                    <span>Export Option:</span>
                  </div>
                  <Select
                    selectedOption={selectedExportOption}
                    onChange={handleOptionChange}
                    options={exportOptionsList}
                    selectedAriaLabel="Selected"
                  />
                </SpaceBetween>
              )
            }
          >
            {ServiceName}
          </Header>
          {ServiceName === "EC2" && (
            <Box margin={{ bottom: 's' }} color="text-body-secondary">
              Notes: Some instances in the China Region do not offer saving plans.
            </Box>
          )}
          <Table
            columnDefinitions={generateColumnDefinitions(ServiceName)}
            columnDisplay={generateColumnDisplay(ServiceName)}
            items={items}
            trackBy="sku"
            contentDensity="compact"
          />
        </Container>
      );
    });
  };

  const handleExport = () => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ec2Items = groupedItems["EC2"] || [];
    const elastiCacheItems = groupedItems["elastiCache"] || [];
    const redshiftItems = groupedItems["Redshift"] || [];
    const AuroraMySQLItems = groupedItems["AuroraMySQL"] || [];
    const AuroraPostgreSQLItems = groupedItems["AuroraPostgreSQL"] || [];
    const RDSforMySQLItems = groupedItems["RDSforMySQL"] || [];
    const RDSforPostgreSQLItems = groupedItems["RDSforPostgreSQL"] || [];
    const RDSforMariaDBItems = groupedItems["RDSforMariaDB"] || [];
    const RDSforOracleItems = groupedItems["RDSforOracle"] || [];
    const RDSforSQLServerItems = groupedItems["RDSforSQLServer"] || [];
    const SagemakerItems = groupedItems["SageMaker"] || [];
    console.log('export redshift items: ', redshiftItems);
    let exportWorksheet = "";
    let exportWorkSheetAuroraMySQL = "";
    let exportWorkSheetAuroraPostgreSQL = "";
    let exportWorkSheetRDSforMySQL = "";
    let exportWorkSheetRDSforPostgreSQL = "";
    let exportWorkSheetRDSforMariaDB = "";
    let exportWorkSheetRDSforOracle = "";
    let exportWorkSheetRDSforSQLServer = "";

    const selectedOption = exportOption.value;
    const selectedOptionAuroraMySQL = exportOptionAuroraMySQL.value;
    const selectedOptionAuroraPostgreSQL = exportOptionAuroraPostgreSQL.value;
    const selectedOptionRDSforMySQL = exportOptionRDSforMySQL.value;
    const selectedOptionRDSforPostgreSQL = exportOptionRDSforPostgreSQL.value;
    const selectedOptionRDSforMariaDB = exportOptionRDSforMariaDB.value;
    const selectedOptionRDSforOracle = exportOptionRDSforOracle.value;
    const selectedOptionRDSforSQLServer = exportOptionRDSforSQLServer.value;

    // const ebsFilters = item?.product?.attributes?.ebsselection;
    const wsData = [
      ["报价单"],
      ['地区', '服务', '实例类型', '配置', '网络', '数量', '按需单价（月）', '按需单价（年）', 'EBS总价（年）', '标准一年RI', '', '', '', '', '', '', '', '', '', '可转换一年RI', '', '', '', '', '', '', '', '', '', '标准三年RI', '', '', '', '', '', '', '', '', '', '可转换三年RI', '', '', '', '', '', '', '', '', '', '计算一年Saving Plan', '', '', '', '', '', '', '', '', '', '实例一年Saving Plan', '', '', '', '', '', '', '', '', '', '计算三年Saving Plan', '', '', '', '', '', '', '', '', '', '实例三年Saving Plan', '', '', '', '', '', '', '', '', '', '按需单价（时）'],
      ['', '', '', '', '', '', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', '单价（月）', '预付费用（年）', '总价（年）= 单价（月）x 12 + 预付费用（年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）= 单价（月）x 12 + 预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）= 单价（月）x 12 + 预付费用（年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）= 单价（月）x 12 + 预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）= 单价（月）x 12 + 预付费用（年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）= 单价（月）x 12 + 预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）= 单价（月）x 12 + 预付费用（年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）= 单价（月）x 12 + 预付费用（年）', '对比按需实例',
        '单价（月）', '预付费用（年）', '总价（年）= 单价（月）x 12 + 预付费用（年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）= 单价（月）x 12 + 预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）= 单价（月）x 12 + 预付费用（年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）= 单价（月）x 12 + 预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）= 单价（月）x 12 + 预付费用（年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）= 单价（月）x 12 + 预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）= 单价（月）x 12 + 预付费用（年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）= 单价（月）x 12 + 预付费用（年）', '对比按需实例', ''],
    ];

    const elastiCacheData = [
      ["ElastiCache"],
      ['Serverless Settings', '', '', '', 'Cluster Settings', '', '', '', '', '', '', '', '', 'Data Tier Cluster Settings'],
      ['Average Cache Data Size', 'Average Simple Request Rate', 'Average Data Transferred Per Request', 'Price', 'Instance Type', 'Nodes', 'Utilization', 'Cache Engine', 'Pricing Model', 'Term', 'Purchase Option', 'Monthly Price', 'Upfront Price',
        'Instance Type', 'Nodes', 'Utilization', 'Pricing Model', 'Term', 'Purchase Option', 'Monthly Price', 'Upfront Price'],
      [],
      ...elastiCacheItems.map(item => [
        item.averageCacheDataSize,
        item.averageRequestRate,
        item.averageDataTransferred,
        item.serverlessPrice,
        item.instanceType,
        item.nodes,
        item.valueUnit,
        item.cacheEngine,
        item.pricingModel,
        item.term,
        item.purchaseOption,
        item.monthlyPrice,
        item.upfrontPrice,
        item.dataTierDetails ? item.dataTierDetails.dataTierInstanceType : 'N/A',
        item.dataTierDetails ? item.dataTierDetails.dataTierNodes : 'N/A',
        item.dataTierDetails ? item.dataTierDetails.dataTierValueUnit : 'N/A',
        item.dataTierDetails ? item.dataTierDetails.pricingModel : 'N/A',
        item.dataTierDetails ? item.dataTierDetails.dataTierTerm : 'N/A',
        item.dataTierDetails ? item.dataTierDetails.dataTierPurchaseOption : 'N/A',
        item.dataTierDetails ? item.dataTierDetails.dataTierMonthlyPrice : 'N/A',
        item.dataTierDetails ? item.dataTierDetails.dataTierUpfrontPrice : 'N/A',
      ]),
    ];

    const SagemakerData = [
      ["SageMaker"],
      ['SageMaker Stuodio Notebook', '', '', '', '', '', 'SageMaker On0Demand Notebook Instances', '', '', '', '', '', '', '', '', '', '', ''],
      ['Number of data scientist(s)', 'Number of Studio Notebook instances per data scientist', 'Studio Notebook hour(s) per day', 'Studio Notebook day(s) per month', 'Instance Type', 'SageMake Studio Notebooks Price', 'Number of data scientist(s)', 'Number of On-Demand Notebook instances per data scientist', 'On-Demand Notebook hour(s) per day', 'On-Demand Notebook day(s) per month', 'Instance Type', 'SageMaker On-Demand Notebook Price', 'Add Elastic Inference Accelerator instances', 'Elastic Inference Instance Type', 'Elastic Inference Instance Price',
        'ML Storage Type', 'ML Storage Amount', 'ML Storage Price'],
      [],
      ...SagemakerItems.map(item => [
        item.NumberOfDataScientists,
        item.NumberOfStudioNotebook,
        item.NumberOfNotebookHours,
        item.NumberOfNotebookDays,
        item.InstanceType,
        item.StudioPrice,
        item.NumberOfOnDemandDataScientists,
        item.NumberOfOnDemandNotebook,
        item.NumberOfOnDemandNotebookHours,
        item.NumberOfOnDemandNotebookDays,
        item.OnDemandInstanceType,
        item.OnDemandNotebookPrice,
        item.ElasticInferenceSelector,
        item.InferenceInstanceType,
        item.InferencePrice,
        item.Storage,
        item.value + ' ' + item.unit,
        item.MLStoragePrice,
      ]),
    ];


    const RedshiftData = [
      ["报价单 Redshift"],
      ['地区', '服务', '实例类型', '配置', '数量', '按需单价（月）', '无服务器单价（月）', '预留一年RI', '', '', '', '', '', '', '', '', '', '预留三年RI', '', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', ''],
      ['', '', '', '', '', '', '', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例'],
      ...redshiftItems.map(item => [
        item.product.attributes.region,
        item.ServiceName,
        item.product.attributes.instanceType,
        `${item.product.attributes.vcpu || ''}vCPU ${item.product.attributes.memory || ''}`,
        item.quantity,
        item.OnDemand_1Month * item.quantity || 0.0,
        item.ServerlessPrice * item.quantity || 0.0,
        item.Reserved_No_Upfront_1yr_per_Month * item.quantity || 0.0,  //1无
        item.UF_Reserved_No_Upfront_1yr * item.quantity || 0.0,
        (item.Reserved_No_Upfront_1yr_per_Month * 12 * item.quantity).toFixed(2) || 0.0,
        item.Compare_CP_RI_No_1yr || 0.0, // 1no
        item.Reserved_All_Upfront_1yr_per_Month * 12 * item.quantity || 0.0,
        item.Compare_CP_RI_All_1yr || 0.0,
        item.Reserved_Partial_Upfront_1yr_per_Month * item.quantity || 0.0, // 1部分
        item.UF_Reserved_Partial_Upfront_1yr * item.quantity || 0.0,
        (item.Reserved_Partial_Upfront_1yr_per_Month * 12 * item.quantity).toFixed(2) || 0.0,
        item.Compare_CP_RI_Pt_1yr || 0.0,
        item.Reserved_No_Upfront_3yr_per_Month * item.quantity || 0.0, //3无
        item.UF_Reserved_No_Upfront_3yr * item.quantity || 0.0,
        (item.Reserved_No_Upfront_3yr_per_Month * 12 * 3 * item.quantity).toFixed(2) || 0.0,
        item.Compare_CP_RI_No_3yr || 0.0,
        item.Reserved_All_Upfront_3yr_per_Month * 12 * 3 * item.quantity || 0.0,
        item.Compare_CP_RI_All_3yr || 0.0,
        item.Reserved_Partial_Upfront_3yr_per_Month * item.quantity || 0.0, //3部分
        item.UF_Reserved_Partial_Upfront_3yr * item.quantity || 0.0,
        (item.Reserved_Partial_Upfront_3yr_per_Month * 12 * 3 * item.quantity).toFixed(2) || 0.0,
        item.Compare_CP_RI_Pt_3yr || 0.0,
      ]),
    ];

    const AuroraMySQLData = [
      ["报价单 AuroraMySQL"],
      ['地区', '服务', '实例类型', '配置', '数量', '按需单价（月）', '无服务器单价（月）', 'RDS 代理', '数据库存储', '预留一年RI', '', '', '', '', '', '', '', '', '', '预留三年RI', '', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', ''],
      ['', '', '', '', '', '', '', '', '', '单价（月）', '预付费用（年）', '总价（1年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（1年）', '对比按需实例', '单价（月）', '预付费用（3年）', '总价（3年）', '对比按需实例', '预付费用（3年）', '对比按需实例', '单价（月）', '预付费用（3年）', '总价（3年）', '对比按需实例'],
      ...AuroraMySQLItems.map(item => [
        item.product.attributes.region,
        item.ServiceName,
        item.product.attributes.instanceType,
        `${item.product.attributes.vcpu || 'N/A '}vCPU ${item.product.attributes.memory || ''} ${item.product.attributes.clusterConfig || ''}`,
        item.quantity,
        item.OnDemand_1Month * item.quantity || 0.0,
        item.ServerlessPrice * item.quantity || 0.0,
        item.RDS_Proxy_Price * item.quantity || 0.0,
        item.Total_Storage_Cost * item.quantity || 0.0,
        item.Reserved_No_Upfront_1yr_per_Month * item.quantity || 0.0,  //1无
        item.UF_Reserved_No_Upfront_1yr * item.quantity || 0.0,
        (item.Reserved_No_Upfront_1yr_per_Month * 12 * item.quantity).toFixed(2) || 0.0,
        item.Compare_CP_RI_No_1yr || 0.0, // 1no
        item.Reserved_All_Upfront_1yr_per_Month * 12 * item.quantity || 0.0,
        item.Compare_CP_RI_All_1yr || 0.0,
        item.Reserved_Partial_Upfront_1yr_per_Month * item.quantity || 0.0, // 1部分
        item.UF_Reserved_Partial_Upfront_1yr * item.quantity || 0.0,
        (item.Reserved_Partial_Upfront_1yr_per_Month * 12 * item.quantity).toFixed(2) || 0.0,
        item.Compare_CP_RI_Pt_1yr || 0.0,
        item.Reserved_No_Upfront_3yr_per_Month * item.quantity || 0.0, //3无
        item.UF_Reserved_No_Upfront_3yr * item.quantity || 0.0,
        (item.Reserved_No_Upfront_3yr_per_Month * 12 * 3 * item.quantity).toFixed(2) || 0.0,
        item.Compare_CP_RI_No_3yr || 0.0,
        item.Reserved_All_Upfront_3yr_per_Month * 12 * 3 * item.quantity || 0.0,
        item.Compare_CP_RI_All_3yr || 0.0,
        item.Reserved_Partial_Upfront_3yr_per_Month * item.quantity || 0.0, //3部分
        item.UF_Reserved_Partial_Upfront_3yr * item.quantity || 0.0,
        (item.Reserved_Partial_Upfront_3yr_per_Month * 12 * 3 * item.quantity).toFixed(2) || 0.0,
        item.Compare_CP_RI_Pt_3yr || 0.0,
      ]),
    ];

    const AuroraPostgreSQLData = [
      ["报价单 AuroraPostgreSQL"],
      ['地区', '服务', '实例类型', '配置', '数量', '按需单价（月）', '无服务器单价（月）', 'RDS 代理', '数据库存储', '预留一年RI', '', '', '', '', '', '', '', '', '', '预留三年RI', '', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', ''],
      ['', '', '', '', '', '', '', '', '', '单价（月）', '预付费用（年）', '总价（1年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（1年）', '对比按需实例', '单价（月）', '预付费用（3年）', '总价（3年）', '对比按需实例', '预付费用（3年）', '对比按需实例', '单价（月）', '预付费用（3年）', '总价（3年）', '对比按需实例'],
      ...AuroraPostgreSQLItems.map(item => [
        item.product.attributes.region,
        item.ServiceName,
        item.product.attributes.instanceType,
        `${item.product.attributes.vcpu || 'N/A '}vCPU ${item.product.attributes.memory || ''} ${item.product.attributes.clusterConfig || ''}`,
        item.quantity,
        item.OnDemand_1Month * item.quantity || 0.0,
        item.ServerlessPrice * item.quantity || 0.0,
        item.RDS_Proxy_Price * item.quantity || 0.0,
        item.Total_Storage_Cost * item.quantity || 0.0,
        item.Reserved_No_Upfront_1yr_per_Month * item.quantity || 0.0,  //1无
        item.UF_Reserved_No_Upfront_1yr * item.quantity || 0.0,
        (item.Reserved_No_Upfront_1yr_per_Month * 12 * item.quantity).toFixed(2) || 0.0,
        item.Compare_CP_RI_No_1yr || 0.0, // 1no
        item.Reserved_All_Upfront_1yr_per_Month * 12 * item.quantity || 0.0,
        item.Compare_CP_RI_All_1yr || 0.0,
        item.Reserved_Partial_Upfront_1yr_per_Month * item.quantity || 0.0, // 1部分
        item.UF_Reserved_Partial_Upfront_1yr * item.quantity || 0.0,
        (item.Reserved_Partial_Upfront_1yr_per_Month * 12 * item.quantity).toFixed(2) || 0.0,
        item.Compare_CP_RI_Pt_1yr || 0.0,
        item.Reserved_No_Upfront_3yr_per_Month * item.quantity || 0.0, //3无
        item.UF_Reserved_No_Upfront_3yr * item.quantity || 0.0,
        (item.Reserved_No_Upfront_3yr_per_Month * 12 * 3 * item.quantity).toFixed(2) || 0.0,
        item.Compare_CP_RI_No_3yr || 0.0,
        item.Reserved_All_Upfront_3yr_per_Month * 12 * 3 * item.quantity || 0.0,
        item.Compare_CP_RI_All_3yr || 0.0,
        item.Reserved_Partial_Upfront_3yr_per_Month * item.quantity || 0.0, //3部分
        item.UF_Reserved_All_Upfront_3yr * item.quantity || 0.0,
        (item.Reserved_Partial_Upfront_3yr_per_Month * 12 * 3 * item.quantity).toFixed(2) || 0.0,
        item.Compare_CP_RI_Pt_3yr || 0.0,
      ]),
    ];

    //Function for switching the right storage display
    function getStorageTypeDisplay(volumeType) {
      switch (volumeType) {
        case "General Purpose":
          return "General Purpose SSD (gp2)";
        case "General Purpose-GP3":
          return "General Purpose SSD (gp3)";
        case "Provisioned IOPS":
          return "Provisioned IOPS SSD (io1)";
        case "Provisioned IOPS-IO2":
          return "Provisioned IOPS SSD (io2)";
        case "Magnetic":
          return "Magnetic (previous generation)";
        default:
          return volumeType || "N/A";
      }
    }

    //Create the Export for full RDSforMySQL
    const RDSforMySQLData = [
      ["报价单 RDS for MySQL"],
      ['地区', '服务', '实例类型', '配置', '部署选项', '利用率', '数量', 'RDS 代理', '数据库存储', '备用数据库存储 (GB)', '快照 (GB per Month)', '按需单价（月）', '预留一年RI', '', '', '', '', '', '', '', '', '', '预留三年RI', '', '', '', '', '', '', ''], // Update this line
      ['', '', '', '', '', '', '', '', '', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '','','全预付', '', '部分预付', '', '',''], // Update this line
      ['', '', '', '', '', '', '', '', '', '', '', '', '单价（月）', '预付费用（年）', '总价（1年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（1年）', '对比按需实例', '预付费用（3年）', '对比按需实例', '单价（月）', '预付费用（3年）', '总价（3年）', '对比按需实例'],
      ...RDSforMySQLItems.map(item => [
       item.Region,
       item.ServiceName,
       item.InstanceType,
       `${item.vCPU || 'N/A '}vCPU ${item.Memory || ''} ${item.InstanceFamily || ''}`,
       `${item.filtervalue.nodes}Nodes ${item.DeploymentOption}`,
       `${item.filtervalue.utilization.value} ${item.filtervalue.utilization.unit}`,
       item.quantity,
       item.rdsProxy, /* item.RDSProxyCost * item.quantity || 0.0, */
       `${getStorageTypeDisplay(item.filtervalue.volumeType)} ${item.filtervalue.volumeSize.amount} ${item.filtervalue.volumeSize.unit}`,/* item.totalmonthlystoragecost * item.quantity || 0.0, */
       item.filtervalue.backupStorage || 'N/A',/* item.backupStorageCost * item.quantity || 0.0, */
       item.filtervalue.snapshotExport || 'N/A', /* item.snapshotExportCost * item.quantity || 0.0, */
       item.OndemandTotalMonthlyCost * item.quantity || 0.0,
       item.Reserved1yrNoUpfrontTotalMonthlyCost * item.quantity || 0.0,
       item.reserved1yrNoUpfrontUpfrontPrice * item.quantity || 0.0,
       (item.Reserved1yrNoUpfrontTotalAnnualCost * item.quantity).toFixed(2) || 0.0,
       (item.Reserved1yrNoUpfrontTotalMonthlyCost/item.OndemandTotalMonthlyCost).toFixed(2) || 0.0,
       item.Reserved1yrAllUpfrontTotalAnnualCost * item.quantity || 0.0,
      ((item.Reserved1yrAllUpfrontTotalAnnualCost/12)/item.OndemandTotalMonthlyCost).toFixed(2) || 0.0,
      item.Reserved1yrPartialUpfrontTotalMonthlyCost * item.quantity || 0.0,
      parseFloat(item.reserved1yrPartialUpfrontFee) * item.quantity || 0.0,
      item.Reserved1yrPartialUpfrontTotalAnnualCost * item.quantity || 0.0,
      ((item.Reserved1yrPartialUpfrontTotalAnnualCost/12)/item.OndemandTotalMonthlyCost).toFixed(2) || 0.0,
      item.Reserved3yrAllUpfrontTotalAnnualCost * 3 * item.quantity || 0.0,
      ((item.Reserved3yrAllUpfrontTotalAnnualCost/12)/item.OndemandTotalMonthlyCost).toFixed(2) || 0.0,
      item.Reserved3yrPartialUpfrontTotalMonthlyCost * item.quantity || 0.0,
      parseFloat(item.reserved3yrPartialUpfrontFee) * item.quantity || 0.0,
      item.Reserved3yrPartialUpfrontTotalAnnualCost * 3 * item.quantity || 0.0,
      ((item.Reserved3yrPartialUpfrontTotalAnnualCost/12)/item.OndemandTotalMonthlyCost).toFixed(2) || 0.0,
      ])

    ];

    //Create the Export for full RDSforPostgreSQL
    const RDSforPostgreSQLData = [
      ["报价单 RDS for PostgreSQL"],
      ['地区', '服务', '实例类型', '配置', '部署选项', '利用率', '数量', 'RDS 代理', '数据库存储', '备用数据库存储 (GB)', '快照 (GB per Month)', '按需单价（月）', '预留一年RI', '', '', '', '', '', '', '', '', '', '预留三年RI', '', '', '', '', '', '', ''], // Update this line
      ['', '', '', '', '', '', '', '', '', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '','','全预付', '', '部分预付', '', '',''], // Update this line
      ['', '', '', '', '', '', '', '', '', '', '', '', '单价（月）', '预付费用（年）', '总价（1年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（1年）', '对比按需实例', '预付费用（3年）', '对比按需实例', '单价（月）', '预付费用（3年）', '总价（3年）', '对比按需实例'],
      ...RDSforPostgreSQLItems.map(item => [
       item.Region,
       item.ServiceName,
       item.InstanceType,
       `${item.vCPU || 'N/A '}vCPU ${item.Memory || ''} ${item.InstanceFamily || ''}`,
       `${item.filtervalue.nodes}Nodes ${item.DeploymentOption}`,
       `${item.filtervalue.utilization.value} ${item.filtervalue.utilization.unit}`,
       item.quantity,
       item.rdsProxy,/* item.RDSProxyCost * item.quantity || 0.0, */
       `${getStorageTypeDisplay(item.filtervalue.volumeType)} ${item.filtervalue.volumeSize.amount} ${item.filtervalue.volumeSize.unit}`,/* item.totalmonthlystoragecost * item.quantity || 0.0, */
       item.filtervalue.backupStorage || 'N/A',/* item.backupStorageCost * item.quantity || 0.0, */
       item.filtervalue.snapshotExport || 'N/A',/* item.snapshotExportCost * item.quantity || 0.0, */
       item.OndemandTotalMonthlyCost * item.quantity || 0.0,
       item.Reserved1yrNoUpfrontTotalMonthlyCost * item.quantity || 0.0,
       item.reserved1yrNoUpfrontUpfrontPrice * item.quantity || 0.0,
       (item.Reserved1yrNoUpfrontTotalAnnualCost * item.quantity).toFixed(2) || 0.0,
       (item.Reserved1yrNoUpfrontTotalMonthlyCost/item.OndemandTotalMonthlyCost).toFixed(2) || 0.0,
       item.Reserved1yrAllUpfrontTotalAnnualCost * item.quantity || 0.0,
      ((item.Reserved1yrAllUpfrontTotalAnnualCost/12)/item.OndemandTotalMonthlyCost).toFixed(2) || 0.0,
      item.Reserved1yrPartialUpfrontTotalMonthlyCost * item.quantity || 0.0,
      parseFloat(item.reserved1yrPartialUpfrontFee) * item.quantity || 0.0,
      item.Reserved1yrPartialUpfrontTotalAnnualCost * item.quantity || 0.0,
      ((item.Reserved1yrPartialUpfrontTotalAnnualCost/12)/item.OndemandTotalMonthlyCost).toFixed(2) || 0.0,
      item.Reserved3yrAllUpfrontTotalAnnualCost * 3 * item.quantity || 0.0,
      ((item.Reserved3yrAllUpfrontTotalAnnualCost/12)/item.OndemandTotalMonthlyCost).toFixed(2) || 0.0,
      item.Reserved3yrPartialUpfrontTotalMonthlyCost * item.quantity || 0.0,
      parseFloat(item.reserved3yrPartialUpfrontFee) * item.quantity || 0.0,
      item.Reserved3yrPartialUpfrontTotalAnnualCost * 3 * item.quantity || 0.0,
      ((item.Reserved3yrPartialUpfrontTotalAnnualCost/12)/item.OndemandTotalMonthlyCost).toFixed(2) || 0.0,
      ])

    ];

    //Create the Export for full RDSforMariaDB
    const RDSforMariaDBData = [
      ["报价单 RDS for MariaDB"],
      ['地区', '服务', '实例类型', '配置', '部署选项', '利用率', '数量', 'RDS 代理', '数据库存储', '备用数据库存储 (GB)', '快照 (GB per Month)', '按需单价（月）', '预留一年RI', '', '', '', '', '', '', '', '', '', '预留三年RI', '', '', '', '', '', '', ''], // Update this line
      ['', '', '', '', '', '', '', '', '', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '','','全预付', '', '部分预付', '', '',''], // Update this line
      ['', '', '', '', '', '', '', '', '', '', '', '', '单价（月）', '预付费用（年）', '总价（1年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（1年）', '对比按需实例', '预付费用（3年）', '对比按需实例', '单价（月）', '预付费用（3年）', '总价（3年）', '对比按需实例'],
      ...RDSforMariaDBItems.map(item => [
       item.Region,
       item.ServiceName,
       item.InstanceType,
       `${item.vCPU || 'N/A '}vCPU ${item.Memory || ''} ${item.InstanceFamily || ''}`,
       `${item.filtervalue.nodes}Nodes ${item.DeploymentOption}`,
       `${item.filtervalue.utilization.value} ${item.filtervalue.utilization.unit}`,
       item.quantity,
       item.rdsProxy,/* item.RDSProxyCost * item.quantity || 0.0, */
       `${getStorageTypeDisplay(item.filtervalue.volumeType)} ${item.filtervalue.volumeSize.amount} ${item.filtervalue.volumeSize.unit}`,/* item.totalmonthlystoragecost * item.quantity || 0.0, */
       item.filtervalue.backupStorage || 'N/A',/* item.backupStorageCost * item.quantity || 0.0, */
       item.filtervalue.snapshotExport || 'N/A',/* item.snapshotExportCost * item.quantity || 0.0, */
       item.OndemandTotalMonthlyCost * item.quantity || 0.0,
       item.Reserved1yrNoUpfrontTotalMonthlyCost * item.quantity || 0.0,
       item.reserved1yrNoUpfrontUpfrontPrice * item.quantity || 0.0,
       (item.Reserved1yrNoUpfrontTotalAnnualCost * item.quantity).toFixed(2) || 0.0,
       (item.Reserved1yrNoUpfrontTotalMonthlyCost/item.OndemandTotalMonthlyCost).toFixed(2) || 0.0,
       item.Reserved1yrAllUpfrontTotalAnnualCost * item.quantity || 0.0,
      ((item.Reserved1yrAllUpfrontTotalAnnualCost/12)/item.OndemandTotalMonthlyCost).toFixed(2) || 0.0,
      item.Reserved1yrPartialUpfrontTotalMonthlyCost * item.quantity || 0.0,
      parseFloat(item.reserved1yrPartialUpfrontFee) * item.quantity || 0.0,
      item.Reserved1yrPartialUpfrontTotalAnnualCost * item.quantity || 0.0,
      ((item.Reserved1yrPartialUpfrontTotalAnnualCost/12)/item.OndemandTotalMonthlyCost).toFixed(2) || 0.0,
      item.Reserved3yrAllUpfrontTotalAnnualCost * 3 * item.quantity || 0.0,
      ((item.Reserved3yrAllUpfrontTotalAnnualCost/12)/item.OndemandTotalMonthlyCost).toFixed(2) || 0.0,
      item.Reserved3yrPartialUpfrontTotalMonthlyCost * item.quantity || 0.0,
      parseFloat(item.reserved3yrPartialUpfrontFee) * item.quantity || 0.0,
      item.Reserved3yrPartialUpfrontTotalAnnualCost * 3 * item.quantity || 0.0,
      ((item.Reserved3yrPartialUpfrontTotalAnnualCost/12)/item.OndemandTotalMonthlyCost).toFixed(2) || 0.0,
      ])

    ];

    //Create the Export for full RDSforOracle
    const RDSforOracleData = [
      ["报价单 RDS for Oracle"],
      ['地区', '服务', '实例类型', '配置', '部署选项', '利用率', '证书', '数据库版本', '数量', '数据库存储', '备用数据库存储 (GB)', '证书按需单价（月）', '预留一年RI', '', '', '', '', '', '', '', '', '', '预留三年RI', '', '', '', '', '', '', ''], // Update this line
      ['', '', '', '', '', '', '', '', '', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '','','全预付', '', '部分预付', '', '',''], // Update this line
      ['', '', '', '', '', '', '', '', '', '', '', '', '单价（月）', '预付费用（年）', '总价（1年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（1年）', '对比按需实例', '预付费用（3年）', '对比按需实例', '单价（月）', '预付费用（3年）', '总价（3年）', '对比按需实例'],
      ...RDSforOracleItems.map(item => [
       item.Region,
       item.ServiceName,
       item.InstanceType,
       `${item.vCPU || 'N/A '}vCPU ${item.Memory || ''} ${item.InstanceFamily || ''}`,
       `${item.filtervalue.nodes}Nodes ${item.DeploymentOption}`,
       `${item.filtervalue.utilization.value} ${item.filtervalue.utilization.unit}`,
       item.License,
       item.DatabaseEdition,
       item.quantity,
       `${getStorageTypeDisplay(item.filtervalue.volumeType)} ${item.filtervalue.volumeSize.amount} ${item.filtervalue.volumeSize.unit}`,/* item.totalmonthlystoragecost * item.quantity || 0.0, */
       item.filtervalue.backupStorage || 'N/A',/* item.backupStorageCost * item.quantity || 0.0, */
       item.OndemandTotalMonthlyCost * item.quantity || 0.0,
       item.Reserved1yrNoUpfrontTotalMonthlyCost * item.quantity || 0.0,
       item.reserved1yrNoUpfrontUpfrontPrice * item.quantity || 0.0,
       (item.Reserved1yrNoUpfrontTotalAnnualCost * item.quantity).toFixed(2) || 0.0,
       (item.Reserved1yrNoUpfrontTotalMonthlyCost/item.OndemandTotalMonthlyCost).toFixed(2) || 0.0,
       item.Reserved1yrAllUpfrontTotalAnnualCost * item.quantity || 0.0,
      ((item.Reserved1yrAllUpfrontTotalAnnualCost/12)/item.OndemandTotalMonthlyCost).toFixed(2) || 0.0,
      item.Reserved1yrPartialUpfrontTotalMonthlyCost * item.quantity || 0.0,
      parseFloat(item.reserved1yrPartialUpfrontFee) * item.quantity || 0.0,
      item.Reserved1yrPartialUpfrontTotalAnnualCost * item.quantity || 0.0,
      ((item.Reserved1yrPartialUpfrontTotalAnnualCost/12)/item.OndemandTotalMonthlyCost).toFixed(2) || 0.0,
      item.Reserved3yrAllUpfrontTotalAnnualCost * 3 * item.quantity || 0.0,
      ((item.Reserved3yrAllUpfrontTotalAnnualCost/12)/item.OndemandTotalMonthlyCost).toFixed(2) || 0.0,
      item.Reserved3yrPartialUpfrontTotalMonthlyCost * item.quantity || 0.0,
      parseFloat(item.reserved3yrPartialUpfrontFee) * item.quantity || 0.0,
      item.Reserved3yrPartialUpfrontTotalAnnualCost * 3 * item.quantity || 0.0,
      ((item.Reserved3yrPartialUpfrontTotalAnnualCost/12)/item.OndemandTotalMonthlyCost).toFixed(2) || 0.0,
      ])

    ];

    //Create the Export for full RDSforSQLServer
    const RDSforSQLServerData = [
      ["报价单 RDS for SQLServer"],
      ['地区', '服务', '实例类型', '配置', '部署选项', '利用率', '数据库版本', '数量', 'RDS 代理', '数据库存储', '备用数据库存储 (GB)', '证书按需单价（月）', '预留一年RI', '', '', '', '', '', '', '', '', '', '预留三年RI', '', '', '', '', '', '', ''], // Update this line
      ['', '', '', '', '', '', '', '', '', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '','','全预付', '', '部分预付', '', '',''], // Update this line
      ['', '', '', '', '', '', '', '', '', '', '', '', '单价（月）', '预付费用（年）', '总价（1年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（1年）', '对比按需实例', '预付费用（3年）', '对比按需实例', '单价（月）', '预付费用（3年）', '总价（3年）', '对比按需实例'],
      ...RDSforSQLServerItems.map(item => [
       item.Region,
       item.ServiceName,
       item.InstanceType,
       `${item.vCPU || 'N/A '}vCPU ${item.Memory || ''} ${item.InstanceFamily || ''}`,
       `${item.filtervalue.nodes}Nodes ${item.DeploymentOption}`,
       `${item.filtervalue.utilization.value} ${item.filtervalue.utilization.unit}`,
       item.DatabaseEdition,
       item.quantity,
       item.rdsProxy,/* item.RDSProxyCost * item.quantity || 0.0, */
       `${getStorageTypeDisplay(item.filtervalue.volumeType)} ${item.filtervalue.volumeSize.amount} ${item.filtervalue.volumeSize.unit}`,/* item.totalmonthlystoragecost * item.quantity || 0.0, */
       item.filtervalue.backupStorage || 'N/A',/* item.backupStorageCost * item.quantity || 0.0, */
       item.OndemandTotalMonthlyCost * item.quantity || 0.0,
       item.Reserved1yrNoUpfrontTotalMonthlyCost * item.quantity || 0.0,
       item.reserved1yrNoUpfrontUpfrontPrice * item.quantity || 0.0,
       (item.Reserved1yrNoUpfrontTotalAnnualCost * item.quantity).toFixed(2) || 0.0,
       (item.Reserved1yrNoUpfrontTotalMonthlyCost/item.OndemandTotalMonthlyCost).toFixed(2) || 0.0,
       item.Reserved1yrAllUpfrontTotalAnnualCost * item.quantity || 0.0,
      ((item.Reserved1yrAllUpfrontTotalAnnualCost/12)/item.OndemandTotalMonthlyCost).toFixed(2) || 0.0,
      item.Reserved1yrPartialUpfrontTotalMonthlyCost * item.quantity || 0.0,
      parseFloat(item.reserved1yrPartialUpfrontFee) * item.quantity || 0.0,
      item.Reserved1yrPartialUpfrontTotalAnnualCost * item.quantity || 0.0,
      ((item.Reserved1yrPartialUpfrontTotalAnnualCost/12)/item.OndemandTotalMonthlyCost).toFixed(2) || 0.0,
      item.Reserved3yrAllUpfrontTotalAnnualCost * 3 * item.quantity || 0.0,
      ((item.Reserved3yrAllUpfrontTotalAnnualCost/12)/item.OndemandTotalMonthlyCost).toFixed(2) || 0.0,
      item.Reserved3yrPartialUpfrontTotalMonthlyCost * item.quantity || 0.0,
      parseFloat(item.reserved3yrPartialUpfrontFee) * item.quantity || 0.0,
      item.Reserved3yrPartialUpfrontTotalAnnualCost * 3 * item.quantity || 0.0,
      ((item.Reserved3yrPartialUpfrontTotalAnnualCost/12)/item.OndemandTotalMonthlyCost).toFixed(2) || 0.0,
      ])

    ];

    const instanceData = ec2Items.flatMap(item => {
      const ebsFilters = item.product.attributes.ebsselection || {}; // 确保 ebsselection 是存在的，如果不存在则使用空对象 
      console.log('ebsFilters', ebsFilters);
      console.log('check export region: ', item.product.attributes.region);
      const config = `${item.product.attributes.operatingSystem || ''} ${item.product.attributes.vcpu || ''}vCPU ${item.product.attributes.memory || ''}`;
      const ebsconfig = `${ebsFilters.volumeSize?.amount || ''} ${ebsFilters.volumeSize?.unit || ''}`;

      const baseData = 
        [
          item.product.attributes.region,
          item.ServiceName,
          item.product.attributes.instanceType,
          config,
          item.product.attributes.networkPerformance,
          item.quantity,
          item.OnDemand_1Month * item.quantity || 0.0, //按需单价月
          item.OnDemand_1Month * item.quantity * 12 || 0.0,
          0.0,
          item.Reserved_No_Upfront_standard_1yr_per_Month * item.quantity || 0.0,
          item.UF_Reserved_No_Upfront_standard_1yr * item.quantity || 0.0,
          (item.Reserved_No_Upfront_standard_1yr_per_Month * 12) * item.quantity || 0.0,
          item.Compare_CP_RI_No_Std_1yr || 0.0,
          item.Reserved_All_Upfront_standard_1yr_per_Month * 12 * item.quantity || 0.0, //标准1年预付年
          item.Compare_CP_RI_All_Std_1yr || 0.0,
          item.Reserved_Partial_Upfront_standard_1yr_per_Month * item.quantity || 0.0,
          item.UF_Reserved_Partial_Upfront_standard_1yr * item.quantity || 0.0,
          (item.TotalPrice_TT_RI_Pt_Std_1yr * 12) * item.quantity || 0.0,
          item.Compare_CP_RI_Pt_Std_1yr || 0.0,
          item.Reserved_No_Upfront_convertible_1yr_per_Month * item.quantity || 0.0,
          item.UF_Reserved_No_Upfront_convertible_1yr * item.quantity || 0.0,
          (item.Reserved_No_Upfront_convertible_1yr_per_Month * 12) * item.quantity || 0.0,
          item.Compare_CP_RI_No_Con_1yr || 0.0,
          item.Reserved_All_Upfront_convertible_1yr_per_Month * 12 * item.quantity || 0.0, //转换1年预付年
          item.Compare_CP_RI_All_Con_1yr || 0.0,
          item.Reserved_Partial_Upfront_convertible_1yr_per_Month * item.quantity || 0.0,
          item.UF_Reserved_Partial_Upfront_convertible_1yr * item.quantity || 0.0,
          (item.TotalPrice_TT_RI_Pt_Con_1yr * 12) * item.quantity || 0.0,
          item.Compare_CP_RI_Pt_Con_1yr || 0.0,
          item.Reserved_No_Upfront_standard_3yr_per_Month * item.quantity || 0.0,
          item.UF_Reserved_No_Upfront_standard_3yr * item.quantity || 0.0,
          (item.Reserved_No_Upfront_standard_3yr_per_Month * 12) * item.quantity || 0.0,
          item.Compare_CP_RI_No_Std_3yr || 0.0,
          item.Reserved_All_Upfront_standard_3yr_per_Month * 12 * item.quantity || 0.0, //标准3年预付年
          item.Compare_CP_RI_All_Std_3yr || 0.0,
          item.Reserved_Partial_Upfront_standard_3yr_per_Month * item.quantity || 0.0,
          item.UF_Reserved_Partial_Upfront_standard_3yr * item.quantity || 0.0,
          (item.TotalPrice_TT_RI_Pt_Std_3yr * 12) * item.quantity || 0.0,
          item.Compare_CP_RI_Pt_Std_3yr || 0.0,
          item.Reserved_No_Upfront_convertible_3yr_per_Month * item.quantity || 0.0,
          item.UF_Reserved_No_Upfront_convertible_3yr * item.quantity || 0.0,
          (item.Reserved_No_Upfront_convertible_3yr_per_Month * 12) * item.quantity || 0.0,
          item.Compare_CP_RI_No_Con_3yr || 0.0,
          item.Reserved_All_Upfront_convertible_3yr_per_Month * 12 * item.quantity || 0.0, //转换3年预付费年
          item.Compare_CP_RI_All_Con_3yr || 0.0,
          item.Reserved_Partial_Upfront_convertible_3yr_per_Month * item.quantity || 0.0,
          item.UF_Reserved_Partial_Upfront_convertible_3yr * item.quantity || 0.0,
          (item.TotalPrice_TT_RI_Pt_Con_3yr * 12) * item.quantity || 0.0,
          item.Compare_CP_RI_Pt_Con_3yr || 0.0,
          item.ComputeSavingsPlans_1_year_No_Upfront_per_Month * item.quantity || 0.0,
          item.UF_ComputeSavingsPlans_1_year_No_Upfront * item.quantity || 0.0,
          (item.ComputeSavingsPlans_1_year_No_Upfront_per_Month * 12) * item.quantity || 0.0,
          item.Compare_CP_SP_No_CPT_1yr || 0.0,
          item.ComputeSavingsPlans_1_year_All_Upfront_per_Month * 12 * item.quantity || 0.0, //计算1 预付年
          item.Compare_CP_SP_All_CPT_1yr || 0.0,
          item.ComputeSavingsPlans_1_year_Partial_Upfront_per_Month * item.quantity || 0.0,
          // item.UF_ComputeSavingsPlans_1_year_Partial_Upfront || 0.0,
          '已包含在单价',
          (item.ComputeSavingsPlans_1_year_Partial_Upfront_per_Month * 12) * item.quantity || 0.0,
          item.Compare_CP_SP_Pt_CPT_1yr || 0.0,
          item.EC2InstanceSavingsPlans_1_year_No_Upfront_per_Month * item.quantity || 0.0,
          item.UF_EC2InstanceSavingsPlans_1_year_No_Upfront * item.quantity || 0.0,
          (item.EC2InstanceSavingsPlans_1_year_No_Upfront_per_Month * 12) * item.quantity || 0.0,
          item.Compare_CP_SP_No_EC2_1yr || 0.0,
          item.EC2InstanceSavingsPlans_1_year_All_Upfront_per_Month * 12 * item.quantity || 0.0, // 实例1 全预付年
          item.Compare_CP_SP_All_EC2_1yr || 0.0,
          item.EC2InstanceSavingsPlans_1_year_Partial_Upfront_per_Month * item.quantity || 0.0,
          // item.UF_EC2InstanceSavingsPlans_1_year_Partial_Upfront || 0.0,
          '已包含在单价',
          (item.EC2InstanceSavingsPlans_1_year_Partial_Upfront_per_Month * 12) * item.quantity || 0.0,
          item.Compare_CP_SP_Pt_EC2_1yr || 0.0,
          item.ComputeSavingsPlans_3_year_No_Upfront_per_Month * item.quantity || 0.0,
          item.UF_ComputeSavingsPlans_3_year_No_Upfront * item.quantity || 0.0,
          (item.ComputeSavingsPlans_3_year_No_Upfront_per_Month * 12) * item.quantity || 0.0,
          item.Compare_CP_SP_No_CPT_3yr || 0.0,
          item.ComputeSavingsPlans_3_year_All_Upfront_per_Month * 12 * item.quantity || 0.0, // 计算3 全预付年
          item.Compare_CP_SP_All_CPT_3yr || 0.0,
          item.ComputeSavingsPlans_3_year_Partial_Upfront_per_Month * item.quantity || 0.0,
          // item.UF_ComputeSavingsPlans_3_year_Partial_Upfront || 0.0,
          '已包含在单价',
          (item.ComputeSavingsPlans_3_year_Partial_Upfront_per_Month * 12) * item.quantity || 0.0,
          item.Compare_CP_SP_Pt_CPT_3yr || 0.0,
          item.EC2InstanceSavingsPlans_3_year_No_Upfront_per_Month * item.quantity || 0.0,
          item.UF_EC2InstanceSavingsPlans_3_year_No_Upfront * item.quantity || 0.0,
          (item.EC2InstanceSavingsPlans_3_year_No_Upfront_per_Month * 12) * item.quantity || 0.0, //实例3 无预付单价年
          item.Compare_CP_SP_No_EC2_3yr || 0.0,
          item.EC2InstanceSavingsPlans_3_year_All_Upfront_per_Month * 12 * item.quantity || 0.0, // 实例3 全预付单价年
          item.Compare_CP_SP_All_EC2_3yr || 0.0,
          item.EC2InstanceSavingsPlans_3_year_Partial_Upfront_per_Month * item.quantity || 0.0,
          // item.UF_EC2InstanceSavingsPlans_3_year_Partial_Upfront || 0.0,
          '已包含在单价',
          (item.EC2InstanceSavingsPlans_3_year_Partial_Upfront_per_Month * 12) * item.quantity || 0.0,
          item.Compare_CP_SP_Pt_EC2_3yr || 0.0,
          parseFloat(item.terms.OnDemand.price) * item.quantity || 0.0, //按需单价时
        ];
        
        if (ebsFilters.numberOfVolumes > 0) {
          const ebsData = [
                  item.product.attributes.region,
                  'EBS',
                  ebsFilters.volumeType || '',
                  ebsconfig,
                  '',
                  ebsFilters.numberOfVolumes * item.quantity || 0.0,
                  0.0,
                  0.0,
                  ebsFilters.totalprice * item.quantity * 12 || 0.0,
                  0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0
                  // '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
          ];
          return [baseData].concat([ebsData]);
      } else {
          return [baseData];
      }
    });

    const finalData = [...wsData, ...instanceData];

    const workSheet = XLSX.utils.aoa_to_sheet(finalData);

    const workSheetElastiCache = XLSX.utils.aoa_to_sheet(elastiCacheData);

    const workSheetRedshift = XLSX.utils.aoa_to_sheet(RedshiftData);

    const workSheetAuroraMySQL = XLSX.utils.aoa_to_sheet(AuroraMySQLData);

    const workSheetAuroraPostgreSQL = XLSX.utils.aoa_to_sheet(AuroraPostgreSQLData);

    const workSheetRDSforMySQL = XLSX.utils.aoa_to_sheet(RDSforMySQLData);

    const workSheetRDSforPostgreSQL = XLSX.utils.aoa_to_sheet(RDSforPostgreSQLData);

    const workSheetRDSforMariaDB = XLSX.utils.aoa_to_sheet(RDSforMariaDBData);

    const workSheetRDSforOracle = XLSX.utils.aoa_to_sheet(RDSforOracleData);

    const workSheetRDSforSQLServer = XLSX.utils.aoa_to_sheet(RDSforSQLServerData);

    const workSheetSagemaker = XLSX.utils.aoa_to_sheet(SagemakerData);

    console.log('Generated workSheetRedshift:', workSheetRedshift);

    const merge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 89 } },
      { s: { r: 1, c: 0 }, e: { r: 3, c: 0 } }, { s: { r: 1, c: 1 }, e: { r: 3, c: 1 } }, { s: { r: 1, c: 2 }, e: { r: 3, c: 2 } }, { s: { r: 1, c: 3 }, e: { r: 3, c: 3 } }, { s: { r: 1, c: 4 }, e: { r: 3, c: 4 } }, { s: { r: 1, c: 5 }, e: { r: 3, c: 5 } }, { s: { r: 1, c: 6 }, e: { r: 3, c: 6 } }, { s: { r: 1, c: 7 }, e: { r: 3, c: 7 } }, { s: { r: 1, c: 8 }, e: { r: 3, c: 8 } },
      { s: { r: 1, c: 9 }, e: { r: 1, c: 18 } },
      { s: { r: 1, c: 19 }, e: { r: 1, c: 28 } },
      { s: { r: 1, c: 29 }, e: { r: 1, c: 38 } },
      { s: { r: 1, c: 39 }, e: { r: 1, c: 48 } },
      { s: { r: 1, c: 49 }, e: { r: 1, c: 58 } },
      { s: { r: 1, c: 59 }, e: { r: 1, c: 68 } },
      { s: { r: 1, c: 69 }, e: { r: 1, c: 78 } },
      { s: { r: 1, c: 79 }, e: { r: 1, c: 88 } },
      { s: { r: 2, c: 9 }, e: { r: 2, c: 12 } },
      { s: { r: 2, c: 13 }, e: { r: 2, c: 14 } },
      { s: { r: 2, c: 15 }, e: { r: 2, c: 18 } },
      { s: { r: 2, c: 19 }, e: { r: 2, c: 22 } },
      { s: { r: 2, c: 23 }, e: { r: 2, c: 24 } },
      { s: { r: 2, c: 25 }, e: { r: 2, c: 28 } },
      { s: { r: 2, c: 29 }, e: { r: 2, c: 32 } },
      { s: { r: 2, c: 33 }, e: { r: 2, c: 34 } },
      { s: { r: 2, c: 35 }, e: { r: 2, c: 38 } },
      { s: { r: 2, c: 39 }, e: { r: 2, c: 42 } },
      { s: { r: 2, c: 43 }, e: { r: 2, c: 44 } },
      { s: { r: 2, c: 45 }, e: { r: 2, c: 48 } },
      { s: { r: 2, c: 49 }, e: { r: 2, c: 52 } },
      { s: { r: 2, c: 53 }, e: { r: 2, c: 54 } },
      { s: { r: 2, c: 55 }, e: { r: 2, c: 58 } },
      { s: { r: 2, c: 59 }, e: { r: 2, c: 62 } },
      { s: { r: 2, c: 63 }, e: { r: 2, c: 64 } },
      { s: { r: 2, c: 65 }, e: { r: 2, c: 68 } },
      { s: { r: 2, c: 69 }, e: { r: 2, c: 72 } },
      { s: { r: 2, c: 73 }, e: { r: 2, c: 74 } },
      { s: { r: 2, c: 75 }, e: { r: 2, c: 78 } },
      { s: { r: 2, c: 79 }, e: { r: 2, c: 82 } },
      { s: { r: 2, c: 83 }, e: { r: 2, c: 84 } },
      { s: { r: 2, c: 85 }, e: { r: 2, c: 88 } },
      { s: { r: 1, c: 89 }, e: { r: 3, c: 89 } }
    ];
    workSheet["!merges"] = merge;

    const elastiCacheMerge = [
      // 合并 A2 到 D2
      { s: { r: 1, c: 0 }, e: { r: 1, c: 3 } },
      // 合并 E2 到 M2
      { s: { r: 1, c: 4 }, e: { r: 1, c: 12 } },
      // 合并 N2 到 U2
      { s: { r: 1, c: 13 }, e: { r: 1, c: 20 } },
      { s: { r: 2, c: 0 }, e: { r: 3, c: 0 } },
      { s: { r: 2, c: 1 }, e: { r: 3, c: 1 } }, { s: { r: 2, c: 2 }, e: { r: 3, c: 2 } }, { s: { r: 2, c: 3 }, e: { r: 3, c: 3 } }, { s: { r: 2, c: 4 }, e: { r: 3, c: 4 } },
      { s: { r: 2, c: 5 }, e: { r: 3, c: 5 } }, { s: { r: 2, c: 6 }, e: { r: 3, c: 6 } }, { s: { r: 2, c: 7 }, e: { r: 3, c: 7 } }, { s: { r: 2, c: 8 }, e: { r: 3, c: 8 } },
      { s: { r: 2, c: 9 }, e: { r: 3, c: 9 } }, { s: { r: 2, c: 10 }, e: { r: 3, c: 10 } }, { s: { r: 2, c: 11 }, e: { r: 3, c: 11 } }, { s: { r: 2, c: 12 }, e: { r: 3, c: 12 } },
      { s: { r: 2, c: 13 }, e: { r: 3, c: 13 } }, { s: { r: 2, c: 14 }, e: { r: 3, c: 14 } }, { s: { r: 2, c: 15 }, e: { r: 3, c: 15 } }, { s: { r: 2, c: 16 }, e: { r: 3, c: 16 } },
      { s: { r: 2, c: 17 }, e: { r: 3, c: 17 } }, { s: { r: 2, c: 18 }, e: { r: 3, c: 18 } }, { s: { r: 2, c: 19 }, e: { r: 3, c: 19 } }, { s: { r: 2, c: 20 }, e: { r: 3, c: 20 } },



    ];

    const SagemakerMerge = [
      // 合并 A2 到 D2
      { s: { r: 1, c: 0 }, e: { r: 1, c: 5 } },
      // 合并 E2 到 M2
      { s: { r: 1, c: 6 }, e: { r: 1, c: 17 } },
      { s: { r: 2, c: 0 }, e: { r: 3, c: 0 } },
      { s: { r: 2, c: 1 }, e: { r: 3, c: 1 } }, { s: { r: 2, c: 2 }, e: { r: 3, c: 2 } }, { s: { r: 2, c: 3 }, e: { r: 3, c: 3 } }, { s: { r: 2, c: 4 }, e: { r: 3, c: 4 } },
      { s: { r: 2, c: 5 }, e: { r: 3, c: 5 } }, { s: { r: 2, c: 6 }, e: { r: 3, c: 6 } }, { s: { r: 2, c: 7 }, e: { r: 3, c: 7 } }, { s: { r: 2, c: 8 }, e: { r: 3, c: 8 } },
      { s: { r: 2, c: 9 }, e: { r: 3, c: 9 } }, { s: { r: 2, c: 10 }, e: { r: 3, c: 10 } }, { s: { r: 2, c: 11 }, e: { r: 3, c: 11 } }, { s: { r: 2, c: 12 }, e: { r: 3, c: 12 } },
      { s: { r: 2, c: 13 }, e: { r: 3, c: 13 } }, { s: { r: 2, c: 14 }, e: { r: 3, c: 14 } }, { s: { r: 2, c: 15 }, e: { r: 3, c: 15 } }, { s: { r: 2, c: 16 }, e: { r: 3, c: 16 } },
      { s: { r: 2, c: 17 }, e: { r: 3, c: 17 } }
    ];

    const redshiftMerge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 26 } },
      { s: { r: 1, c: 0 }, e: { r: 3, c: 0 } }, { s: { r: 1, c: 1 }, e: { r: 3, c: 1 } }, { s: { r: 1, c: 2 }, e: { r: 3, c: 2 } }, { s: { r: 1, c: 3 }, e: { r: 3, c: 3 } }, { s: { r: 1, c: 4 }, e: { r: 3, c: 4 } }, { s: { r: 1, c: 5 }, e: { r: 3, c: 5 } }, { s: { r: 1, c: 6 }, e: { r: 3, c: 6 } },
      { s: { r: 1, c: 7 }, e: { r: 1, c: 16 } },
      { s: { r: 1, c: 17 }, e: { r: 1, c: 26 } },
      { s: { r: 2, c: 7 }, e: { r: 2, c: 10 } },
      { s: { r: 2, c: 11 }, e: { r: 2, c: 12 } },
      { s: { r: 2, c: 13 }, e: { r: 2, c: 16 } },
      { s: { r: 2, c: 17 }, e: { r: 2, c: 20 } },
      { s: { r: 2, c: 21 }, e: { r: 2, c: 22 } },
      { s: { r: 2, c: 23 }, e: { r: 2, c: 26 } },
    ];

    const auroraMySQLMerge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 28 } },
      { s: { r: 1, c: 0 }, e: { r: 3, c: 0 } }, { s: { r: 1, c: 1 }, e: { r: 3, c: 1 } }, { s: { r: 1, c: 2 }, e: { r: 3, c: 2 } }, { s: { r: 1, c: 3 }, e: { r: 3, c: 3 } }, { s: { r: 1, c: 4 }, e: { r: 3, c: 4 } }, { s: { r: 1, c: 5 }, e: { r: 3, c: 5 } }, { s: { r: 1, c: 6 }, e: { r: 3, c: 6 } }, { s: { r: 1, c: 7 }, e: { r: 3, c: 7 } }, { s: { r: 1, c: 8 }, e: { r: 3, c: 8 } },
      { s: { r: 1, c: 9 }, e: { r: 1, c: 18 } },
      { s: { r: 1, c: 19 }, e: { r: 1, c: 28 } },
      { s: { r: 2, c: 9 }, e: { r: 2, c: 12 } },
      { s: { r: 2, c: 13 }, e: { r: 2, c: 14 } },
      { s: { r: 2, c: 15 }, e: { r: 2, c: 18 } },
      { s: { r: 2, c: 19 }, e: { r: 2, c: 22 } },
      { s: { r: 2, c: 23 }, e: { r: 2, c: 24 } },
      { s: { r: 2, c: 25 }, e: { r: 2, c: 28 } },
    ];

    const auroraPostgreSQLMerge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 28 } },
      { s: { r: 1, c: 0 }, e: { r: 3, c: 0 } }, { s: { r: 1, c: 1 }, e: { r: 3, c: 1 } }, { s: { r: 1, c: 2 }, e: { r: 3, c: 2 } }, { s: { r: 1, c: 3 }, e: { r: 3, c: 3 } }, { s: { r: 1, c: 4 }, e: { r: 3, c: 4 } }, { s: { r: 1, c: 5 }, e: { r: 3, c: 5 } }, { s: { r: 1, c: 6 }, e: { r: 3, c: 6 } }, { s: { r: 1, c: 7 }, e: { r: 3, c: 7 } }, { s: { r: 1, c: 8 }, e: { r: 3, c: 8 } },
      { s: { r: 1, c: 9 }, e: { r: 1, c: 18 } },
      { s: { r: 1, c: 19 }, e: { r: 1, c: 28 } },
      { s: { r: 2, c: 9 }, e: { r: 2, c: 12 } },
      { s: { r: 2, c: 13 }, e: { r: 2, c: 14 } },
      { s: { r: 2, c: 15 }, e: { r: 2, c: 18 } },
      { s: { r: 2, c: 19 }, e: { r: 2, c: 22 } },
      { s: { r: 2, c: 23 }, e: { r: 2, c: 24 } },
      { s: { r: 2, c: 25 }, e: { r: 2, c: 28 } },
    ];

    const RDSforMySQLMerge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 27 } },
      { s: { r: 1, c: 0 }, e: { r: 3, c: 0 } }, { s: { r: 1, c: 1 }, e: { r: 3, c: 1 } }, { s: { r: 1, c: 2 }, e: { r: 3, c: 2 } }, { s: { r: 1, c: 3 }, e: { r: 3, c: 3 } }, { s: { r: 1, c: 4 }, e: { r: 3, c: 4 } }, { s: { r: 1, c: 5 }, e: { r: 3, c: 5 } }, { s: { r: 1, c: 6 }, e: { r: 3, c: 6 } }, { s: { r: 1, c: 7 }, e: { r: 3, c: 7 } }, { s: { r: 1, c: 8 }, e: { r: 3, c: 8 } },
      { s: { r: 1, c: 9 }, e: { r: 3, c: 9 } },
      { s: { r: 1, c: 10 }, e: { r: 3, c: 10 } },
      { s: { r: 1, c: 11 }, e: { r: 3, c: 11 } },
      { s: { r: 1, c: 12 }, e: { r: 1, c: 21 } },
      { s: { r: 1, c: 22 }, e: { r: 1, c: 27 } },
      { s: { r: 2, c: 12 }, e: { r: 2, c: 15 } },
      { s: { r: 2, c: 16 }, e: { r: 2, c: 17 } },
      { s: { r: 2, c: 18 }, e: { r: 2, c: 21 } },
      { s: { r: 2, c: 22 }, e: { r: 2, c: 23 } },
      { s: { r: 2, c: 24 }, e: { r: 2, c: 27 } },
    ];

    const RDSforPostgreSQLMerge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 27 } },
      { s: { r: 1, c: 0 }, e: { r: 3, c: 0 } }, { s: { r: 1, c: 1 }, e: { r: 3, c: 1 } }, { s: { r: 1, c: 2 }, e: { r: 3, c: 2 } }, { s: { r: 1, c: 3 }, e: { r: 3, c: 3 } }, { s: { r: 1, c: 4 }, e: { r: 3, c: 4 } }, { s: { r: 1, c: 5 }, e: { r: 3, c: 5 } }, { s: { r: 1, c: 6 }, e: { r: 3, c: 6 } }, { s: { r: 1, c: 7 }, e: { r: 3, c: 7 } }, { s: { r: 1, c: 8 }, e: { r: 3, c: 8 } },
      { s: { r: 1, c: 9 }, e: { r: 3, c: 9 } },
      { s: { r: 1, c: 10 }, e: { r: 3, c: 10 } },
      { s: { r: 1, c: 11 }, e: { r: 3, c: 11 } },
      { s: { r: 1, c: 12 }, e: { r: 1, c: 21 } },
      { s: { r: 1, c: 22 }, e: { r: 1, c: 27 } },
      { s: { r: 2, c: 12 }, e: { r: 2, c: 15 } },
      { s: { r: 2, c: 16 }, e: { r: 2, c: 17 } },
      { s: { r: 2, c: 18 }, e: { r: 2, c: 21 } },
      { s: { r: 2, c: 22 }, e: { r: 2, c: 23 } },
      { s: { r: 2, c: 24 }, e: { r: 2, c: 27 } },
    ];

    const RDSforMariaDBMerge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 27 } },
      { s: { r: 1, c: 0 }, e: { r: 3, c: 0 } }, { s: { r: 1, c: 1 }, e: { r: 3, c: 1 } }, { s: { r: 1, c: 2 }, e: { r: 3, c: 2 } }, { s: { r: 1, c: 3 }, e: { r: 3, c: 3 } }, { s: { r: 1, c: 4 }, e: { r: 3, c: 4 } }, { s: { r: 1, c: 5 }, e: { r: 3, c: 5 } }, { s: { r: 1, c: 6 }, e: { r: 3, c: 6 } }, { s: { r: 1, c: 7 }, e: { r: 3, c: 7 } }, { s: { r: 1, c: 8 }, e: { r: 3, c: 8 } },
      { s: { r: 1, c: 9 }, e: { r: 3, c: 9 } },
      { s: { r: 1, c: 10 }, e: { r: 3, c: 10 } },
      { s: { r: 1, c: 11 }, e: { r: 3, c: 11 } },
      { s: { r: 1, c: 12 }, e: { r: 1, c: 21 } },
      { s: { r: 1, c: 22 }, e: { r: 1, c: 27 } },
      { s: { r: 2, c: 12 }, e: { r: 2, c: 15 } },
      { s: { r: 2, c: 16 }, e: { r: 2, c: 17 } },
      { s: { r: 2, c: 18 }, e: { r: 2, c: 21 } },
      { s: { r: 2, c: 22 }, e: { r: 2, c: 23 } },
      { s: { r: 2, c: 24 }, e: { r: 2, c: 27 } },
    ];

    const RDSforOracleMerge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 27 } },
      { s: { r: 1, c: 0 }, e: { r: 3, c: 0 } }, { s: { r: 1, c: 1 }, e: { r: 3, c: 1 } }, { s: { r: 1, c: 2 }, e: { r: 3, c: 2 } }, { s: { r: 1, c: 3 }, e: { r: 3, c: 3 } }, { s: { r: 1, c: 4 }, e: { r: 3, c: 4 } }, { s: { r: 1, c: 5 }, e: { r: 3, c: 5 } }, { s: { r: 1, c: 6 }, e: { r: 3, c: 6 } }, { s: { r: 1, c: 7 }, e: { r: 3, c: 7 } }, { s: { r: 1, c: 8 }, e: { r: 3, c: 8 } },
      { s: { r: 1, c: 9 }, e: { r: 3, c: 9 } },
      { s: { r: 1, c: 10 }, e: { r: 3, c: 10 } },
      { s: { r: 1, c: 11 }, e: { r: 3, c: 11 } },
      { s: { r: 1, c: 12 }, e: { r: 1, c: 21 } },
      { s: { r: 1, c: 22 }, e: { r: 1, c: 27 } },
      { s: { r: 2, c: 12 }, e: { r: 2, c: 15 } },
      { s: { r: 2, c: 16 }, e: { r: 2, c: 17 } },
      { s: { r: 2, c: 18 }, e: { r: 2, c: 21 } },
      { s: { r: 2, c: 22 }, e: { r: 2, c: 23 } },
      { s: { r: 2, c: 24 }, e: { r: 2, c: 27 } },
    ];

    const RDSforSQLServerMerge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 27 } },
      { s: { r: 1, c: 0 }, e: { r: 3, c: 0 } }, { s: { r: 1, c: 1 }, e: { r: 3, c: 1 } }, { s: { r: 1, c: 2 }, e: { r: 3, c: 2 } }, { s: { r: 1, c: 3 }, e: { r: 3, c: 3 } }, { s: { r: 1, c: 4 }, e: { r: 3, c: 4 } }, { s: { r: 1, c: 5 }, e: { r: 3, c: 5 } }, { s: { r: 1, c: 6 }, e: { r: 3, c: 6 } }, { s: { r: 1, c: 7 }, e: { r: 3, c: 7 } }, { s: { r: 1, c: 8 }, e: { r: 3, c: 8 } },
      { s: { r: 1, c: 9 }, e: { r: 3, c: 9 } },
      { s: { r: 1, c: 10 }, e: { r: 3, c: 10 } },
      { s: { r: 1, c: 11 }, e: { r: 3, c: 11 } },
      { s: { r: 1, c: 12 }, e: { r: 1, c: 21 } },
      { s: { r: 1, c: 22 }, e: { r: 1, c: 27 } },
      { s: { r: 2, c: 12 }, e: { r: 2, c: 15 } },
      { s: { r: 2, c: 16 }, e: { r: 2, c: 17 } },
      { s: { r: 2, c: 18 }, e: { r: 2, c: 21 } },
      { s: { r: 2, c: 22 }, e: { r: 2, c: 23 } },
      { s: { r: 2, c: 24 }, e: { r: 2, c: 27 } },
    ];

    workSheetRedshift['!merges'] = redshiftMerge;
    workSheetAuroraMySQL['!merges'] = auroraMySQLMerge;
    workSheetAuroraPostgreSQL['!merges'] = auroraPostgreSQLMerge;
    workSheetRDSforMySQL['!merges'] = RDSforMySQLMerge;//RDS
    workSheetRDSforPostgreSQL['!merges'] = RDSforPostgreSQLMerge;//RDS
    workSheetRDSforMariaDB['!merges'] = RDSforMariaDBMerge;//RDS
    workSheetRDSforOracle['!merges'] = RDSforOracleMerge;//RDS
    workSheetRDSforSQLServer['!merges'] = RDSforSQLServerMerge;//RDS
    workSheetElastiCache['!merges'] = elastiCacheMerge;
    workSheetSagemaker['!merges'] = SagemakerMerge
    workSheetElastiCache["A1"].s = {
      font: {
        sz: 20,
        bold: true,
      },
      fill: { fgColor: { rgb: "fb92ee" } },
      alignment: {
        horizontal: "center",
      },
    };
    workSheetSagemaker["A1"].s = {
      font: {
        sz: 20,
        bold: true,
      },
      fill: { fgColor: { rgb: "fb92ee" } },
      alignment: {
        horizontal: "center",
      },
    };
    const elasticCacheStyle1 = {
      font: {
        sz: 16,
        bold: true,
      },
      fill: { fgColor: { rgb: "9FE3FF" } },
      alignment: {
        horizontal: "center",
        wrapText: true,
      },
    };
    const elasticCacheStylePrice = {
      font: {
        sz: 16,
        bold: true,
      },
      fill: { fgColor: { rgb: "fb92ee" } },
      alignment: {
        horizontal: "center",
        wrapText: true,
      },
    };
    workSheetElastiCache['A2'].s = elasticCacheStyle1;
    workSheetElastiCache['A3'].s = elasticCacheStyle1;
    workSheetElastiCache['B3'].s = elasticCacheStyle1;
    workSheetElastiCache['C3'].s = elasticCacheStyle1;
    workSheetElastiCache['D3'].s = elasticCacheStylePrice;

    workSheetSagemaker['A2'].s = elasticCacheStyle1;
    workSheetSagemaker['A3'].s = elasticCacheStyle1;
    workSheetSagemaker['B3'].s = elasticCacheStyle1;
    workSheetSagemaker['C3'].s = elasticCacheStyle1;
    workSheetSagemaker['D3'].s = elasticCacheStyle1;
    workSheetSagemaker['E3'].s = elasticCacheStyle1;
    workSheetSagemaker['F3'].s = elasticCacheStylePrice;

    const elasticCacheStyle2 = {
      font: {
        sz: 16,
        bold: true,
      },
      fill: { fgColor: { rgb: "66CD00" } },
      alignment: {
        horizontal: "center",
        wrapText: true,
      },
    };
    workSheetElastiCache['E2'].s = elasticCacheStyle2;
    workSheetElastiCache['E3'].s = elasticCacheStyle2;
    workSheetElastiCache['F3'].s = elasticCacheStyle2;
    workSheetElastiCache['G3'].s = elasticCacheStyle2;
    workSheetElastiCache['H3'].s = elasticCacheStyle2;
    workSheetElastiCache['I3'].s = elasticCacheStyle2;
    workSheetElastiCache['J3'].s = elasticCacheStyle2;
    workSheetElastiCache['K3'].s = elasticCacheStyle2;
    workSheetElastiCache['L3'].s = elasticCacheStylePrice;
    workSheetElastiCache['M3'].s = elasticCacheStylePrice;

    workSheetSagemaker['G2'].s = elasticCacheStyle2;
    workSheetSagemaker['G3'].s = elasticCacheStyle2;
    workSheetSagemaker['H3'].s = elasticCacheStyle2;
    workSheetSagemaker['I3'].s = elasticCacheStyle2;
    workSheetSagemaker['J3'].s = elasticCacheStyle2;
    workSheetSagemaker['K3'].s = elasticCacheStyle2;
    workSheetSagemaker['L3'].s = elasticCacheStylePrice;
    workSheetSagemaker['M3'].s = elasticCacheStyle2;
    workSheetSagemaker['N3'].s = elasticCacheStyle2;
    workSheetSagemaker['O3'].s = elasticCacheStylePrice;
    workSheetSagemaker['P3'].s = elasticCacheStyle2;
    workSheetSagemaker['Q3'].s = elasticCacheStyle2;
    workSheetSagemaker['R3'].s = elasticCacheStylePrice;

    const elasticCacheStyle3 = {
      font: {
        sz: 16,
        bold: true,
      },
      fill: { fgColor: { rgb: "FFC125" } },
      alignment: {
        horizontal: "center",
        wrapText: true,
      },
    };

    workSheetElastiCache['N2'].s = elasticCacheStyle3;
    workSheetElastiCache['N3'].s = elasticCacheStyle3;
    workSheetElastiCache['O3'].s = elasticCacheStyle3;
    workSheetElastiCache['P3'].s = elasticCacheStyle3;
    workSheetElastiCache['Q3'].s = elasticCacheStyle3;
    workSheetElastiCache['R3'].s = elasticCacheStyle3;
    workSheetElastiCache['S3'].s = elasticCacheStyle3;
    workSheetElastiCache['T3'].s = elasticCacheStylePrice;
    workSheetElastiCache['U3'].s = elasticCacheStylePrice;


    const columnWidths = [
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }
    ];
    workSheet["!cols"] = columnWidths;
    workSheetElastiCache["!cols"] = columnWidths;
    workSheetSagemaker["!cols"] = columnWidths;


    const redshiftColumnWidthis = [
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }
    ];

    workSheetRedshift["!cols"] = redshiftColumnWidthis;

    const auroraMySQLColumnWidthis = [
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 20 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
    ];
    workSheetAuroraMySQL["!cols"] = auroraMySQLColumnWidthis;

    const auroraPostgreSQLColumnWidthis = [
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 20 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
    ];
    workSheetAuroraPostgreSQL["!cols"] = auroraPostgreSQLColumnWidthis;

    const RDSforMySQLColumnWidths = [
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }
    ];
    workSheetRDSforMySQL["!cols"] = RDSforMySQLColumnWidths;

    const RDSforPostgreSQLColumnWidths = [
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }
    ];
    workSheetRDSforPostgreSQL["!cols"] = RDSforPostgreSQLColumnWidths;

    const RDSforMariaDBColumnWidths = [
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }
    ];
    workSheetRDSforMariaDB["!cols"] = RDSforMariaDBColumnWidths;

    const RDSforOracleColumnWidths = [
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }
    ];
    workSheetRDSforOracle["!cols"] = RDSforOracleColumnWidths;

    const RDSforSQLServerColumnWidths = [
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }
    ];
    workSheetRDSforSQLServer["!cols"] = RDSforSQLServerColumnWidths;


    const borderStyle = {
      top: { style: "thin", color: { rgb: "000000" } },
      bottom: { style: "thin", color: { rgb: "000000" } },
      left: { style: "thin", color: { rgb: "000000" } },
      right: { style: "thin", color: { rgb: "000000" } },
    };

    workSheetRedshift["A1"].s = {
      font: {
        sz: 24,
        bold: true,
      },
      alignment: {
        horizontal: "left",
        vertical: "center",
      },
      border: borderStyle
    };

    workSheet["A1"].s = {
      font: {
        sz: 24,
        bold: true,
      },
      alignment: {
        horizontal: "left",
        vertical: "center",
      },
      border: borderStyle
    };

    workSheetAuroraMySQL["A1"].s = {
      font: {
        sz: 24,
        bold: true,
      },
      alignment: {
        horizontal: "left",
        vertical: "center",
      },
      border: borderStyle
    };

    workSheetAuroraPostgreSQL["A1"].s = {
      font: {
        sz: 24,
        bold: true,
      },
      alignment: {
        horizontal: "left",
        vertical: "center",
      },
      border: borderStyle
    };

    workSheetRDSforMySQL["A1"].s = {
      font: {
        sz: 24,
        bold: true,
      },
      alignment: {
        horizontal: "left",
        vertical: "center",
      },
      border: borderStyle
    };

    workSheetRDSforPostgreSQL["A1"].s = {
      font: {
        sz: 24,
        bold: true,
      },
      alignment: {
        horizontal: "left",
        vertical: "center",
      },
      border: borderStyle
    };

    workSheetRDSforMariaDB["A1"].s = {
      font: {
        sz: 24,
        bold: true,
      },
      alignment: {
        horizontal: "left",
        vertical: "center",
      },
      border: borderStyle
    };

    workSheetRDSforOracle["A1"].s = {
      font: {
        sz: 24,
        bold: true,
      },
      alignment: {
        horizontal: "left",
        vertical: "center",
      },
      border: borderStyle
    };

    workSheetRDSforSQLServer["A1"].s = {
      font: {
        sz: 24,
        bold: true,
      },
      alignment: {
        horizontal: "left",
        vertical: "center",
      },
      border: borderStyle
    };


    const columnStyle1 = {
      font: {
        sz: 12,
        bold: false,
      },
      fill: { fgColor: { rgb: "ccffcc" } },
      alignment: {
        horizontal: "center",
        vertical: "center",
        wrapText: true,
      },
      border: borderStyle
    };

    const columnStyle2 = {
      font: {
        sz: 12,
        bold: false,
      },
      fill: { fgColor: { rgb: "ffcc02" } },
      alignment: {
        horizontal: "center",
        vertical: "center",
        wrapText: true,
      },
      border: borderStyle
    };

    const columnStyle3 = {
      font: {
        sz: 12,
        bold: false,
      },
      alignment: {
        horizontal: "center",
        vertical: "center",
        wrapText: true,
      },
      border: borderStyle,
    };

    const columnStyleRed = {
      font: {
        sz: 12,
        bold: false,
      },
      fill: { fgColor: { rgb: "ff0235" } },
      alignment: {
        horizontal: "center",
        vertical: "center",
        wrapText: true,
      },
      border: borderStyle
    };

    const letter = "A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z,AA,AB,AC,AD,AE,AF,AG,AH,AI,AJ,AK,AL,AM,AN,AO,AP,AQ,AR,AS,AT,AU,AV,AW,AX,AY,AZ,BA,BB,BC,BD,BE,BF,BG,BH,BI,BJ,BK,BL,BM,BN,BO,BP,BQ,BR,BS,BT,BU,BV,BW,BX,BY,BZ,CA,CB,CC,CD,CE,CF,CG,CH,CI,CJ,CK,CL".split(",");
    for (let i = 0; i < 90; i++) {
      const cell2 = workSheet[`${letter[i]}2`];
      const cell3 = workSheet[`${letter[i]}3`];
      const cell4 = workSheet[`${letter[i]}4`];
      if (cell2) cell2.s = columnStyle1;
      if (cell3) cell3.s = columnStyle1;
      if (cell4) cell4.s = columnStyle1;
    }

    for (let i = 0; i < 27; i++) {
      const cell2 = workSheetRedshift[`${letter[i]}2`];
      const cell3 = workSheetRedshift[`${letter[i]}3`];
      const cell4 = workSheetRedshift[`${letter[i]}4`];
      if (cell2) cell2.s = columnStyle1;
      if (cell3) cell3.s = columnStyle1;
      if (cell4) cell4.s = columnStyle1;
    }

    for (let i = 0; i < 29; i++) {
      const cell2 = workSheetAuroraMySQL[`${letter[i]}2`];
      const cell3 = workSheetAuroraMySQL[`${letter[i]}3`];
      const cell4 = workSheetAuroraMySQL[`${letter[i]}4`];
      if (cell2) cell2.s = columnStyle1;
      if (cell3) cell3.s = columnStyle1;
      if (cell4) cell4.s = columnStyle1;
    }

    for (let i = 0; i < 29; i++) {
      const cell2 = workSheetAuroraPostgreSQL[`${letter[i]}2`];
      const cell3 = workSheetAuroraPostgreSQL[`${letter[i]}3`];
      const cell4 = workSheetAuroraPostgreSQL[`${letter[i]}4`];
      if (cell2) cell2.s = columnStyle1;
      if (cell3) cell3.s = columnStyle1;
      if (cell4) cell4.s = columnStyle1;
    }

    for (let i = 0; i < 26; i++) {
      const cell2 = workSheetRDSforMySQL[`${letter[i]}2`];
      const cell3 = workSheetRDSforMySQL[`${letter[i]}3`];
      const cell4 = workSheetRDSforMySQL[`${letter[i]}4`];
      if (cell2) cell2.s = columnStyle1;
      if (cell3) cell3.s = columnStyle1;
      if (cell4) cell4.s = columnStyle1;
    }

    for (let i = 0; i < 26; i++) {
      const cell2 = workSheetRDSforPostgreSQL[`${letter[i]}2`];
      const cell3 = workSheetRDSforPostgreSQL[`${letter[i]}3`];
      const cell4 = workSheetRDSforPostgreSQL[`${letter[i]}4`];
      if (cell2) cell2.s = columnStyle1;
      if (cell3) cell3.s = columnStyle1;
      if (cell4) cell4.s = columnStyle1;
    }

    for (let i = 0; i < 26; i++) {
      const cell2 = workSheetRDSforMariaDB[`${letter[i]}2`];
      const cell3 = workSheetRDSforMariaDB[`${letter[i]}3`];
      const cell4 = workSheetRDSforMariaDB[`${letter[i]}4`];
      if (cell2) cell2.s = columnStyle1;
      if (cell3) cell3.s = columnStyle1;
      if (cell4) cell4.s = columnStyle1;
    }

    for (let i = 0; i < 26; i++) {
      const cell2 = workSheetRDSforOracle[`${letter[i]}2`];
      const cell3 = workSheetRDSforOracle[`${letter[i]}3`];
      const cell4 = workSheetRDSforOracle[`${letter[i]}4`];
      if (cell2) cell2.s = columnStyle1;
      if (cell3) cell3.s = columnStyle1;
      if (cell4) cell4.s = columnStyle1;
    }

    for (let i = 0; i < 26; i++) {
      const cell2 = workSheetRDSforSQLServer[`${letter[i]}2`];
      const cell3 = workSheetRDSforSQLServer[`${letter[i]}3`];
      const cell4 = workSheetRDSforSQLServer[`${letter[i]}4`];
      if (cell2) cell2.s = columnStyle1;
      if (cell3) cell3.s = columnStyle1;
      if (cell4) cell4.s = columnStyle1;
    }

    // 黄色红色
    const applyPatternStyle = (workSheet, startCol, pattern, style) => {
      const range = XLSX.utils.decode_range(workSheet['!ref']);
      let colIndex = startCol;
      let patternIndex = 0;

      while (colIndex <= range.e.c) {

        const cellAddress = XLSX.utils.encode_cell({ r: 3, c: colIndex });
        if (!workSheet[cellAddress]) {
          workSheet[cellAddress] = { t: 's', v: '' };
        }
        workSheet[cellAddress].s = { ...style, border: borderStyle };

        colIndex += pattern[patternIndex];
        patternIndex = (patternIndex + 1) % pattern.length;
      }
    };
    // 黄色间隔模式
    const pattern = [2, 4, 4, 2, 4, 4];
    const patternrdsred = [2, 4, 2, 4, 4, 2];
    const patternrdsyellow = [2, 4, 2, 4, 4, 2];
    applyPatternStyle(workSheet, 11, pattern, columnStyle2);
    applyPatternStyle(workSheetRedshift, 9, pattern, columnStyle2);
    applyPatternStyle(workSheetAuroraMySQL, 11, pattern, columnStyle2);
    applyPatternStyle(workSheetAuroraPostgreSQL, 11, pattern, columnStyle2);
    applyPatternStyle(workSheetRDSforMySQL, 14, patternrdsyellow, columnStyle2);
    applyPatternStyle(workSheetRDSforPostgreSQL, 14, patternrdsyellow, columnStyle2);
    applyPatternStyle(workSheetRDSforMariaDB, 14, patternrdsyellow, columnStyle2);
    applyPatternStyle(workSheetRDSforOracle, 14, patternrdsyellow, columnStyle2);
    applyPatternStyle(workSheetRDSforSQLServer, 14, patternrdsyellow, columnStyle2);

    //应用红色
    applyPatternStyle(workSheet, 12, pattern, columnStyleRed);
    applyPatternStyle(workSheetRedshift, 10, pattern, columnStyleRed);
    applyPatternStyle(workSheetAuroraMySQL, 12, pattern, columnStyleRed);
    applyPatternStyle(workSheetAuroraPostgreSQL, 12, pattern, columnStyleRed);
    applyPatternStyle(workSheetRDSforMySQL, 15, patternrdsred, columnStyleRed);
    applyPatternStyle(workSheetRDSforPostgreSQL, 15, patternrdsred, columnStyleRed);
    applyPatternStyle(workSheetRDSforMariaDB, 15, patternrdsred, columnStyleRed);
    applyPatternStyle(workSheetRDSforOracle, 15, patternrdsred, columnStyleRed);
    applyPatternStyle(workSheetRDSforSQLServer, 15, patternrdsred, columnStyleRed);

    //表头边框
    const applyBorderToMergedCells = (workSheet, merge, borderStyle) => {
      merge.forEach(({ s, e }) => {
        for (let row = s.r; row <= e.r; row++) {
          for (let col = s.c; col <= e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            if (!workSheet[cellAddress]) {
              workSheet[cellAddress] = { t: 's', v: '' };
            }
            if (!workSheet[cellAddress].s) {
              workSheet[cellAddress].s = {};
            }
            workSheet[cellAddress].s.border = borderStyle;
          }
        }
      });
    };
    applyBorderToMergedCells(workSheet, merge, borderStyle);

    const applyBorderToMergedCellsRedshift = (workSheetRedshift, redshiftMerge, borderStyle) => {
      redshiftMerge.forEach(({ s, e }) => {
        for (let row = s.r; row <= e.r; row++) {
          for (let col = s.c; col <= e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            if (!workSheetRedshift[cellAddress]) {
              workSheetRedshift[cellAddress] = { t: 's', v: '' };
            }
            if (!workSheetRedshift[cellAddress].s) {
              workSheetRedshift[cellAddress].s = {};
            }
            workSheetRedshift[cellAddress].s.border = borderStyle;
          }
        }
      });
    };
    applyBorderToMergedCellsRedshift(workSheetRedshift, redshiftMerge, borderStyle);

    const applyBorderToMergedCellsAuroraMySQL = (workSheetAuroraMySQL, auroraMySQLMerge, borderStyle) => {
      auroraMySQLMerge.forEach(({ s, e }) => {
        for (let row = s.r; row <= e.r; row++) {
          for (let col = s.c; col <= e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            if (!workSheetAuroraMySQL[cellAddress]) {
              workSheetAuroraMySQL[cellAddress] = { t: 's', v: '' };
            }
            if (!workSheetAuroraMySQL[cellAddress].s) {
              workSheetAuroraMySQL[cellAddress].s = {};
            }
            workSheetAuroraMySQL[cellAddress].s.border = borderStyle;
          }
        }
      });
    };
    applyBorderToMergedCellsAuroraMySQL(workSheetAuroraMySQL, auroraMySQLMerge, borderStyle);
    applyBorderToMergedCellsAuroraMySQL(workSheetAuroraPostgreSQL, auroraPostgreSQLMerge, borderStyle);

    const applyBorderToMergedCellsRDSforMySQL = (workSheetRDSforMySQL, RDSforMySQLMerge, borderStyle) => {
      RDSforMySQLMerge.forEach(({ s, e }) => {
        for (let row = s.r; row <= e.r; row++) {
          for (let col = s.c; col <= e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            if (!workSheetRDSforMySQL[cellAddress]) {
              workSheetRDSforMySQL[cellAddress] = { t: 's', v: '' };
            }
            if (!workSheetRDSforMySQL[cellAddress].s) {
              workSheetRDSforMySQL[cellAddress].s = {};
            }
            workSheetRDSforMySQL[cellAddress].s.border = borderStyle;
          }
        }
      });
    };
    applyBorderToMergedCellsRDSforMySQL(workSheetRDSforMySQL, RDSforMySQLMerge, borderStyle);

    const applyBorderToMergedCellsRDSforPostgreSQL = (workSheetRDSforPostgreSQL, RDSforPostgreSQLMerge, borderStyle) => {
      RDSforPostgreSQLMerge.forEach(({ s, e }) => {
        for (let row = s.r; row <= e.r; row++) {
          for (let col = s.c; col <= e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            if (!workSheetRDSforPostgreSQL[cellAddress]) {
              workSheetRDSforPostgreSQL[cellAddress] = { t: 's', v: '' };
            }
            if (!workSheetRDSforPostgreSQL[cellAddress].s) {
              workSheetRDSforPostgreSQL[cellAddress].s = {};
            }
            workSheetRDSforPostgreSQL[cellAddress].s.border = borderStyle;
          }
        }
      });
    };
    applyBorderToMergedCellsRDSforPostgreSQL(workSheetRDSforPostgreSQL, RDSforPostgreSQLMerge, borderStyle);

    const applyBorderToMergedCellsRDSforMariaDB = (workSheetRDSforMariaDB, RDSforMariaDBMerge, borderStyle) => {
      RDSforMariaDBMerge.forEach(({ s, e }) => {
        for (let row = s.r; row <= e.r; row++) {
          for (let col = s.c; col <= e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            if (!workSheetRDSforMariaDB[cellAddress]) {
              workSheetRDSforMariaDB[cellAddress] = { t: 's', v: '' };
            }
            if (!workSheetRDSforMariaDB[cellAddress].s) {
              workSheetRDSforMariaDB[cellAddress].s = {};
            }
            workSheetRDSforMariaDB[cellAddress].s.border = borderStyle;
          }
        }
      });
    };
    applyBorderToMergedCellsRDSforMariaDB(workSheetRDSforMariaDB, RDSforMariaDBMerge, borderStyle);

    const applyBorderToMergedCellsRDSforOracle = (workSheetRDSforOracle, RDSforOracleMerge, borderStyle) => {
      RDSforOracleMerge.forEach(({ s, e }) => {
        for (let row = s.r; row <= e.r; row++) {
          for (let col = s.c; col <= e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            if (!workSheetRDSforOracle[cellAddress]) {
              workSheetRDSforOracle[cellAddress] = { t: 's', v: '' };
            }
            if (!workSheetRDSforOracle[cellAddress].s) {
              workSheetRDSforOracle[cellAddress].s = {};
            }
            workSheetRDSforOracle[cellAddress].s.border = borderStyle;
          }
        }
      });
    };
    applyBorderToMergedCellsRDSforOracle(workSheetRDSforOracle, RDSforOracleMerge, borderStyle);

    const applyBorderToMergedCellsRDSforSQLServer = (workSheetRDSforSQLServer, RDSforSQLServerMerge, borderStyle) => {
      RDSforSQLServerMerge.forEach(({ s, e }) => {
        for (let row = s.r; row <= e.r; row++) {
          for (let col = s.c; col <= e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            if (!workSheetRDSforSQLServer[cellAddress]) {
              workSheetRDSforSQLServer[cellAddress] = { t: 's', v: '' };
            }
            if (!workSheetRDSforSQLServer[cellAddress].s) {
              workSheetRDSforSQLServer[cellAddress].s = {};
            }
            workSheetRDSforSQLServer[cellAddress].s.border = borderStyle;
          }
        }
      });
    };
    applyBorderToMergedCellsRDSforSQLServer(workSheetRDSforSQLServer, RDSforSQLServerMerge, borderStyle);

    const applyColumnStyle = (workSheet) => {
      const range = XLSX.utils.decode_range(workSheet['!ref']);
      const rows = [];
      for (let row = range.s.r + 4; row <= range.e.r; row++) { //跳过表头
        for (let col = range.s.c; col <= range.e.c; col++) {
          const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
          if (!workSheet[cellAddress]) {
            workSheet[cellAddress] = { t: 's', v: '' };
          }
          workSheet[cellAddress].s = columnStyle3;
        }
        rows.push({ hpt: 50 });
      }
      workSheet["!rows"] = rows;
    };
    applyColumnStyle(workSheet);

    const applyColumnStyleRedshift = (workSheetRedshift) => {
      const range = XLSX.utils.decode_range(workSheetRedshift['!ref']);
      const rows = [];
      for (let row = range.s.r + 4; row <= range.e.r; row++) { //跳过表头
        for (let col = range.s.c; col <= range.e.c; col++) {
          const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
          if (!workSheetRedshift[cellAddress]) {
            workSheetRedshift[cellAddress] = { t: 's', v: '' };
          }
          workSheetRedshift[cellAddress].s = columnStyle3;
        }
        rows.push({ hpt: 50 });
      }
      workSheetRedshift["!rows"] = rows;
    };
    applyColumnStyleRedshift(workSheetRedshift);

    const applyColumnStyleAuroraMySQL = (workSheetAuroraMySQL) => {
      const range = XLSX.utils.decode_range(workSheetAuroraMySQL['!ref']);
      const rows = [];
      for (let row = range.s.r + 4; row <= range.e.r; row++) { //跳过表头
        for (let col = range.s.c; col <= range.e.c; col++) {
          const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
          if (!workSheetAuroraMySQL[cellAddress]) {
            workSheetAuroraMySQL[cellAddress] = { t: 's', v: '' };
          }
          workSheetAuroraMySQL[cellAddress].s = columnStyle3;
        }
        rows.push({ hpt: 50 });
      }
      workSheetAuroraMySQL["!rows"] = rows;
    };
    applyColumnStyleAuroraMySQL(workSheetAuroraMySQL);
    applyColumnStyleAuroraMySQL(workSheetAuroraPostgreSQL);

    const applyColumnStyleRDSforMySQL = (workSheetRDSforMySQL) => {
      const range = XLSX.utils.decode_range(workSheetRDSforMySQL['!ref']);
      const rows = [];
      for (let row = range.s.r + 4; row <= range.e.r; row++) { //跳过表头
        let colRange = range.e.c;
        colRange -= 2;
        for (let col = range.s.c; col <= colRange; col++) {
          const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
          if (!workSheetRDSforMySQL[cellAddress]) {
            workSheetRDSforMySQL[cellAddress] = { t: 's', v: '' };
          }
          workSheetRDSforMySQL[cellAddress].s = columnStyle3;
        }
        rows.push({ hpt: 50 });
      }
      workSheetRDSforMySQL["!rows"] = rows;
    };
    applyColumnStyleRDSforMySQL(workSheetRDSforMySQL);

    const applyColumnStyleRDSforPostgreSQL = (workSheetRDSforPostgreSQL) => {
      const range = XLSX.utils.decode_range(workSheetRDSforPostgreSQL['!ref']);
      const rows = [];
      for (let row = range.s.r + 4; row <= range.e.r; row++) { //跳过表头
        let colRange = range.e.c;
        colRange -= 2;
        for (let col = range.s.c; col <= colRange; col++) {
          const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
          if (!workSheetRDSforPostgreSQL[cellAddress]) {
            workSheetRDSforPostgreSQL[cellAddress] = { t: 's', v: '' };
          }
          workSheetRDSforPostgreSQL[cellAddress].s = columnStyle3;
        }
        rows.push({ hpt: 50 });
      }
      workSheetRDSforPostgreSQL["!rows"] = rows;
    };
    applyColumnStyleRDSforPostgreSQL(workSheetRDSforPostgreSQL);

    const applyColumnStyleRDSforMariaDB = (workSheetRDSforMariaDB) => {
      const range = XLSX.utils.decode_range(workSheetRDSforMariaDB['!ref']);
      const rows = [];
      for (let row = range.s.r + 4; row <= range.e.r; row++) { //跳过表头
        let colRange = range.e.c;
        colRange -= 2;
        for (let col = range.s.c; col <= colRange; col++) {
          const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
          if (!workSheetRDSforMariaDB[cellAddress]) {
            workSheetRDSforMariaDB[cellAddress] = { t: 's', v: '' };
          }
          workSheetRDSforMariaDB[cellAddress].s = columnStyle3;
        }
        rows.push({ hpt: 50 });
      }
      workSheetRDSforMariaDB["!rows"] = rows;
    };
    applyColumnStyleRDSforMariaDB(workSheetRDSforMariaDB);

    const applyColumnStyleRDSforOracle = (workSheetRDSforOracle) => {
      const range = XLSX.utils.decode_range(workSheetRDSforOracle['!ref']);
      const rows = [];
      for (let row = range.s.r + 4; row <= range.e.r; row++) { //跳过表头
        let colRange = range.e.c;
        colRange -= 2;
        for (let col = range.s.c; col <= colRange; col++) {
          const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
          if (!workSheetRDSforOracle[cellAddress]) {
            workSheetRDSforOracle[cellAddress] = { t: 's', v: '' };
          }
          workSheetRDSforOracle[cellAddress].s = columnStyle3;
        }
        rows.push({ hpt: 50 });
      }
      workSheetRDSforOracle["!rows"] = rows;
    };
    applyColumnStyleRDSforOracle(workSheetRDSforOracle);

    const applyColumnStyleRDSforSQLServer = (workSheetRDSforSQLServer) => {
      const range = XLSX.utils.decode_range(workSheetRDSforSQLServer['!ref']);
      const rows = [];
      for (let row = range.s.r + 4; row <= range.e.r; row++) { //跳过表头
        let colRange = range.e.c;
        colRange -= 2;
        for (let col = range.s.c; col <= colRange; col++) {
          const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
          if (!workSheetRDSforSQLServer[cellAddress]) {
            workSheetRDSforSQLServer[cellAddress] = { t: 's', v: '' };
          }
          workSheetRDSforSQLServer[cellAddress].s = columnStyle3;
        }
        rows.push({ hpt: 50 });
      }
      workSheetRDSforSQLServer["!rows"] = rows;
    };
    applyColumnStyleRDSforSQLServer(workSheetRDSforSQLServer);

    const rowHeights = [
      { hpt: 65 }, // 全局默认高度设置
      { hpt: 31 },
      { hpt: 31 },
      { hpt: 31 },
    ];

    const rowHeightsRedshift = [
      { hpt: 65 }, // 全局默认高度设置
      { hpt: 31 },
      { hpt: 31 },
      { hpt: 31 },
    ];

    const rowHeightsAuroraMySQL = [
      { hpt: 65 }, // 全局默认高度设置
      { hpt: 31 },
      { hpt: 31 },
      { hpt: 31 },
    ];

    const rowHeightsAuroraPostgreSQL = [
      { hpt: 65 }, // 全局默认高度设置
      { hpt: 31 },
      { hpt: 31 },
      { hpt: 31 },
    ];

    const rowHeightsElastiCache = [
      { hpt: 25 }, // 全局默认高度设置
      { hpt: 20 },
      { hpt: 50 },
    ];
    workSheetElastiCache["!rows"] = rowHeightsElastiCache;

    const rowHeightsRDSforMySQL = [
      { hpt: 65 }, // 全局默认高度设置
      { hpt: 31 },
      { hpt: 31 },
      { hpt: 31 },
    ];

    const rowHeightsRDSforPostgreSQL = [
      { hpt: 65 }, // 全局默认高度设置
      { hpt: 31 },
      { hpt: 31 },
      { hpt: 31 },
    ];

    const rowHeightsRDSforMariaDB = [
      { hpt: 65 }, // 全局默认高度设置
      { hpt: 31 },
      { hpt: 31 },
      { hpt: 31 },
    ];

    const rowHeightsRDSforOracle = [
      { hpt: 65 }, // 全局默认高度设置
      { hpt: 31 },
      { hpt: 31 },
      { hpt: 31 },
    ];

    const rowHeightsRDSforSQLServer = [
      { hpt: 65 }, // 全局默认高度设置
      { hpt: 31 },
      { hpt: 31 },
      { hpt: 31 },
    ];

    const rowHeightsSagemaker = [
      { hpt: 25 }, // 全局默认高度设置
      { hpt: 20 },
      { hpt: 100 },
    ];
    workSheetSagemaker["!rows"] = rowHeightsSagemaker;


    for (let i = 4; i < workSheet['!rows'].length + 4; i++) { // 从第5行开始设置高度
      rowHeights.push({ hpt: 72 });
    }

    for (let i = 4; i < workSheetRedshift['!rows'].length + 4; i++) { // 从第5行开始设置高度
      rowHeightsRedshift.push({ hpt: 72 });
    }

    for (let i = 4; i < workSheetAuroraMySQL['!rows'].length + 4; i++) { // 从第5行开始设置高度
      rowHeightsAuroraMySQL.push({ hpt: 72 });
    }

    for (let i = 4; i < workSheetAuroraPostgreSQL['!rows'].length + 4; i++) { // 从第5行开始设置高度
      rowHeightsAuroraPostgreSQL.push({ hpt: 72 });
    }

    for (let i = 4; i < workSheetRDSforMySQL['!rows'].length + 4; i++) { // 从第5行开始设置高度
      rowHeightsRDSforMySQL.push({ hpt: 72 });
    }

    for (let i = 4; i < workSheetRDSforPostgreSQL['!rows'].length + 4; i++) { // 从第5行开始设置高度
      rowHeightsRDSforPostgreSQL.push({ hpt: 72 });
    }

    for (let i = 4; i < workSheetRDSforMariaDB['!rows'].length + 4; i++) { // 从第5行开始设置高度
      rowHeightsRDSforMariaDB.push({ hpt: 72 });
    }

    for (let i = 4; i < workSheetRDSforOracle['!rows'].length + 4; i++) { // 从第5行开始设置高度
      rowHeightsRDSforOracle.push({ hpt: 72 });
    }

    for (let i = 4; i < workSheetRDSforSQLServer['!rows'].length + 4; i++) { // 从第5行开始设置高度
      rowHeightsRDSforSQLServer.push({ hpt: 72 });
    }

    workSheet["!rows"] = rowHeights;
    workSheetRedshift["!rows"] = rowHeightsRedshift;
    workSheetAuroraMySQL["!rows"] = rowHeightsAuroraMySQL;
    workSheetAuroraPostgreSQL["!rows"] = rowHeightsAuroraPostgreSQL;
    workSheetRDSforMySQL["!rows"] = rowHeightsRDSforMySQL;
    workSheetRDSforPostgreSQL["!rows"] = rowHeightsRDSforPostgreSQL;
    workSheetRDSforMariaDB["!rows"] = rowHeightsRDSforMariaDB;
    workSheetRDSforOracle["!rows"] = rowHeightsRDSforOracle;
    workSheetRDSforSQLServer["!rows"] = rowHeightsRDSforSQLServer;

    console.log('before workbook ec2: ', workSheet);
    console.log('before workbook elasticcache: ', workSheetElastiCache);
    console.log('before workbook redshift: ', workSheetRedshift);
    console.log('before workbook rdsmysql: ', workSheetRDSforMySQL);
    console.log('before workbook rdspostgresql: ', workSheetRDSforPostgreSQL);
    console.log('before workbook rdsmariadb: ', workSheetRDSforMariaDB);
    console.log('before workbook rdsoracle: ', workSheetRDSforOracle);
    console.log('before workbook rdsosqlserver: ', workSheetRDSforSQLServer);


    // 1yr - OD + RI +SP
    const wsData_1yr_OnDemand_RI_Saving = [
      ["报价单"],
      ['地区', '服务', '实例类型', '配置', '网络', '数量','按需单价（月）', '按需单价（年）', 'EBS总价', '标准一年RI', '', '', '', '', '', '', '', '', '', '可转换一年RI', '', '', '', '', '', '', '', '', '', '计算一年Saving Plan', '', '', '', '', '', '', '', '', '', '实例一年Saving Plan', '', '', '', '', '', '', '', '', '', '按需单价（时）'],
      ['', '', '', '', '', '', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例',
        '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', ''],
    ];

    const instanceData_1yr_OnDemand_RI_Saving = ec2Items.flatMap(item => {
      const ebsFilters = item.product.attributes.ebsselection || {}; // 确保 ebsselection 是存在的，如果不存在则使用空对象 
      console.log('ebsFilters', ebsFilters);
      console.log('check export region: ', item.product.attributes.region);
      const config = `${item.product.attributes.operatingSystem || ''} ${item.product.attributes.vcpu || ''}vCPU ${item.product.attributes.memory || ''}`;
      const ebsconfig = `${ebsFilters.volumeSize?.amount || ''} ${ebsFilters.volumeSize?.unit || ''}`;

      return [
        [
          item.product.attributes.region,
          item.ServiceName,
          item.product.attributes.instanceType,
          config,
          item.product.attributes.networkPerformance,
          item.quantity,
          item.OnDemand_1Month * item.quantity || 0.0, //按需单价月
          item.OnDemand_1Year * item.quantity || 0.0,
          0.0,
          item.Reserved_No_Upfront_standard_1yr_per_Month * item.quantity || 0.0,
          item.UF_Reserved_No_Upfront_standard_1yr * item.quantity || 0.0,
          (item.Reserved_No_Upfront_standard_1yr_per_Month * 12) * item.quantity || 0.0,
          item.Compare_CP_RI_No_Std_1yr || 0.0,
          item.Reserved_All_Upfront_standard_1yr_per_Month * 12 * item.quantity || 0.0, //标准1年预付年
          item.Compare_CP_RI_All_Std_1yr || 0.0,
          item.Reserved_Partial_Upfront_standard_1yr_per_Month * item.quantity || 0.0,
          item.UF_Reserved_Partial_Upfront_standard_1yr * item.quantity || 0.0,
          (item.TotalPrice_TT_RI_Pt_Std_1yr * 12) * item.quantity || 0.0,
          item.Compare_CP_RI_Pt_Std_1yr || 0.0,
          item.Reserved_No_Upfront_convertible_1yr_per_Month * item.quantity || 0.0,
          item.UF_Reserved_No_Upfront_convertible_1yr * item.quantity || 0.0,
          (item.Reserved_No_Upfront_convertible_1yr_per_Month * 12) * item.quantity || 0.0,
          item.Compare_CP_RI_No_Con_1yr || 0.0,
          item.Reserved_All_Upfront_convertible_1yr_per_Month * 12 * item.quantity || 0.0, //转换1年预付年
          item.Compare_CP_RI_All_Con_1yr || 0.0,
          item.Reserved_Partial_Upfront_convertible_1yr_per_Month * item.quantity || 0.0,
          item.UF_Reserved_Partial_Upfront_convertible_1yr * item.quantity || 0.0,
          (item.TotalPrice_TT_RI_Pt_Con_1yr * 12) * item.quantity || 0.0,
          item.Compare_CP_RI_Pt_Con_1yr || 0.0,
          item.ComputeSavingsPlans_1_year_No_Upfront_per_Month * item.quantity || 0.0,
          item.UF_ComputeSavingsPlans_1_year_No_Upfront * item.quantity || 0.0,
          (item.ComputeSavingsPlans_1_year_No_Upfront_per_Month * 12) * item.quantity || 0.0,
          item.Compare_CP_SP_No_CPT_1yr || 0.0,
          item.ComputeSavingsPlans_1_year_All_Upfront_per_Month * 12 * item.quantity || 0.0, //计算1 预付年
          item.Compare_CP_SP_All_CPT_1yr || 0.0,
          item.ComputeSavingsPlans_1_year_Partial_Upfront_per_Month * item.quantity || 0.0,
          // item.UF_ComputeSavingsPlans_1_year_Partial_Upfront || 0.0,
          '已包含在单价',
          (item.ComputeSavingsPlans_1_year_Partial_Upfront_per_Month * 12) * item.quantity || 0.0,
          item.Compare_CP_SP_Pt_CPT_1yr || 0.0,
          item.EC2InstanceSavingsPlans_1_year_No_Upfront_per_Month * item.quantity || 0.0,
          item.UF_EC2InstanceSavingsPlans_1_year_No_Upfront * item.quantity || 0.0,
          (item.EC2InstanceSavingsPlans_1_year_No_Upfront_per_Month * 12) * item.quantity || 0.0,
          item.Compare_CP_SP_No_EC2_1yr || 0.0,
          item.EC2InstanceSavingsPlans_1_year_All_Upfront_per_Month * 12 * item.quantity || 0.0, // 实例1 全预付年
          item.Compare_CP_SP_All_EC2_1yr || 0.0,
          item.EC2InstanceSavingsPlans_1_year_Partial_Upfront_per_Month * item.quantity || 0.0,
          // item.UF_EC2InstanceSavingsPlans_1_year_Partial_Upfront || 0.0,
          '已包含在单价',
          (item.EC2InstanceSavingsPlans_1_year_Partial_Upfront_per_Month * 12) * item.quantity || 0.0,
          item.Compare_CP_SP_Pt_EC2_1yr || 0.0,
          parseFloat(item.terms.OnDemand.price) * item.quantity || 0.0,
        ],
        [
          item.product.attributes.region,
          'EBS',
          ebsFilters.volumeType || '',
          ebsconfig || '',
          '',
          ebsFilters.numberOfVolumes * item.quantity || 0.0,
          0.0,
          0.0,
          ebsFilters.totalprice * item.quantity * 12 || 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0
        ]
      ]
    });

    const finalData_1yr_OnDemand_RI_Saving = [...wsData_1yr_OnDemand_RI_Saving, ...instanceData_1yr_OnDemand_RI_Saving];
    const workSheet_1yr_OnDemand_RI_Saving = XLSX.utils.aoa_to_sheet(finalData_1yr_OnDemand_RI_Saving);

    const merge_1yr_OnDemand_RI_Saving = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 49 } },
      { s: { r: 1, c: 0 }, e: { r: 3, c: 0 } }, { s: { r: 1, c: 1 }, e: { r: 3, c: 1 } }, { s: { r: 1, c: 2 }, e: { r: 3, c: 2 } }, { s: { r: 1, c: 3 }, e: { r: 3, c: 3 } }, { s: { r: 1, c: 4 }, e: { r: 3, c: 4 } }, { s: { r: 1, c: 5 }, e: { r: 3, c: 5 } }, { s: { r: 1, c: 6 }, e: { r: 3, c: 6 } }, { s: { r: 1, c: 7 }, e: { r: 3, c: 7 } }, { s: { r: 1, c: 8 }, e: { r: 3, c: 8 } },
      { s: { r: 1, c: 9 }, e: { r: 1, c: 18 } },
      { s: { r: 1, c: 19 }, e: { r: 1, c: 28 } },
      { s: { r: 1, c: 29 }, e: { r: 1, c: 38 } },
      { s: { r: 1, c: 39 }, e: { r: 1, c: 48 } },
      { s: { r: 2, c: 9 }, e: { r: 2, c: 12 } },
      { s: { r: 2, c: 13 }, e: { r: 2, c: 14 } },
      { s: { r: 2, c: 15 }, e: { r: 2, c: 18 } },
      { s: { r: 2, c: 19 }, e: { r: 2, c: 22 } },
      { s: { r: 2, c: 23 }, e: { r: 2, c: 24 } },
      { s: { r: 2, c: 25 }, e: { r: 2, c: 28 } },
      { s: { r: 2, c: 29 }, e: { r: 2, c: 32 } },
      { s: { r: 2, c: 33 }, e: { r: 2, c: 34 } },
      { s: { r: 2, c: 35 }, e: { r: 2, c: 38 } },
      { s: { r: 2, c: 39 }, e: { r: 2, c: 42 } },
      { s: { r: 2, c: 43 }, e: { r: 2, c: 44 } },
      { s: { r: 2, c: 45 }, e: { r: 2, c: 48 } },
      { s: { r: 1, c: 49 }, e: { r: 3, c: 49 } },
    ];
    workSheet_1yr_OnDemand_RI_Saving["!merges"] = merge_1yr_OnDemand_RI_Saving;

    for (let i = 0; i < 60; i++) {
      const cell2 = workSheet_1yr_OnDemand_RI_Saving[`${letter[i]}2`];
      const cell3 = workSheet_1yr_OnDemand_RI_Saving[`${letter[i]}3`];
      const cell4 = workSheet_1yr_OnDemand_RI_Saving[`${letter[i]}4`];
      if (cell2) cell2.s = columnStyle1;
      if (cell3) cell3.s = columnStyle1;
      if (cell4) cell4.s = columnStyle1;
    }

    const pattern_1yr_OnDemand_RI_Saving = [2, 4, 4, 2, 4, 4];
    applyPatternStyle(workSheet_1yr_OnDemand_RI_Saving, 11, pattern_1yr_OnDemand_RI_Saving, columnStyle2);
    applyPatternStyle(workSheet_1yr_OnDemand_RI_Saving, 12, pattern_1yr_OnDemand_RI_Saving, columnStyleRed);


    const columnWidths_1yr_OnDemand_RI_Saving = [
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
    ];
    workSheet_1yr_OnDemand_RI_Saving["!cols"] = columnWidths_1yr_OnDemand_RI_Saving;
    workSheet_1yr_OnDemand_RI_Saving["A1"].s = {
      font: {
        sz: 24,
        bold: true,
      },
      alignment: {
        horizontal: "left",
        vertical: "center",
      },
      border: borderStyle
    };

    applyColumnStyle(workSheet_1yr_OnDemand_RI_Saving);

    const rowHeights_1yr_OnDemand_RI_Saving = [
      { hpt: 65 }, // 全局默认高度设置
      { hpt: 31 },
      { hpt: 31 },
      { hpt: 31 },
    ];

    for (let i = 4; i < workSheet_1yr_OnDemand_RI_Saving['!rows'].length + 4; i++) { // 从第5行开始设置高度
      rowHeights_1yr_OnDemand_RI_Saving.push({ hpt: 72 });
    }
    workSheet_1yr_OnDemand_RI_Saving["!rows"] = rowHeights;

    // 1yr - OD + RI
    const wsData_1yr_OnDemand_RI = [
      ["报价单"],
      ['地区', '服务', '实例类型', '配置', '网络', '数量','按需单价（月）', '按需单价（年）', 'EBS总价', '标准一年RI', '', '', '', '', '', '', '', '', '', '可转换一年RI', '', '', '', '', '', '', '', '', '', '按需单价（时）'],
      ['', '', '', '', '', '', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', ''],
    ];

    const instanceData_1yr_OnDemand_RI = ec2Items.flatMap(item => {
      const ebsFilters = item.product.attributes.ebsselection || {}; // 确保 ebsselection 是存在的，如果不存在则使用空对象 
      console.log('ebsFilters', ebsFilters);
      console.log('check export region: ', item.product.attributes.region);
      const config = `${item.product.attributes.operatingSystem || ''} ${item.product.attributes.vcpu || ''}vCPU ${item.product.attributes.memory || ''}`;
      const ebsconfig = `${ebsFilters.volumeSize?.amount || ''} ${ebsFilters.volumeSize?.unit || ''}`;

      return [
        [
          item.product.attributes.region,
          item.ServiceName,
          item.product.attributes.instanceType,
          config,
          item.product.attributes.networkPerformance,
          item.quantity,
          item.OnDemand_1Month * item.quantity || 0.0, //按需单价月
          item.OnDemand_1Year * item.quantity || 0.0,
          0.0,
          item.Reserved_No_Upfront_standard_1yr_per_Month * item.quantity || 0.0,
          item.UF_Reserved_No_Upfront_standard_1yr * item.quantity || 0.0,
          (item.Reserved_No_Upfront_standard_1yr_per_Month * 12) * item.quantity || 0.0,
          item.Compare_CP_RI_No_Std_1yr || 0.0,
          item.Reserved_All_Upfront_standard_1yr_per_Month * 12 * item.quantity || 0.0, //标准1年预付年
          item.Compare_CP_RI_All_Std_1yr || 0.0,
          item.Reserved_Partial_Upfront_standard_1yr_per_Month * item.quantity || 0.0,
          item.UF_Reserved_Partial_Upfront_standard_1yr * item.quantity || 0.0,
          (item.TotalPrice_TT_RI_Pt_Std_1yr * 12) * item.quantity || 0.0,
          item.Compare_CP_RI_Pt_Std_1yr || 0.0,
          item.Reserved_No_Upfront_convertible_1yr_per_Month * item.quantity || 0.0,
          item.UF_Reserved_No_Upfront_convertible_1yr * item.quantity || 0.0,
          (item.Reserved_No_Upfront_convertible_1yr_per_Month * 12) * item.quantity || 0.0,
          item.Compare_CP_RI_No_Con_1yr || 0.0,
          item.Reserved_All_Upfront_convertible_1yr_per_Month * 12 * item.quantity || 0.0, //转换1年预付年
          item.Compare_CP_RI_All_Con_1yr || 0.0,
          item.Reserved_Partial_Upfront_convertible_1yr_per_Month * item.quantity || 0.0,
          item.UF_Reserved_Partial_Upfront_convertible_1yr * item.quantity || 0.0,
          (item.TotalPrice_TT_RI_Pt_Con_1yr * 12) * item.quantity || 0.0,
          item.Compare_CP_RI_Pt_Con_1yr || 0.0,
          parseFloat(item.terms.OnDemand.price) * item.quantity || 0.0,
        ],
        [
          item.product.attributes.region,
          'EBS',
          ebsFilters.volumeType || '',
          ebsconfig || '',
          '',
          ebsFilters.numberOfVolumes * item.quantity || 0.0,
          0.0,
          0.0,
          ebsFilters.totalprice * item.quantity * 12 || 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0
        ]
      ]
    });

    const finalData_1yr_OnDemand_RI = [...wsData_1yr_OnDemand_RI, ...instanceData_1yr_OnDemand_RI];
    const workSheet_1yr_OnDemand_RI = XLSX.utils.aoa_to_sheet(finalData_1yr_OnDemand_RI);

    const merge_1yr_OnDemand_RI = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 29 } },
      { s: { r: 1, c: 0 }, e: { r: 3, c: 0 } }, { s: { r: 1, c: 1 }, e: { r: 3, c: 1 } }, { s: { r: 1, c: 2 }, e: { r: 3, c: 2 } }, { s: { r: 1, c: 3 }, e: { r: 3, c: 3 } }, { s: { r: 1, c: 4 }, e: { r: 3, c: 4 } }, { s: { r: 1, c: 5 }, e: { r: 3, c: 5 } }, { s: { r: 1, c: 6 }, e: { r: 3, c: 6 } }, { s: { r: 1, c: 7 }, e: { r: 3, c: 7 } }, { s: { r: 1, c: 8 }, e: { r: 3, c: 8 } },
      { s: { r: 1, c: 9 }, e: { r: 1, c: 18 } },
      { s: { r: 1, c: 19 }, e: { r: 1, c: 28 } },
      { s: { r: 2, c: 9 }, e: { r: 2, c: 12 } },
      { s: { r: 2, c: 13 }, e: { r: 2, c: 14 } },
      { s: { r: 2, c: 15 }, e: { r: 2, c: 18 } },
      { s: { r: 2, c: 19 }, e: { r: 2, c: 22 } },
      { s: { r: 2, c: 23 }, e: { r: 2, c: 24 } },
      { s: { r: 2, c: 25 }, e: { r: 2, c: 28 } },
      { s: { r: 1, c: 29 }, e: { r: 3, c: 29 } },
    ];
    workSheet_1yr_OnDemand_RI["!merges"] = merge_1yr_OnDemand_RI;

    for (let i = 0; i < 40; i++) {
      const cell2 = workSheet_1yr_OnDemand_RI[`${letter[i]}2`];
      const cell3 = workSheet_1yr_OnDemand_RI[`${letter[i]}3`];
      const cell4 = workSheet_1yr_OnDemand_RI[`${letter[i]}4`];
      if (cell2) cell2.s = columnStyle1;
      if (cell3) cell3.s = columnStyle1;
      if (cell4) cell4.s = columnStyle1;
    }

    const pattern_1yr_OnDemand_RI = [2, 4, 4, 2, 4, 4];
    applyPatternStyle(workSheet_1yr_OnDemand_RI, 11, pattern_1yr_OnDemand_RI, columnStyle2);
    applyPatternStyle(workSheet_1yr_OnDemand_RI, 12, pattern_1yr_OnDemand_RI, columnStyleRed);

    const columnWidths_1yr_OnDemand_RI = [
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
    ];
    workSheet_1yr_OnDemand_RI["!cols"] = columnWidths_1yr_OnDemand_RI;

    workSheet_1yr_OnDemand_RI["A1"].s = {
      font: {
        sz: 24,
        bold: true,
      },
      alignment: {
        horizontal: "left",
        vertical: "center",
      },
      border: borderStyle
    };

    applyColumnStyle(workSheet_1yr_OnDemand_RI);

    const rowHeights_1yr_OnDemand_RI = [
      { hpt: 65 }, // 全局默认高度设置
      { hpt: 31 },
      { hpt: 31 },
      { hpt: 31 },
    ];

    for (let i = 4; i < workSheet_1yr_OnDemand_RI['!rows'].length + 4; i++) { // 从第5行开始设置高度
      rowHeights_1yr_OnDemand_RI.push({ hpt: 72 });
    }
    workSheet_1yr_OnDemand_RI["!rows"] = rowHeights_1yr_OnDemand_RI;

    //1yr - OnDemand + SavingPlans
    const wsData_1yr_OnDemand_Saving = [
      ["报价单"],
      ['地区', '服务', '实例类型', '配置', '网络', '数量','按需单价（月）', '按需单价（年）', 'EBS总价', '计算一年Saving Plan', '', '', '', '', '', '', '', '', '', '实例一年Saving Plan', '', '', '', '', '', '', '', '', '', '按需单价（时）'],
      ['', '', '', '', '', '', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', ''],
    ];

    const instanceData_1yr_OnDemand_Saving = ec2Items.flatMap(item => {
      const ebsFilters = item.product.attributes.ebsselection || {}; // 确保 ebsselection 是存在的，如果不存在则使用空对象 
      console.log('ebsFilters', ebsFilters);
      console.log('check export region: ', item.product.attributes.region);
      const config = `${item.product.attributes.operatingSystem || ''} ${item.product.attributes.vcpu || ''}vCPU ${item.product.attributes.memory || ''}`;
      const ebsconfig = `${ebsFilters.volumeSize?.amount || ''} ${ebsFilters.volumeSize?.unit || ''}`;

      return [
        [
          item.product.attributes.region,
          item.ServiceName,
          item.product.attributes.instanceType,
          config,
          item.product.attributes.networkPerformance,
          item.quantity,
          item.OnDemand_1Month * item.quantity || 0.0, //按需单价月
          item.OnDemand_1Year * item.quantity || 0.0,
          0.0,
          item.ComputeSavingsPlans_1_year_No_Upfront_per_Month * item.quantity || 0.0,
          item.UF_ComputeSavingsPlans_1_year_No_Upfront * item.quantity || 0.0,
          (item.ComputeSavingsPlans_1_year_No_Upfront_per_Month * 12) * item.quantity || 0.0,
          item.Compare_CP_SP_No_CPT_1yr || 0.0,
          item.ComputeSavingsPlans_1_year_All_Upfront_per_Month * 12 * item.quantity || 0.0, //计算1 预付年
          item.Compare_CP_SP_All_CPT_1yr || 0.0,
          item.ComputeSavingsPlans_1_year_Partial_Upfront_per_Month * item.quantity || 0.0,
          // item.UF_ComputeSavingsPlans_1_year_Partial_Upfront || 0.0,
          '已包含在单价',
          (item.ComputeSavingsPlans_1_year_Partial_Upfront_per_Month * 12) * item.quantity || 0.0,
          item.Compare_CP_SP_Pt_CPT_1yr || 0.0,
          item.EC2InstanceSavingsPlans_1_year_No_Upfront_per_Month * item.quantity || 0.0,
          item.UF_EC2InstanceSavingsPlans_1_year_No_Upfront * item.quantity || 0.0,
          (item.EC2InstanceSavingsPlans_1_year_No_Upfront_per_Month * 12) * item.quantity || 0.0,
          item.Compare_CP_SP_No_EC2_1yr || 0.0,
          item.EC2InstanceSavingsPlans_1_year_All_Upfront_per_Month * 12 * item.quantity || 0.0, // 实例1 全预付年
          item.Compare_CP_SP_All_EC2_1yr || 0.0,
          item.EC2InstanceSavingsPlans_1_year_Partial_Upfront_per_Month * item.quantity || 0.0,
          // item.UF_EC2InstanceSavingsPlans_1_year_Partial_Upfront || 0.0,
          '已包含在单价',
          (item.EC2InstanceSavingsPlans_1_year_Partial_Upfront_per_Month * 12) * item.quantity || 0.0,
          item.Compare_CP_SP_Pt_EC2_1yr || 0.0,
          parseFloat(item.terms.OnDemand.price) * item.quantity || 0.0,
        ],
        [
          item.product.attributes.region,
          'EBS',
          ebsFilters.volumeType || '',
          ebsconfig || '',
          '',
          ebsFilters.numberOfVolumes * item.quantity || 0.0,
          0.0,
          0.0,
          ebsFilters.totalprice * item.quantity * 12 || 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0
        ]
      ]
    });

    const finalData_1yr_OnDemand_Saving = [...wsData_1yr_OnDemand_Saving, ...instanceData_1yr_OnDemand_Saving];
    const workSheet_1yr_OnDemand_Saving = XLSX.utils.aoa_to_sheet(finalData_1yr_OnDemand_Saving);

    const merge_1yr_OnDemand_Saving = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 29 } },
      { s: { r: 1, c: 0 }, e: { r: 3, c: 0 } }, { s: { r: 1, c: 1 }, e: { r: 3, c: 1 } }, { s: { r: 1, c: 2 }, e: { r: 3, c: 2 } }, { s: { r: 1, c: 3 }, e: { r: 3, c: 3 } }, { s: { r: 1, c: 4 }, e: { r: 3, c: 4 } }, { s: { r: 1, c: 5 }, e: { r: 3, c: 5 } }, { s: { r: 1, c: 6 }, e: { r: 3, c: 6 } }, { s: { r: 1, c: 7 }, e: { r: 3, c: 7 } }, { s: { r: 1, c: 8 }, e: { r: 3, c: 8 } },
      { s: { r: 1, c: 9 }, e: { r: 1, c: 18 } },
      { s: { r: 1, c: 19 }, e: { r: 1, c: 28 } },
      { s: { r: 2, c: 9 }, e: { r: 2, c: 12 } },
      { s: { r: 2, c: 13 }, e: { r: 2, c: 14 } },
      { s: { r: 2, c: 15 }, e: { r: 2, c: 18 } },
      { s: { r: 2, c: 19 }, e: { r: 2, c: 22 } },
      { s: { r: 2, c: 23 }, e: { r: 2, c: 24 } },
      { s: { r: 2, c: 25 }, e: { r: 2, c: 28 } },
      { s: { r: 1, c: 29 }, e: { r: 3, c: 29 } },
    ];
    workSheet_1yr_OnDemand_Saving["!merges"] = merge_1yr_OnDemand_Saving;

    for (let i = 0; i < 30; i++) {
      const cell2 = workSheet_1yr_OnDemand_Saving[`${letter[i]}2`];
      const cell3 = workSheet_1yr_OnDemand_Saving[`${letter[i]}3`];
      const cell4 = workSheet_1yr_OnDemand_Saving[`${letter[i]}4`];
      if (cell2) cell2.s = columnStyle1;
      if (cell3) cell3.s = columnStyle1;
      if (cell4) cell4.s = columnStyle1;
    }

    const pattern_1yr_OnDemand_Saving = [2, 4, 4, 2, 4, 4];
    applyPatternStyle(workSheet_1yr_OnDemand_Saving, 11, pattern_1yr_OnDemand_Saving, columnStyle2);
    applyPatternStyle(workSheet_1yr_OnDemand_Saving, 12, pattern_1yr_OnDemand_Saving, columnStyleRed);

    const columnWidths_1yr_OnDemand_Saving = [
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
    ];
    workSheet_1yr_OnDemand_Saving["!cols"] = columnWidths_1yr_OnDemand_Saving;

    workSheet_1yr_OnDemand_Saving["A1"].s = {
      font: {
        sz: 24,
        bold: true,
      },
      alignment: {
        horizontal: "left",
        vertical: "center",
      },
      border: borderStyle
    };

    applyColumnStyle(workSheet_1yr_OnDemand_Saving);

    const rowHeights_1yr_OnDemand_Saving = [
      { hpt: 65 }, // 全局默认高度设置
      { hpt: 31 },
      { hpt: 31 },
      { hpt: 31 },
    ];

    for (let i = 4; i < workSheet_1yr_OnDemand_Saving['!rows'].length + 4; i++) { // 从第5行开始设置高度
      rowHeights_1yr_OnDemand_Saving.push({ hpt: 72 });
    }
    workSheet_1yr_OnDemand_Saving["!rows"] = rowHeights_1yr_OnDemand_Saving;

    // 3yr - OnDemand + RI + Saving Plans
    const wsData_3yr_OnDemand_RI_Saving = [
      ["报价单"],
      ['地区', '服务', '实例类型', '配置', '网络', '数量','按需单价（月）', '按需单价（年）', 'EBS总价', '标准三年RI', '', '', '', '', '', '', '', '', '', '可转换三年RI', '', '', '', '', '', '', '', '', '', '计算三年Saving Plan', '', '', '', '', '', '', '', '', '', '实例三年Saving Plan', '', '', '', '', '', '', '', '', '', '按需单价（时）'],
      ['', '', '', '', '', '', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例',
        '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', ''],
    ];

    const instanceData_3yr_OnDemand_RI_Saving = ec2Items.flatMap(item => {
      const ebsFilters = item.product.attributes.ebsselection || {}; // 确保 ebsselection 是存在的，如果不存在则使用空对象 
      console.log('ebsFilters', ebsFilters);
      console.log('check export region: ', item.product.attributes.region);
      const config = `${item.product.attributes.operatingSystem || ''} ${item.product.attributes.vcpu || ''}vCPU ${item.product.attributes.memory || ''}`;
      const ebsconfig = `${ebsFilters.volumeSize?.amount || ''} ${ebsFilters.volumeSize?.unit || ''}`;

      return [
        [
          item.product.attributes.region,
          item.ServiceName,
          item.product.attributes.instanceType,
          config,
          item.product.attributes.networkPerformance,
          item.quantity,
          item.OnDemand_1Month * item.quantity || 0.0, //按需单价月
          item.OnDemand_1Year * item.quantity || 0.0,
          0.0,
          item.Reserved_No_Upfront_standard_3yr_per_Month * item.quantity || 0.0,
          item.UF_Reserved_No_Upfront_standard_3yr * item.quantity || 0.0,
          (item.Reserved_No_Upfront_standard_3yr_per_Month * 12 * 3) * item.quantity || 0.0,
          item.Compare_CP_RI_No_Std_3yr || 0.0,
          item.Reserved_All_Upfront_standard_3yr_per_Month * 12 * 3 * item.quantity || 0.0, //标准3年预付年
          item.Compare_CP_RI_All_Std_3yr || 0.0,
          item.Reserved_Partial_Upfront_standard_3yr_per_Month * item.quantity || 0.0,
          item.UF_Reserved_Partial_Upfront_standard_3yr * item.quantity || 0.0,
          (item.TotalPrice_TT_RI_Pt_Std_3yr * 12 * 3) * item.quantity || 0.0,
          item.Compare_CP_RI_Pt_Std_3yr || 0.0,
          item.Reserved_No_Upfront_convertible_3yr_per_Month * item.quantity || 0.0,
          item.UF_Reserved_No_Upfront_convertible_3yr * item.quantity || 0.0,
          (item.Reserved_No_Upfront_convertible_3yr_per_Month * 12 * 3) * item.quantity || 0.0,
          item.Compare_CP_RI_No_Con_3yr || 0.0,
          item.Reserved_All_Upfront_convertible_3yr_per_Month * 12 * 3 * item.quantity || 0.0, //转换3年预付年
          item.Compare_CP_RI_All_Con_3yr || 0.0,
          item.Reserved_Partial_Upfront_convertible_3yr_per_Month * item.quantity || 0.0,
          item.UF_Reserved_Partial_Upfront_convertible_3yr * item.quantity || 0.0,
          (item.TotalPrice_TT_RI_Pt_Con_3yr * 12 * 3) * item.quantity || 0.0,
          item.Compare_CP_RI_Pt_Con_3yr || 0.0,
          item.ComputeSavingsPlans_3_year_No_Upfront_per_Month * item.quantity || 0.0,
          item.UF_ComputeSavingsPlans_3_year_No_Upfront * item.quantity || 0.0,
          (item.ComputeSavingsPlans_3_year_No_Upfront_per_Month * 12 * 3) * item.quantity || 0.0,
          item.Compare_CP_SP_No_CPT_3yr || 0.0,
          item.ComputeSavingsPlans_3_year_All_Upfront_per_Month * 12 * 3 * item.quantity || 0.0, //计算3 预付年
          item.Compare_CP_SP_All_CPT_3yr || 0.0,
          item.ComputeSavingsPlans_3_year_Partial_Upfront_per_Month * item.quantity || 0.0,
          '已包含在单价',
          (item.ComputeSavingsPlans_3_year_Partial_Upfront_per_Month * 12 * 3) * item.quantity || 0.0,
          item.Compare_CP_SP_Pt_CPT_3yr || 0.0,
          item.EC2InstanceSavingsPlans_3_year_No_Upfront_per_Month * item.quantity || 0.0,
          item.UF_EC2InstanceSavingsPlans_3_year_No_Upfront * item.quantity || 0.0,
          (item.EC2InstanceSavingsPlans_3_year_No_Upfront_per_Month * 12 * 3) * item.quantity || 0.0,
          item.Compare_CP_SP_No_EC2_3yr || 0.0,
          item.EC2InstanceSavingsPlans_3_year_All_Upfront_per_Month * 12 * 3 * item.quantity || 0.0, // 实例3 全预付年
          item.Compare_CP_SP_All_EC2_3yr || 0.0,
          item.EC2InstanceSavingsPlans_3_year_Partial_Upfront_per_Month * item.quantity || 0.0,
          '已包含在单价',
          (item.EC2InstanceSavingsPlans_3_year_Partial_Upfront_per_Month * 12 * 3) * item.quantity || 0.0,
          item.Compare_CP_SP_Pt_EC2_3yr || 0.0,
          parseFloat(item.terms.OnDemand.price) * item.quantity || 0.0,
        ],
        [
          item.product.attributes.region,
          'EBS',
          ebsFilters.volumeType || '',
          ebsconfig || '',
          '',
          ebsFilters.numberOfVolumes * item.quantity || 0.0,
          0.0,
          0.0,
          ebsFilters.totalprice * item.quantity * 12 || 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0
        ]
      ]
    });

    const finalData_3yr_OnDemand_RI_Saving = [...wsData_3yr_OnDemand_RI_Saving, ...instanceData_3yr_OnDemand_RI_Saving];
    const workSheet_3yr_OnDemand_RI_Saving = XLSX.utils.aoa_to_sheet(finalData_3yr_OnDemand_RI_Saving);

    const merge_3yr_OnDemand_RI_Saving = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 49 } },
      { s: { r: 1, c: 0 }, e: { r: 3, c: 0 } }, { s: { r: 1, c: 1 }, e: { r: 3, c: 1 } }, { s: { r: 1, c: 2 }, e: { r: 3, c: 2 } }, { s: { r: 1, c: 3 }, e: { r: 3, c: 3 } }, { s: { r: 1, c: 4 }, e: { r: 3, c: 4 } }, { s: { r: 1, c: 5 }, e: { r: 3, c: 5 } }, { s: { r: 1, c: 6 }, e: { r: 3, c: 6 } }, { s: { r: 1, c: 7 }, e: { r: 3, c: 7 } }, { s: { r: 1, c: 8 }, e: { r: 3, c: 8 } },
      { s: { r: 1, c: 9 }, e: { r: 1, c: 18 } },
      { s: { r: 1, c: 19 }, e: { r: 1, c: 28 } },
      { s: { r: 1, c: 29 }, e: { r: 1, c: 38 } },
      { s: { r: 1, c: 39 }, e: { r: 1, c: 48 } },
      { s: { r: 2, c: 9 }, e: { r: 2, c: 12 } },
      { s: { r: 2, c: 13 }, e: { r: 2, c: 14 } },
      { s: { r: 2, c: 15 }, e: { r: 2, c: 18 } },
      { s: { r: 2, c: 19 }, e: { r: 2, c: 22 } },
      { s: { r: 2, c: 23 }, e: { r: 2, c: 24 } },
      { s: { r: 2, c: 25 }, e: { r: 2, c: 28 } },
      { s: { r: 2, c: 29 }, e: { r: 2, c: 32 } },
      { s: { r: 2, c: 33 }, e: { r: 2, c: 34 } },
      { s: { r: 2, c: 35 }, e: { r: 2, c: 38 } },
      { s: { r: 2, c: 39 }, e: { r: 2, c: 42 } },
      { s: { r: 2, c: 43 }, e: { r: 2, c: 44 } },
      { s: { r: 2, c: 45 }, e: { r: 2, c: 48 } },
      { s: { r: 1, c: 49 }, e: { r: 3, c: 49 } },
    ];
    workSheet_3yr_OnDemand_RI_Saving["!merges"] = merge_3yr_OnDemand_RI_Saving;

    for (let i = 0; i < 50; i++) {
      const cell2 = workSheet_3yr_OnDemand_RI_Saving[`${letter[i]}2`];
      const cell3 = workSheet_3yr_OnDemand_RI_Saving[`${letter[i]}3`];
      const cell4 = workSheet_3yr_OnDemand_RI_Saving[`${letter[i]}4`];
      if (cell2) cell2.s = columnStyle1;
      if (cell3) cell3.s = columnStyle1;
      if (cell4) cell4.s = columnStyle1;
    }

    const pattern_3yr_OnDemand_RI_Saving = [2, 4, 4, 2, 4, 4];
    applyPatternStyle(workSheet_3yr_OnDemand_RI_Saving, 11, pattern_3yr_OnDemand_RI_Saving, columnStyle2);
    applyPatternStyle(workSheet_3yr_OnDemand_RI_Saving, 12, pattern_3yr_OnDemand_RI_Saving, columnStyleRed);

    const columnWidths_3yr_OnDemand_RI_Saving = [
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
    ];
    workSheet_3yr_OnDemand_RI_Saving["!cols"] = columnWidths_3yr_OnDemand_RI_Saving;

    workSheet_3yr_OnDemand_RI_Saving["A1"].s = {
      font: {
        sz: 24,
        bold: true,
      },
      alignment: {
        horizontal: "left",
        vertical: "center",
      },
      border: borderStyle
    };

    applyColumnStyle(workSheet_3yr_OnDemand_RI_Saving);

    const rowHeights_3yr_OnDemand_RI_Saving = [
      { hpt: 65 }, // 全局默认高度设置
      { hpt: 31 },
      { hpt: 31 },
      { hpt: 31 },
    ];

    for (let i = 4; i < workSheet_3yr_OnDemand_RI_Saving['!rows'].length + 4; i++) { // 从第5行开始设置高度
      rowHeights_3yr_OnDemand_RI_Saving.push({ hpt: 72 });
    }
    workSheet_3yr_OnDemand_RI_Saving["!rows"] = rowHeights_3yr_OnDemand_RI_Saving;

    // 3yr - OD + RI
    const wsData_3yr_OnDemand_RI = [
      ["报价单"],
      ['地区', '服务', '实例类型', '配置', '网络', '数量','按需单价（月）', '按需单价（年）', 'EBS总价', '标准三年RI', '', '', '', '', '', '', '', '', '', '可转换三年RI', '', '', '', '', '', '', '', '', '', '按需单价（时）'],
      ['', '', '', '', '', '', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', ''],
    ];

    const instanceData_3yr_OnDemand_RI = ec2Items.flatMap(item => {
      const ebsFilters = item.product.attributes.ebsselection || {}; // 确保 ebsselection 是存在的，如果不存在则使用空对象 
      console.log('ebsFilters', ebsFilters);
      console.log('check export region: ', item.product.attributes.region);
      const config = `${item.product.attributes.operatingSystem || ''} ${item.product.attributes.vcpu || ''}vCPU ${item.product.attributes.memory || ''}`;
      const ebsconfig = `${ebsFilters.volumeSize?.amount || ''} ${ebsFilters.volumeSize?.unit || ''}`;

      return [
        [
          item.product.attributes.region,
          item.ServiceName,
          item.product.attributes.instanceType,
          config,
          item.product.attributes.networkPerformance,
          item.quantity,
          item.OnDemand_1Month * item.quantity || 0.0, //按需单价月
          item.OnDemand_1Year * item.quantity || 0.0,
          0.0,
          item.Reserved_No_Upfront_standard_3yr_per_Month * item.quantity || 0.0,
          item.UF_Reserved_No_Upfront_standard_3yr * item.quantity || 0.0,
          (item.Reserved_No_Upfront_standard_3yr_per_Month * 12 * 3) * item.quantity || 0.0,
          item.Compare_CP_RI_No_Std_3yr || 0.0,
          item.Reserved_All_Upfront_standard_3yr_per_Month * 12 * 3 * item.quantity || 0.0, //标准3年预付年
          item.Compare_CP_RI_All_Std_3yr || 0.0,
          item.Reserved_Partial_Upfront_standard_3yr_per_Month * item.quantity || 0.0,
          item.UF_Reserved_Partial_Upfront_standard_3yr * item.quantity || 0.0,
          (item.TotalPrice_TT_RI_Pt_Std_3yr * 12 * 3) * item.quantity || 0.0,
          item.Compare_CP_RI_Pt_Std_3yr || 0.0,
          item.Reserved_No_Upfront_convertible_3yr_per_Month * item.quantity || 0.0,
          item.UF_Reserved_No_Upfront_convertible_3yr * item.quantity || 0.0,
          (item.Reserved_No_Upfront_convertible_3yr_per_Month * 12 * 3) * item.quantity || 0.0,
          item.Compare_CP_RI_No_Con_3yr || 0.0,
          item.Reserved_All_Upfront_convertible_3yr_per_Month * 12 * 3 * item.quantity || 0.0, //转换3年预付年
          item.Compare_CP_RI_All_Con_3yr || 0.0,
          item.Reserved_Partial_Upfront_convertible_3yr_per_Month * item.quantity || 0.0,
          item.UF_Reserved_Partial_Upfront_convertible_3yr * item.quantity || 0.0,
          (item.TotalPrice_TT_RI_Pt_Con_3yr * 12 * 3) * item.quantity || 0.0,
          item.Compare_CP_RI_Pt_Con_3yr || 0.0,
          parseFloat(item.terms.OnDemand.price) * item.quantity || 0.0,
        ],
        [
          item.product.attributes.region,
          'EBS',
          ebsFilters.volumeType || '',
          ebsconfig || '',
          '',
          ebsFilters.numberOfVolumes * item.quantity || 0.0,
          0.0,
          0.0,
          ebsFilters.totalprice * item.quantity * 12 || 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0
        ]
      ]
    });

    const finalData_3yr_OnDemand_RI = [...wsData_3yr_OnDemand_RI, ...instanceData_3yr_OnDemand_RI];
    const workSheet_3yr_OnDemand_RI = XLSX.utils.aoa_to_sheet(finalData_3yr_OnDemand_RI);

    const merge_3yr_OnDemand_RI = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 29 } },
      { s: { r: 1, c: 0 }, e: { r: 3, c: 0 } }, { s: { r: 1, c: 1 }, e: { r: 3, c: 1 } }, { s: { r: 1, c: 2 }, e: { r: 3, c: 2 } }, { s: { r: 1, c: 3 }, e: { r: 3, c: 3 } }, { s: { r: 1, c: 4 }, e: { r: 3, c: 4 } }, { s: { r: 1, c: 5 }, e: { r: 3, c: 5 } }, { s: { r: 1, c: 6 }, e: { r: 3, c: 6 } }, { s: { r: 1, c: 7 }, e: { r: 3, c: 7 } }, { s: { r: 1, c: 8 }, e: { r: 3, c: 8 } },
      { s: { r: 1, c: 9 }, e: { r: 1, c: 18 } },
      { s: { r: 1, c: 19 }, e: { r: 1, c: 28 } },
      { s: { r: 2, c: 9 }, e: { r: 2, c: 12 } },
      { s: { r: 2, c: 13 }, e: { r: 2, c: 14 } },
      { s: { r: 2, c: 15 }, e: { r: 2, c: 18 } },
      { s: { r: 2, c: 19 }, e: { r: 2, c: 22 } },
      { s: { r: 2, c: 23 }, e: { r: 2, c: 24 } },
      { s: { r: 2, c: 25 }, e: { r: 2, c: 28 } },
      { s: { r: 1, c: 29 }, e: { r: 3, c: 29 } },
    ];
    workSheet_3yr_OnDemand_RI["!merges"] = merge_3yr_OnDemand_RI;

    for (let i = 0; i < 40; i++) {
      const cell2 = workSheet_3yr_OnDemand_RI[`${letter[i]}2`];
      const cell3 = workSheet_3yr_OnDemand_RI[`${letter[i]}3`];
      const cell4 = workSheet_3yr_OnDemand_RI[`${letter[i]}4`];
      if (cell2) cell2.s = columnStyle1;
      if (cell3) cell3.s = columnStyle1;
      if (cell4) cell4.s = columnStyle1;
    }

    const pattern_3yr_OnDemand_RI = [2, 4, 4, 2, 4, 4];
    applyPatternStyle(workSheet_3yr_OnDemand_RI, 11, pattern_3yr_OnDemand_RI, columnStyle2);
    applyPatternStyle(workSheet_3yr_OnDemand_RI, 12, pattern_3yr_OnDemand_RI, columnStyleRed);

    const columnWidths_3yr_OnDemand_RI = [
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
    ];
    workSheet_3yr_OnDemand_RI["!cols"] = columnWidths_3yr_OnDemand_RI;

    workSheet_3yr_OnDemand_RI["A1"].s = {
      font: {
        sz: 24,
        bold: true,
      },
      alignment: {
        horizontal: "left",
        vertical: "center",
      },
      border: borderStyle
    };

    applyColumnStyle(workSheet_3yr_OnDemand_RI);

    const rowHeights_3yr_OnDemand_RI = [
      { hpt: 65 }, // 全局默认高度设置
      { hpt: 31 },
      { hpt: 31 },
      { hpt: 31 },
    ];

    for (let i = 4; i < workSheet_3yr_OnDemand_RI['!rows'].length + 4; i++) { // 从第5行开始设置高度
      rowHeights_3yr_OnDemand_RI.push({ hpt: 72 });
    }
    workSheet_3yr_OnDemand_RI["!rows"] = rowHeights_3yr_OnDemand_RI;

    // 3yr - OD + SavingPlans
    const wsData_3yr_OnDemand_Saving = [
      ["报价单"],
      ['地区', '服务', '实例类型', '配置', '网络', '数量','按需单价（月）', '按需单价（年）', 'EBS总价', '计算三年Saving Plan', '', '', '', '', '', '', '', '', '', '实例三年Saving Plan', '', '', '', '', '', '', '', '', '', '按需单价（时）'],
      ['', '', '', '', '', '', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（年）', '对比按需实例', ''],
    ];

    const instanceData_3yr_OnDemand_Saving = ec2Items.flatMap(item => {
      const ebsFilters = item.product.attributes.ebsselection || {}; // 确保 ebsselection 是存在的，如果不存在则使用空对象 
      console.log('ebsFilters', ebsFilters);
      console.log('check export region: ', item.product.attributes.region);
      const config = `${item.product.attributes.operatingSystem || ''} ${item.product.attributes.vcpu || ''}vCPU ${item.product.attributes.memory || ''}`;
      const ebsconfig = `${ebsFilters.volumeSize?.amount || ''} ${ebsFilters.volumeSize?.unit || ''}`;

      return [
        [
          item.product.attributes.region,
          item.ServiceName,
          item.product.attributes.instanceType,
          config,
          item.product.attributes.networkPerformance,
          item.quantity,
          item.OnDemand_1Month * item.quantity || 0.0, //按需单价月
          item.OnDemand_1Year * item.quantity || 0.0,
          0.0,
          item.ComputeSavingsPlans_3_year_No_Upfront_per_Month * item.quantity || 0.0,
          item.UF_ComputeSavingsPlans_3_year_No_Upfront * item.quantity || 0.0,
          (item.ComputeSavingsPlans_3_year_No_Upfront_per_Month * 12 * 3) * item.quantity || 0.0,
          item.Compare_CP_SP_No_CPT_3yr || 0.0,
          item.ComputeSavingsPlans_3_year_All_Upfront_per_Month * 12 * 3 * item.quantity || 0.0, //计算3年预付年
          item.Compare_CP_SP_All_CPT_3yr || 0.0,
          item.ComputeSavingsPlans_3_year_Partial_Upfront_per_Month * item.quantity || 0.0,
          // item.UF_ComputeSavingsPlans_3_year_Partial_Upfront || 0.0,
          '已包含在单价',
          (item.ComputeSavingsPlans_3_year_Partial_Upfront_per_Month * 12 * 3) * item.quantity || 0.0,
          item.Compare_CP_SP_Pt_CPT_3yr || 0.0,
          item.EC2InstanceSavingsPlans_3_year_No_Upfront_per_Month * item.quantity || 0.0,
          item.UF_EC2InstanceSavingsPlans_3_year_No_Upfront * item.quantity || 0.0,
          (item.EC2InstanceSavingsPlans_3_year_No_Upfront_per_Month * 12 * 3) * item.quantity || 0.0,
          item.Compare_CP_SP_No_EC2_3yr || 0.0,
          item.EC2InstanceSavingsPlans_3_year_All_Upfront_per_Month * 12 * 3 * item.quantity || 0.0, // 实例3年预付年
          item.Compare_CP_SP_All_EC2_3yr || 0.0,
          item.EC2InstanceSavingsPlans_3_year_Partial_Upfront_per_Month * item.quantity || 0.0,
          // item.UF_EC2InstanceSavingsPlans_3_year_Partial_Upfront || 0.0,
          '已包含在单价',
          (item.EC2InstanceSavingsPlans_3_year_Partial_Upfront_per_Month * 12 * 3) * item.quantity || 0.0,
          item.Compare_CP_SP_Pt_EC2_3yr || 0.0,
          parseFloat(item.terms.OnDemand.price) * item.quantity || 0.0,
        ],
        [
          item.product.attributes.region,
          'EBS',
          ebsFilters.volumeType || '',
          ebsconfig || '',
          '',
          ebsFilters.numberOfVolumes * item.quantity || 0.0,
          0.0,
          0.0,
          ebsFilters.totalprice * item.quantity * 12 || 0.0,
          0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0
        ]
      ]
    });

    const finalData_3yr_OnDemand_Saving = [...wsData_3yr_OnDemand_Saving, ...instanceData_3yr_OnDemand_Saving];
    const workSheet_3yr_OnDemand_Saving = XLSX.utils.aoa_to_sheet(finalData_3yr_OnDemand_Saving);

    const merge_3yr_OnDemand_Saving = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 29 } },
      { s: { r: 1, c: 0 }, e: { r: 3, c: 0 } }, { s: { r: 1, c: 1 }, e: { r: 3, c: 1 } }, { s: { r: 1, c: 2 }, e: { r: 3, c: 2 } }, { s: { r: 1, c: 3 }, e: { r: 3, c: 3 } }, { s: { r: 1, c: 4 }, e: { r: 3, c: 4 } }, { s: { r: 1, c: 5 }, e: { r: 3, c: 5 } }, { s: { r: 1, c: 6 }, e: { r: 3, c: 6 } }, { s: { r: 1, c: 7 }, e: { r: 3, c: 7 } }, { s: { r: 1, c: 8 }, e: { r: 3, c: 8 } },
      { s: { r: 1, c: 9 }, e: { r: 1, c: 18 } },
      { s: { r: 1, c: 19 }, e: { r: 1, c: 28 } },
      { s: { r: 2, c: 9 }, e: { r: 2, c: 12 } },
      { s: { r: 2, c: 13 }, e: { r: 2, c: 14 } },
      { s: { r: 2, c: 15 }, e: { r: 2, c: 18 } },
      { s: { r: 2, c: 19 }, e: { r: 2, c: 22 } },
      { s: { r: 2, c: 23 }, e: { r: 2, c: 24 } },
      { s: { r: 2, c: 25 }, e: { r: 2, c: 28 } },
      { s: { r: 1, c: 29 }, e: { r: 3, c: 29 } },
    ];
    workSheet_3yr_OnDemand_Saving["!merges"] = merge_3yr_OnDemand_Saving;

    for (let i = 0; i < 40; i++) {
      const cell2 = workSheet_3yr_OnDemand_Saving[`${letter[i]}2`];
      const cell3 = workSheet_3yr_OnDemand_Saving[`${letter[i]}3`];
      const cell4 = workSheet_3yr_OnDemand_Saving[`${letter[i]}4`];
      if (cell2) cell2.s = columnStyle1;
      if (cell3) cell3.s = columnStyle1;
      if (cell4) cell4.s = columnStyle1;
    }

    const pattern_3yr_OnDemand_Saving = [2, 4, 4, 2, 4, 4];
    applyPatternStyle(workSheet_3yr_OnDemand_Saving, 11, pattern_3yr_OnDemand_Saving, columnStyle2);
    applyPatternStyle(workSheet_3yr_OnDemand_Saving, 12, pattern_3yr_OnDemand_Saving, columnStyleRed);

    const columnWidths_3yr_OnDemand_Saving = [
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
    ];
    workSheet_3yr_OnDemand_Saving["!cols"] = columnWidths_3yr_OnDemand_Saving;

    workSheet_3yr_OnDemand_Saving["A1"].s = {
      font: {
        sz: 24,
        bold: true,
      },
      alignment: {
        horizontal: "left",
        vertical: "center",
      },
      border: borderStyle
    };

    applyColumnStyle(workSheet_3yr_OnDemand_Saving);

    const rowHeights_3yr_OnDemand_Saving = [
      { hpt: 65 }, // 全局默认高度设置
      { hpt: 31 },
      { hpt: 31 },
      { hpt: 31 },
    ];

    for (let i = 4; i < workSheet_3yr_OnDemand_Saving['!rows'].length + 4; i++) { // 从第5行开始设置高度
      rowHeights_3yr_OnDemand_Saving.push({ hpt: 72 });
    }
    workSheet_3yr_OnDemand_Saving["!rows"] = rowHeights_3yr_OnDemand_Saving;

    // 1yr - OD + RI - AuroraMySQL
    const AuroraMySQLData_1yr_OD_RI = [
      ["报价单 AuroraMySQL"],
      ['地区', '服务', '实例类型', '配置', '数量', '按需单价（月）', '无服务器单价（月）', 'RDS 代理', '数据库存储', '预留一年RI', '', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', ''],
      ['', '', '', '', '', '', '', '', '', '单价（月）', '预付费用（年）', '总价（1年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（1年）', '对比按需实例'],
      ...AuroraMySQLItems.map(item => [
        item.product.attributes.region,
        item.ServiceName,
        item.product.attributes.instanceType,
        `${item.product.attributes.vcpu || 'N/A '}vCPU ${item.product.attributes.memory || ''} ${item.product.attributes.clusterConfig || ''}`,
        item.quantity,
        item.OnDemand_1Month * item.quantity || 0.0,
        item.ServerlessPrice * item.quantity|| 0.0,
        item.RDS_Proxy_Price * item.quantity|| 0.0,
        item.Total_Storage_Cost * item.quantity|| 0.0,
        item.Reserved_No_Upfront_1yr_per_Month * item.quantity || 0.0,  //1无
        item.UF_Reserved_No_Upfront_1yr * item.quantity || 0.0,
        (item.Reserved_No_Upfront_1yr_per_Month * 12 * item.quantity).toFixed(2) || 0.0,
        item.Compare_CP_RI_No_1yr || 0.0, // 1no
        item.UF_Reserved_All_Upfront_1yr * item.quantity || 0.0,
        item.Compare_CP_RI_All_1yr || 0.0,
        item.Reserved_Partial_Upfront_1yr_per_Month * item.quantity || 0.0, // 1部分
        item.UF_Reserved_Partial_Upfront_1yr * item.quantity || 0.0,
        item.Reserved_Partial_Upfront_1yr_per_Month * 12 * item.quantity || 0.0,
        item.Compare_CP_RI_Pt_1yr || 0.0,
      ]),
    ];
    const workSheet_1yr_OnDemand_RI_AuroraMySQL = XLSX.utils.aoa_to_sheet(AuroraMySQLData_1yr_OD_RI);

    const merge_1yr_OnDemand_RI_AuroraMySQL = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 18 } },
      { s: { r: 1, c: 0 }, e: { r: 3, c: 0 } }, { s: { r: 1, c: 1 }, e: { r: 3, c: 1 } }, { s: { r: 1, c: 2 }, e: { r: 3, c: 2 } }, { s: { r: 1, c: 3 }, e: { r: 3, c: 3 } }, { s: { r: 1, c: 4 }, e: { r: 3, c: 4 } }, { s: { r: 1, c: 5 }, e: { r: 3, c: 5 } }, { s: { r: 1, c: 6 }, e: { r: 3, c: 6 } }, { s: { r: 1, c: 7 }, e: { r: 3, c: 7 } }, { s: { r: 1, c: 8 }, e: { r: 3, c: 8 } },
      { s: { r: 1, c: 9 }, e: { r: 1, c: 18 } },
      { s: { r: 2, c: 9 }, e: { r: 2, c: 12 } },
      { s: { r: 2, c: 13 }, e: { r: 2, c: 14 } },
      { s: { r: 2, c: 15 }, e: { r: 2, c: 18 } },
    ];
    workSheet_1yr_OnDemand_RI_AuroraMySQL["!merges"] = merge_1yr_OnDemand_RI_AuroraMySQL;

    for (let i = 0; i < 20; i++) {
      const cell2 = workSheet_1yr_OnDemand_RI_AuroraMySQL[`${letter[i]}2`];
      const cell3 = workSheet_1yr_OnDemand_RI_AuroraMySQL[`${letter[i]}3`];
      const cell4 = workSheet_1yr_OnDemand_RI_AuroraMySQL[`${letter[i]}4`];
      if (cell2) cell2.s = columnStyle1;
      if (cell3) cell3.s = columnStyle1;
      if (cell4) cell4.s = columnStyle1;
    }

    const pattern_1yr_OnDemand_RI_AuroraMySQL = [2, 4, 4, 2, 4, 4];
    applyPatternStyle(workSheet_1yr_OnDemand_RI_AuroraMySQL, 11, pattern_1yr_OnDemand_RI_AuroraMySQL, columnStyle2);
    applyPatternStyle(workSheet_1yr_OnDemand_RI_AuroraMySQL, 12, pattern_1yr_OnDemand_RI_AuroraMySQL, columnStyleRed);

    const columnWidths_1yr_OnDemand_RI_AuroraMySQL = [
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 20 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }
    ];
    workSheet_1yr_OnDemand_RI_AuroraMySQL["!cols"] = columnWidths_1yr_OnDemand_RI_AuroraMySQL;

    workSheet_1yr_OnDemand_RI_AuroraMySQL["A1"].s = {
      font: {
        sz: 24,
        bold: true,
      },
      alignment: {
        horizontal: "left",
        vertical: "center",
      },
      border: borderStyle
    };

    applyColumnStyle(workSheet_1yr_OnDemand_RI_AuroraMySQL);

    const rowHeights_1yr_OnDemand_RI_AuroraMySQL = [
      { hpt: 65 }, // 全局默认高度设置
      { hpt: 31 },
      { hpt: 31 },
      { hpt: 31 },
    ];

    for (let i = 4; i < workSheet_1yr_OnDemand_RI_AuroraMySQL['!rows'].length + 4; i++) { // 从第5行开始设置高度
      rowHeights_1yr_OnDemand_RI_AuroraMySQL.push({ hpt: 72 });
    }
    workSheet_1yr_OnDemand_RI_AuroraMySQL["!rows"] = rowHeights_1yr_OnDemand_RI_AuroraMySQL;

    // 3yr - OD + RI
    const AuroraMySQLData_3yr_OD_RI = [
      ["报价单 AuroraMySQL"],
      ['地区', '服务', '实例类型', '配置', '数量', '按需单价（月）', '无服务器单价（月）', 'RDS 代理', '数据库存储', '预留三年RI', '', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', ''],
      ['', '', '', '', '', '', '', '', '', '单价（月）', '预付费用（三年）', '总价（三年）', '对比按需实例', '预付费用（三年）', '对比按需实例', '单价（月）', '预付费用（三年）', '总价（三年）', '对比按需实例'],
      ...AuroraMySQLItems.map(item => [
        item.product.attributes.region,
        item.ServiceName,
        item.product.attributes.instanceType,
        `${item.product.attributes.vcpu || 'N/A '}vCPU ${item.product.attributes.memory || ''} ${item.product.attributes.clusterConfig || ''}`,
        item.quantity,
        item.OnDemand_1Month * item.quantity || 0.0,
        item.ServerlessPrice * item.quantity || 0.0,
        item.RDS_Proxy_Price * item.quantity || 0.0,
        item.Total_Storage_Cost * item.quantity || 0.0,
        item.Reserved_No_Upfront_3yr_per_Month * item.quantity || 0.0,  // 3 无
        item.UF_Reserved_No_Upfront_3yr * item.quantity || 0.0,
        (item.Reserved_No_Upfront_3yr_per_Month * 36 * item.quantity).toFixed(2) || 0.0,
        item.Compare_CP_RI_No_3yr || 0.0, // 3 no
        item.UF_Reserved_All_Upfront_3yr * item.quantity || 0.0,
        item.Compare_CP_RI_All_3yr || 0.0,
        item.Reserved_Partial_Upfront_3yr_per_Month * item.quantity || 0.0, // 3 部分
        item.UF_Reserved_Partial_Upfront_3yr * item.quantity || 0.0,
        item.Reserved_Partial_Upfront_3yr_per_Month * 36 * item.quantity || 0.0,
        item.Compare_CP_RI_Pt_3yr || 0.0,
      ]),
    ];
    const workSheet_3yr_OnDemand_RI_AuroraMySQL = XLSX.utils.aoa_to_sheet(AuroraMySQLData_3yr_OD_RI);

    const merge_3yr_OnDemand_RI_AuroraMySQL = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 18 } },
      { s: { r: 1, c: 0 }, e: { r: 3, c: 0 } }, { s: { r: 1, c: 1 }, e: { r: 3, c: 1 } }, { s: { r: 1, c: 2 }, e: { r: 3, c: 2 } }, { s: { r: 1, c: 3 }, e: { r: 3, c: 3 } }, { s: { r: 1, c: 4 }, e: { r: 3, c: 4 } }, { s: { r: 1, c: 5 }, e: { r: 3, c: 5 } }, { s: { r: 1, c: 6 }, e: { r: 3, c: 6 } }, { s: { r: 1, c: 7 }, e: { r: 3, c: 7 } }, { s: { r: 1, c: 8 }, e: { r: 3, c: 8 } },
      { s: { r: 1, c: 9 }, e: { r: 1, c: 18 } },
      { s: { r: 2, c: 9 }, e: { r: 2, c: 12 } },
      { s: { r: 2, c: 13 }, e: { r: 2, c: 14 } },
      { s: { r: 2, c: 15 }, e: { r: 2, c: 18 } },
    ];
    workSheet_3yr_OnDemand_RI_AuroraMySQL["!merges"] = merge_3yr_OnDemand_RI_AuroraMySQL;

    for (let i = 0; i < 20; i++) {
      const cell2 = workSheet_3yr_OnDemand_RI_AuroraMySQL[`${letter[i]}2`];
      const cell3 = workSheet_3yr_OnDemand_RI_AuroraMySQL[`${letter[i]}3`];
      const cell4 = workSheet_3yr_OnDemand_RI_AuroraMySQL[`${letter[i]}4`];
      if (cell2) cell2.s = columnStyle1;
      if (cell3) cell3.s = columnStyle1;
      if (cell4) cell4.s = columnStyle1;
    }

    const pattern_3yr_OnDemand_RI_AuroraMySQL = [2, 4, 4, 2, 4, 4];
    applyPatternStyle(workSheet_3yr_OnDemand_RI_AuroraMySQL, 11, pattern_3yr_OnDemand_RI_AuroraMySQL, columnStyle2);
    applyPatternStyle(workSheet_3yr_OnDemand_RI_AuroraMySQL, 12, pattern_3yr_OnDemand_RI_AuroraMySQL, columnStyleRed);

    const columnWidths_3yr_OnDemand_RI_AuroraMySQL = [
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 20 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }
    ];
    workSheet_3yr_OnDemand_RI_AuroraMySQL["!cols"] = columnWidths_3yr_OnDemand_RI_AuroraMySQL;

    workSheet_3yr_OnDemand_RI_AuroraMySQL["A1"].s = {
      font: {
        sz: 24,
        bold: true,
      },
      alignment: {
        horizontal: "left",
        vertical: "center",
      },
      border: borderStyle
    };

    applyColumnStyle(workSheet_3yr_OnDemand_RI_AuroraMySQL);

    const rowHeights_3yr_OnDemand_RI_AuroraMySQL = [
      { hpt: 65 }, // 全局默认高度设置
      { hpt: 31 },
      { hpt: 31 },
      { hpt: 31 },
    ];

    for (let i = 4; i < workSheet_3yr_OnDemand_RI_AuroraMySQL['!rows'].length + 4; i++) { // 从第5行开始设置高度
      rowHeights_3yr_OnDemand_RI_AuroraMySQL.push({ hpt: 72 });
    }
    workSheet_3yr_OnDemand_RI_AuroraMySQL["!rows"] = rowHeights_3yr_OnDemand_RI_AuroraMySQL;

    // 1yr - OD + RI - AuroraPostgreSQL
    const AuroraPostgreSQLData_1yr_OD_RI = [
      ["报价单 AuroraPostgreSQL"],
      ['地区', '服务', '实例类型', '配置', '数量', '按需单价（月）', '无服务器单价（月）', 'RDS 代理', '数据库存储', '预留一年RI', '', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', ''],
      ['', '', '', '', '', '', '', '', '', '单价（月）', '预付费用（年）', '总价（1年）', '对比按需实例', '预付费用（年）', '对比按需实例', '单价（月）', '预付费用（年）', '总价（1年）', '对比按需实例'],
      ...AuroraPostgreSQLItems.map(item => [
        item.product.attributes.region,
        item.ServiceName,
        item.product.attributes.instanceType,
        `${item.product.attributes.vcpu || 'N/A '}vCPU ${item.product.attributes.memory || ''} ${item.product.attributes.clusterConfig || ''}`,
        item.quantity,
        item.OnDemand_1Month * item.quantity || 0.0,
        item.ServerlessPrice * item.quantity || 0.0,
        item.RDS_Proxy_Price * item.quantity || 0.0,
        item.Total_Storage_Cost * item.quantity || 0.0,
        item.Reserved_No_Upfront_1yr_per_Month * item.quantity || 0.0,  //1无
        item.UF_Reserved_No_Upfront_1yr * item.quantity || 0.0,
        (item.Reserved_No_Upfront_1yr_per_Month * 12 * item.quantity).toFixed(2) || 0.0,
        item.Compare_CP_RI_No_1yr || 0.0, // 1no
        item.UF_Reserved_All_Upfront_1yr * item.quantity || 0.0,
        item.Compare_CP_RI_All_1yr || 0.0,
        item.Reserved_Partial_Upfront_1yr_per_Month * item.quantity || 0.0, // 1部分
        item.UF_Reserved_Partial_Upfront_1yr * item.quantity || 0.0,
        item.Reserved_Partial_Upfront_1yr_per_Month * 12 * item.quantity || 0.0,
        item.Compare_CP_RI_Pt_1yr || 0.0,
      ]),
    ];

    const workSheet_1yr_OnDemand_RI_AuroraPostgreSQL = XLSX.utils.aoa_to_sheet(AuroraPostgreSQLData_1yr_OD_RI);

    const merge_1yr_OnDemand_RI_AuroraPostgreSQL = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 18 } },
      { s: { r: 1, c: 0 }, e: { r: 3, c: 0 } }, { s: { r: 1, c: 1 }, e: { r: 3, c: 1 } }, { s: { r: 1, c: 2 }, e: { r: 3, c: 2 } }, { s: { r: 1, c: 3 }, e: { r: 3, c: 3 } }, { s: { r: 1, c: 4 }, e: { r: 3, c: 4 } }, { s: { r: 1, c: 5 }, e: { r: 3, c: 5 } }, { s: { r: 1, c: 6 }, e: { r: 3, c: 6 } }, { s: { r: 1, c: 7 }, e: { r: 3, c: 7 } }, { s: { r: 1, c: 8 }, e: { r: 3, c: 8 } },
      { s: { r: 1, c: 9 }, e: { r: 1, c: 18 } },
      { s: { r: 2, c: 9 }, e: { r: 2, c: 12 } },
      { s: { r: 2, c: 13 }, e: { r: 2, c: 14 } },
      { s: { r: 2, c: 15 }, e: { r: 2, c: 18 } },
    ];
    workSheet_1yr_OnDemand_RI_AuroraPostgreSQL["!merges"] = merge_1yr_OnDemand_RI_AuroraPostgreSQL;

    for (let i = 0; i < 20; i++) {
      const cell2 = workSheet_1yr_OnDemand_RI_AuroraPostgreSQL[`${letter[i]}2`];
      const cell3 = workSheet_1yr_OnDemand_RI_AuroraPostgreSQL[`${letter[i]}3`];
      const cell4 = workSheet_1yr_OnDemand_RI_AuroraPostgreSQL[`${letter[i]}4`];
      if (cell2) cell2.s = columnStyle1;
      if (cell3) cell3.s = columnStyle1;
      if (cell4) cell4.s = columnStyle1;
    }

    const pattern_1yr_OnDemand_RI_AuroraPostgreSQL = [2, 4, 4, 2, 4, 4];
    applyPatternStyle(workSheet_1yr_OnDemand_RI_AuroraPostgreSQL, 11, pattern_1yr_OnDemand_RI_AuroraPostgreSQL, columnStyle2);
    applyPatternStyle(workSheet_1yr_OnDemand_RI_AuroraPostgreSQL, 12, pattern_1yr_OnDemand_RI_AuroraPostgreSQL, columnStyleRed);

    const columnWidths_1yr_OnDemand_RI_AuroraPostgreSQL = [
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 20 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }
    ];
    workSheet_1yr_OnDemand_RI_AuroraPostgreSQL["!cols"] = columnWidths_1yr_OnDemand_RI_AuroraPostgreSQL;

    workSheet_1yr_OnDemand_RI_AuroraPostgreSQL["A1"].s = {
      font: {
        sz: 24,
        bold: true,
      },
      alignment: {
        horizontal: "left",
        vertical: "center",
      },
      border: borderStyle
    };

    applyColumnStyle(workSheet_1yr_OnDemand_RI_AuroraPostgreSQL);

    const rowHeights_1yr_OnDemand_RI_AuroraPostgreSQL = [
      { hpt: 65 }, // 全局默认高度设置
      { hpt: 31 },
      { hpt: 31 },
      { hpt: 31 },
    ];

    for (let i = 4; i < workSheet_1yr_OnDemand_RI_AuroraPostgreSQL['!rows'].length + 4; i++) { // 从第5行开始设置高度
      rowHeights_1yr_OnDemand_RI_AuroraPostgreSQL.push({ hpt: 72 });
    }
    workSheet_1yr_OnDemand_RI_AuroraPostgreSQL["!rows"] = rowHeights_1yr_OnDemand_RI_AuroraPostgreSQL;

    // 3yr - OD + RI
    const AuroraPostgreSQLData_3yr_OD_RI = [
      ["报价单 AuroraPostgreSQL"],
      ['地区', '服务', '实例类型', '配置', '数量', '按需单价（月）', '无服务器单价（月）', 'RDS 代理', '数据库存储', '预留三年RI', '', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', '无预付', '', '', '', '全预付', '', '部分预付', '', '', ''],
      ['', '', '', '', '', '', '', '', '', '单价（月）', '预付费用（三年）', '总价（3年）', '对比按需实例', '预付费用（三年）', '对比按需实例', '单价（月）', '预付费用（三年）', '总价（3年）', '对比按需实例'],
      ...AuroraPostgreSQLItems.map(item => [
        item.product.attributes.region,
        item.ServiceName,
        item.product.attributes.instanceType,
        `${item.product.attributes.vcpu || 'N/A '}vCPU ${item.product.attributes.memory || ''} ${item.product.attributes.clusterConfig || ''}`,
        item.quantity,
        item.OnDemand_1Month * item.quantity || 0.0,
        item.ServerlessPrice * item.quantity || 0.0,
        item.RDS_Proxy_Price * item.quantity || 0.0,
        item.Total_Storage_Cost * item.quantity || 0.0,
        item.Reserved_No_Upfront_3yr_per_Month * item.quantity || 0.0,  //3无
        item.UF_Reserved_No_Upfront_3yr * item.quantity || 0.0,
        (item.Reserved_No_Upfront_3yr_per_Month * 12 * 3 * item.quantity).toFixed(2) || 0.0,
        item.Compare_CP_RI_No_3yr || 0.0, // 3no
        item.UF_Reserved_All_Upfront_3yr * item.quantity || 0.0,
        item.Compare_CP_RI_All_3yr || 0.0,
        item.Reserved_Partial_Upfront_3yr_per_Month * item.quantity || 0.0, // 3部分
        item.UF_Reserved_Partial_Upfront_3yr * item.quantity || 0.0,
        (item.Reserved_Partial_Upfront_3yr_per_Month * 12 * 3 * item.quantity).toFixed(2) || 0.0,
        item.Compare_CP_RI_Pt_3yr || 0.0,
      ]),
    ];

    const workSheet_3yr_OnDemand_RI_AuroraPostgreSQL = XLSX.utils.aoa_to_sheet(AuroraPostgreSQLData_3yr_OD_RI);

    const merge_3yr_OnDemand_RI_AuroraPostgreSQL = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 18 } },
      { s: { r: 1, c: 0 }, e: { r: 3, c: 0 } }, { s: { r: 1, c: 1 }, e: { r: 3, c: 1 } }, { s: { r: 1, c: 2 }, e: { r: 3, c: 2 } }, { s: { r: 1, c: 3 }, e: { r: 3, c: 3 } }, { s: { r: 1, c: 4 }, e: { r: 3, c: 4 } }, { s: { r: 1, c: 5 }, e: { r: 3, c: 5 } }, { s: { r: 1, c: 6 }, e: { r: 3, c: 6 } }, { s: { r: 1, c: 7 }, e: { r: 3, c: 7 } }, { s: { r: 1, c: 8 }, e: { r: 3, c: 8 } },
      { s: { r: 1, c: 9 }, e: { r: 1, c: 18 } },
      { s: { r: 2, c: 9 }, e: { r: 2, c: 12 } },
      { s: { r: 2, c: 13 }, e: { r: 2, c: 14 } },
      { s: { r: 2, c: 15 }, e: { r: 2, c: 18 } },
    ];
    workSheet_3yr_OnDemand_RI_AuroraPostgreSQL["!merges"] = merge_3yr_OnDemand_RI_AuroraPostgreSQL;

    for (let i = 0; i < 20; i++) {
      const cell2 = workSheet_3yr_OnDemand_RI_AuroraPostgreSQL[`${letter[i]}2`];
      const cell3 = workSheet_3yr_OnDemand_RI_AuroraPostgreSQL[`${letter[i]}3`];
      const cell4 = workSheet_3yr_OnDemand_RI_AuroraPostgreSQL[`${letter[i]}4`];
      if (cell2) cell2.s = columnStyle1;
      if (cell3) cell3.s = columnStyle1;
      if (cell4) cell4.s = columnStyle1;
    }

    const pattern_3yr_OnDemand_RI_AuroraPostgreSQL = [2, 4, 4, 2, 4, 4];
    applyPatternStyle(workSheet_3yr_OnDemand_RI_AuroraPostgreSQL, 11, pattern_3yr_OnDemand_RI_AuroraPostgreSQL, columnStyle2);
    applyPatternStyle(workSheet_3yr_OnDemand_RI_AuroraPostgreSQL, 12, pattern_3yr_OnDemand_RI_AuroraPostgreSQL, columnStyleRed);

    const columnWidths_3yr_OnDemand_RI_AuroraPostgreSQL = [
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 20 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 },
      { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }, { wch: 18 }
    ];
    workSheet_3yr_OnDemand_RI_AuroraPostgreSQL["!cols"] = columnWidths_3yr_OnDemand_RI_AuroraPostgreSQL;

    workSheet_3yr_OnDemand_RI_AuroraPostgreSQL["A1"].s = {
      font: {
        sz: 24,
        bold: true,
      },
      alignment: {
        horizontal: "left",
        vertical: "center",
      },
      border: borderStyle
    };

    applyColumnStyle(workSheet_3yr_OnDemand_RI_AuroraPostgreSQL);

    const rowHeights_3yr_OnDemand_RI_AuroraPostgreSQL = [
      { hpt: 65 }, // 全局默认高度设置
      { hpt: 31 },
      { hpt: 31 },
      { hpt: 31 },
    ];

    for (let i = 4; i < workSheet_3yr_OnDemand_RI_AuroraPostgreSQL['!rows'].length + 4; i++) { // 从第5行开始设置高度
      rowHeights_3yr_OnDemand_RI_AuroraPostgreSQL.push({ hpt: 72 });
    }
    workSheet_3yr_OnDemand_RI_AuroraPostgreSQL["!rows"] = rowHeights_3yr_OnDemand_RI_AuroraPostgreSQL;

    switch (selectedOptionAuroraPostgreSQL) {
      case "all":
        exportWorkSheetAuroraPostgreSQL = workSheetAuroraPostgreSQL;
        break;
      case "1yr-ondemand-ri":
        exportWorkSheetAuroraPostgreSQL = workSheet_1yr_OnDemand_RI_AuroraPostgreSQL;
        break;
      case "3yr-ondemand-ri":
        exportWorkSheetAuroraPostgreSQL = workSheet_3yr_OnDemand_RI_AuroraPostgreSQL;
        break;
    }

    switch (selectedOptionAuroraMySQL) {
      case "all":
        exportWorkSheetAuroraMySQL = workSheetAuroraMySQL;
        break;
      case "1yr-ondemand-ri":
        exportWorkSheetAuroraMySQL = workSheet_1yr_OnDemand_RI_AuroraMySQL;
        break;
      case "3yr-ondemand-ri":
        exportWorkSheetAuroraMySQL = workSheet_3yr_OnDemand_RI_AuroraMySQL;
        break;
    }

    switch (selectedOptionRDSforMySQL) { // Add this switch statement
      case "all":
        exportWorkSheetRDSforMySQL = workSheetRDSforMySQL; // Assign the appropriate worksheet
        break;
      // Add other cases based on your export options for RDSforMySQL
      default:
        break;
    }

    switch (selectedOptionRDSforPostgreSQL) { // Add this switch statement
      case "all":
        exportWorkSheetRDSforPostgreSQL = workSheetRDSforPostgreSQL; // Assign the appropriate worksheet
        break;
      // Add other cases based on your export options for RDSforPostgreSQL
      default:
        break;
    }

    switch (selectedOptionRDSforMariaDB) { // Add this switch statement
      case "all":
        exportWorkSheetRDSforMariaDB = workSheetRDSforMariaDB; // Assign the appropriate worksheet
        break;
      // Add other cases based on your export options for RDSforMariaDB
      default:
        break;
    }

    switch (selectedOptionRDSforOracle) { // Add this switch statement
      case "all":
        exportWorkSheetRDSforOracle = workSheetRDSforOracle; // Assign the appropriate worksheet
        break;
      // Add other cases based on your export options for RDSforOracle
      default:
        break;
    }

    switch (selectedOptionRDSforSQLServer) { // Add this switch statement
      case "all":
        exportWorkSheetRDSforSQLServer = workSheetRDSforSQLServer; // Assign the appropriate worksheet
        break;
      // Add other cases based on your export options for RDSforSQLServer
      default:
        break;
    }


    switch (selectedOption) {
      case "all":
        exportWorksheet = workSheet;
        break;
      case "1yr-ondemand-ri-saving":
        exportWorksheet = workSheet_1yr_OnDemand_RI_Saving;
        break;
      case "1yr-ondemand-ri":
        exportWorksheet = workSheet_1yr_OnDemand_RI;
        break;
      case "1yr-ondemand-saving":
        exportWorksheet = workSheet_1yr_OnDemand_Saving;
        break;
      case "3yr-ondemand-ri-saving":
        exportWorksheet = workSheet_3yr_OnDemand_RI_Saving;
        break;
      case "3yr-ondemand-ri":
        exportWorksheet = workSheet_3yr_OnDemand_RI;
        break;
      case "3yr-ondemand-saving":
        exportWorksheet = workSheet_3yr_OnDemand_Saving;
        break;
    }

    const workBook = {
      Sheets: {
        "EC2 Items": exportWorksheet,
        "ElastiCache Items": workSheetElastiCache,
        "Redshift Items": workSheetRedshift,
        "AuroraMySQL Items": exportWorkSheetAuroraMySQL,
        "AuroraPostgreSQL Items": exportWorkSheetAuroraPostgreSQL,
        "RDSforMySQL Items": exportWorkSheetRDSforMySQL,
        "RDSforPostgreSQL Items": exportWorkSheetRDSforPostgreSQL,
        "RDSforMariaDB Items": exportWorkSheetRDSforMariaDB,
        "RDSforOracle Items": exportWorkSheetRDSforOracle,
        "RDSforSQLServer Items": exportWorkSheetRDSforSQLServer,
        "SageMaker Items": workSheetSagemaker,
      },
      SheetNames: ["EC2 Items", "ElastiCache Items", "Redshift Items", "AuroraMySQL Items", "AuroraPostgreSQL Items","RDSforMySQL Items", "RDSforPostgreSQL Items", "RDSforMariaDB Items", "RDSforOracle Items", "RDSforSQLServer Items", "SageMaker Items"],
    };
    const excelBuffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });
    console.log('Generated excelBuffer:', excelBuffer);

    const fileData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(fileData, 'cart_Items' + fileExtension);
  };

  return (
    <Layout>
      <Container
        header={
          <Header variant="h2" description="">
            Cart Items
          </Header>
        }
      >
        <SpaceBetween direction="vertical" size="s">
          <Box className="header-flex">
            <span className="note">
              Note: You can select more purchasing options when exporting from the cart page.
            </span>
            <Box>
              <Button variant="primary" onClick={handleExport}>
                Export
              </Button>
              <Button onClick={handleClearCart}>
                Clear All
              </Button>
            </Box>
          </Box>
          <SpaceBetween size="l">
            {cartItems.length > 0 ? renderTables() : <Container textAlign="center">Your cart is empty.</Container>}
          </SpaceBetween>
        </SpaceBetween>
      </Container>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  cartItems: state.cart.cartItems
});

const mapDispatchToProps = (dispatch) => ({
  removeFromCart: (sku) => dispatch(removeFromCart(sku)),
  updateCartItemQuantity: (sku, quantity) => dispatch(updateCartItemQuantity(sku, quantity))
});

export default connect(mapStateToProps, mapDispatchToProps)(CartPage);